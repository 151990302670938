import { Link } from 'react-router-dom';

const PFAAgremeent = () => {
    return (
        <div className="w-100 pb-3">
            <span className="c-grey-700">Last Updated: January 1, 2023</span>
            <h2 className="c-grey-900 py-2">Buyer Platform Fee Agreement</h2>
            <div className="w-100 border-bottom-grey-2-200 mb-4"></div>

            <p className="c-grey-550 pb-2">
                The 1Konnection Application (the <span className="c-grey-900 ff-i700">“Application”</span>) and web-based service is provided to you by
                1Konnection Technology, Inc., a Delaware C corporation (“<span className="c-grey-900 ff-i700">1Konnection</span>,” <span className="c-grey-900 ff-i700">“Company</span>,” “<span className="c-grey-900 ff-i700">us</span>,”
                <span className="c-grey-900 ff-i700">“our</span>,” or “<span className="c-grey-900 ff-i700">we</span>”). This Buyer Platform Fee Agreement (this “<span className="c-grey-900 ff-i700">Fee Agreement</span>”), along with our Terms of
                Service <Link to="/terms">https://www.1konnection.com/terms</Link> (the “<span className="c-grey-900 ff-i700">Terms</span>”) and Privacy Policy
                <Link to="/privacy"> https://www.1konnection.com/privacy</Link> (the “<span className="c-grey-900 ff-i700">Privacy Policy</span>”), incorporated into this Fee
                Agreement by reference, and any other applicable policies and guidelines, as may be updated
                from time to time, govern your use of the 1Konnection Application and web-based services.
                This Fee Agreement constitute a legal agreement between you and 1Konnection. In order to
                use the 1Konnection Application you must agree to these this Fee Agreement.
            </p>

            <p className="c-grey-550 pb-2">
                All references to “<span className="c-grey-900 ff-i700">you</span>”, <span className="c-grey-900 ff-i700">“your</span>,” as applicable, mean the person who accesses, uses, and/or
                participates in the Application in any manner, and each of your heirs, assigns, and successors.
            </p>

            <p className="c-grey-900 ff-i700 pb-2">
                1KONNECTION DOES NOT, AND THE SERVICE IS NOT INTENDED TO, PROVIDE
                FINANCIAL OR REAL ESTATE ADVICE. 1KONNECTION IS NOT A FINANCIAL OR REAL
                ESTATE BROKER OR LENDER.
            </p>

            <p className="c-grey-900 ff-i700 pb-2">
                BY AFFIRMATIVELY CLICKING THE “I AGREE” BOX ASSOCIATED WITH THIS FEE
                AGREEMENT AND/OR COMPLETING ANY MARKETING EVENT THROUGH THE
                1KONNECTION APPLICATION, YOU ARE INDICATING YOUR AGREEMENT TO BE
                BOUND BY THIS FEE AGREEMENT. IF YOU DO NOT ACCEPT THE TERMS OF THIS FEE
                AGREEMENT, DO NOT CONDUCT OR COMPLETE ANY MARKETING EVENTS
                THROUGH THE 1KONNECTION APPLICATION OR ANY 1KONNECTION WEB BASED
                SERVICES.
            </p>

            <h6 className="c-grey-900 ff-i700 pb-2 text-uppercase">General</h6>

            <p className="c-grey-550 pb-2">
                The terms of this Fee Agreement apply to transactions for the sale of a real property (each, a
                “<span className="c-grey-900 ff-i700">Property</span>”) conducted on the 1Konnection platform. <span className="c-grey-900 ff-i700">YOU ACKNOWLEGE THAT PROPERTY
                    INFORMATION HAS NOT BEEN INDEPENDENTLY VERIFIED BY 1KONNECTION.</span>
            </p>

            <p className="c-grey-550 pb-2">
                For purposes of this Fee Agreement, a transaction refers to a marketing event in which a
                prospective buyer (each, a “<span className="c-grey-900 ff-i700">Participant</span>”) agrees to Match with a Property (each, a “<span className="c-grey-900 ff-i700">Marketing
                    Event</span>”). <span className="c-grey-900 ff-i700">IF YOU ARE A PARTICIPANT, YOU ACKNOWLEDGE THAT (i) IT IS YOUR
                        RESPONSIBILITY TO CONFIRM THE LICENSED STATUS OF ANY SELLER PARTIES
                        SELLING A PROPERTY ON THE 1KONNECTION PLATFORM, AND (II) 1KONNECTION IS
                        NOT RESPONSIBLE FOR CONFIRMING THE LICENSES STATUS OF ANY SELLER
                        PARTIES SELLING A PROPERTY ON THE 1KONNECTION PLATFORM.</span>
            </p>

            <p className="c-grey-550 pb-2">
                For purposes of this Fee Agreement, a “<span className="c-grey-900 ff-i700">Seller</span>” refers to the seller of a Property. A Seller may
                sell a property alone or together with any brokers or other representatives working on the
                Seller’s behalf (collectively, the “<span className="c-grey-900 ff-i700">Seller Parties</span>”).
            </p>

            <p className="c-grey-550 pb-2">
                For the purposes of this Fee Agreement, a “<span className="c-grey-900 ff-i700">Match</span>” means: (i) when a Participant agrees to
                interact with the Seller Parties, (ii) when a Participant agrees to be connected to a Property by
                1Konnection, or 1Konnection’s members, owners, or agents; or (ii) when a Participant agrees
                to the use of 1Konnection’s platform for introductions of Participant to Seller Parties from time
                to time.
            </p>

            <p className="c-grey-550 pb-2">
                For the purposes of this Fee Agreement, the terms “1Konnection”, “Seller”, “Seller Parties” and
                “Participant” also refer to the respective principals, representatives, agents, brokers and
                affiliates of each. A “<span className="c-grey-900 ff-i700">Matched Property</span>” is a Property the Participant has instituted a Match.
            </p>

            <p className="c-grey-550 pb-2">
                1Konnection conducts Marketing Events solely on behalf of Seller Parties. 1Konnection is not
                acting as an agent for any Participant or Seller Parties in any capacity. By accepting a Match
                via 1Konnection, Participant acknowledges having read and accepted these terms.
            </p>

            <p className="c-grey-550 pb-2">
                All communications on or through the Application or 1Konnection are subject to our Terms and
                Privacy Policy.
            </p>

            <h6 className="c-grey-900 ff-i700 pb-2 text-uppercase">MATCHING FEE</h6>

            <p className="c-grey-550 pb-2">
                In the event of a Match between a Participant and a Seller or Seller Parties, Participant shall
                pay 1Konnection an amount equal to one percent (1%) of the purchase price, or if the event is
                a lease Participant shall pay1Konnection the amount equal to the price of first month of rent,
                of each Property (the “<span className="c-grey-900 ff-i700">Matching Fee</span>”). In no event will the Matching Fee exceed $250,000.
            </p>

            <p className="c-grey-550">
                The Matching Fee shall be payable by Participant to 1Konnection if:
            </p>

            <p className="c-grey-550">
                <span className="ps-3 pe-4">(i)</span> the Participant and Seller or Seller Parties Match to a Property,
            </p>

            <p className="c-grey-550">
                <span className="ps-3 pe-4">(ii)</span> the Participant places an offer the Matched Property within one (1) year of being
                Matched with the Seller or Seller Party,
            </p>

            <p className="c-grey-550">
                <span className="ps-3 pe-4">(iii)</span> the offer for the Matched Property is accepted by the Seller or Seller Party, and
            </p>

            <p className="c-grey-550">
                <span className="ps-3 pe-4">(iv)</span> the Participant closes on or leases the Property.
            </p>

            <p className="c-grey-550">
                Participant will notify 1Konnection when it has closed on the Property and will invoice the
                Participant for the Matching Fee.
            </p>

            <h6 className="c-grey-900 ff-i700 pb-2 text-uppercase">PAYMENT</h6>

            <p className="c-grey-550 pb-2">
                Within thirty (30) days of notice from 1Konnection, Participant shall pay 1Konnection the
                Matching Fee in the form and manner specified by 1Konnection.
            </p>

            <p className="c-grey-550 pb-2">
                Each payment of the Matching Fee will be payable via bank wire, certified check or drawn from
                an attorney’s escrow account. Participant agrees that if each such Matching Fee is not paid to
                the Company at or prior to the closing, then 1Konnection shall be entitled to all legal and
                equitable remedies available to 1Konnection to pursue such Participant and the Matching Fee,
                as well as reimbursement for 1Konnection’s costs and expenses incurred in connection with
                pursuing such Matching Fee.
            </p>

            <p className="c-grey-550 pb-2">
                All payments are subject to our Terms and Privacy Policy.
            </p>

            <h6 className="c-grey-900 ff-i700 pb-2 text-uppercase">DISCLAIMERS, REPRESENTATIONS, CANCELLATION, AND LIQUIDATED DAMAGES</h6>

            <p className="c-grey-550 pb-2">
                1KONNECTION MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND OR
                CHARACTER WHATSOEVER, WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO
                ANY PROPERTY OR THE LEGAL SUFFICIENCY OR TAX CONSEQUENCES OF ANY
                DOCUMENT PROVIDED BY 1KONNECTION.
            </p>

            <p className="c-grey-550 pb-2">
                EACH PARTICIPANT HEREBY RELEASES 1KONNECTION AND ITS EMPLOYEES,
                PRINCIPALS, OFFICERS,DIRECTORS, REPRESENTATIVES, ATTORNEYS, BROKERS,
                AGENTS, AND AFFILIATES (COLLECTIVELY,“REPRESENTATIVES”) FROM ANY AND ALL
                CLAIMS OR DEMANDS THAT ANY SUCH PARTICIPANT MAY NOW HAVE OR
                HEREAFTER ACQUIRE AGAINST 1KONNECTION OR ANY OF ITS REPRESENTATIVES
                FOR ANY DAMAGE OR INJURY RESULTING FROM OR RELATING TO ANY ERRORS,
                OMISSIONS, OR OTHER CONDITIONS AFFECTING ANY PROPERTY, THE DOCUMENTS
                USED IN EVALUATING OR ACQUIRING ANY PROPERTY, OR THE CONDUCTING OF ANY
                MARKETING EVENT. THIS RELEASE INCLUDES ALL CLAIMS OF WHICH PARTICIPANT
                IS PRESENTLY UNAWARE OR DOES NOT PRESENTLY SUSPECT TO EXIST IN
                PARTICIPANT’S FAVOR WHICH, IF KNOWN BY PARTICIPANT, WOULD MATERIALLY
                AFFECT PARTICIPANT’S RELEASE OF 1KONNECTION.
            </p>

            <p className="c-grey-550 pb-2">
                EACH PARTICIPANT SPECIFICALLY WAIVES THE PROVISIONS OF CALIFORNIA CIVIL
                CODE SECTION 1542 (AND ANY OTHER SUBSTANTIALLY SIMILAR APPLICABLE STATE
                STATUTES) WHICH PROVIDES AS FOLLOWS:
            </p>

            <p className="c-grey-550 ps-4 pb-2">
                A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
                DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME
                OF EXECUTING THE RELEASE WHICH IF KNOWN BY HIM OR HER MUST HAVE
                MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.
            </p>

            <p className="c-grey-550 pb-2">
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
                IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY
                RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND
                LIMITATIONS MAY NOT APPLY TO YOU.
            </p>

            <h6 className="c-grey-900 ff-i700 pb-2 text-uppercase">LIABILITY</h6>

            <p className="c-grey-550 pb-2">
                EXCEPT AS EXPRESSLY PROVIDED IN THIS FEE AGREEMENT, 1KONNECTION’S
                MAXIMUM LIABILITY IS THE AMOUNT PAID, OR PAYABLE, BY PARTICIPANT TO
                1KONNECTION.
            </p>

            <p className="c-grey-550 pb-2">
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
                OR LIMITED UNDER APPLICABLE LAW.
            </p>

            <h6 className="c-grey-900 ff-i700 pb-2 text-uppercase">INDEMNIFICATION</h6>

            <p className="c-grey-550 pb-2">
                Participant will indemnify and hold harmless 1Konnection and its and its officers, directors,
                employees, agents, affiliates, successors and assigns from and against any and all liabilities,
                losses, damages, costs, and any other expenses (including attorneys’ and expert witnesses’
                costs and fees) arising from or relating to any breach of any representation, warranty,
                covenant, or obligation of Participant in this Fee Agreement or any intentional misconduct or
                negligence by Participant or any other claims or losses resulting from any actions of the conduct
                of Participant.
            </p>

            <p className="c-grey-550 pb-2">
                In the event of any third-party claim, demand, suit, or action, that 1Konnection or its officers,
                directors, employees, agents, affiliates, successors and assigns are entitled to indemnification
                hereunder, 1Konnection may, at its option, require Participant to defend such claim at
                Participant’s sole expense. Participant may not agree to settle any such claim without
                1Konnection’s express prior written consent.
            </p>

            <h6 className="c-grey-900 ff-i700 pb-2 text-uppercase">MISCELLANEOUS</h6>

            <p className="c-grey-550 pb-2">
                <span className="c-grey-900 ff-i700">Entire Agreement.</span> This Fee Agreement, the Terms and the Privacy P comprises the entire
                agreement between you and 1Konnection related to the subject of this Fee Agreement, and
                supersedes all prior or contemporaneous negotiations, discussions, or agreements, whether
                written or oral, between the parties regarding the subject matter contained in this Fee
                Agreement. If there is any conflict between the terms of this Free Agreement and the Terms,
                the terms of this Fee Agreement will prevail.
            </p>

            <p className="c-grey-550 pb-2">
                <span className="c-grey-900 ff-i700">Headings.</span> All headings and numbering in the Agreement are for convenience of reference
                only and shall in no way be used in interpretation of any of the provisions in the Agreement.
            </p>

            <p className="c-grey-550 pb-2">
                <span className="c-grey-900 ff-i700">Assignment of Obligations.</span> You may not assign your rights or obligations under this Fee
                Agreement without 1Konnection’s prior written consent. You understand that 1Konnection may
                assign this Agreement without your consent to any entity (i) resulting from any merger,
                consolidation or other reorganization involving 1Konnection, including, without limitation, a
                merger or other reorganization for the purpose of changing the Company’s domicile, or (ii) to
                which the 1Konnection transfers all or substantially all of its assets.
            </p>

            <p className="c-grey-900 ff-i700">
                Representations and Warranties.
            </p>

            <p className="c-grey-550">
                1Konnection represents and warrants to Participant that: (i) it has the full power to enter into
                this Agreement; and (ii) 1Konnection’s execution of, and performance under, this Agreement
                will not breach any oral or written agreement with any third party or any obligation owed to any
                third party.
            </p>

            <p className="c-grey-550 pb-2">
                Participant represents and warrants to 1Konnection that: (i) it has the full power and legal
                capacity to enter into this Agreement; and (ii) Participant’s execution of, and performance
                under, this Agreement will not breach any oral or written agreement with any third party or any
                obligation owed to any third party.
            </p>

            <p className="c-grey-550 pb-2">
                <span className="c-grey-900 ff-i700">Severability.</span> If any provision of this Fee Agreement is declared void or unenforceable, such
                provision shall be deemed severed from this Fee Agreement, and this Fee Agreement shall
                otherwise remain in full force and effect.
            </p>

            <p className="c-grey-550 pb-2">
                <span className="c-grey-900 ff-i700">Relationship of the Parties.</span> No joint venture, partnership, employment, or agency relationship
                exists between you, 1Konnection or any third-party provider as a result of the Fee Agreement
                or use of the Application.
            </p>

            <p className="c-grey-550 pb-2">
                <span className="c-grey-900 ff-i700">Choice of Law.</span> This Fee Agreement is governed by and construed in accordance with the
                internal laws of the State of Delaware without giving effect to any choice or conflict of law
                provision or rule
            </p>

            <p className="c-grey-550 pb-2">
                <span className="c-grey-900 ff-i700">Arbitration and Dispute Resolution.</span> Any dispute, controversy, or claim arising out of or
                related to this Fee Agreement or any breach of this Fee Agreement shall be submitted to and
                decided by binding arbitration administered exclusively by American Arbitration Association in the
                state of Illinois, city of Chicago. Any arbitral award determination shall be final and binding upon
                the parties. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS,
                YOU AND 1KONNECTION ARE EACH WAIVING THE RIGHT TO GO TO COURT OR TO
                PARTICIPATE IN A CLASS OR REPRESENTATIVE ACTION.
            </p>

            <p className="c-grey-550 pb-2">
                <span className="c-grey-900 ff-i700">Waiver.</span> 1Konnection’s failure, at any time, to require your performance of any provision of this
                Fee Agreement shall in no way affect 1Konnection’s right to enforce such provision, nor shall
                1Konnection’s waiver of any breach of any provision of this Fee Agreement be taken or held to
                be a waiver of any further breach of the same provision or any other provision.
            </p>

            <p className="c-grey-550 pb-2">
                <span className="c-grey-900 ff-i700">Captions.</span> Captions and headings of the sections and paragraphs of this Fee Agreement are
                intended solely for convenience and no provision of this Agreement is to be construed by
                reference to the caption or heading of any section or paragraph.
            </p>
        </div>
    );
}

export default PFAAgremeent;