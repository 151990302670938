function Testimonial({ authorImageUrl, message, author, authorJob }) {
    return (
        <>
            <h5 dangerouslySetInnerHTML={{ __html: message }} className="d-block py-4 ff-i500 mt-md-3 c-grey-900 text-justify text-center">
            </h5>
            <div className="d-flex justify-content-center align-items-center">
                <img loading="lazy" src={authorImageUrl} alt="author" width="64" height="64" />
                <span className="mx-3">
                    <h6 className="c-grey-900 lh-normal">{author}</h6>
                    <div className="ff-i500 c-grey-550 pb-2 py-2">{authorJob}</div>
                </span>
            </div>
        </>
    );
}

export default Testimonial;