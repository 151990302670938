import axios from 'axios';
import { traceError } from './common/response-handlers';

function CommunitiesService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; }
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getCommunities = () => axios
        .get(`/users/communities`, config())
        .catch(error => traceError(error, `/users/communities`, 'GET'));

    const addCommunity = ({ quantity, assetTypeIds, stateIds }) => axios
        .post(`/users/communities`, { quantity, assetTypeIds, stateIds }, config())
        .catch(error => traceError(error, `/users/communities`, 'POST'));

    const updateCommunity = (communityId, { quantity, assetTypeIds, stateIds }) => axios
        .put(`/users/communities/${communityId}`, { quantity, assetTypeIds, stateIds }, config())
        .catch(error => traceError(error, `/users/communities/${communityId}`, 'PUT'));

    const updateNoCommunities = ({ noCommunities }) => axios
        .patch(`/users/communities/no-communities`, { noCommunities }, config())
        .catch(error => traceError(error, `/users/communities/no-communities`, 'PATCH'));

    const deleteCommunity = (communityId) => axios
        .delete(`/users/communities/${communityId}`, config())
        .catch(error => traceError(error, `/users/communities/${communityId}`, 'DELETE'));

    return {
        init,
        dispose,
        getCommunities,
        addCommunity,
        updateCommunity,
        updateNoCommunities,
        deleteCommunity
    };
}

export default CommunitiesService;