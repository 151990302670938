import { useEffect, useState } from 'react';

const useCreatePagesArray = ({ totalItems, itemsPerPage }) => {
    const [pagesNumber, setPagesNumber] = useState([1]);

    useEffect(() => {
        setPagesNumber(
            Array(Math.ceil(totalItems / itemsPerPage))
                .fill(null)
                .map((_, i) => i + 1)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalItems]);

    return {
        pagesNumber,
    };
};

export default useCreatePagesArray;
