import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Channel, sendBirdSelectors, useSendbirdStateContext } from 'sendbird-uikit';

import { SendBirdConsts } from 'models/SendBirdConsts';

import MessageInput from './MessageInput';
import ChatHeader from './ChatHeader';
import ChatService from 'services/chat-service';
import { NotificationType } from 'models/NotificationType';
import NotificationsService from 'services/notifications-service';
import { useNotifications } from 'contexts/NotificationsContext';
import Tooltip from 'components/atoms/Tooltip';
import useTimeout from 'hooks/useTimeout';

const dateTimeFormatOptionsHourMinutes = { hour: 'numeric', minute: 'numeric' };
const MINUTE_DELAY = 1000 * 60 * 2;
const chatService = ChatService();
const notificationsService = NotificationsService();

const ChannelConversation = ({
    currentChannelUrl,
    showChannelSettings,
    userId,
    sellerView,
    setCurrentChannelUrl,
    setShowChannelSettings,
    selectedChannel,
    messageEducation,
    manageLeadEducation,
    dealName,
    is1KTeamChat
}) => {
    const { handleFetchNotifications, notificationsData } = useNotifications();
    const sendbirdContext = useSendbirdStateContext();
    const sb = sendBirdSelectors.getSdk(sendbirdContext);
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        chatService.init();
        notificationsService.init();

        return () => {
            notificationsService.dispose();
            chatService.dispose();
            if (sb && sb.removeAllChannelHandlers) sb.removeAllChannelHandlers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendMessageNotification = () => {
        if (!notification) return;
        let { channelUrl, recipientId, isRecipientSeller, dealId } = notification;
        chatService
            .postNewMessage(channelUrl, recipientId, isRecipientSeller, dealId)
            .then(() => setNotification(null));
    }

    const [setTimer, stopTimer, triggerTimer] = useTimeout(sendMessageNotification, MINUTE_DELAY);

    useEffect(() => {
        if (!sb || !currentChannelUrl) return;
        if (sb.ChannelHandler && sb.addChannelHandler) {
            const channelHandler = new sb.ChannelHandler();
            channelHandler.onMessageReceived = function (channel, message) {
                if (message && message?.channelUrl === currentChannelUrl) {
                    stopTimer();
                    setNotification(null);
                }
            };
            sb.addChannelHandler(currentChannelUrl, channelHandler);
        }

        return () => {
            if (currentChannelUrl && sb && sb.removeChannelHandler) sb.removeChannelHandler(currentChannelUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sb, currentChannelUrl]);

    const onMsgSentCallback = () => {
        if (is1KTeamChat && +userId !== +process.env.REACT_APP_SEND_BIRD_SYSTEM_USER_ID) return;
        setNotification({
            channelUrl: currentChannelUrl,
            recipientId: selectedChannel.contact.userId,
            isRecipientSeller: !sellerView,
            dealId: +selectedChannel.metadata?.listingId || null
        });
        setTimer();
    };

    useEffect(() => {
        const currentDealId = +selectedChannel?.metadata?.listingId;
        const buyerNotification = notificationsData?.buyer?.dealNotifications?.find(n => n?.id === currentDealId && (n?.message || n?.new || n?.document));
        const sellerNotification = notificationsData?.seller?.dealNotifications?.find(n => n?.id === currentDealId && (n?.message || n?.lead || n?.document));
        if (selectedChannel && buyerNotification) {
            if (buyerNotification.message)
                return notificationsService
                    .patchProcess({ type: NotificationType.NewMessage, isSeller: sellerView, dealId: currentDealId })
                    .then(handleFetchNotifications);

            if (buyerNotification.new)
                notificationsService
                    .patchProcess({ type: NotificationType.NewDeal, isSeller: sellerView, dealId: currentDealId })
                    .then(handleFetchNotifications);

            if (buyerNotification.document)
                notificationsService
                    .patchProcess({ type: NotificationType.NewDocument, isSeller: sellerView, dealId: currentDealId })
                    .then(handleFetchNotifications);
        }
        if (selectedChannel && sellerNotification) {
            if (sellerNotification.message)
                notificationsService
                    .patchProcess({ type: NotificationType.NewMessage, isSeller: sellerView, dealId: currentDealId })
                    .then(handleFetchNotifications);

            if (sellerNotification.lead)
                notificationsService
                    .patchProcess({ type: NotificationType.NewLead, isSeller: sellerView, dealId: currentDealId })
                    .then(handleFetchNotifications);

            if (sellerNotification.document)
                notificationsService
                    .patchProcess({ type: NotificationType.NewDocument, isSeller: sellerView, dealId: currentDealId })
                    .then(handleFetchNotifications);
        }
        triggerTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChannel]);

    const renderCustomMessage = (message) => {
        if (message.customType === SendBirdConsts.MessageCustomTypeCalendarEvent
            || message.customType === SendBirdConsts.MessageCustomTypeCalendarEventCanceled
            || message.customType === SendBirdConsts.MessageCustomTypeScheduleEvent) {
            const createdAt = new Date(message.createdAt);
            const linkToMeetingsPage =
                message.customType !== SendBirdConsts.MessageCustomTypeScheduleEvent
                    ? `/${sellerView ? 'seller-dashboard' : 'buyer-dashboard'}/meetings?eventId=${message.data}`
                    : '?meeting=true';

            return () =>
                <div className={`sendbird-message-hoc__message-content sendbird-message-content ${message._sender.userId === userId ? 'outgoing' : 'incoming'}`}>
                    <div className={`sendbird-message-content__middle ${message.customType === SendBirdConsts.MessageCustomTypeCalendarEventCanceled ? 'calendar-event-canceled' : ''}`}>
                        <div className="sendbird-message-content__middle__body-container">
                            <div className={`sendbird-message-content__middle__body-container__created-at ${message._sender.userId === userId ? 'right' : 'left'}`}>
                                <span className="sendbird-message-status__text sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-2">
                                    {new Intl.DateTimeFormat('en-US', dateTimeFormatOptionsHourMinutes).format(new Date(createdAt))}
                                </span>
                            </div>
                            <span className={`sendbird-label sendbird-label--body-1 sendbird-label--color-oncontent-1 ${message.customType !== SendBirdConsts.MessageCustomTypeScheduleEvent ? 'before-blue-element' : ''}`}>
                                <p className="sendbird-message-content__middle__message-item-body sendbird-text-message-item-body outgoing">
                                    <span className="message">{message.message}</span>
                                    {message.data &&
                                        <>
                                            {message.customType === SendBirdConsts.MessageCustomTypeCalendarEvent &&
                                                <Link to={linkToMeetingsPage} className="d-block">Open Details</Link>
                                            }
                                            {message.customType === SendBirdConsts.MessageCustomTypeCalendarEventCanceled &&
                                                <span className="d-block">{message.data}</span>
                                            }
                                        </>
                                    }
                                    {message.customType === SendBirdConsts.MessageCustomTypeScheduleEvent && !sellerView &&
                                        <Link to={linkToMeetingsPage} className="d-block">Schedule a meeting</Link>
                                    }
                                </p>
                            </span>
                        </div>
                    </div>
                </div>;
        }
    };

    return (
        <>
            {currentChannelUrl
                ? <div className={`channel-conversation ${showChannelSettings ? 'display-none' : 'w-100'}`}>
                    <Tooltip
                        show={messageEducation?.show}
                        content="Let’s get it done! Here, type up your chats to send and attach documents. Message history stays consistent, no matter what device you’re accessing the chat from."
                        secondaryContent={`Step 2/${messageEducation?.total}`}
                        direction="bottom-stick"
                        bodyClasses="w-100 h-100"
                        additionalClasses="offset-bottom"
                        ctaText="Next"
                        ctaAction={() => { messageEducation?.moveNext(2) }}>
                        <Channel
                            disableUserProfile={true}
                            channelUrl={currentChannelUrl}
                            useReaction={false}
                            useMessageGrouping={true}
                            renderCustomMessage={renderCustomMessage}
                            renderChatHeader={() => (
                                <ChatHeader
                                    userId={userId}
                                    sellerView={is1KTeamChat ? false : sellerView}
                                    setCurrentChannelUrl={setCurrentChannelUrl}
                                    toggleChannelSettings={setShowChannelSettings}
                                    showChannelSettings={showChannelSettings}
                                    selectedChannel={selectedChannel}
                                    manageLeadEducation={manageLeadEducation}
                                    is1KTeamChat={is1KTeamChat}
                                    dealName={dealName}
                                />
                            )}
                            onBeforeSendFileMessage={(file) => {
                                const params = new sb.FileMessageParams();
                                params.file = file;
                                params.mentionType = 'channel';
                                return params;
                            }}
                            renderMessageInput={({ channel, disabled }) => (
                                <MessageInput
                                    userId={userId}
                                    channel={channel}
                                    selectedChannel={selectedChannel}
                                    onMsgSentCallback={onMsgSentCallback}
                                    disabled={disabled}
                                    setShowChannelSettings={setShowChannelSettings}
                                    showChannelSettings={showChannelSettings}
                                    is1KTeamChat={is1KTeamChat}
                                    sellerView={is1KTeamChat ? false : sellerView}
                                />
                            )}
                        />
                    </Tooltip>
                </div>

                : <div className="d-none d-lg-flex bc-grey-50 select-channel">
                    <span className="border-grey px-3 py-1">Select a chat to start messaging</span>
                </div>
            }
        </>
    );
}

export default ChannelConversation;