const Colors =
{
    Blue: 'blue',
    Green: 'green',
    Violet: 'violet',
    Orange: 'orange',
    Pink: 'pink',
    Turquoise: 'turquoise',
    Yellow: 'yellow',
    Red: 'red',
    DarkBlue: 'dark-blue'
};

export const getRandomColor = function () {
    var keys = Object.keys(Colors);
    return Colors[keys[keys.length * Math.random() << 0]];
};

Object.freeze(Colors);
export { Colors };