import axios from 'axios';

const AuthInterceptor = () => {
    const AUTH_ROUTE_PATH = '/secure';
    const SERVER_ERROR_ROUTE_PATH = '/server-error';
    const AUTH_CHANGE_EVENT = 'auth';
    const ACCESS_TOKEN_KEY = 'access-token';
    const USER_ID_KEY = 'unique-identifier';
    const USER_EMAIL_KEY = 'unique-email';

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    axios.interceptors.request.use(
        request => {
            const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
            const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);
            const isDefaultRelativeApiUrl = request.url.startsWith('/');
            if (token && (isApiUrl || isDefaultRelativeApiUrl))
                request.headers.common.Authorization = `Bearer ${token}`;
            return request;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        response => response,
        error => {
            if (!error || !error.response || !error.response.status || !error.response.request)
                return Promise.reject(error);
            const isApiUrl = error.response.request.responseURL?.startsWith(process.env.REACT_APP_API_URL) ?? false;
            const isUnauthorized = error.response.status === 401;
            if (isUnauthorized && isApiUrl) {
                window.localStorage.removeItem(ACCESS_TOKEN_KEY);
                window.localStorage.removeItem(USER_ID_KEY);
                window.localStorage.removeItem(USER_EMAIL_KEY);
                window.dispatchEvent(new Event(AUTH_CHANGE_EVENT));
                if (window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
            }
            if (error.response.status >= 500) window.location.href = SERVER_ERROR_ROUTE_PATH;
            return Promise.reject(error);
        });
}

export default AuthInterceptor;