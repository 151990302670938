import AuthHandler from 'services/common/auth-handler';
import { IS_SHADOW_KEY } from 'hooks/useLocalStorage';
const authHandler = AuthHandler();

export function trackPageview({ title, location, path } = {}) {
    trackEvent('page_view', {
        'page_title': title || document.title,
        'page_location': location || window.location.href,
        'page_path': path || window.location.pathname
    });
}

/*** Conversion Events ***/
export function trackSignUp({ method } = {}) {
    trackEvent('sign_up', { 'method': method || 'Landing' });
}

export function trackVerificationEmail() {
    trackEvent('verification_email');
}

export function trackAgreementAccepted() {
    trackEvent('agreemnet_accepted');
}

export function trackFinancialBackgroundEntered() {
    trackEvent('financial_background_entered');
}

export function trackCriteriaStart({ count }) {
    trackEvent('criteria_start', { 'entities_count': count || 0 });
}

export function trackListingStart({ count }) {
    trackEvent('listing_start', { 'entities_count': count || 0 });
}

export function trackCriteriaFinish({ count }) {
    trackEvent('criteria_finish', { 'entities_count': count || 0 });
}

export function trackListingFinish({ count }) {
    trackEvent('listing_finish', { 'entities_count': count || 0 });
}

export function trackDealInterested({ matchId, count }) {
    trackEvent('new-deal_interested', { 'match_id': matchId || 0, 'entities_count': count || 0 });
}

/*** Custom Events ***/
export function trackLogin({ method } = {}) {
    trackEvent('login', { 'method': method || 'Website' });
}

export function trackExistingUser({ method } = {}) {
    trackEvent('existing_user', { 'method': method || 'Website' });
}

export function trackLogout() {
    trackEvent('logout');
}

export function trackAgreementClosed() {
    trackEvent('agreemnet_closed');
}

export function trackFinancialBackgroundUpdated() {
    trackEvent('financial_background_updated');
}

export function trackFinancialBackgroundClosed() {
    trackEvent('financial_background_closed');
}

export function trackCompanyInformationEntered() {
    trackEvent('company_information_entered');
}

export function trackCompanyInformationUpdated() {
    trackEvent('company_information_updated');
}

export function trackCommunityAdded() {
    trackEvent('community_added');
}

export function trackCommunityUpdated() {
    trackEvent('community_updated');
}

export function trackCommunityDeleted() {
    trackEvent('community_deleted');
}

export function trackCriteriaRename() {
    trackEvent('criteria_rename');
}

export function trackCriteriaDeactivate({ isDeactivated }) {
    if (isDeactivated)
        trackEvent('criteria_deactivate');
    else
        trackEvent('criteria_activate');
}

export function trackCriteriaComplete() {
    trackEvent('criteria_complete');
}

export function trackListingRename() {
    trackEvent('listing_rename');
}

export function trackListingPriceEdit() {
    trackEvent('listing_edit-price');
}

export function trackListingDeactivate({ isDeactivated }) {
    if (isDeactivated)
        trackEvent('listing_deactivate');
    else
        trackEvent('listing_activate');
}

export function trackListingComplete() {
    trackEvent('listing_complete');
}

export function trackDealNotInterested({ matchId, count }) {
    trackEvent('new-deal_not-interested', { 'match_id': matchId || 0, 'entities_count': count || 0 });
}

export function trackContactImportStart() {
    trackEvent('contact_import-start');
}

export function trackContactImportUpload() {
    trackEvent('contact_import-upload');
}

export function trackContactImportPreview() {
    trackEvent('contact_import-preview');
}

export function trackContactImportFinish() {
    trackEvent('contact_import-finish');
}

export function trackAssociateDealAdd() {
    trackEvent('associate_deal-add');
}

export function trackEvent(event, params = {}) {
    let eventParams = {
        ...params,
        'user_identifier': authHandler.getUserId() || 'anonymous'
    };
    const isShadow = !!localStorage.getItem(IS_SHADOW_KEY);
    if (!window.gtag || !event || isShadow)
        console.info(`[GA4]: ${event} ${JSON.stringify(eventParams)}`);
    else window.gtag('event', event, eventParams);
}

const Analytics = {
    trackPageview,
    trackSignUp,
    trackLogin,
    trackExistingUser,
    trackVerificationEmail,
    trackAgreementAccepted,
    trackAgreementClosed,
    trackFinancialBackgroundEntered,
    trackFinancialBackgroundUpdated,
    trackFinancialBackgroundClosed,
    trackCompanyInformationEntered,
    trackCompanyInformationUpdated,
    trackCommunityAdded,
    trackCommunityUpdated,
    trackCommunityDeleted,
    trackLogout,
    trackCriteriaStart,
    trackCriteriaFinish,
    trackCriteriaRename,
    trackCriteriaDeactivate,
    trackListingStart,
    trackListingFinish,
    trackListingRename,
    trackListingPriceEdit,
    trackListingDeactivate,
    trackDealInterested,
    trackDealNotInterested,
    trackContactImportStart,
    trackContactImportUpload,
    trackContactImportPreview,
    trackContactImportFinish,
    trackAssociateDealAdd,
    trackEvent
};

export default Analytics;