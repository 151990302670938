const AddNote = ({ formik, handleBack }) => {
    return (
        <form
            name="form-notes"
            onSubmit={async e => {
                await formik.handleSubmit(e);
                handleBack();
            }}
        >
            <textarea
                className="w-100 min-h-15vh p-3"
                value={formik.values.notes}
                name={'notes'}
                onChange={formik.handleChange}
                placeholder={'Placeholder'}
                maxLength={500}
            />
            <span className="ps-2 c-grey-450 text-s">Max. count of symbols is 500</span>
            <div className="row justify-content-center mt-4">
                <div className="col-6 col-md-4">
                    <button className="btn-secondary ff-i600 w-100 me-2" onClick={handleBack}>
                        Cancel
                    </button>
                </div>
                <div className="col-6 col-md-4">
                    <button
                        type="submit"
                        color="primary"
                        disabled={!formik.isValid}
                        className="btn-primary ff-i600 w-100 me-2"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    );
};

export default AddNote;
