import axios from 'axios';
import { traceError } from 'services/common/response-handlers';

function ChatService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getAllGroupChannels = () => axios
        .get('/chats', config())
        .catch(error => traceError(error, '/chats', 'GET'));

    const getAllGroupChannelsByMetadata = (isBuyer = false) => axios
        .get(`/chats/by-metadata?buyer=${isBuyer}`, config())
        .catch(error => traceError(error, '/chats', 'GET'));

    const getAllGroupChannelsPagination = (nextToken = '') => axios
        .get(`/chats/paged?nextToken=${nextToken}`, config())
        .catch(error => traceError(error, '/chats', 'GET'));

    const getAllGroupChannelMessagesFiles = (channelUrl) => axios
        .get(`/chats/${channelUrl}/messages-files`, config())
        .catch(error => traceError(error, '/chats/messages-files', 'GET'));

    const postMessage = (channelUrl, message, messageCustomType, metadata = '') => axios
        .post(`/chats/${channelUrl}/messages`, { message, messageCustomType, metadata }, config())
        .catch(error => traceError(error, '/chats/message', 'POST'));

    const postAdminMessage = (channelsUrl, message) => axios
        .post(`/chats/messages/admin`, { channelsUrl, message }, config())
        .catch(error => traceError(error, '/chats/message/admin', 'POST'));

    const postNewMessage = (channelUrl, recipientId, isRecipientSeller, dealId) => axios
        .post(`/chats/${channelUrl}/new-message`, { recipientId, isRecipientSeller, dealId }, config())
        .catch(error => traceError(error, `/chats/${channelUrl}/new-message`, 'POST'));

    const postSystemMessage = (message, isShadowMode) => axios
        .post('/chats/messages/system', { message, isShadowMode }, config())
        .catch(error => traceError(error, `/chats/messages/system`, 'POST'));

    const getSystemMessagesHistory = () => axios
        .get('/chats/messages/system', config())
        .catch(error => traceError(error, '/chats/messages/system', 'GET'));

    return {
        init,
        dispose,
        getAllGroupChannels,
        getAllGroupChannelsByMetadata,
        getAllGroupChannelsPagination,
        getAllGroupChannelMessagesFiles,
        postMessage,
        postAdminMessage,
        postNewMessage,
        postSystemMessage,
        getSystemMessagesHistory
    };
}

export default ChatService;