import Toast from '../atoms/Toast';

const Toasts = ({toasts}) => {
    return (
        <div className='notifications-container d-flex justify-content-end'>
            <div className='w-100 d-flex flex-column align-items-center'>
                {toasts.map((toast) => (
                    <Toast key={toast.id} {...toast} />
                ))}
            </div>
        </div>
    );
};

export default Toasts;