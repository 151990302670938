import { useEffect, useState, useRef } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import useToast from 'hooks/useToast';
import useLoader from 'hooks/useLoader';

import { Modal } from 'components/atoms/Modal';
import Select from 'components/atoms/select/Select';
import Alert from 'components/atoms/alert/Alert';
import List from 'components/molecules/List';
import ListMobile from 'components/molecules/ListMobile';

import UsersService from 'services/users-service';
import AdminService from 'services/admin-service';
import AdminBroadcastService from 'services/admin-broadcast-service';

const usersService = UsersService();
const adminService = AdminService();
const adminBroadcastService = AdminBroadcastService();

const dateComparer = (prev, next) => new Date(next.createdDate) - new Date(prev.createdDate);

const profileTabs = [
    { id: 1, label: 'All users' },
    { id: 2, label: 'By persona' },
    { id: 3, label: 'By emails' }
];

const subTypes = [
    { id: 1, name: "User Types" },
    { id: 2, name: "Seller Types" },
    { id: 3, name: "Buyer Types" },
    { id: 4, name: "By Criteria" },
    { id: 5, name: "By Deal" },
    { id: 6, name: "Reached deal limit" },
    { id: 7, name: "Incomplete profile" }
];

const criteriaTypes = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Not Active' }
];

const dealTypes = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Not Active' }
];

const BroadcastMessage = () => {
    const { load } = useLoader();
    const { toast } = useToast();

    const [messageHistory, setMessageHistory] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [buyerTypes, setBuyerTypes] = useState([]);
    const [sellerTypes, setSellerTypes] = useState([]);

    const [message, setMessage] = useState('');
    const [isShadow, setIsShadow] = useState(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [alertIsShow, setAlertIsShow] = useState(false);
    const [disabledSendButton, setDisabledSendButton] = useState(false);

    const [emailItems, setEmailItems] = useState([]);
    const [selectedType, setSelectedType] = useState(1);
    const [selectedSubType, setSelectedSubType] = useState(null);
    const [selectedOption, setSelectedOption] = useState();

    const [loading, setLoading] = useState(false);
    const [userEmails, setUserEmails] = useState([]);
    const typeaheadRef = useRef(null);

    useEffect(() => {
        usersService.init();
        adminBroadcastService.init();
        adminService.init();

        load(() => reFetchHistory());

        usersService
            .getTypes()
            .then(({ userTypes = [] }) => setUserTypes(userTypes))
            .catch(error => toast.handleError(error));

        usersService
            .getBuyerTypes()
            .then(({ buyerTypes = [] }) => setBuyerTypes(buyerTypes))
            .catch(error => toast.handleError(error));

        usersService
            .getSellerTypes()
            .then(({ sellerTypes = [] }) => setSellerTypes(sellerTypes))
            .catch(error => toast.handleError(error));

        return () => {
            usersService.dispose();
            adminBroadcastService.dispose();
            adminService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reFetchHistory = () =>
        adminBroadcastService
            .getMessages()
            .then(response =>
                setMessageHistory(response?.data?.systemMessagesHistory.sort(dateComparer))
            );

    const onIsShadowChange = e => setIsShadow(e);

    const closeConfirmationModal = () => setShowConfirmationModal(false);

    const handleRemoveEmail = index =>
        setEmailItems(prev => [
            ...prev.slice(0, index),
            ...prev.slice(index + 1, prev.length)
        ]);

    const handleAddEmail = emailItem => {
        const ids = new Set(emailItems.map(e => e.id));
        setEmailItems(prev => [...prev, ...emailItem.filter(d => !ids.has(d.id))]);
    }

    const disabledButton = () => (selectedType === 2 && selectedSubType !== 6 && selectedSubType !== 7 && selectedOption === null) || (selectedType === 3 && !emailItems?.length);

    const sentMessage = () => {
        if (selectedType === 1)
            return adminBroadcastService.postMessageToAllUsers(message, isShadow);
        if (selectedType === 2)
            switch (selectedSubType) {
                case 1:
                    return adminBroadcastService.postMessageByUserTypes(message, isShadow, selectedOption);
                case 2:
                    return adminBroadcastService.postMessageBySellerTypes(message, isShadow, selectedOption);
                case 3:
                    return adminBroadcastService.postMessageByBuyerTypes(message, isShadow, selectedOption);
                case 4:
                    return adminBroadcastService.postMessageByCriteriaTypes(message, isShadow, !!selectedOption);
                case 5:
                    return adminBroadcastService.postMessageByDealTypes(message, isShadow, !!selectedOption);
                case 6:
                    return adminBroadcastService.postMessageByReachedDealsLimit(message, isShadow);
                case 7:
                    return adminBroadcastService.postMessageToNoCompletedProfile(message, isShadow);
                default:
                    return;
            }
        if (selectedType === 3)
            return adminBroadcastService.postMessageByEmails(message, isShadow, emailItems.map(e => e.userId));
    }

    const sendMessageToChannels = () => {
        setDisabledSendButton(true);
        load(() => sentMessage()
            .then(_ => {
                if (!isShadow)
                    reFetchHistory();
                setAlertIsShow(true);
                setMessage('');
                setSelectedType(1);
                setSelectedSubType(null);
                setSelectedOption();
                setEmailItems([]);
            })
            .catch(error => toast.handleError(error))
            .finally(_ => {
                setDisabledSendButton(false);
                closeConfirmationModal();
            }));
    }

    const getSubTypeSelect = () => {
        switch (selectedSubType) {
            case 1:
                return (
                    <Select
                        isInheritHeight
                        options={userTypes}
                        placeholder={'User types'}
                        keys={{ labelKey: "name", valueKey: "id" }}
                        onChange={value => setSelectedOption(value.id)}
                        value={userTypes.find(type => type.id === selectedOption) || null} />
                );
            case 2:
                return (
                    <Select
                        isInheritHeight
                        options={sellerTypes}
                        placeholder={'Seller types'}
                        keys={{ labelKey: "name", valueKey: "id" }}
                        onChange={value => setSelectedOption(value.id)}
                        value={sellerTypes.find(type => type.id === selectedOption) || null} />
                );
            case 3:
                return (
                    <Select
                        isInheritHeight
                        options={buyerTypes}
                        placeholder={'Buyer types'}
                        keys={{ labelKey: "name", valueKey: "id" }}
                        onChange={value => setSelectedOption(value.id)}
                        value={buyerTypes.find(type => type.id === selectedOption) || null} />
                );

            case 4:
                return (
                    <Select
                        isInheritHeight
                        options={criteriaTypes}
                        placeholder={'Criteria types'}
                        keys={{ labelKey: "name", valueKey: "id" }}
                        onChange={value => setSelectedOption(value.id)}
                        value={criteriaTypes.find(type => type.id === selectedOption) || null} />
                );
            case 5:
                return (
                    <Select
                        isInheritHeight
                        options={dealTypes}
                        placeholder={'Deal types'}
                        keys={{ labelKey: "name", valueKey: "id" }}
                        onChange={value => setSelectedOption(value.id)}
                        value={dealTypes.find(type => type.id === selectedOption) || null} />
                );
            default:
                return;
        }
    }

    return (
        <div className="p-3">
            <div className="row gx-3">
                <div className="col-12">
                    <div className="px-3 px-lg-5 py-4">
                        <h4 className="c-grey-700">Message broadcasting</h4>
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center justify-content-lg-start flex-wrap border-bottom-grey-200">
                                    {profileTabs.map(option => (
                                        <div
                                            key={option.id}
                                            className={`btn-tab d-flex align-items-center ${selectedType === option.id ? 'active' : ''}`}
                                            onClick={() => { setSelectedType(option.id); setSelectedSubType(null); setSelectedOption(null); }}>
                                            <span className="ff-i600">{option.label}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-3">
                                {selectedType === 2 &&
                                    <>
                                        <div className="ff-i500 c-grey-700 text-sm mx-4 mb-2">Select users, who will get this message:</div>
                                        <div className="row radio-bar">
                                            {subTypes.map(subType =>
                                                <div className="col-12 col-lg-6">
                                                    <div key={subType.id}>
                                                        <input
                                                            type="radio"
                                                            name="radio-types"
                                                            checked={subType.id === selectedSubType}
                                                            onChange={() => { setSelectedSubType(subType.id); setSelectedOption(null); }}
                                                            id={subType.id} />
                                                        <label
                                                            htmlFor={subType.id}
                                                            className="w-100">
                                                            <span className="radio-icon top-auto"></span>
                                                            <div className="radio-header">{subType.name}</div>
                                                        </label>
                                                    </div>
                                                    {subType.id === selectedSubType &&
                                                        <div className="mx-5">
                                                            {getSubTypeSelect()}
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                                {selectedType === 3 &&
                                    <>
                                        <div className="ff-i500 c-grey-700 text-sm mx-3 mb-2">Put user emails, who will get this message:</div>
                                        <AsyncTypeahead
                                            id="emails"
                                            ref={typeaheadRef}
                                            minLength={3}
                                            useCache={false}
                                            isLoading={loading}
                                            labelKey={option => `${option.email}`}
                                            placeholder='Enter an email'
                                            onChange={selected => {
                                                handleAddEmail(selected);
                                                typeaheadRef.current.clear();
                                            }}
                                            onSearch={emailTerm => {
                                                setLoading(true);
                                                adminService
                                                    .getUsersEmails(emailTerm)
                                                    .then(response => {
                                                        setLoading(false);
                                                        setUserEmails(response.data.userEmails);
                                                    });
                                            }}
                                            options={userEmails} />
                                        <div className="d-flex breadcrumb">
                                            {emailItems.map((emailItem, index) =>
                                                <div
                                                    key={emailItem.id}
                                                    className="d-flex align-items-center bc-blue-500 border-radius cursor-pointer mt-2 me-2 px-2 py-1"
                                                    onClick={() => handleRemoveEmail(index)}>
                                                    <span className="c-white me-2">{emailItem.email}</span>
                                                    <span className="icon icon-cancel-white" />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                        <textarea
                            className="ff-i500 p-3 pe-4 w-100 mt-3"
                            maxLength="4000"
                            style={{ minHeight: "100px", maxHeight: "300px" }}
                            placeholder="Write an admin message"
                            value={message}
                            onChange={e => setMessage(e.target.value)} />

                        <div className="d-flex justify-content-between mt-3">
                            <div className="d-flex justify-content-center align-items-center">
                                <span className="c-grey-550 ff-i500">Only Shadow Users</span>
                                <span className="d-flex align-items-center">
                                    <label className="custom-switch ms-3">
                                        <input
                                            onChange={e => onIsShadowChange(e.target.checked)}
                                            type="checkbox"
                                            checked={isShadow}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                    {isShadow
                                        ? <span className="c-green-500 ms-2">ON</span>
                                        : <span className="c-red ms-2">OFF</span>
                                    }
                                </span>
                            </div>
                            <div className="col-md-5">
                                <button
                                    className="btn-secondary blue ff-i600 w-100 py-3"
                                    disabled={!message || !message.trim() || disabledButton()}
                                    onClick={() => setShowConfirmationModal(true)}>
                                    Send
                                </button>
                            </div>
                        </div>
                        <div className="mt-4">
                            {alertIsShow && (
                                <>
                                    <Alert
                                        type="SUCCESS"
                                        closeButton={{
                                            isVisible: alertIsShow,
                                            callback: () => setAlertIsShow(prev => !prev),
                                        }}
                                        text={`Users successfully got message within 1Konnection chat`}
                                    />
                                </>
                            )}
                        </div>
                        {!!messageHistory.length &&
                            <>
                                <h4 className="c-grey-700 mt-5">Message history</h4>
                                <p className="c-grey-550 ff-i600 pb-3">
                                    Check messages previously sent by 1Konnection team to all users.
                                </p>

                                <List
                                    paginate
                                    columns={[
                                        {
                                            title: 'Date',
                                            key: 'createdDate',
                                            type: 'component',
                                            component: item => <span className="ff-i500 c-grey-700 text-s">{new Date(item).toLocaleDateString()}</span>
                                        },
                                        {
                                            title: 'Name',
                                            key: 'administrator',
                                            type: 'component',
                                            component: item => <span className="ff-i500 c-grey-700 text-s">{item}</span>
                                        },
                                        {
                                            title: 'Type',
                                            key: 'type',
                                            type: 'component',
                                            component: item => <span className="ff-i500 c-grey-700 text-s">{item}</span>
                                        },
                                        {
                                            title: 'Message',
                                            key: 'message',
                                            type: 'component',
                                            grid: 'col-6',
                                            component: item => (
                                                <div className="me-5">
                                                    <div className="ff-i500 c-grey-700 text-s">{item.text}</div>
                                                </div>
                                            )
                                        },

                                    ]}
                                    data={messageHistory.map(message => {
                                        return {
                                            message: {
                                                id: message.id,
                                                text: message.message
                                            },
                                            type: message.type,
                                            administrator: message.userFullName,
                                            createdDate: message.createdDate
                                        };
                                    })}
                                />
                                <ListMobile
                                    paginate
                                    data={messageHistory.map(message => {
                                        return {
                                            content: [
                                                {
                                                    title: 'Date',
                                                    type: 'component',
                                                    emptyState: '',
                                                    component: () => <span className="ff-i500 c-grey-700">{new Date(message.createdDate).toLocaleDateString()}</span>,
                                                },
                                                {
                                                    title: 'Type',
                                                    type: 'component',
                                                    component: () => <span className="ff-i500 c-grey-700">{message.type}</span>
                                                },
                                                {
                                                    title: 'Name',
                                                    type: 'component',
                                                    emptyState: '',
                                                    component: () => <span className="ff-i500 c-grey-700">{message.userFullName}</span>,
                                                },
                                                {
                                                    title: 'Message',
                                                    type: 'component',
                                                    emptyState: '',
                                                    component: () => <span className="ff-i500 c-grey-700">{message.message}</span>,
                                                },
                                            ],
                                        };
                                    })}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>

            <Modal
                isVisible={showConfirmationModal}
                setIsVisible={closeConfirmationModal}
                backdrop={false}
                size={'lg'}
                header={
                    <div className="d-flex my-3 mx-4 justify-content-between">
                        <h4 className="c-grey-700">Confirm message sending</h4>
                        <span
                            className="icon icon-close cursor-pointer"
                            style={{ objectFit: 'contain' }}
                            onClick={closeConfirmationModal}
                        />
                    </div>
                }
                body={
                    <div className="d-flex mx-4 my-5">
                        <div className="ff-i500 c-grey-700">
                            Attention! This action will sent your message to users in the system.
                            Confirm that you want to send this message to users.
                        </div>
                    </div>
                }
                footer={
                    <div className="flex-fill my-3">
                        <div className="row justify-content-center px-5">
                            <div className="col-6">
                                <button
                                    className="btn-secondary ff-i600 w-100"
                                    onClick={closeConfirmationModal}>
                                    Cancel
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn-primary ff-i600 h-100 w-100"
                                    onClick={sendMessageToChannels}
                                    disabled={disabledSendButton}>
                                    Send to users
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export default BroadcastMessage;