import axios from 'axios';

export function getCache(url, controller) {
    return window.memoryStorage.has(url)
        ? new Promise(resolve => resolve(window.memoryStorage.get(url)))
        : axios
            .get(url, { signal: controller.signal })
            .then(response => {
                if (response && response.data && !window.memoryStorage.has(url)) {
                    window.memoryStorage.set(url, response.data);
                    console.info(`[CACHED]: GET ${url}`);
                }
                return response?.data ?? null;
            })
            .catch(error => traceError(error, url, 'GET'));
}

export function traceError(error, url, method) {
    if (axios.isCancel(error)) {
        console.info(`[CANCELED]: ${method} ${url}`);
        return Promise.reject(error);
    }
    console.error(`[FAILED]: ${method} ${url}\n`, error);
    return Promise.reject(error);
}

const responseHandlers = {
    getCache,
    traceError
};

export default responseHandlers;