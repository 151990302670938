import jwt from 'jwt-decode';

const AuthHandler = () => {
    const AUTH_CHANGE_EVENT = 'auth';
    const AUTH_ADMIN_ROLE = 'admin';
    const AUTH_ROLE_CLAIM = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
    const USER_ID_KEY = 'unique-identifier';
    const USER_EMAIL_KEY = 'unique-email';
    const ACCESS_TOKEN_KEY = 'access-token';
    const ORIGIN_TOKEN_KEY = 'origin-token';

    const getSavedAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_KEY);
    const getSavedOriginToken = () => window.localStorage.getItem(ORIGIN_TOKEN_KEY);
    const getSavedUserId = () => window.localStorage.getItem(USER_ID_KEY);
    const getSavedUserEmail = () => window.localStorage.getItem(USER_EMAIL_KEY);

    const authenticated = token => {
        window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
        window.localStorage.removeItem(USER_ID_KEY);
        window.localStorage.removeItem(USER_EMAIL_KEY);
        window.dispatchEvent(new Event(AUTH_CHANGE_EVENT));
    };

    const reset = () => {
        window.localStorage.removeItem(ACCESS_TOKEN_KEY);
        window.localStorage.removeItem(USER_ID_KEY);
        window.localStorage.removeItem(USER_EMAIL_KEY);
        window.localStorage.removeItem(ORIGIN_TOKEN_KEY);
        window.dispatchEvent(new Event(AUTH_CHANGE_EVENT));
    }

    const isAnonymous = () => !isRegistered() && !isAuthenticated();
    const isRegistered = () => isAuthenticated() || (!!getSavedUserId() && !!getSavedUserEmail());
    const justRegistered = () => isRegistered() && !isAuthenticated();
    const isAuthenticated = () => !!getSavedAccessToken() && !!jwt(getSavedAccessToken());
    const isAdmin = () => {
        if (!isAuthenticated()) return false;
        const role = jwt(getSavedAccessToken())[AUTH_ROLE_CLAIM];

        if (!role) return false;
        return role === AUTH_ADMIN_ROLE || (role.find && role?.find(r => r === AUTH_ADMIN_ROLE));
    }
    const isSimulationEnabled = () => !!getSavedOriginToken() && !!jwt(getSavedOriginToken());

    const getAuth = () => ({
        isAnonymous: isAnonymous(),
        isRegistered: isRegistered(),
        justRegistered: justRegistered(),
        isAuthenticated: isAuthenticated(),
        isAdmin: isAdmin()
    });

    const getUser = () => !!getSavedAccessToken() ? jwt(getSavedAccessToken()) : null;
    const getUserId = () => !!getUser() ? getUser().sid : getSavedUserId();
    const getUserEmail = () => !!getUser() ? getUser().email : getSavedUserEmail();

    const simulate = token => {
        if (token) {
            const accessToken = getSavedAccessToken();
            window.localStorage.setItem(ORIGIN_TOKEN_KEY, accessToken);
            window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
        }
        else {
            const originToken = getSavedOriginToken();
            window.localStorage.setItem(ACCESS_TOKEN_KEY, originToken);
            window.localStorage.removeItem(ORIGIN_TOKEN_KEY);
        }
        window.dispatchEvent(new Event(AUTH_CHANGE_EVENT));
    }

    const parseToken = token => jwt(token);

    const subscribe = (listener, silent = true) => {
        if (!listener) return () => { };
        const callback = () => listener(getAuth());
        window.addEventListener(AUTH_CHANGE_EVENT, callback);
        if (!silent) callback();
        return () => window.removeEventListener(AUTH_CHANGE_EVENT, callback);
    }

    return {
        authenticated,
        reset,
        isAnonymous,
        isRegistered,
        justRegistered,
        isAuthenticated,
        isAdmin,
        getAuth,
        getUser,
        getUserId,
        getUserEmail,
        isSimulationEnabled,
        simulate,
        parseToken,
        subscribe
    };
}

export default AuthHandler;