import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ConfirmModal = ({ show, messageHeader, messageBody, handleConfirmClick, handleClose, confirmButtonText = 'Ok', rejectButtonText = 'Cancel', disableCta = false }) => {
    return (
        <Modal show={show} onHide={handleClose} centered size="lg" className="c-grey-700 pb-lg-0">
            <Modal.Header closeButton className="p-3 p-md-4">
                <Modal.Title className="ps-md-2 h5" style={{ fontWeight: '600' }}>{messageHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-5 text-center font-size-18 ">
                {messageBody}
            </Modal.Body>
            <Modal.Footer className="py-4">
                <div className="flex-fill">
                    <div className="row justify-content-center">
                        <div className="col-6 col-md-4">
                            <button
                                className="grey-button ff-i600 w-100 me-2 border-radius bc-white c-grey-700 border-grey"
                                onClick={handleClose}>{rejectButtonText}</button>
                        </div>
                        <div className="col-6 col-md-4">
                            <button
                                disabled={disableCta}
                                color="primary"
                                className="blue-button ff-i600 w-100 me-2 border-radius bc-blue-500 px-2"
                                onClick={handleConfirmClick}>{confirmButtonText}</button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

ConfirmModal.propTypes = {
    show: PropTypes.bool.isRequired,
    messageHeader: PropTypes.string,
    confirmButtonText: PropTypes.string,
    rejectButtonText: PropTypes.string,
    handleConfirmClick: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default ConfirmModal;