import { useState, useEffect, useRef } from 'react';

const EmailInput = ({ email, setEmail, isValid, placeholder, emailRef, forceValidation = false, showIcon = true }) => {
    const emailReference = useRef();
    const [showError, setShowError] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);

    if (!emailRef)
        emailRef = emailReference;

    const error = "Enter a valid email address";
    const emptyError = "Your email must be at least 5 characters long and not more than 320";

    const isValidEmail = (value) => /.+@.+\..+/.test(value);
    const isNotEmpty = (value) => value.length > 5 && value.length < 320;

    useEffect(() => {
        if (!email.length && forceValidation) {
            emailRef.current.classList.toggle('invalid', true);
            setShowEmptyError(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, forceValidation])

    const reset = () => {
        setShowError(false);
        setShowEmptyError(false);
        emailRef.current.classList.toggle('invalid', false);
        emailRef.current.classList.toggle('valid', false);
    }

    const onChange = (value) => {
        setEmail(value);
        reset();

        if (!isNotEmpty(value)) {
            emailRef.current.classList.toggle('invalid', true);
            isValid(false);
            setShowEmptyError(true);
            return;
        }

        if (!isValidEmail(value)) {
            emailRef.current.classList.toggle('invalid', true);
            isValid(false);
            setShowError(true);
            return;
        }

        isValid(true);
        emailRef.current.classList.toggle('valid', true);
        setEmail(value);
    }

    return (
        <>
            <input
                type="text"
                className={`w-100 c-grey-700 ${showIcon ? 'icon-left' : ''}`}
                style={showIcon ? { backgroundImage: `url(${process.env.PUBLIC_URL}/images/icons/address-sign.svg)` } : {}}
                placeholder={placeholder}
                value={email}
                onChange={(e) => onChange(e.target.value)}
                ref={emailRef} />
            {showEmptyError && <div className="text-start c-red-500 px-2 text-s">{emptyError}</div>}
            {email && showError && <div className="text-start c-red-500 px-2 text-s">{error}</div>}
        </>
    );
}

export default EmailInput;