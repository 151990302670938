import { Modal } from 'components/atoms/Modal';

const EducationModal = ({
    isVisible,
    title,
    paragraphs,
    handleClose,
    handleAccept
}) => {

    return (
        <Modal
            isVisible={isVisible}
            backdrop
            size={'lg'}
            body={
                <div className="flex-column">
                    <div className="w-100 bc-violet-500 border-radius-top-medium mb-1" style={{ height: 16 }}></div>
                    <div className="w-100 text-center pt-5 px-4">
                        <h4 className="c-grey-700 pb-4">{title}</h4>
                        {
                            paragraphs?.map((paragraph, index) => {
                                return (
                                    <p
                                        key={index}
                                        className={`c-grey-700 ${paragraph.bold ? 'ff-i600' : ''} ${index !== paragraphs.length - 1 ? 'pb-4' : ''}`}>
                                        {paragraph.text}
                                    </p>
                                );
                            })
                        }
                    </div>
                </div>
            }
            footer={
                <div className="flex-fill px-5 py-4">
                    <div className="row justify-content-center">
                        <div className="col-6">
                            <button className="w-100 btn-secondary violet-500" onClick={() => handleClose()}>Not now</button>
                        </div>
                        <div className="col-6">
                            <button className="w-100 btn-primary violet-500" onClick={() => handleAccept()}>Start Tour</button>
                        </div>
                    </div>
                </div>
            }
        />
    );
};

export default EducationModal;