import { useState, useEffect } from 'react';

import useToast from 'hooks/useToast';
import useLoader from 'hooks/useLoader';

import EmailInput from 'components/atoms/EmailInput';
import CriteriaService from 'services/criteria-service';
import UsersService from 'services/users-service';
import ListingService from 'services/listing-service';

import Select from 'components/atoms/select/Select';
import MatchingsService from 'services/matchings-service';

const criteriaService = CriteriaService();
const listingService = ListingService();
const usersService = UsersService();
const matchingsService = MatchingsService();

const AddMatch = () => {
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [buyerEmail, setBuyerEmail] = useState('');
    const { toast } = useToast();
    const { load } = useLoader();

    const [deals, setDeals] = useState([]);

    const [sellerId, setSellerId] = useState(0);
    const [buyerId, setBuyerId] = useState(0);
    const [selectedDeal, setSelectedDeal] = useState(0);

    useEffect(() => {
        criteriaService.init();
        listingService.init();
        usersService.init();
        matchingsService.init();

        load(() =>
            listingService
                .getAllAdmin()
                .then(response => {
                    setDeals(
                        response?.data?.listingEntities
                            ?.filter((entity) => entity
                                && entity.listing
                                && entity.listing.id
                                && !entity.listing.deleted
                                && !entity.listing.deactivated
                                && entity.listing.completed
                                && entity.listing.verified)
                            ?.map(entity => {
                                return {
                                    id: entity?.listing?.id,
                                    name: `#${entity?.listing?.id} ${entity?.listing?.name}`,
                                    userId: entity?.listing?.userId,
                                };
                            })
                    );
                })
                .catch(error => toast.handleError(error))
        );

        return () => {
            criteriaService.dispose();
            listingService.dispose();
            usersService.dispose();
            matchingsService.dispose();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearchClick = e => {
        e.target.disabled = true;

        load(() =>
            usersService
                .getByEmail(buyerEmail)
                .then(response => setBuyerId(response?.data?.user?.id))
                .catch(error => toast.handleError(error))
        );

        setSelectedDeal(0);
    }

    const onMatchClick = () => {
        if (!buyerId || !sellerId || !selectedDeal)
            return;

        load(() =>
            matchingsService
                .post({
                    buyerId,
                    sellerId: sellerId,
                    dealId: selectedDeal
                })
                .then(() => toast.success({ body: `Match for the buyer - ${buyerEmail} has been created.` }))
                .catch(error => toast.handleError(error))
                .finally(_ => {
                    setSelectedDeal(0);
                    setSellerId(0);
                })
        );
    }

    return (
        <div className="p-3">
            <div className="row gx-3">
                <div className="col-12">
                    <div className="px-3 px-lg-5 py-4">
                        <h4 className="c-grey-700">Add match</h4>
                        <p className="c-grey-550 ff-i600 pb-3">
                            Enter buyer e-mail to match buyer with the selected deal.
                        </p>
                        <div className="row">
                            <div className="col-12 col-lg-8">
                                <EmailInput
                                    email={buyerEmail}
                                    setEmail={setBuyerEmail}
                                    placeholder="Enter buyer email"
                                    isValid={setIsValidEmail} />
                            </div>
                            <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                <button
                                    className="btn-secondary blue ff-i600 w-100 py-3"
                                    disabled={!isValidEmail}
                                    onClick={e => onSearchClick(e)}>
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>

                    {buyerId !== 0 &&
                        <>
                            <div className="row px-3 px-lg-5 py-4">
                                <div className="col-12 col-lg-8">
                                    <Select
                                        options={deals}
                                        isInheritHeight
                                        placeholder="Deal"
                                        onChange={newDeal => {
                                            setSelectedDeal(newDeal.id);
                                            setSellerId(newDeal.userId);
                                        }}
                                        keys={{ labelKey: 'name', valueKey: 'id' }}
                                        value={deals.find(deal => deal.id === selectedDeal) || null} />
                                </div>
                                <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                    <button
                                        className="btn-primary ff-i600 w-100"
                                        onClick={() => onMatchClick()}
                                        disabled={!selectedDeal || !sellerId || !buyerId}>
                                        Add Match
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default AddMatch;