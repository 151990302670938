import axios from 'axios';
import { traceError } from './common/response-handlers';

function FundsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; }
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getFunds = () =>
        axios
            .get(`/users/funds`, config())
            .catch(error => traceError(error, `/users/funds`, 'GET'));

    const createFund = (fundsAvailable, isFinancingNeeded, is1031Exchange) =>
        axios
            .post(`/users/funds`, {fundsAvailable, isFinancingNeeded, is1031Exchange}, config())
            .catch(error => traceError(error, `/users/funds`, 'GET'));

    const updateFund = (fundsAvailable, isFinancingNeeded, is1031Exchange) =>
        axios
            .put(`/users/funds`, {fundsAvailable, isFinancingNeeded, is1031Exchange}, config())
            .catch(error => traceError(error, `/users/funds`, 'GET'));

    return {
        init,
        dispose,
        getFunds,
        createFund,
        updateFund
    };
}

export default FundsService;