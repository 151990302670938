const tasksTabsArray = [
    { id: 1, label: 'All' },
    { id: 2, label: 'Active' },
    { id: 3, label: 'Expired' },
    { id: 4, label: 'Archived' },
];

const tasksTabs = {
    all: 'All',
    active: 'Active',
    expired: 'Expired',
    archived: 'Archived',
};

const taskStatuses = {
    active: 'ACTIVE',
    expired: 'EXPIRED',
    archived: 'ARCHIVED',
};

Object.freeze(tasksTabsArray);
Object.freeze(taskStatuses);
Object.freeze(tasksTabs);
export { tasksTabsArray, taskStatuses, tasksTabs };
