import { Buffer } from 'buffer';

const useBase64 = () => {
    const to = value => (value ? Buffer.from(value).toString('base64') : '');
    const from = value => (value ? Buffer.from(value, 'base64').toString() : '');

    return {
        to,
        from
    };
};

export default useBase64;
