const NotificationType =  {
    NewMatches: 1,
    NewLead: 2,
    NewDeal: 3,
    NewMessage: 4,
    NewDocument: 5,
    LeadUpdate: 6,
    DealUpdate: 7,
    Meeting: 8
};

Object.freeze(NotificationType);
export { NotificationType }