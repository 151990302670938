import { useState } from 'react';
import TasksService from 'services/tasks-service';
import useToast from 'hooks/useToast';
import useLoader from 'hooks/useLoader';

const tasksService = TasksService();

const useDeleteTask = ({ handleFetchTasks }) => {
    const { toast } = useToast();
    const { load } = useLoader();
    const [selectedId, setSelectedId] = useState(null);
    const [isVisibleDeleteTaskModal, setIsVisibleDeleteTaskModal] = useState(false);

    const handleOpenDeleteTaskModal = id => {
        setSelectedId(id);
        setIsVisibleDeleteTaskModal(true);
    };

    const handleCloseDeleteTaskModal = () => {
        setSelectedId(null);
        setIsVisibleDeleteTaskModal(false);
    };

    const handleDeleteTask = () => {
        if (!selectedId) return;
        load(() =>
            tasksService
                .deleteTask(selectedId)
                .then(handleFetchTasks)
                .then(() => {
                    setIsVisibleDeleteTaskModal(false);
                    setSelectedId(null);
                    toast.success({ body: 'Task was successfully deleted.' });
                })
                .catch(toast.handleError)
        );
    };

    return {
        handleDeleteTask,
        isVisibleDeleteTaskModal,
        handleOpenDeleteTaskModal,
        handleCloseDeleteTaskModal,
    };
};

export default useDeleteTask;
