import { useState, useEffect } from 'react';

const ListMobile = ({ data, paginate = false, itemsPerPage = 5 }) => {
    const [pagesNumber, setPagesNumber] = useState([1]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setPagesNumber(
            Array(Math.ceil(data.length / itemsPerPage))
                .fill(null)
                .map((_, i) => i + 1)
        );
    }, [data, itemsPerPage]);

    useEffect(() => {
        const slicedData = data.slice(currentPage * itemsPerPage - itemsPerPage, itemsPerPage * currentPage);
        if (!!slicedData.length) {
            return setPaginatedData(slicedData);
        }
        if (currentPage === 1) return;
        return setCurrentPage(prevState => prevState - 1);
    }, [pagesNumber, currentPage, itemsPerPage, data]);

    const renderContent = ({ title, description, emptyState, type, component, data, isColumnComponentDirection }, index) => {
        if (type === 'text')
            return (
                <div key={title} className="col-12 pt-1">
                    {title}:<span className="c-grey-700 ff-i600 ms-1 word-break">{description || emptyState}</span>
                </div>
            );
        if (type === 'currency')
            return (
                <div key={title} className="col-12 pt-1">
                    {title}:
                    <span className="c-grey-700 ff-i600 ms-1">
                        {description ? `$${new Intl.NumberFormat('en-US').format(description)}` : emptyState}
                    </span>
                </div>
            );
        if (type === 'component')
            return (
                <div key={title} className={"d-flex w-100 pt-1 " + (isColumnComponentDirection ? "flex-column" : "")}>
                    <div className="text-nowrap">{title ? `${title}: ` : ''}</div>
                    <span className={`c-grey-700 w-100 ff-i600 ${!!title && 'ms-1'}`}>{component(description, data, index) || emptyState}</span>
                </div>
            );
    };

    return (
        <div className="d-lg-none mt-3">
            {paginatedData.map(({ head, content, supportData }, index) => (
                <div className="row align-items-center ff-i500 c-grey-450 border-top-grey-150 bc-red-30 p-3" key={index}>
                    {head && (
                        <>

                            <div className="col-12">#{head?.title}</div>
                            <div className="col-12 c-grey-700 ff-i600 text-m pt-1">
                                {head.component && <>{head.component(head?.description || head.emptyState, index)}</>}
                                {!head.component && (<>{head?.description || head.emptyState}</>)}
                            </div>
                        </>
                    )}
                    {content.map(({ title, description, emptyState, type, component, isColumnComponentDirection }, idx) => (
                        <div key={idx} className="">
                            {renderContent({ title, description, emptyState, type, component, data: supportData, isColumnComponentDirection }, index)}
                        </div>
                    ))}
                </div>
            ))
            }
            {
                pagesNumber.length > 1 && paginate && (
                    <div className="p-4">
                        <div className="d-flex justify-content-center mb-4">
                            <p className="text-m m-0 ms-5 c-grey-550" style={{ width: '200px' }}>
                                Shown {currentPage * itemsPerPage - itemsPerPage + 1}-
                                {data.length < itemsPerPage * currentPage ? data.length : itemsPerPage * currentPage} of{' '}
                                {data.length} items
                            </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-2 mb-2">
                            <button
                                disabled={currentPage === 1}
                                onClick={() => {
                                    setCurrentPage(currentPage - 1);
                                    window.scrollTo(0, 0);
                                }}
                                className="d-flex align-items-center btn-borderless c-grey-550 me-2"
                            >
                                <span
                                    className="icon icon-arrow-right-grey img-rotate-180 me-1"
                                    style={{ width: '14px', height: '15px' }}
                                ></span>
                                Previous
                            </button>
                            <div className="d-flex align-items-center gap-1">
                                {pagesNumber?.map(page => {
                                    if (
                                        page === 1 ||
                                        page === pagesNumber.length ||
                                        page === currentPage ||
                                        (currentPage - page <= 1 && page - currentPage <= 1)
                                    )
                                        return (
                                            <button
                                                key={page}
                                                onClick={() => {
                                                    setCurrentPage(page);
                                                    window.scrollTo(0, 0);
                                                }}
                                                className={` ${currentPage === page ? 'btn-primary' : 'btn-borderless  c-grey-550'
                                                    } ff-i500 d-flex align-items-center justify-content-center `}
                                                style={{ width: '30px', height: '30px' }}
                                            >
                                                {page}
                                            </button>
                                        );
                                    if (currentPage - page === 2 || page - currentPage === 2) return <div>...</div>;
                                    return <></>;
                                })}
                            </div>
                            <button
                                disabled={pagesNumber.length === currentPage}
                                onClick={() => {
                                    setCurrentPage(currentPage + 1);
                                    window.scrollTo(0, 0);
                                }}
                                className="d-flex align-items-center btn-borderless c-grey-550 ms-2"
                            >
                                Next
                                <span
                                    className="icon icon-arrow-right-grey ms-2"
                                    style={{ width: '14px', height: '15px' }}
                                ></span>
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default ListMobile;
