import { useState, useEffect } from "react";

const EducationSlider = ({
    setIsOpen,
    className,
    steps = [{ number: 1, title: '', description: '' }],
}) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [currentTitle, setCurrentTitle] = useState("");
    const [currentDescription, setCurrentDescription] = useState("");

    useEffect(() => {
        setCurrentTitle(steps.find(({ number }) => number === currentStep)?.title);
        setCurrentDescription(steps.find(({ number }) => number === currentStep)?.description);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    return (
        <div className={`edu-wrapper ${className}`}>
            <div className="text-center c-white pt-3 pb-5 px-5 mx-2">
                <div className="ff-m700 text-xl mb-2">{currentTitle}</div>
                <div className="ff-i500 text-m">{currentDescription}</div>
            </div>
            <div className="edu-bubble-wrapper">
                {steps.map(({ number }) => (
                    <span
                        key={number}
                        className={`edu-bubble bc-white border-radius-round ${currentStep === number ? 'active' : ''}`}>
                    </span>
                ))}
            </div>
            <div className="btn-wrapper prev">
                <button
                    className="btn-arrow"
                    disabled={currentStep === 1}
                    onClick={() => currentStep > 1 && setCurrentStep(currentStep - 1)}>
                    <span className="icon icon-arrow-right img-rotate-180" style={{ width: "12px", height: "20px" }}></span>
                </button>
            </div>
            <div className="btn-wrapper next">
                <button
                    className="btn-arrow"
                    onClick={() => {
                        currentStep < steps.length && setCurrentStep(currentStep + 1);
                        if (currentStep === steps.length) {
                            setCurrentStep(1);
                            setIsOpen(false);
                        }
                    }}>
                    <span className="icon icon-arrow-right" style={{ width: "12px", height: "20px" }}></span>
                </button>
            </div>
        </div>
    )
}

export default EducationSlider
