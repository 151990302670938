import { useState, useEffect, useRef } from 'react';

const PhoneInput = ({
    number,
    setNumber,
    isValid,
    placeholder,
    forceValidation = false,
    showIcon = true,
    countryCode = '+1',
}) => {
    const phoneRef = useRef();
    const [showError, setShowError] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);

    const error = 'Please enter valid phone number';
    const emptyError = 'Your phone must be at least 8 characters long and not more than 10';

    const isValidPhone = value => /^[0-9]+$/.test(value);
    const isValidLength = value => value.length > 7 && value.length < 21;

    const reset = () => {
        setShowError(false);
        setShowEmptyError(false);
        phoneRef.current.classList.toggle('invalid', false);
        phoneRef.current.classList.toggle('valid', false);
    };

    useEffect(() => {
        if (!number && forceValidation) {
            phoneRef.current.classList.toggle('invalid', true);
            setShowError(true);
        }
    }, [number, forceValidation]);

    const onChange = value => {
        reset();
        if (!value) setNumber(value);

        if (!isValidPhone(value)) {
            phoneRef.current.classList.toggle('invalid', true);
            isValid(false);
            setShowError(true);
            return;
        }

        setNumber(value);

        if (!isValidLength(value)) {
            phoneRef.current.classList.toggle('invalid', true);
            isValid(false);
            setShowEmptyError(true);
            return;
        }

        phoneRef.current.classList.toggle('valid', true);
        isValid(true);
    };

    return (
        <>
            <div className={`mobileMainContainer`}>
                <input
                    type="text"
                    className={`w-10 c-grey-700 ${showIcon ? 'icon-left' : ''} disable-input`}
                    style={
                        showIcon
                            ? {
                                  backgroundImage: `url(${process.env.PUBLIC_URL}/images/icons/phone.svg)`,
                                  width: '90px',
                              }
                            : {}
                    }
                    value={countryCode}
                    disabled
                />
                <div className={`mobileNumberContainer`}>
                    <input
                        type="text"
                        className={`w-100 c-grey-700 disable-input`}
                        style={{ marginLeft: '10px' }}
                        placeholder={placeholder}
                        value={number}
                        maxLength="10"
                        onChange={e => onChange(e.target.value)}
                        ref={phoneRef}
                    />
                    {showEmptyError && <div className="c-red-500 px-2 text-s">{emptyError}</div>}
                    {showError && <div className="c-red-500 px-2 text-s">{error}</div>}
                </div>
            </div>
        </>
    );
};

export default PhoneInput;
