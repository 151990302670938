import { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useNotifications } from 'contexts/NotificationsContext';
import { useProfile } from 'contexts/ProfileContext';
import NewMatchesContext from 'contexts/NewMatchesContext';

import useEducationCenter, { BUYER_OVERVIEW_EDUCATION } from 'hooks/useEducationCenter';
import EducationModal from 'components/molecules/education-system/EducationModal';
import Tooltip from 'components/atoms/Tooltip';

import UsersService from 'services/users-service';
import OrganizationsService from 'services/organizations-service';
import CompanyProfileService from 'services/company-profile-service';
import CommunitiesService from 'services/communities-service';
import FundsService from 'services/funds-service';

const usersService = UsersService();
const companyProfileService = CompanyProfileService();
const communitiesService = CommunitiesService();
const fundsService = FundsService();
const organizationsService = OrganizationsService();

const Sidebar = ({ showEducationalModal }) => {
    const location = useLocation();
    const { path } = useRouteMatch();

    const [isOpenMenu, setIsOpenMenu] = useState(null);

    const {
        isFulfilled,
        setFulfilled,
        isAssetFulfilled,
        setAssetFulfilled,
        isNoCommunities,
        setNoCommunities,
        isFundsFulfilled,
        setFundsFulfilled
    } = useProfile();
    const { newMatchesAmount } = useContext(NewMatchesContext);

    const BUYERS_DASHBOARD_PATH = path;
    const NEW_MATCHES_PATH = `${path}/new-matches`;
    const MY_DEALS_PATH = `${path}/deals`;
    const CHAT_PATH = `${path}/chat`;
    const BUYING_CRITERIA_PATH = `${path}/criteria`;
    const MEETINGS_PATH = `${path}/meetings`;
    const PROFILE_PATH = `${path}/profile`

    const BUYERS_DASHBOARD_ICON = 'dashboard-2';
    const NEW_MATCHES_ICON = 'new-deals';
    const MY_DEALS_ICON = 'my-deals';
    const CHAT_ICON = 'chat';
    const BUYING_CRITERIA_ICON = 'criteria';
    const MEETINGS_ICON = 'calendar';
    const PROFILE_ICON = 'profile';
    const PROFILE_WARNING_ICON = 'profile-warning';

    const buttons = [
        {
            name: 'Dashboard',
            path: BUYERS_DASHBOARD_PATH,
            icon: BUYERS_DASHBOARD_ICON,
            warning: false
        },
        {
            name: 'New Matches',
            path: NEW_MATCHES_PATH,
            icon: NEW_MATCHES_ICON,
            warning: false
        },
        {
            name: 'My Deals',
            path: MY_DEALS_PATH,
            icon: MY_DEALS_ICON,
            warning: false
        },
        {
            name: 'Chat',
            path: CHAT_PATH,
            icon: CHAT_ICON,
            warning: false
        },
        {
            name: 'Buying Criteria',
            path: BUYING_CRITERIA_PATH,
            icon: BUYING_CRITERIA_ICON,
            warning: false
        },
        {
            name: 'Meetings',
            path: MEETINGS_PATH,
            icon: MEETINGS_ICON
        },
        {
            name: 'Profile',
            path: PROFILE_PATH,
            icon: PROFILE_ICON,
            warning: !isFulfilled || !isFundsFulfilled || (!isAssetFulfilled && !isNoCommunities)
        }
    ];

    /* Education section start */
    const {
        getEducationItem,
        acceptEducation,
        rejectEducation,
        moveNext,
        moveLast } = useEducationCenter();
    const educationScope = BUYER_OVERVIEW_EDUCATION;
    const tooltipsTotal = 6;
    const [needEducation, setNeedEducation] = useState(false);
    const [educationOverview, setEducationOverview] = useState({});

    const onEducationAccepted = () => acceptEducation(educationScope, educationOverview, setEducationOverview);
    const onEducationRejected = () => rejectEducation(setEducationOverview);
    const onLastTooltipClicked = () => moveLast(educationScope, educationOverview, setEducationOverview);

    const onTooltipClicked = (order) => {
        if (order === tooltipsTotal)
            onLastTooltipClicked();
        else {
            moveNext(educationScope, order, educationOverview, setEducationOverview);
        }
    }

    const showEducationalTooltip = order =>
        showEducationalModal && needEducation && location.pathname === path && (
            !educationOverview?.rejected
            && educationOverview?.modalShown
            && !educationOverview?.tooltipsShown
            && educationOverview?.order === order);

    const tooltips = [
        {
            content: "Your dashboard is the heartbeat of your business. From here, you can see your new matches, which deals you’ve already been matched to, and set your buying criteria. Also, get a quick view of your notifications to stay up to date."
        },
        {
            content: "After entering your buying criteria, our AI matchmaking gets to work. In your New Matches panel, you’ll see the deals that best align with your buying criteria. You can enter multiple criteria to easily stay organized and keep your matches highly relevant!"
        },
        {
            content: "Say goodbye to emails, spreadsheets and whatever other tool from 2003 you may be using to track your deals. Once a seller grants you access, all of your deal info live inside your Deals panel."
        },
        {
            content: "Spend too much time in email and on the phone? We’ve got your back. Securely chat with your sellers who’ve invited you into their deal and cut down the communication wait time from days to seconds."
        },
        {
            content: "Stop searching. Our AI matchmaking will do that for you. Set your buying criteria, and our platform will match you with the best options."
        },
        {
            content: "All that back and forth of ‘Let’s find a time to connect!’ is gone. With our meeting scheduling feature, you can book meetings directly with sellers based on the availability that they’ve set."
        }
    ];

    /* Education section end */

    const [currentButtons, setCurrentButtons] = useState([]);
    const [hiddenButtons, setHiddenButtons] = useState([]);

    const { notificationsData } = useNotifications();

    const moveArrayItemToNewIndex = (arr, from, to) => {
        if (to >= arr.length) {
            let k = to - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(to, 0, arr.splice(from, 1)[0]);
    };

    useEffect(() => {
        usersService.init();
        companyProfileService.init();
        communitiesService.init();
        fundsService.init();
        organizationsService.init();

        setEducationOverview(getEducationItem(educationScope));

        fundsService
            .getFunds()
            .then(response =>
                setFundsFulfilled(response?.data?.funds?.fundsAvailable > 0)
            ).catch(() => setFundsFulfilled(false));

        companyProfileService
            .getCompanyInformation()
            .then(result => setFulfilled(!result?.data?.jobTitle))
            .catch(() => setFulfilled(false));

        communitiesService
            .getCommunities()
            .then(communitiesResponse => {
                setNoCommunities(communitiesResponse?.data?.noCommunities?.noCommunities);
                setAssetFulfilled(!!communitiesResponse?.data?.communities?.length);
            });

        usersService
            .getStats()
            .then(statsResponse => {
                if (!statsResponse?.data?.stats?.newDealsAmount
                    && !statsResponse?.data?.stats?.activeDealsAmount
                    && !statsResponse?.data?.stats?.buyerCriteriaAmount) {
                    setNeedEducation(true);
                }
            });

        return () => {
            usersService.dispose();
            fundsService.dispose();
            organizationsService.dispose();
            companyProfileService.dispose();
            communitiesService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (location.pathname === BUYING_CRITERIA_PATH)
            moveArrayItemToNewIndex(buttons, 4, 3);

        if (location.pathname === MEETINGS_PATH)
            moveArrayItemToNewIndex(buttons, 5, 3);

        if (location.pathname.includes('profile'))
            moveArrayItemToNewIndex(buttons, 6, 3);

        setCurrentButtons(buttons.slice(0, 4));
        setHiddenButtons(buttons.slice(4, buttons.length));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, isFulfilled, isFundsFulfilled, isAssetFulfilled, isNoCommunities]);

    const activeLink = (href, pathname = location.pathname) => pathname === href
        || (pathname.includes('/profile') && href === PROFILE_PATH)
        || (pathname.includes('/deals/') && href === MY_DEALS_PATH)
        || (pathname.includes('/agreement/') && href === NEW_MATCHES_PATH);

    function CustomLink({ href, icon, children, ...props }) {
        return (
            <Link
                to={{ pathname: href, state: { showNavTabs: true } }}
                className={`position-relative d-flex align-items-center c-grey-550 border-radius text-decoration-none p-2 mb-3 link ${activeLink(href) ? 'active c-blue-500' : ''}`}
                {...props}>
                <span style={{ width: 24 }} className={`icon icon-${icon} ${!activeLink(href) ? 'icon-grayscale' : ''}`}></span>
                <span className='ff-i600 ms-3 sidebar-text'>{children}</span>
            </Link>
        );
    }

    function CustomLinkMobile({ href, icon, children, ...props }) {
        return (
            <Link
                to={{ pathname: href, state: { showNavTabs: true } }}
                className={`position-relative d-flex flex-column align-items-center border-radius text-decoration-none mt-2 w-100 link ${activeLink(href) ? 'active' : ''
                    }`}
                {...props}>
                <div className="p-2 border-radius icon-overlay">
                    <span
                        style={{ height: 28, width: 28 }}
                        className={`icon icon-${icon} ${!activeLink(href) ? 'icon-grayscale' : 'active'} d-flex justify-content-between`}></span>
                </div>
                <span
                    className={`ff-i600 mt-2 text-center ${activeLink(href) ? 'c-blue-500' : ''}`}
                    style={{ fontSize: '10px' }}>
                    {children}
                </span>
            </Link>
        );
    }

    const handleIsShowNotification = (tabName) => {
        if (tabName === "New Matches" && newMatchesAmount > 0) return true;
        if (tabName === "My Deals" && notificationsData?.buyer?.dealNotifications?.some(n => (n.new || n.update))) return true;
        if (tabName === "Meetings" && notificationsData?.buyer?.dealNotifications?.some(n => n.meeting)) return true;
        return !!(tabName === "Chat" && notificationsData?.buyer?.dealNotifications?.some(n => (n.message || n.new || n.document)));
    };

    return (
        <>
            <div
                className='d-none d-lg-block sidebar d-flex flex-column border-right-grey-200 px-3'
                style={{ zIndex: !educationOverview.tooltipsShown && educationOverview?.modalShown && '1049' }}>
                <CustomLink href={BUYERS_DASHBOARD_PATH} icon={BUYERS_DASHBOARD_ICON}>
                    <Tooltip
                        show={showEducationalTooltip(0)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(1)}>
                        <span className="ff-i600">Buyer Dashboard</span>
                    </Tooltip>
                </CustomLink>

                <CustomLink href={NEW_MATCHES_PATH} icon={NEW_MATCHES_ICON}>
                    <Tooltip
                        show={showEducationalTooltip(1)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(2)}>
                        <span className="ff-i600">New Matches</span>
                        {newMatchesAmount > 0 &&
                            <span className="position-absolute dot bc-blue-500 align-self-start"
                                style={{ height: '10px', width: '10px', top: '7px', right: '6px' }} />}
                    </Tooltip>
                </CustomLink>

                <CustomLink href={MY_DEALS_PATH} icon={MY_DEALS_ICON}>
                    <Tooltip
                        show={showEducationalTooltip(2)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(3)}>
                        <span className="ff-i600">My Deals</span>
                        {notificationsData?.buyer?.dealNotifications?.some(n => (n.new || n.update)) &&
                            <span className="position-absolute dot bc-blue-500 align-self-start"
                                style={{ height: '10px', width: '10px', top: '7px', right: '6px' }} />
                        }
                    </Tooltip>
                </CustomLink >
                <CustomLink href={CHAT_PATH} icon={CHAT_ICON}>
                    <Tooltip
                        show={showEducationalTooltip(3)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(4)}>
                        <span className="ff-i600">Chat</span>
                        {notificationsData?.buyer?.dealNotifications?.some(n => (n.message || n.new || n.document)) &&
                            <span className="position-absolute dot bc-blue-500 align-self-start"
                                style={{ height: '10px', width: '10px', top: '7px', right: '6px' }} />
                        }
                    </Tooltip>
                </CustomLink >
                <CustomLink href={BUYING_CRITERIA_PATH} icon={BUYING_CRITERIA_ICON}>
                    <Tooltip
                        show={showEducationalTooltip(4)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(5)}>
                        <span className="ff-i600">Buying Criteria</span>
                    </Tooltip>
                </CustomLink>
                <CustomLink href={MEETINGS_PATH} icon={MEETINGS_ICON}>
                    <Tooltip
                        show={showEducationalTooltip(5)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(6)}>
                        <span className="ff-i600">Meetings</span>
                        {notificationsData?.buyer?.dealNotifications?.some(n => n.meeting) &&
                            <span className="position-absolute dot bc-blue-500 align-self-start"
                                style={{ height: '10px', width: '10px', top: '7px', right: '6px' }} />
                        }
                    </Tooltip>
                </CustomLink>
                <CustomLink href={PROFILE_PATH} icon={PROFILE_ICON} pathname={location.pathname}>
                    Profile
                    {((!isAssetFulfilled && !isNoCommunities) || !isFundsFulfilled || !isFulfilled) &&
                        <span className="icon icon-warning-orange ms-2" style={{ width: '20px', height: '20px', verticalAlign: 'text-bottom' }}></span>
                    }
                </CustomLink>
            </div >

            {
                isOpenMenu !== null &&
                <div className="d-lg-none">
                    {isOpenMenu &&
                        <div
                            className='underlay'
                            onClick={() => setIsOpenMenu(false)} />
                    }
                    <div className={`mobile-sidebar ${isOpenMenu ? 'slide-in' : 'slide-out'}`}
                        style={{ height: 241 }}>
                        <h5 className="text-center c-grey-700 pt-3">More</h5>
                        <div className='px-4 pt-3'>
                            {hiddenButtons.map(e =>
                                <CustomLink
                                    key={e.name}
                                    href={e.path}
                                    icon={e.icon}
                                    pathname={location.pathname}
                                    onClick={() => setIsOpenMenu(false)}>
                                    {e.name}
                                    {e.warning &&
                                        <span className="icon icon-warning-orange ms-2" style={{ width: '20px', height: '20px', verticalAlign: 'text-bottom' }}></span>
                                    }
                                </CustomLink>
                            )}
                        </div>
                        <div
                            className='d-flex justify-content-center py-3'
                            onClick={() => setIsOpenMenu(false)}>
                            <div className='grey-line' />
                        </div>
                    </div>
                </div>
            }

            <div
                className='d-flex d-lg-none justify-content-between position-fixed bottom-0 border-top-grey-100 sidebar bc-white w-100 pb-2'
                style={{ zIndex: 1000 }}>
                <Tooltip
                    show={showEducationalTooltip(educationOverview?.order)}
                    content={tooltips[educationOverview?.order]?.content}
                    secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                    direction="top"
                    additionalClasses={`arrow-left-${educationOverview?.order + 1}-5`}
                    bodyClasses="d-flex d-lg-none justify-content-between position-fixed bottom-0 border-top-grey-100 sidebar bc-white w-100 pb-2"
                    ctaText="Got it!"
                    ctaAction={() => onTooltipClicked(educationOverview?.order + 1)}>
                    {
                        currentButtons.map(e =>
                            <CustomLinkMobile
                                key={e.name}
                                href={e.path}
                                icon={e.warning ? PROFILE_WARNING_ICON : e.icon}
                                pathname={location.pathname}>
                                {e.name}
                                {handleIsShowNotification(e.name) && <span className="position-absolute dot bc-blue-500 align-self-start"
                                    style={{ height: '10px', width: '10px', top: 0, right: '12px' }} />}
                            </CustomLinkMobile>
                        )
                    }
                    <div
                        className='active-link d-flex flex-column align-items-center justify-content-between border-radius mt-2 w-100 cursor-pointer'
                        onClick={() => setIsOpenMenu(!isOpenMenu)}>
                        <div className="pt-2">
                            {((!isAssetFulfilled && !isNoCommunities) || !isFulfilled || !isFundsFulfilled) && !currentButtons.find(b => b.name === 'Profile')
                                ? <span className={`icon icon-burger-menu-warning`}></span>
                                : <span className={`icon icon-burger-menu ${!isOpenMenu ? 'icon-grayscale' : ''}`} style={{ width: 28, height: 28 }}></span>
                            }
                        </div>
                        <span
                            className={`acive-blue ff-i600 c-grey-700 ${isOpenMenu ? 'c-blue-500' : ''}`}
                            style={{ fontSize: '10px' }}>
                            More
                        </span>
                    </div>
                </Tooltip>
            </div>

            {
                showEducationalModal && needEducation && location.pathname === path &&
                <EducationModal
                    isVisible={
                        !educationOverview?.rejected
                        && !educationOverview?.modalShown}
                    title={'Connect. Transact. Grow. All in one place.'}
                    paragraphs={
                        [
                            { text: 'Welcome to the New 1Konnection Dealmaking Platform, your new one-stop shop for everything you need to get more deals done in the Senior Housing & Healthcare Real Estate space.' },
                            { text: 'Gone are the days of manual outreach, complicated deal flow, and using dozens of tools. Our new confidential dealmaking platform changes what’s possible for our industry. From a bespoke CRM and confidential doc sharing to AI matchmaking and an industry-specific communication platform, we’ve got you (and your business) covered.' },
                            { text: 'Let’s take a look around.', bold: true }
                        ]}
                    handleAccept={onEducationAccepted}
                    handleClose={onEducationRejected} />
            }

        </>
    );
};

export default Sidebar;