import { useState, useEffect } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

const Sidebar = () => {
    const { path } = useRouteMatch();
    const location = useLocation();

    const USER_SIMULATION_PATH = path;
    const USER_METADATA_PATH = `${path}/metadata`;
    const ADD_MATCH_PATH = `${path}/add-match`;
    const MESSAGE_BROADCASTING_PATH = `${path}/message-broadcasting`;
    const VERIFICATION_EMAIL_PATH = `${path}/verification-email`;
    const VERIFICATION_USERS_PATH = `${path}/verification-users`;

    const USER_SIMULATION_ICON = 'toggle';
    const USER_METADATA_ICON = 'folder';
    const ADD_MATCH_ICON = 'new-deals';
    const MESSAGE_BROADCASTING_ICON = 'chat';
    const VERIFICATION_EMAIL_ICON = 'lock';
    const VERIFICATION_USERS_ICON = 'profile';

    const buttons = [
        {
            name: 'User Simulation',
            path: USER_SIMULATION_PATH,
            icon: USER_SIMULATION_ICON
        },
        {
            name: 'User Metadata',
            path: USER_METADATA_PATH,
            icon: USER_METADATA_ICON
        },
        {
            name: 'Add Match',
            path: ADD_MATCH_PATH,
            icon: ADD_MATCH_ICON
        },
        {
            name: 'Message Broadcasting',
            path: MESSAGE_BROADCASTING_PATH,
            icon: MESSAGE_BROADCASTING_ICON
        },
        {
            name: 'Email Verification',
            path: VERIFICATION_EMAIL_PATH,
            icon: VERIFICATION_EMAIL_ICON
        },
        {
            name: 'Users Verification',
            path: VERIFICATION_USERS_PATH,
            icon: VERIFICATION_USERS_ICON
        }
    ];

    const [currentButtons, setCurrentButtons] = useState([]);
    const [hiddenButtons, setHiddenButtons] = useState([]);
    const [isOpenMenu, setIsOpenMenu] = useState(null);

    const moveArrayItemToNewIndex = (arr, from, to) => {
        if (to >= arr.length) {
            let k = to - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(to, 0, arr.splice(from, 1)[0]);
    };

    useEffect(() => {
        if (location.pathname === VERIFICATION_EMAIL_PATH)
            moveArrayItemToNewIndex(buttons, 4, 3);

        if (location.pathname === VERIFICATION_USERS_PATH)
            moveArrayItemToNewIndex(buttons, 5, 3);

        setCurrentButtons(buttons.slice(0, 4));
        setHiddenButtons(buttons.slice(4, buttons.length));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const activeLink = (href, pathname = location.pathname) => pathname === href;

    function CustomLink({ href, pathname, icon, children, ...props }) {
        return (
            <Link
                to={{ pathname: href, state: { showNavTabs: true } }}
                className={`position-relative d-flex align-items-center c-grey-550 border-radius text-decoration-none p-2 mb-3 link ${activeLink(href) ? 'active c-blue-500' : ''}`}
                {...props}>
                <span style={{ width: 24 }} className={`icon icon-${icon} ${!activeLink(href) ? 'icon-grayscale' : ''}`}></span>
                <span className='ff-i600 ms-3 sidebar-text'>{children}</span>
            </Link>
        );
    }

    function CustomLinkMobile({ href, pathname, icon, children, ...props }) {
        return (
            <Link
                to={{ pathname: href, state: { showNavTabs: true } }}
                className={`position-relative d-flex flex-column align-items-center border-radius text-decoration-none mt-2 w-100 link 
                ${activeLink(href) ? 'active' : ''}`}
                {...props}
            >
                <div className="p-2 border-radius icon-overlay">
                    <span
                        style={{ height: 28, width: 28 }}
                        className={`icon icon-${icon} ${!activeLink(href) ? 'icon-grayscale' : ''} d-flex justify-content-between`}
                    ></span>
                </div>
                <span
                    className={`ff-i600 mt-2 text-center ${activeLink(href) ? 'c-blue-500' : ''}`}
                    style={{ fontSize: '10px' }}
                >
                    {children}
                </span>
            </Link>
        );
    }

    return (
        <>
            <div className="d-none d-lg-block sidebar d-flex flex-column border-right-grey-200 px-3">
                {buttons.map(button =>
                    <CustomLink
                        key={button.name}
                        href={button.path}
                        icon={button.icon}
                        pathname={location.pathname}>
                        {button.name}
                    </CustomLink>
                )}
            </div >

            {isOpenMenu !== null &&
                <div className="d-lg-none">
                    {isOpenMenu &&
                        <div
                            className='underlay'
                            onClick={() => setIsOpenMenu(false)} />
                    }
                    <div className={`mobile-sidebar ${isOpenMenu ? 'slide-in' : 'slide-out'}`}
                        style={{ height: 185 }}>
                        <h5 className="text-center c-grey-700 pt-3">More</h5>
                        <div className='px-4 pt-3'>
                            {hiddenButtons.map(e =>
                                <CustomLink
                                    key={e.name}
                                    href={e.path}
                                    icon={e.icon}
                                    pathname={location.pathname}
                                    onClick={() => setIsOpenMenu(false)}>
                                    {e.name}
                                </CustomLink>
                            )}
                        </div>
                        <div
                            className='d-flex justify-content-center py-3'
                            onClick={() => setIsOpenMenu(false)}>
                            <div className='grey-line' />
                        </div>
                    </div>
                </div>
            }

            <div
                className='d-flex d-lg-none justify-content-between position-fixed border-top-grey-100 bottom-0 sidebar bc-white w-100 pb-2'
                style={{ zIndex: 1000 }}>
                {
                    currentButtons.map(e =>
                        <CustomLinkMobile
                            key={e.name}
                            href={e.path}
                            icon={e.icon}
                            pathname={location.pathname}>
                            {e.name}
                        </CustomLinkMobile>
                    )
                }
                <div
                    className='active-link d-flex flex-column align-items-center justify-content-between border-radius mt-2 w-100 cursor-pointer'
                    onClick={() => setIsOpenMenu(!isOpenMenu)}>
                    <span className={`mt-2 icon icon-burger-menu ${!isOpenMenu ? 'icon-grayscale' : ''}`} style={{ width: 28, height: 28 }}></span>
                    <span
                        className={`acive-blue ff-i600 c-grey-700 ${isOpenMenu ? 'c-blue-500' : ''}`}
                        style={{ fontSize: '10px' }}>
                        More
                    </span>
                </div>
            </div>
        </>
    )
}

export default Sidebar;