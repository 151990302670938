import createdDateLinkComparer from './createdDateLinkComparer';
import sharedDateFileComparer from './sharedDateFileComparer';

const sortLinks = (links = []) => [
    ...links.filter(f => f.sharedDate && f.pinned).sort(sharedDateFileComparer),
    ...links.filter(f => f.sharedDate && !f.pinned).sort(sharedDateFileComparer),
    ...links.filter(f => !f.sharedDate && f.pinned).sort(createdDateLinkComparer),
    ...links.filter(f => !f.sharedDate && !f.pinned).sort(createdDateLinkComparer),
];

export default sortLinks;