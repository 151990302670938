import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import { SENT_EMAIL_VERIFICATION_KEY } from 'hooks/useLocalStorage';
import useToast from 'hooks/useToast';
import useLoader from 'hooks/useLoader';

import analyticsService from 'services/common/analytic-trackers';
import AuthService from 'services/auth-service';
import UsersService from 'services/users-service';

import PhoneInput from 'components/atoms/PhoneInput';
import EmailInput from 'components/atoms/EmailInput';
import NameInput from 'components/atoms/NameInput';
import PasswordInput from 'components/atoms/PasswordInput';
import Testimonials from 'pages/Landing/components/Testimonials';
import Select from 'components/atoms/select/Select';
import OneKonnectionPromo from 'components/molecules/OneKonnectionPromo';

const authService = AuthService();
const usersService = UsersService();

function Register() {
    const { toast } = useToast();
    const { load } = useLoader();
    const history = useHistory();
    const signUpRef = useRef();

    const [userTypes, setUserTypes] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [isValidFirstName, setIsValidFirstName] = useState(false);

    const [lastName, setLastName] = useState('');
    const [isValidLastName, setIsValidLastName] = useState(false);

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(false);

    const [agreeWithPolicies, setAgreeWithPolicies] = useState(false);
    const [role, setRole] = useState(null);

    const newPasswordRef = useRef();
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordShown, setNewPasswordShown] = useState(false);

    const confirmPasswordRef = useRef();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);

    const [isValidNewPassword, setIsValidNewPassword] = useState(false);
    const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
    const [showMatchPasswordErrorMessage, setShowMatchPasswordErrorMessage] = useState(false);

    useEffect(() => {
        setShowMatchPasswordErrorMessage(false);
        if (confirmPassword !== newPassword) {
            confirmPasswordRef.current.classList.toggle('invalid', true);
            setShowMatchPasswordErrorMessage(true);
            return;
        }

        if (isValidConfirmPassword) {
            confirmPasswordRef.current.classList.toggle('invalid', false);
            confirmPasswordRef.current.classList.toggle('valid', true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmPassword, newPassword]);

    useEffect(() => {
        document.title = '1Konnection - Register';
        analyticsService.trackPageview();

        authService.init();
        usersService.init();
        usersService
            .getTypes()
            .then(({ userTypes = [] }) => {
                let types = userTypes.filter(t => t.name !== 'Broker - Buying & Selling');
                if (types && types.length > 0 && types.some(x => x.id === 3))
                    types.push(
                        types.splice(
                            types.findIndex(x => x.id === 3),
                            1
                        )[0]
                    );
                setUserTypes(types);
            })
            .catch(error => toast.handleError(error));

        return () => {
            authService.dispose();
            usersService.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const postRegister = e => {
        e.preventDefault();
        if (signUpRef.current) signUpRef.current.setAttribute('disabled', 'disabled');
        load(() =>
            authService
                .register({
                    firstName,
                    lastName,
                    email,
                    phone: phoneNumber,
                    type: role.id,
                    password: newPassword,
                    CountryCode: '+1',
                })
                .then(_ => {
                    analyticsService.trackSignUp({ method: 'Registration' });
                    toast.success({ body: 'You have been successfully registered.' });
                    localStorage.removeItem(SENT_EMAIL_VERIFICATION_KEY);
                    history.push('/secure/verify-email', { email: email });
                })
                .catch(error => {
                    if (!error || !error.response) return;
                    toast.handleError(error);
                    if (error.response.status === 409) {
                        analyticsService.trackExistingUser();
                        history.push('/secure');
                        return;
                    }
                })
                .finally(() => signUpRef.current && signUpRef.current.removeAttribute('disabled'))
        );
    };

    return (
        <div>
            <div className="container" id="user-register">
                <form onSubmit={postRegister}>
                    <div className="row bc-white mt-4 mx-2 mx-md-0 border-radius-small">
                        <div className="col-12 text-center">
                            <h2 className="c-grey-900 pb-md-2">Register</h2>
                            <p className="c-grey-550 text-center">
                                Join over 6,000 members who have already joined the largest
                                <br />
                                Senior Housing & Healthcare Real Estate Dealmaking Platform and Marketplace.
                            </p>
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 mt-4 pt-md-2 px-3 px-md-2">
                            <NameInput
                                name={firstName}
                                setName={setFirstName}
                                isValid={setIsValidFirstName}
                                placeholder="First Name"
                                errorMessage="Any special characters (e.g., @#$%^&*) or digits are not allowed"
                            />
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 mt-4 pt-md-2 px-3 px-md-2">
                            <NameInput
                                name={lastName}
                                setName={setLastName}
                                isValid={setIsValidLastName}
                                placeholder="Last Name"
                                errorMessage="Any special characters (e.g., @#$%^&*) or digits are not allowed"
                            />
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 mt-4 pt-md-2 px-3 px-md-2">
                            <Select
                                value={role}
                                options={userTypes}
                                isInheritHeight={true}
                                isClearable
                                onChange={setRole}
                                icon={'supervisor-account'}
                                placeholder={'Buyer, Seller or Broker?'}
                                keys={{ valueKey: 'id', labelKey: 'name' }}
                            />
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 mt-4 pt-md-2 px-3 px-md-2">
                            <EmailInput
                                email={email}
                                setEmail={setEmail}
                                isValid={setIsValidEmail}
                                placeholder="Email"
                            />
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 mt-4 pt-md-2 px-3 px-md-2">
                            <PhoneInput
                                number={phoneNumber}
                                setNumber={setPhoneNumber}
                                isValid={setIsValidPhone}
                                placeholder="Mobile Number"
                            />
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 mt-4 pt-md-2 px-3 px-md-2">
                            <PasswordInput
                                passwordShown={newPasswordShown}
                                setPasswordShown={setNewPasswordShown}
                                password={newPassword}
                                setPassword={setNewPassword}
                                passwordRef={newPasswordRef}
                                autocomplete="new-password"
                                isValid={setIsValidNewPassword}
                                placeholder={'Password'}
                            />
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 mt-4 pt-md-2 px-3 px-md-2">
                            <PasswordInput
                                passwordShown={confirmPasswordShown}
                                setPasswordShown={setconfirmPasswordShown}
                                password={confirmPassword}
                                setPassword={setConfirmPassword}
                                passwordRef={confirmPasswordRef}
                                autocomplete="new-password"
                                isValid={setIsValidConfirmPassword}
                                placeholder={'Confirm Password'}
                            />

                            {showMatchPasswordErrorMessage && (
                                <span className="text-start c-red-500 px-2 text-s">Password does not match.</span>
                            )}
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 mt-4 mb-md-2 pt-md-2 px-3 px-md-2 c-grey-550">
                            <div className="row">
                                <div className="col-1">
                                    <input
                                        type="checkbox"
                                        id="remember-me"
                                        onChange={() => setAgreeWithPolicies(!agreeWithPolicies)}
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="remember-me" className="ff-i500">
                                        I agree with the
                                        <Link className="text-decoration-none ms-1" to="/terms">
                                            Terms of Service
                                        </Link>
                                        <span> and </span>
                                        <Link className="text-decoration-none" to="/privacy">
                                            Privacy Policy
                                        </Link>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 offset-md-3 pb-3 px-2 c-grey-550 px-3 px-md-2 ">
                            <button
                                className="btn-primary ff-i600 w-100 mt-4"
                                disabled={
                                    !firstName ||
                                    !isValidFirstName ||
                                    !lastName ||
                                    !isValidLastName ||
                                    !email ||
                                    !isValidEmail ||
                                    !phoneNumber ||
                                    !isValidPhone ||
                                    !role ||
                                    !isValidNewPassword ||
                                    !isValidConfirmPassword ||
                                    newPassword !== confirmPassword ||
                                    !agreeWithPolicies
                                }
                                ref={signUpRef}
                            >
                                Register
                            </button>
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 pb-md-3 px-2 c-grey-550 px-3 px-md-2 ">
                            <p className="ff-i500 c-grey-450 text-center">
                                <span>
                                    Already have an account?
                                    <Link to="/secure" className="text-decoration-none ms-1">
                                        Login
                                    </Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </form>
                <div className="d-none d-md-block">
                    <Testimonials />
                </div>
            </div>

            <OneKonnectionPromo />
        </div>
    );
}

export default Register;
