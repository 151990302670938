import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthHandler from 'services/common/auth-handler';

const authHandler = AuthHandler();

const ResourceCenterTile = () => {
    const [isRegistered, setIsRegistered] = useState(authHandler.isRegistered());
    useEffect(() => {
        const unsubscribe = authHandler.subscribe(({ isRegistered }) => setIsRegistered(isRegistered));
        return () => unsubscribe();
    }, []);

    const history = useHistory();

    return (
        <div className="reveal-bottom">
            {isRegistered &&
                <div className="row justify-content-center tile b-white-gradient border-radius mt-2 mx-2 mb-5"
                    onClick={() => history.push({
                        pathname: '/survey',
                        state: { typeFormUrl: 'https://1konnection.typeform.com/to/pYFLr5Iq#email=' }
                    })}>
                    <div className="col-7 col-lg-4 order-2 order-lg-1 pt-lg-3">
                        <div className="pe-5 pt-1">
                            <img className="w-100" alt="discover" src={`${process.env.PUBLIC_URL}/images/landing/discover-vendors.png`} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start pt-4 pt-lg-0 pe-md-3 pe-lg-0">
                        <div>
                            <h3 className="tile-title-blue text-center text-lg-start pb-2">Owners &#38; Operators</h3>
                            <div className="text-m ff-i500">Simplify how you find the right services, vendors &#38; resources</div>
                        </div>
                    </div>
                    <div className="col-2 order-3 d-none d-lg-flex align-items-center justify-content-center">
                        <div className="zoom">
                            <img alt="arrow-black" className="w-100 btn-black"
                                src={`${process.env.PUBLIC_URL}/images/icons/arrow-black.svg`} />
                            <img alt="arrow-blue" className="w-100 btn-blue"
                                src={`${process.env.PUBLIC_URL}/images/icons/arrow-blue.svg`} />
                        </div>
                    </div>
                </div>
            }
            <div className={`row justify-content-center tile border-radius mt-2 mx-2 mb-5 ${isRegistered ? "b-white-gradient" : "b-blue-gradient"}`}
                onClick={() => history.push({
                    pathname: '/survey',
                    state: { typeFormUrl: 'https://1konnection.typeform.com/to/ndxE4m4K#email=' }
                })}>
                <div className="col-7 col-lg-4 order-2 order-lg-1 pt-lg-3">
                    <div className="pe-5 pt-1">
                        <img alt="browse-jobs" className="w-100" src={`${process.env.PUBLIC_URL}/images/landing/browse-jobs.png`} />
                    </div>
                </div>
                <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start pt-4 pt-lg-0 pe-md-3 pe-lg-0">
                    <div>
                        <h3 className={`${isRegistered ? "tile-title-blue" : "c-white"} text-blue text-center text-lg-start pb-2`}>Services &#38; Vendors</h3>
                        <div className={`${isRegistered ? "" : "c-white"} ff-i500 text-m`}>Optimize how you grow your business</div>
                    </div>
                </div>
                <div className="col-2 order-3 d-none d-lg-flex align-items-center justify-content-center">
                    <div className={`${isRegistered ? "d-block" : "d-none"} zoom`}>
                        <img alt="arrow-black" className="w-100 btn-black"
                            src={`${process.env.PUBLIC_URL}/images/icons/arrow-black.svg`} />
                        <img alt="arrow-blue" className="w-100 btn-blue"
                            src={`${process.env.PUBLIC_URL}/images/icons/arrow-blue.svg`} />
                    </div>
                    <div className={`${isRegistered ? "d-none" : "d-block"} scale`}>
                        <img alt="arrow-light" className="w-100"
                            src={`${process.env.PUBLIC_URL}/images/icons/arrow-light.svg`} />
                    </div>
                </div>
            </div>
            <div className={`row justify-content-center tile border-radius mt-2 mx-2 ${isRegistered ? "b-white-gradient" : "b-green-gradient"}`}
                onClick={() => history.push({
                    pathname: '/survey',
                    state: { typeFormUrl: 'https://1konnection.typeform.com/to/v6pe4x50#email=' }
                })}>
                <div className="col-7 col-lg-4 order-2 order-lg-1 pt-lg-3">
                    <div className="pe-5 pt-1">
                        <img alt="event" className="w-100" src={`${process.env.PUBLIC_URL}/images/landing/event-organizers.png`} />
                    </div>
                </div>
                <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start pt-4 pt-lg-0 pe-md-3 pe-lg-0">
                    <div>
                        <h3 className={`${isRegistered ? "tile-title-blue" : "c-white"} text-center text-lg-start text-blue pb-2`}>Event Organizers &#38; News Sources</h3>
                        <div className={`${isRegistered ? "" : "c-white"} ff-i500 text-m`}>Streamline how your target audience finds you</div>
                    </div>
                </div>
                <div className="col-2 order-3 d-none d-lg-flex align-items-center justify-content-center">
                    <div className={`${isRegistered ? "d-block" : "d-none"} zoom`}>
                        <img alt="arrow-black" className="w-100 btn-black"
                            src={`${process.env.PUBLIC_URL}/images/icons/arrow-black.svg`} />
                        <img alt="arrow-blue" className="w-100 btn-blue"
                            src={`${process.env.PUBLIC_URL}/images/icons/arrow-blue.svg`} />
                    </div>
                    <div className={`${isRegistered ? "d-none" : "d-block"} scale`}>
                        <img alt="arrow-light" className="w-100"
                            src={`${process.env.PUBLIC_URL}/images/icons/arrow-light.svg`} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResourceCenterTile;