import { components } from 'react-select';

const ControlComponent = ({ props, isIcon, controlText }) => {
    if (controlText) {
        return (
            <components.Control {...props}>
                <span style={{ paddingLeft: !!isIcon ? 42 : 12 }} className={'c-grey-450'}>
                    {controlText}
                </span>
                {props.children}
            </components.Control>
        );
    }
    return <components.Control {...props}>{props.children}</components.Control>;
};

export default ControlComponent;
