import { useEffect, useState } from 'react';
import toSelectOption from '../helpers/toSelectOption';

const useSelect = ({ value, options, onChange, valueKey, labelKey }) => {
    const getOption = toSelectOption({ valueKey, labelKey });

    const [selectOptions, setSelectOptions] = useState(options?.map(getOption));
    const [selectValue, setSelectValue] = useState(value === null ? value : getOption(value));

    useEffect(() => {
        setSelectOptions(options?.map(getOption));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    useEffect(() => {
        setSelectValue(value === null ? value : getOption(value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const selectChangeHandler = val => {
        onChange(val === null ? val : val.source);
    };

    return {
        selectOptions,
        selectValue,
        selectChangeHandler,
    };
};

export default useSelect;
