import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import PasswordInput from 'components/atoms/PasswordInput';

import { IS_SHADOW_KEY } from 'hooks/useLocalStorage';

import { trackPageview, trackLogin } from 'services/common/analytic-trackers';
import AuthService from 'services/auth-service';
import AuthHandler from 'services/common/auth-handler';
import UsersService from 'services/users-service';

import EmailInput from 'components/atoms/EmailInput';
import useQueryParams from 'hooks/useQueryParams';
import useLoader from 'hooks/useLoader';
import OneKonnectionPromo from 'components/molecules/OneKonnectionPromo';
import Testimonials from 'pages/Landing/components/Testimonials';

const authService = AuthService();
const authHandler = AuthHandler();
const usersService = UsersService();

const Login = () => {
    const { load } = useLoader();
    const history = useHistory();
    const emailRef = useRef();
    const passwordRef = useRef();
    const query = useQueryParams();
    const emailParam = query.get('email');

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);

    const [password, setPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(false);

    const [rememberMe, setRememberMe] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    const [isDisabledButton, setIsDisabledButton] = useState(false);

    const [highlightRegister, setHighlightRegister] = useState(false);
    const [highlightForgot, setHighlightForgot] = useState(false);

    const onEmailValidation = (value) => {
        passwordRef.current.classList.toggle('invalid', false);
        passwordRef.current.classList.toggle('valid', true);

        setHighlightRegister(false);
        setHighlightForgot(false);
        setIsValidEmail(value);
    }

    const onPasswordValidation = (value) => {
        setHighlightRegister(false);
        setHighlightForgot(false);
        setIsValidPassword(value);
    }

    useEffect(() => {
        document.title = '1Konnection - Login';

        if (emailParam) {
            setEmail(emailParam);
            setIsValidEmail(true);
        }

        trackPageview();
        authService.init();
        usersService.init();

        return () => {
            authService.dispose();
            usersService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const postLogin = e => {
        setIsDisabledButton(true);
        e.preventDefault();
        if (!isValidEmail || !isValidPassword) {
            return;
        }
        load(() =>
            authService
                .login({ email, password, rememberMe })
                .then(response => {
                    authHandler.authenticated(response.data.token);
                    usersService.getIsShadow()
                        .then(response => response.data.isShadow && localStorage.setItem(IS_SHADOW_KEY, true))
                        .finally(_ => {
                            trackLogin();
                            if (history.location?.state && history.location.state?.redirectUrl) {
                                history.push(history.location.state.redirectUrl);
                                return;
                            }
                            const user = authHandler.getUser();
                            const redirectUrl = !user
                                ? '/'
                                : user.identitytype === 'Buyer' || user.identitytype === 'Broker - Buying'
                                    ? '/buyer-dashboard'
                                    : '/seller-dashboard';
                            history.push(redirectUrl);
                        });
                })
                .catch((error) => {
                    const responseTitle = error?.response?.data?.title;
                    setHighlightForgot(false);
                    setHighlightRegister(false);

                    passwordRef.current.classList.toggle('valid', false);
                    // check whether error is related to the wrong email
                    if (responseTitle?.includes('@')) {

                        setHighlightRegister(true);
                        emailRef.current.classList.toggle('valid', false);
                        emailRef.current.classList.toggle('invalid', true);
                    }

                    if (responseTitle?.includes('credentials')) {
                        setHighlightForgot(true);
                        passwordRef.current.classList.toggle('invalid', true);
                    }
                })
                .finally(() => setIsDisabledButton(false))
        );
    };

    return (
        <div>
            <div className="container">
                <form onSubmit={postLogin}>
                    <div className="row bc-white mx-2 mx-md-0 border-radius-small">
                        <div className="col-12 text-center pt-4 pt-md-5">
                            <h4 className="c-grey-900 pb-md-2">Login</h4>
                            {
                                !highlightForgot && !highlightRegister &&
                                <p className="ff-i500 c-grey-450">
                                    <span>Don't have an account yet?</span>
                                    <Link to="/secure/register" className="text-decoration-none ms-1">
                                        Register
                                    </Link>
                                </p>
                            }

                            {
                                highlightRegister &&
                                <div className="col-12 col-md-6 offset-md-3 bc-yellow-800 px-4 px-lg-5 py-3 my-2 text-start border-radius">
                                    <p className="ff-i500 c-white mb-0">
                                        <span className="d-block">Sorry, we can’t find an account with this email address.</span>
                                        <span>Please try again or</span>
                                        <Link to="/secure/register" className="c-white ms-1">
                                            create an account
                                        </Link>
                                    </p>
                                </div>
                            }

                            {
                                highlightForgot &&
                                <div className="col-12 col-md-6 offset-md-3 bc-yellow-800 px-4 px-lg-5 py-3 my-2 text-start border-radius">
                                    <p className="ff-i500 c-white mb-0">
                                        <span className="d-block">Sorry, that password is incorrect.</span>
                                        <span>Please try again, or</span>
                                        <Link to="/secure/forgot-password" className="c-white ms-1">
                                            reset your password.
                                        </Link>
                                    </p>
                                </div>
                            }
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 text-center pt-md-2 pb-3 px-3 px-md-2">
                            <EmailInput
                                email={email}
                                setEmail={setEmail}
                                isValid={onEmailValidation}
                                emailRef={emailRef}
                                placeholder="Your Email" />
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 text-center py-3 px-md-2">
                            <PasswordInput
                                passwordShown={passwordShown}
                                setPasswordShown={setPasswordShown}
                                password={password}
                                setPassword={setPassword}
                                passwordRef={passwordRef}
                                isValid={onPasswordValidation} />
                        </div>
                        <div className="col-12 col-md-3 offset-md-3 mt-3 px-3 px-md-2 c-grey-550 order-0 order-md-0 d-flex justify-content-center justify-content-md-start">
                            <input
                                type="checkbox"
                                id="remember-me"
                                onChange={() => setRememberMe(!rememberMe)}
                                disabled={!email || !password} />
                            <label htmlFor="remember-me" className="ms-3 ff-i500 c-grey-450 order-2 order-lg-1">
                                Remember Me
                            </label>
                        </div>
                        <div className="col-12 col-md-3 px-3 px-md-2 mt-3 text-center text-md-start order-2 order-md-1">
                            <Link
                                to="/secure/forgot-password"
                                className="text-decoration-none ff-i500 c-grey-550 float-md-end">
                                Forgot Password?
                            </Link>
                        </div>
                        <div className="col-12 col-md-6 offset-md-3 px-2 text-center text-md-start order-1 order-md-2">
                            <button
                                id='login-button-form'
                                className="btn-primary ff-i600 w-100 py-3 mt-3"
                                type='submit'
                                disabled={!isValidEmail || !isValidPassword || isDisabledButton}>
                                Login
                            </button>
                        </div>
                    </div>
                </form>

                <div className="d-none d-md-block">
                    <Testimonials />
                </div>
            </div>

            <OneKonnectionPromo />
        </div>
    );
};

export default Login;
