import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import useScroll from 'hooks/useScroll';
import LandingRegisterForm from './LandingRegisterForm';
import ResourceCenterTile from 'components/atoms/ResourceCenterTile';
import AuthHandler from 'services/common/auth-handler';

const authHandler = AuthHandler();

const RegisterFull = forwardRef(({ userTypes, userRole, info, background }, ref) => {
    const [scroll, elRef] = useScroll();
    useImperativeHandle(ref, () => ({ scroll }));

    const [isAnonymous, setIsAnonymous] = useState(authHandler.isAnonymous());
    useEffect(() => {
        const unsubscribe = authHandler.subscribe(({ isAnonymous }) => setIsAnonymous(isAnonymous));
        return () => unsubscribe();
    }, []);

    const pluralize = (word) => `${word}s`;

    return (
        <div id="register"
            name="register"
            ref={elRef}
            className="pt-5 c-white w-100"
            style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'left',
                marginTop: '-59px',
                ...background
            }}>
            {isAnonymous &&
                <div className="container pt-2 pt-md-5 mt-md-5 scroll-observe" style={{ maxWidth: '1400px' }}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7 py-4 py-md-0 text-center text-md-start reveal-left">
                            <div className="pe-md-4 h-100">
                                <div>
                                    {userRole &&
                                        <div className="my-4">
                                            <p className="ff-m600 text-uppercase">{pluralize(userRole)}
                                            </p>
                                        </div>
                                    }
                                    <h1 className="text-brand">{info.headerText1}</h1>
                                    {info.headerText2 && <h1 className="text-brand">{info.headerText2}</h1>}
                                    {info.headerText3 && <h1>{info.headerText3}</h1>}
                                    <h6 className="mt-md-2 pt-5 lh-large">{info.headerDescription}</h6>
                                </div>

                                <div className="d-flex justify-content-center mt-md-4 pt-5 flex-wrap">
                                    <div className="flex-column pe-4 pt-2">
                                        <h1 className="lh-normal">$2 Billion</h1>
                                        <span className="text-l lh-normal">Deal Volume</span>
                                    </div>
                                    <div className="flex-column px-4 pt-2">
                                        <h1 className="lh-normal">600+</h1>
                                        <span className="text-l lh-normal">Brokers</span>
                                    </div>
                                    <div className="flex-column ps-5 pt-2">
                                        <h1 className="lh-normal">12,000+</h1>
                                        <span className="text-l lh-normal">Owners/Operators</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 my-auto reveal-top">
                            <LandingRegisterForm userTypes={userTypes} userRole={userRole} description={info.registrationDescription} />
                        </div>
                    </div>

                    <div className="d-flex justify-content-center pt-4 flex-wrap">
                        <div className="d-flex px-5">
                            <img alt="plains" src={`${process.env.PUBLIC_URL}/images/partners/plains.png`} />
                        </div>
                        <div className="d-flex px-5">
                            <img alt="senior-living-investment-brokerage" src={`${process.env.PUBLIC_URL}/images/partners/senior-living-investment-brokerage.png`} />
                        </div>
                        <div className="d-flex px-5">
                            <img alt="walker-and-dunlop" src={`${process.env.PUBLIC_URL}/images/partners/walker-and-dunlop.png`} />
                        </div>
                    </div>
                </div>
            }
            {!isAnonymous &&
                <div className="container pb-md-5 pb-3 my-md-5 scroll-observe">
                    <div className="row text-center mb-4 reveal-top">
                        <div className="col-12 mt-md-4 mt-5">
                            <h1>Welcome to 1Konnection, <br /> we look forward to getting you Konnected!</h1>
                        </div>
                    </div>
                    <ResourceCenterTile />
                </div>
            }
        </div>
    );
});

export default RegisterFull;