import axios from 'axios';
import { traceError } from './common/response-handlers';

function ConnectedDealsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getAll = () => axios
        .get('/connected-deals', config())
        .catch(error => traceError(error, '/connected-deals', 'GET'));

    const get = id => axios
        .get(`/connected-deals/${id}`, config())
        .catch(error => traceError(error, `/connected-deals/${id}`, 'GET'));

    const patchStage = (id, sellerId, stageId) => axios
        .patch(`/connected-deals/${id}/stage`, { sellerId, stageId }, config())
        .catch(error => traceError(error, `/connected-deals/${id}/stage`, 'PATCH'));

    return {
        init,
        dispose,
        getAll,
        get,
        patchStage
    };
}

export default ConnectedDealsService;