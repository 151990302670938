import { createContext, useContext, useEffect, useState } from 'react';
import NotificationsService from 'services/notifications-service';
import AuthHandler from 'services/common/auth-handler';
import useInterval from 'hooks/useInterval';

// TWO MINUTES
const INTERVAL_FOR_FETCH_NOTIFICATIONS = 1000 * 60 * 2;
const authHandler = AuthHandler();
const notificationsService = NotificationsService();

export const NotificationsContext = createContext({});

export const NotificationsProvider = ({ children }) => {
    const [auth, setAuth] = useState(authHandler.getAuth());
    const [notificationsData, setNotificationsData] = useState(null);

    const handleFetchNotifications = () => {
        let prevTree = notificationsData;
        notificationsService.getTree().then(response => {
            let newTree = response.data.tree;
            if (JSON.stringify(newTree) !== JSON.stringify(prevTree)) setNotificationsData(newTree);
        });
    };

    useInterval(handleFetchNotifications, auth.isAuthenticated ? INTERVAL_FOR_FETCH_NOTIFICATIONS : null);

    useEffect(() => {
        notificationsService.init();
        const unsubscribe = authHandler.subscribe(auth => setAuth(auth));
        if (auth.isAuthenticated) handleFetchNotifications();
        return () => {
            unsubscribe();
            notificationsService.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <NotificationsContext.Provider value={{ notificationsData, handleFetchNotifications }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = () => {
    return useContext(NotificationsContext);
};
