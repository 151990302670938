import { useEffect, useState } from 'react';
import TasksService from 'services/tasks-service';
import modifyTasksData from '../helpers/modifyTasksData';
import useLoader from 'hooks/useLoader';
import { tasksTabs } from 'models/TasksTabs';
import filterTasksByDeal from '../helpers/filterTasksByDeal';
import filterTasksByTab from '../helpers/filterTasksByTab';

const tasksService = TasksService();

const useGetTasks = ({ selectedTab, selectedFilter }) => {
    const { load } = useLoader();
    const [tasks, setTasks] = useState(null);

    const handleFetchTasks = () =>
        load(() =>
            tasksService.getTasks().then(response => {
                const modifyData = modifyTasksData(response.data.tasks);
                if (selectedTab.label !== tasksTabs.all && selectedFilter?.id) {
                    return setTasks(filterTasksByDeal(filterTasksByTab(modifyData, selectedTab), selectedFilter.id));
                }
                if (selectedTab.label === tasksTabs.all && selectedFilter?.id) {
                    return setTasks(filterTasksByDeal(modifyData, selectedFilter.id));
                }
                if (selectedTab.label === tasksTabs.all) {
                    return setTasks(modifyData);
                }
                if (selectedTab.label !== tasksTabs.all) {
                    return setTasks(filterTasksByTab(modifyData, selectedTab));
                }
            })
        );

    useEffect(() => {
        tasksService.init();

        return () => {
            tasksService.dispose();
        };
    }, []);

    useEffect(() => {
        handleFetchTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab?.id, selectedFilter?.id]);

    return {
        tasks,
        handleFetchTasks,
    };
};

export default useGetTasks;
