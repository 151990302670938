import PropTypes from 'prop-types';

const ToggleSwitch = ({ id, labelText, onChange, isChecked = false, name = '' }) => {
    return (
        <>
            <label className="switch">
                <input type="checkbox" id={id} checked={isChecked} name={name} onChange={onChange} />
                <span className="slider round"></span>
            </label>
            {labelText && (
                <label htmlFor={id} className="ps-3 pe-1 cursor-pointer c-grey-700 align-middle">
                    <span className="ff-i500">{labelText}</span>
                </label>
            )}
        </>
    );
};

ToggleSwitch.propTypes = {
    id: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool,
};

export default ToggleSwitch;
