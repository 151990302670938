import { Suspense, lazy, useEffect, useState } from 'react';
import { Switch, useRouteMatch, useHistory, Redirect } from 'react-router-dom';

import AuthRoute from 'guards/AuthRoute';
import useQueryParams from 'hooks/useQueryParams';
import useLocalStorage, {
    PFA_ACCEPTED_KEY,
    PFA_SHOWN_KEY,
    EMAIL_VERIFIED_KEY,
    FUNDS_SHOWN_KEY,
    FUNDS_AVAILABLE_KEY
} from 'hooks/useLocalStorage';
import { useProfile } from 'contexts/ProfileContext';

import { trackAgreementAccepted, trackAgreementClosed, trackFinancialBackgroundClosed, trackFinancialBackgroundEntered } from 'services/common/analytic-trackers';
import UsersService from 'services/users-service';
import UserEmailsService from 'services/user-emails-service';
import AuthHandler from 'services/common/auth-handler';

import VerifyEmailModal from 'components/molecules/verify-email-modal/VerifyEmailModal';
import PFAModal from 'components/molecules/pfa-modal/PFAModal';
import Sidebar from './components/dashboard/Sidebar';
import Dashboard from './components/dashboard/Dashboard';

import 'styles/pages/_criteria.scss';
import FundsModal from 'components/molecules/funds-modal/FundsModal';
import FundsService from 'services/funds-service';
import CompanyProfileService from 'services/company-profile-service';
import useToast from 'hooks/useToast';

import ChatBuyerPage from 'pages/Chat/ChatBuyerPage';

const NewDeals = lazy(() => import('./components/dashboard/NewDeals'));
const Profile = lazy(() => import('./components/dashboard/Profile'));
const DealPage = lazy(() => import('./components/deal-details/DealPage'));
const BuyerDeals = lazy(() => import('./components/dashboard/BuyerDeals'));
const Meetings = lazy(() => import('./components/dashboard/Meetings'));
const NewCriteriaPage = lazy(() => import('./NewCriteriaPage'));
const EditCriteriaPage = lazy(() => import('./EditCriteriaPage'));
const AllBuyerCriteriaPage = lazy(() => import('./AllBuyerCriteriaPage'));

const BuyerDashboardLayoutPage = () => {
    const authHandler = AuthHandler();
    const usersService = UsersService();
    const fundsService = FundsService();
    const userEmailsService = UserEmailsService();
    const companyProfileService = CompanyProfileService();

    const { toast } = useToast();
    const { path } = useRouteMatch();
    const history = useHistory();
    const query = useQueryParams();
    const { setWithExpiry, getWithExpiry } = useLocalStorage();
    const {
        isFulfilled,
        isFundsFulfilled,
        setFundsFulfilled
    } = useProfile();

    const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const [isShowPFAModal, setIsShowPFAModal] = useState(false);
    const [isShowFundsModal, setIsShowFundsModal] = useState(false);

    const [isShowEmailVerify, setIsShowEmailVerify] = useState(false);

    const interestId = +query.get('interest');
    const declineId = +query.get('decline');

    const [showEducationModal, setShowEducationModal] = useState(false);
    useEffect(() => {
        if (!isShowPFAModal && !isShowFundsModal && !isShowEmailVerify)
            setShowEducationModal(true);
        else
            setShowEducationModal(false);
    }, [isShowPFAModal, isShowFundsModal, isShowEmailVerify])
    const onPFAClosed = () => {
        setIsShowPFAModal(false);
        trackAgreementClosed();
        setWithExpiry(PFA_SHOWN_KEY, true);
    }

    const onPFAAccepted = () => {
        usersService
            .patchAgreementAccepted({ agreementAccepted: true })
            .then(() => {
                setIsAgreementAccepted(true);
                trackAgreementAccepted();
                localStorage.setItem(PFA_ACCEPTED_KEY, true);
            })
            .catch(error => toast.handleError(error));

        setIsShowPFAModal(false);
    }

    const onFundsClosed = () => {
        setIsShowFundsModal(false);
        trackFinancialBackgroundClosed();
        setWithExpiry(FUNDS_SHOWN_KEY, true, 1000 * 7 * 60 * 60 * 24); // store for 1 week
    }

    const onSaveFunds = (capitalAvailable, financing, exchange) => {
        fundsService
            .createFund(capitalAvailable, financing, exchange)
            .then(() => {
                toast.success({ body: 'Thank you! Your info was successfully added.' });
                setFundsFulfilled(true);
                trackFinancialBackgroundEntered();
                localStorage.setItem(FUNDS_AVAILABLE_KEY, true);
            })
            .catch(error => toast.handleError(error));
        setIsShowFundsModal(false);
    };

    useEffect(() => {
        usersService.init();
        userEmailsService.init();
        fundsService.init();
        companyProfileService.init();

        const emailVerified = localStorage.getItem(EMAIL_VERIFIED_KEY);
        if (!emailVerified) {
            const userEmail = authHandler.getUserEmail();
            userEmailsService
                .getAll(userEmail)
                .then(response => {
                    const isVerified = response?.data?.userEmails?.find(emailItem => emailItem.email === userEmail)?.verified;
                    if (!isVerified)
                        setIsShowEmailVerify(true);
                    else {
                        localStorage.setItem(EMAIL_VERIFIED_KEY, true);
                        setIsEmailVerified(true);
                        setIsShowEmailVerify(false);
                        showModals();
                    }
                });
        }
        else {
            showModals();
            setIsEmailVerified(true);
        }

        if (interestId > 0 && history.location.pathname !== '/buyer-dashboard/new-matches') {
            history.push(`/buyer-dashboard/new-matches?interest=${interestId}`);
            return;
        }
        if (declineId > 0 && history.location.pathname !== '/buyer-dashboard/new-matches')
            history.push(`/buyer-dashboard/new-matches?decline=${declineId}`);

        return () => {
            usersService.dispose();
            userEmailsService.dispose();
            fundsService.dispose();
            companyProfileService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showModals = () => {
        const pfaAcceptedItem = localStorage.getItem(PFA_ACCEPTED_KEY);
        if (!pfaAcceptedItem)
            usersService
                .getAgreementAccepted()
                .then((response) => {
                    setIsAgreementAccepted(response?.data?.agreementAccepted);
                    if (!response?.data?.agreementAccepted)
                        setIsShowPFAModal(!(getWithExpiry(PFA_SHOWN_KEY) || false));
                    else
                        localStorage.setItem(PFA_ACCEPTED_KEY, true);
                });
        else
            setIsAgreementAccepted(true);

        const fundsAvailable = localStorage.getItem(FUNDS_AVAILABLE_KEY);
        if (!fundsAvailable)
            fundsService
                .getFunds()
                .then(() => {
                    setFundsFulfilled(true);
                    localStorage.setItem(FUNDS_AVAILABLE_KEY, true);
                })
                .catch(() => {
                    setFundsFulfilled(false);
                    setIsShowFundsModal(!(getWithExpiry(FUNDS_SHOWN_KEY) || false));
                });
    }

    return (
        <div className="d-flex">
            {!history.location.pathname.endsWith('/new') && <Sidebar showEducationalModal={showEducationModal} />}
            {history.location.state?.showMobileSideBar !== true && (
                <div style={{ marginBottom: 70 }} className="w-100 mb-lg-0">
                    {path === history.location.pathname &&
                        <Dashboard
                            emailVerified={isEmailVerified}
                            pfaAccepted={isAgreementAccepted}
                            companyVerified={isFulfilled}
                            fundsAvailable={isFundsFulfilled}
                            pfaAction={() => setIsShowPFAModal(true)}
                            fundsAction={() => setIsShowFundsModal(true)}
                            emailAction={() => setIsShowEmailVerify(true)} />
                    }
                    <Suspense fallback={
                        <div className="loader">
                            <img alt="loader" className="loader-image" src={`${process.env.PUBLIC_URL}/images/loader-double-ring.gif`} />
                        </div>
                    }>
                        <Switch>
                            <AuthRoute path={`${path}/new-matches`} component={NewDeals} />
                            <AuthRoute path={`${path}/profile`} component={Profile} />
                            <AuthRoute path={`${path}/deals/:id`} component={DealPage} />
                            <AuthRoute path={`${path}/deals`} component={BuyerDeals} />
                            <AuthRoute path={`${path}/chat`} component={ChatBuyerPage} />
                            <AuthRoute path={`${path}/meetings`} component={Meetings} />
                            <AuthRoute path={`${path}/criteria/:id/new`} component={NewCriteriaPage} />
                            <AuthRoute path={`${path}/criteria/:id/edit`} component={EditCriteriaPage} />
                            <AuthRoute path={`${path}/criteria`} component={AllBuyerCriteriaPage} />
                            <Redirect from={`${path}/**/`} to="/not-found" />
                        </Switch>
                    </Suspense>
                </div>
            )}

            <VerifyEmailModal isVisible={isShowEmailVerify} />
            <PFAModal
                isVisible={isShowPFAModal && !isAgreementAccepted}
                handleClose={onPFAClosed}
                handleAccept={onPFAAccepted} />

            <FundsModal
                isVisible={isShowFundsModal && !isShowPFAModal}
                handleClose={onFundsClosed}
                handleAccept={onSaveFunds}
            />
        </div>
    );
};

export default BuyerDashboardLayoutPage;
