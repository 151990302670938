import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { trackPageview } from 'services/common/analytic-trackers';
import useQueryParams from 'hooks/useQueryParams';
import AuthHandler from 'services/common/auth-handler';
import CalendarsService from 'services/calendars-service';
import useToast from 'hooks/useToast';

const authHandler = AuthHandler();
const calendarsService = CalendarsService();

const CalendarConnector = () => {
    const { toast } = useToast();
    const history = useHistory();
    const query = useQueryParams();
    const accountIdParam = query.get('account_id');
    const accessTokenParam = query.get('access_token');
    const userStateParam = query.get('state');
    const redirectPath = query.get('redirectPath');

    useEffect(() => {
        document.title = '1Konnection - Calendar Connector';
        trackPageview();
        calendarsService.init();

        if (authHandler.isAnonymous())
            history.push('/secure');

        if (!accountIdParam || !accessTokenParam || !userStateParam)
            history.push('/server-error');

        if (userStateParam !== authHandler.getUserId())
            history.push('/forbidden');

        calendarsService
            .createAccount(accountIdParam, accessTokenParam)
            .then(() => {
                if (redirectPath)
                    history.push(`/${redirectPath}`);
                else
                    history.push('/seller-dashboard/meetings');
            })
            .catch((error) => {
                toast.handleError(error);
                history.push('/server-error');
            });

        return () => calendarsService.dispose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="loader">
            <img alt="loader" className="loader-image" src={`${process.env.PUBLIC_URL}/images/loader-double-ring.gif`} />
        </div>
    );
}

export default CalendarConnector;