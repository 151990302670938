import axios from 'axios';
import { traceError } from './common/response-handlers';

function LeadsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getAll = () => axios
        .get(`/leads/all`, config())
        .catch(error => traceError(error, `/deals/leads/all`, 'GET'));

    const get = ({ buyerId = null, dealId = null } = {}) => {
        let queryParams = '';
        if (buyerId || dealId)
            queryParams += '?';

        if (buyerId)
            queryParams += `buyerId=${buyerId}${dealId ? '&' : ''}`;

        if (dealId)
            queryParams += 'dealId=' + dealId;

        return axios
            .get(`/leads${queryParams}`, config())
            .catch(error => traceError(error, '/leads', 'GET'));
    }

    const getDeals = leadId => axios
        .get(`/leads/${leadId}/deals?v2=true`, config())
        .catch(error => traceError(error, `/deals/leads?dealId=${dealId}`, 'GET'));

    const postDeals = (leadId, dealIds) => axios
        .post(`/leads/${leadId}/deals`, { dealIds }, config())
        .catch(error => traceError(error, `/lead/${leadId}/deals`, 'POST'));

    const patchDeal = (leadId, dealId, stageId) => {
        let queryParams = `?stageId=${stageId}`
        return axios
            .patch(`/leads/${leadId}/deals/${dealId}/stages`, { stageId }, config())
            .catch(error => traceError(error, `/leads/${leadId}/deals/${dealId}/stages${queryParams}`, 'PATCH'));
    }

    const putDeals = (buyerId, associatedDeals) => axios
        .put(`/leads/${buyerId}/deals`, { associatedDeals }, config())
        .catch(error => traceError(error, `/leads/${buyerId}/deals`, 'PUT'));

    const getStages = () => axios
        .get('/leads/stages', config())
        .catch(error => traceError(error, '/leads/stages', 'GET'));

    return {
        init,
        dispose,
        getAll,
        get,
        getDeals,
        postDeals,
        patchDeal,
        putDeals,
        getStages
    };
}

export default LeadsService;