import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import useLoader from 'hooks/useLoader';
import useToast from 'hooks/useToast';
import { SENT_EMAIL_VERIFICATION_KEY } from 'hooks/useLocalStorage';

import UserEmailsService from 'services/user-emails-service';
import AuthHandler from 'services/common/auth-handler';

import { Modal } from 'components/atoms/Modal';

const userEmailsService = UserEmailsService();
const authHandler = AuthHandler();

const VerifyEmailModal = ({ isVisible }) => {
    const { toast } = useToast();
    const { load } = useLoader();
    const email = authHandler.getUserEmail();
    const [isVerificationSend, setIsVerificationSend] = useState();

    useEffect(() => {
        userEmailsService.init();

        setIsVerificationSend(window.localStorage.getItem(SENT_EMAIL_VERIFICATION_KEY));

        return () => userEmailsService.dispose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendVerified = () =>
        load(() => userEmailsService
            .postEmailVerification(email)
            .then(_ => {
                const localStorageStatus = isVerificationSend === null ? '0' : '1';
                window.localStorage.setItem(SENT_EMAIL_VERIFICATION_KEY, localStorageStatus);
                setIsVerificationSend(localStorageStatus);
                toast.success({ body: 'Email was successfully send.' });
            })
            .catch(error => toast.handleError(error)));

    const renderSendEmailVerification = () => {
        switch (isVerificationSend) {
            case '0':
                return 'Resend'
            default:
                return 'Send verification email'
        }
    }

    return (
        <Modal
            isVisible={isVisible}
            backdrop
            size={'lg'}
            body={
                <div className="d-flex flex-column align-items-center text-center mt-5 pt-3 mx-5 mb-5 pb-4">
                    <span className="icon icon-1k-logo" />
                    <h1 className="ff-m700 c-grey-700 mt-4 pt-2">Please, verify your email</h1>
                    <div className="ff-i500 c-grey-550 mb-3">
                        We will sent an email to&nbsp;
                        <span className="ff-i500 c-grey-900 text-break">{email}.&nbsp;</span>
                        Not yours?&nbsp;
                        <Link className="text-decoration-none ff-i500" to="/contact-us">Contact us.</Link>
                    </div>
                    <div className="ff-i500 c-grey-550">
                        Click the link in the email to confirm your address and activate your account and get access to all functionality.
                    </div>
                    {isVerificationSend === '1' &&
                        <div className="ff-i500 c-grey-550 mt-4">
                            Didn't get the email?&nbsp;
                            <Link className="text-decoration-none ff-i500" to="/contact-us">Contact us.</Link>
                        </div>
                    }
                </div>
            }
            footer={
                <>
                    {isVerificationSend !== '1' &&
                        <div className="d-flex justify-content-center py-4">
                            <button
                                className="btn-primary ff-i600 px-5"
                                onClick={sendVerified}>
                                {renderSendEmailVerification()}
                            </button>
                        </div>
                    }
                </>
            }
        />
    );
};

export default VerifyEmailModal;