import { preventDefault } from 'services/common/event-helpers';
import Tooltip from 'components/atoms/Tooltip';

const DashboardEmptyTile = ({ img, header, content, ctaText, ctaAction, ctaTheme, isCta = false, education }) => {
    const onCtaClick = event => preventDefault(event, _ => ctaAction());

    return (
        <div
            className="row g-0 border-grey-200 border-radius mb-3"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/images/dashboards/' + img + '-m.svg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'right',
                borderTopRightRadius: '8px'
            }}>
            <div className="col-12 d-block d-lg-none" style={{ minHeight: 136 }}>
                <div className="px-2 mx-3 py-4 mb-3 h-100 d-flex flex-column justify-content-center">
                    <h5 className="c-grey-700 text-xl">{header}</h5>
                    <span className="ff-i500 c-grey-550 text-s">{content}</span>
                </div>
            </div>
            <div className="col-lg-8 d-none d-lg-block" style={{ minHeight: 200 }}>
                <div className="c-grey-550 ps-4 pe-5 h-100 d-flex flex-column justify-content-center">
                    <Tooltip
                        show={education?.show(education?.order)}
                        content={education?.content}
                        secondaryContent={`Step ${education?.order + 1}/${education?.total}`}
                        direction="bottom-left"
                        ctaText="Got it!"
                        ctaAction={() => education?.moveNext(education?.order + 1)}>
                        <h2>{header}</h2>
                    </Tooltip>
                    <span className="ff-i500">{content}</span>
                </div>
            </div>
            {
                isCta &&
                <div className="col-4 d-none d-lg-flex justify-content-center align-items-center">
                    <div className="w-100 ps-2 pe-3">
                        <button
                            onClick={onCtaClick}
                            className={`btn-secondary ${ctaTheme} ff-i600 w-100`}>
                            {ctaText}
                        </button>
                    </div>
                </div>
            }
        </div >
    );
}

export default DashboardEmptyTile;