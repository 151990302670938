import { useEffect, useState, useRef } from 'react';
import RegisterFull from './components/RegisterFull';
import PlatformFlows from './components/PlatformFlows';
import Testimonials from './components/Testimonials';
import AboutUs from './components/AboutUs';
import ResourceCenterTile from 'components/atoms/ResourceCenterTile';
import { trackPageview } from 'services/common/analytic-trackers';
import UsersService from 'services/users-service';
import AuthHandler from 'services/common/auth-handler';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useToast from 'hooks/useToast';
import ToolkitForIndustry from './components/ToolkitForIndustry';

const usersService = UsersService();
const authHandler = AuthHandler();

function LandingPage() {
    const { toast } = useToast();
    const { scrollTrigger } = useIntersectionObserver();
    const [userTypes, setUserTypes] = useState([]);
    const [isAnonymous, setIsAnonymous] = useState(authHandler.isAnonymous());

    const landingInfo = {
        headerText1: 'Connect, Transact, and Grow',
        headerText2: '',
        headerText3: 'on the Largest Platform for Senior Housing and Healthcare Real Estate.',
        headerDescription: 'Join brokers and buyers on the industry’s #1 acquisition marketplace.  Preserve confidentiality. Match with leads. Close more deals faster with the power of our end-to-end transaction management platform.'
    };

    const platformFlowsInfo = {
        text1: 'Sign-up on our platform for FREE early access membership - it only takes 30 seconds.',
        headerText2: 'Add your deal or criteria'
    }

    const background = {
        backgroundImage: `linear-gradient(180deg, rgba(55, 125, 255, 0) 0%, #377DFF 100%),url(${process.env.PUBLIC_URL + '/images/landing/background.jpg)'} `,
    }

    useEffect(() => {
        document.title = '1Konnection';
        trackPageview();
        usersService.init();
        usersService
            .getTypes()
            .then(({ userTypes = [] }) => setUserTypes(userTypes.filter(t => t.name !== 'Broker - Buying & Selling')))
            .catch(error => toast.handleError(error));
        const unsubscribe = authHandler.subscribe(({ isAnonymous }) => setIsAnonymous(isAnonymous));

        return () => {
            usersService.dispose();
            unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    scrollTrigger('.scroll-observe');

    const registerRef = useRef();
    const scrollToRegister = () => registerRef.current && registerRef.current.scroll && registerRef.current.scroll();

    return (
        <div>
            <RegisterFull ref={registerRef} userTypes={userTypes} info={landingInfo} background={background} />
            <Testimonials />
            <ToolkitForIndustry isBcGrey={true} />
            <PlatformFlows info={platformFlowsInfo} scrollToRegister={scrollToRegister} />
            <AboutUs />
            {isAnonymous &&
                <div id="comingSoon"
                    className="py-5 bc-white">
                    <div className="container">
                        <h1 className="c-grey-900 text-brand-m mb-5 text-center">Additional Resources</h1>
                        <ResourceCenterTile />
                    </div>
                </div>
            }
        </div>
    );
}

export default LandingPage;