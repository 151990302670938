import { Modal } from 'react-bootstrap';

const EditContact = ({
    show,
    handleClose,
    children
}) => {
    return (
        <Modal show={show} onHide={handleClose}
            className="modal-fullscreen-lg-down pe-0 pb-lg-0"
            contentClassName="border-radius-0 h-100"
            dialogClassName="m-0 ms-auto mw-600 h-100">
            <Modal.Body className="h-100 px-0 px-md-3">
                <div className="d-flex flex-column h-100 c-grey-700">
                    <div className="d-none d-md-flex">
                        <Modal.Header className="p-3 w-100" closeButton onHide={handleClose}>
                            <div className="d-flex align-items-center w-100">
                                <span onClick={() => handleClose()} className="cursor-pointer icon icon-arrow-left"></span>
                                <h1 className="ps-3">Edit Contact</h1>
                            </div>
                        </Modal.Header>
                    </div>
                    <div className="d-flex d-md-none">
                        <Modal.Header className="d-flex flex-column p-3 w-100 border-bottom-md" onHide={handleClose}>
                            <div className="d-flex w-100">
                                <span onClick={() => handleClose()} className="cursor-pointer icon icon-arrow-left"></span>
                                <h3 className="ps-3 mx-auto c-grey-900">Edit Contact</h3>
                            </div>
                        </Modal.Header>
                    </div>
                    {children}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default EditContact;