import setStatusForTask from './setStatusForTask';

const modifyTasksData = tasks => {
    return tasks.map(task => {
        return {
            ...task,
            status: setStatusForTask(task.completed, task.dueToDate),
        };
    });
};

export default modifyTasksData;
