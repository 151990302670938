import { useMemo, useState, useEffect } from 'react';
import useQueryParams from 'hooks/useQueryParams';
import SellerChannelPreview from './SellerChannelPreview';
import { SendBirdConsts } from 'models/SendBirdConsts';
import { sendBirdSelectors, useSendbirdStateContext } from 'sendbird-uikit';

const ChannelsWrapper = ({
    name,
    description,
    channels,
    userId,
    currentChannelUrl,
    onChannelSelect,
    index,
    setCurrentIndex,
    openDetails,
    selectedChannel,
}) => {
    const query = useQueryParams();
    const currentChannelUrlQuery = query.get('channel');

    const sendbirdContext = useSendbirdStateContext();
    const sb = sendBirdSelectors.getSdk(sendbirdContext);

    const activeChannelWrapper = useMemo(
        () => channels.some(x => x.channel_url === currentChannelUrl),
        [channels, currentChannelUrl]
    );

    const isSystemChatWrapper = useMemo(() => channels.some(x => x.metadata?.is1KTeamChat), [channels]);

    const unreadChannelsCount =
        channels.reduce(
            (accumulator, currentValue) => accumulator + (currentValue.unread_message_count && currentValue.last_message?.user?.user_id !== userId) ? 1 : 0,
            0
        );

    if (
        currentChannelUrl &&
        channels.length > 0 &&
        sb &&
        sb.GroupChannel &&
        sb.getConnectionState() === SendBirdConsts.ConnectionStateOpen
    )
        sb.GroupChannel.getChannel(currentChannelUrl, response => {
            if (
                response?.lastMessage?._sender?.userId === userId &&
                channels.some(x => x.channel_url === currentChannelUrl && x.last_message?.user?.user_id !== userId)
            )
                channels = channels.map(channel => {
                    if (channel.channel_url === currentChannelUrl) {
                        channel.unread_message_count = 0;
                        channel.last_message = {
                            type: response?.lastMessage?.messageType,
                            custom_type: response?.lastMessage?.customType,
                            user: {
                                user_id: response?.lastMessage?._sender?.userId,
                            },
                        };
                    }
                    return channel;
                });
        });

    const [showChannels, setShowChannels] = useState(openDetails);

    useEffect(() => {
        channels.forEach(channel => {
            if (channel.channel_url === currentChannelUrlQuery)
                setCurrentIndex(index);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => setShowChannels(openDetails), [openDetails]);

    return (
        <>
            <div
                onClick={() => { setShowChannels(prev => !prev); setCurrentIndex(index); }}
                className={`pe-2 py-3 c-grey-900 position-relative ${activeChannelWrapper === true ? 'bc-blue-100' : ''}`}>
                {!isSystemChatWrapper ? (
                    <div className="d-flex align-items-center">
                        <span className={`icon icon-arrow-right-grey arrow-icon mx-3 ${showChannels && 'img-rotate-90'}`}></span>
                        <div>
                            <span className={`${showChannels ? 'ff-i600' : ''}`}>{name}</span>
                            <div className="c-grey-700 text-xs d-flex">
                                <span className="text-break">{description}</span>
                            </div>
                        </div>
                        {unreadChannelsCount > 0 && (
                            <span
                                className="bc-blue-500 ms-auto"
                                style={unreadChannelsLabel}>
                            </span>
                        )}
                    </div>
                ) : (
                    <div className="d-flex align-items-center">
                        <span className={`icon icon-arrow-right-grey arrow-icon mx-3 ${showChannels && 'img-rotate-90'}`}></span>
                        <span className="d-flex">{name}</span>
                        {unreadChannelsCount > 0 && (
                            <span
                                className="bc-blue-500 ms-auto"
                                style={unreadChannelsLabel}>
                            </span>
                        )}
                    </div>
                )}
            </div>
            {showChannels &&
                <div className="mx-2">
                    {channels.map(channel => (
                        <SellerChannelPreview
                            selectedChannel={selectedChannel}
                            key={channel.channel_url}
                            userId={userId}
                            channel={channel}
                            onChannelSelect={onChannelSelect}
                        />
                    ))}
                </div>
            }
        </>
    );
};

const unreadChannelsLabel = {
    borderRadius: '100vmax',
    height: '10px',
    aspectRatio: '1',
};

export default ChannelsWrapper;