import { useEffect, useState, useRef } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import useToast from 'hooks/useToast';
import useLoader from 'hooks/useLoader';

import AdminService from 'services/admin-service';

const adminService = AdminService();

const VerificationEmail = () => {
    const { load } = useLoader();
    const { toast } = useToast();

    const typeaheadRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [userEmails, setUserEmails] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);

    useEffect(() => {
        adminService.init();

        return () => adminService.dispose();
    }, []);

    const verifyEmail = () =>
        load(() => adminService
            .postUserEmailVerification(selectedEmail.id)
            .then(() => {
                setSelectedEmail(null);
                typeaheadRef.current.clear();
                toast.success({ body: 'Email was successfully verified.' });
            })
            .catch(error => toast.handleError(error)));

    return (
        <div className="p-3">
            <div className="row gx-3">
                <div className="col-12">
                    <div className="px-3 px-lg-5 py-4">
                        <h4 className="c-grey-700">Email Verification</h4>
                        <p className="c-grey-550 ff-i600 pb-3">
                            Enter user e-mail to verify email.
                        </p>
                        <div className="row">
                            <div className="col-12 col-lg-8">
                                <AsyncTypeahead
                                    id="emails"
                                    ref={typeaheadRef}
                                    useCache={false}
                                    minLength={3}
                                    isLoading={loading}
                                    labelKey={option => `${option.email}`}
                                    placeholder='Enter an email'
                                    onChange={selected => setSelectedEmail(selected[0] ?? null)}
                                    onSearch={emailTerm => {
                                        setLoading(true);
                                        adminService
                                            .getUsersEmails(emailTerm, true)
                                            .then(response => {
                                                setLoading(false);
                                                setUserEmails(response.data.userEmails);
                                            });
                                    }}
                                    options={userEmails} />
                                {selectedEmail?.verified &&
                                    <span className='c-green-500 text-s ms-2'>Email is verified</span>
                                }
                            </div>
                            <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                <button
                                    className="btn-secondary blue ff-i600 w-100 py-3"
                                    disabled={!selectedEmail || selectedEmail?.verified}
                                    onClick={verifyEmail}>
                                    Verify
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerificationEmail;