import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ScrollToTop from 'core/ScrollToTop';
import MemoryCache from 'services/common/memory-cache';
import AuthInterceptor from 'interceptors/auth-interceptor';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/main.scss';

window.memoryStorage = new MemoryCache();
AuthInterceptor();

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop />
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);
