import { Modal } from 'react-bootstrap';
import Lottie from "lottie-react";

const MatchesFoundModal = ({ show, handleClose, header, content, extendedContent, ctaText, ctaAction, animation }) => {
    return (
        <Modal show={show} onHide={handleClose} id="matchesModal" centered size="md" className="pb-lg-0">
            <Modal.Header closeButton className="border-0 pt-3 mt-2 mt-lg-3 px-3 mx-2 mx-lg-3 pb-0" />
            <Modal.Body className="modal-body text-center pt-0 pb-3 px-3 mx-2 mx-lg-3">
                <Lottie animationData={animation} loop={true} style={{ width: "200px", margin: "auto" }} />
                <p className="c-grey-700 mb-2 text-m">
                    {header}
                </p>
                <span className="c-grey-550">{content}</span>
                {extendedContent &&
                    <div>
                        <span className="c-grey-550 pt-4">{extendedContent}</span>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer className="border-0 pt-3 pb-3 mb-3">
                <div className="row justify-content-center g-0 m-0 w-100">
                    <div className="col-10 col-lg-8">
                        <button
                            className="btn-primary ff-i600 w-100"
                            onClick={ctaAction}>{ctaText}</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default MatchesFoundModal;