import { useState, useEffect, useRef } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { trackPageview } from 'services/common/analytic-trackers';
import AuthService from 'services/auth-service';
import EmailInput from 'components/atoms/EmailInput';

const authService = AuthService();

const ForgotPassword = () => {
    const emailRef = useRef();
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [disableSendEmailButton, setDisableSendEmailButton] = useState(false);
    const [emailResent, setEmailResent] = useState(false);

    const [highlightForgot, setHighlightForgot] = useState(false);

    useEffect(() => {
        document.title = '1Konnection - Forgot Password';
        trackPageview();
        authService.init();
        return () => authService.dispose();
    }, []);

    const onEmailValidation = (value) => {
        setHighlightForgot(false);
        setIsValidEmail(value);
    }

    const postForgotPassword = () => {
        setDisableSendEmailButton(true);
        authService
            .forgotPassword(email)
            .then(() => setEmailSent(true))
            .catch(_ => {
                setHighlightForgot(true);
                emailRef.current.classList.toggle('valid', false);
                emailRef.current.classList.toggle('invalid', true);
            })
            .finally(() => setDisableSendEmailButton(false));
    }

    return (
        <div className="container mb-md-5 py-5">
            <div className={`row mt-md-5 pb-5 mx-2 mx-md-0 border-radius-small collapse ${emailSent ? '' : 'show'}`}>
                <div className="col-12 text-center pt-4 pt-md-5">
                    <h4 className="c-grey-900 pb-md-2">Forgot Your Password?</h4>
                    <div className="col-12 col-md-6 offset-md-3 border-radius">
                        <p className="ff-i500 c-grey-450">
                            Enter your email address and we'll send you an access code so you can reset your password.
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-6 offset-md-3 text-center pt-md-2 px-3 px-md-2">
                    {
                        highlightForgot &&
                        <div className="bc-yellow-800 px-4 px-lg-5 py-3 mb-3 mb-lg-5 text-start border-radius">
                            <p className="ff-i500 c-white mb-0">
                                <span className="d-block">Sorry, we can’t find an account with this email address.</span>
                                <span>Please try again or</span>
                                <Link to="/secure/register" className="c-white ms-1">
                                    create an account
                                </Link>
                            </p>
                        </div>
                    }
                    <EmailInput
                        email={email}
                        setEmail={setEmail}
                        isValid={onEmailValidation}
                        emailRef={emailRef}
                        placeholder="Your Email" />
                </div>
                <div className="col-12 col-md-6 offset-md-3 mb-md-5 pb-md-3 pt-3 px-3 px-md-2">
                    <button
                        className="btn-primary ff-i600 w-100 mt-4"
                        onClick={postForgotPassword}
                        disabled={disableSendEmailButton || !isValidEmail}>Send Recovery Code</button>
                </div>
            </div>

            <div className={`row pb-4 pb-md-5 mx-2 mx-md-0 border-radius-small collapse ${emailSent ? 'show' : ''}`}>
                <div className="col-12 text-center pt-4 px-3 pt-md-3">
                    <span className="icon icon-forgot-password-email"></span>
                    <h4 className="c-grey-900 py-3">Check Your Email</h4>
                    <div className="col-12 col-md-6 offset-md-3">
                        <p className="ff-i500 c-grey-450">
                            Follow the link sent <span className="ff-i700">{email}</span> to reset your password. If you did not receive an email, resend code or check your spam folder.
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-6 offset-md-3 mb-md-3 px-3 c-grey-550">
                    <p className="ff-i500 c-grey-450 mt-2 text-center">
                        {!emailResent &&
                            <a href="#/" className="text-decoration-none" onClick={() => { postForgotPassword(); setEmailResent(true); }}>Resend Code</a>
                        }
                        {emailResent &&
                            <span>Email has been resent</span>
                        }
                    </p>
                    <p className="ff-i500 c-grey-450 mt-5 text-center">
                        Wrong email?&nbsp;
                        <Link className="text-decoration-none ff-i500" to="/contact-us">Contact us.</Link>
                    </p>
                </div>
            </div>
        </div >
    );
}

export default ForgotPassword;