import { useState } from 'react';
import AddNote from './components/AddNote';
import NoteView from './components/NoteView';

const Note = ({ formik, handleDelete, handleEdit, note, createdDateNote, emptyStateText }) => {
    const [isShowAddNewNote, setIsShowAddNewNote] = useState(false);

    const handleOnClickAddNote = () => setIsShowAddNewNote(true);

    if (isShowAddNewNote) return <AddNote formik={formik} handleBack={() => setIsShowAddNewNote(false)} />;

    return (
        <NoteView
            createdDateNote={createdDateNote}
            handleOnClickAddNote={handleOnClickAddNote}
            handleDelete={handleDelete}
            note={note}
            handleEdit={() => {
                handleEdit();
                setIsShowAddNewNote(true);
            }}
            emptyStateText={emptyStateText}
        />
    );
};

export default Note;
