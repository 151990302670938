import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import ConnectedDealsService from 'services/connected-deals-service';
import ContactsService from 'services/contacts-service';
import ListingService from 'services/listing-service';
import OrganizationsService from 'services/organizations-service';

import DealDocumentsLinks from 'components/organisms/DealDocumentsLinks';
import EducationSlider from 'components/molecules/education-system/EducationSlider';
import DealManageModal from 'components/molecules/DealManageModal';
import Select from 'components/atoms/select/Select';

import { MatchingState } from 'models/MatchingState';

import useLoader from 'hooks/useLoader';

import sortFiles from './helpers/sortFiles';
import sortLinks from './helpers/sortLinks';
import StatesService from 'services/states-service';
import DealFeedback from 'pages/Criteria/components/deal-details/DealFeedback';
import useToast from 'hooks/useToast';
import DealsFeedbackService from 'services/deals-feedback-service';
import MatchmakingService from 'services/matchmaking-service';

const connectedDealsService = ConnectedDealsService();
const contactsService = ContactsService();
const listingService = ListingService();
const organizationsService = OrganizationsService();
const statesService = StatesService();
const dealsFeedbackService = DealsFeedbackService();
const matchmakingService = MatchmakingService();

const unfilledSpan = () => {
    return (<span className="c-grey-450">Unfilled</span>)
}

const LeadInfoPanelBuyerSide = ({
    isVisible,
    setIsVisible,
    selectedChannel,
    channelUrl,
    userId
}) => {
    const { toast } = useToast();
    const { load } = useLoader();
    const tabOptions = [
        { id: 1, label: "Info" },
        { id: 2, label: "Items" },
    ];
    const dealStatus = [
        { id: 10, name: 'Active' },
        { id: 11, name: 'Not interested' }
    ];
    const [showEducationWrapper, setShowEducationWrapper] = useState(false);

    const [showUpdateDealStatusModal, setShowUpdateDealStatusModal] = useState(false);
    const [selectedDealStatus, setSelectedDealStatus] = useState(10);
    const [feedbackReasonsIds, setFeedbackReasonsIds] = useState([]);
    const [feedbackComment, setFeedbackComment] = useState('');
    const [isValidFeedback, setIsValidFeedback] = useState(false);
    const [disabledDealManageModal, setDisabledDealManageModal] = useState(false);

    const [selectedTabId, setSelectedTabId] = useState(2);
    const dealId = selectedChannel?.metadata?.listingId;
    const sellerId = selectedChannel?.metadata?.sellerId;
    const [dealDetails, setDealDetails] = useState(null);

    const [dealFiles, setDealFiles] = useState([]);
    const [receivedFiles, setReceivedFiles] = useState([]);
    const [dealLinks, setDealLinks] = useState([]);

    useEffect(() => {
        connectedDealsService.init();
        contactsService.init();
        organizationsService.init();
        statesService.init();
        dealsFeedbackService.init();
        matchmakingService.init();

        return () => {
            connectedDealsService.dispose();
            contactsService.dispose();
            organizationsService.dispose()
            statesService.dispose();
            dealsFeedbackService.dispose();
            matchmakingService.dispose()
        }
    }, [])

    useEffect(() => {
        if (!channelUrl) return;
        setSelectedTabId(2);
        load(() => Promise.all([
            connectedDealsService.get(dealId),
            organizationsService.getAssetTypes(),
            organizationsService.getFinancialOpportunities(),
            organizationsService.getStatuses(),
            organizationsService.getSellingInvestmentTypes(),
            organizationsService.getPayorMixes(),
            statesService.getAll(),
            listingService.getFiles(dealId),
            listingService.getLinks(dealId),
            listingService.getSharedFiles({
                id: dealId,
                ownerId: userId,
                recipientId: +sellerId,
            }),
            listingService.getSharedFiles({
                id: dealId,
                ownerId: +sellerId,
                recipientId: userId,
            }),
            listingService.getSharedLinks({ id: dealId, ownerId: +sellerId, recipientId: userId }),
        ]).then(([
            connectedDealsResponse, { assetTypes }, { opportunities }, { statuses }, { investmentTypes }, { payorMixes }, { states },
            getFilesResponse,
            getLinksResponse,
            getSharedFilesResponse,
            getReceivedFilesResponse,
            getSharedLinksResponse,
        ]) => {
            const connectedDeal = connectedDealsResponse.data;
            connectedDeal.assetTypes = connectedDeal?.assetTypeIds.map(id => assetTypes.find(a => a.id === id)?.name).join(', ');
            connectedDeal.organizationStatus = statuses.find(s => s.id === connectedDeal.organizationStatusId)?.name;
            connectedDeal.financialOpportunity = opportunities.find(f => f.id === connectedDeal.financialOpportunityId)?.name;
            connectedDeal.investmentTypeName = investmentTypes.find(i => i.id === connectedDeal.investmentType)?.name;
            connectedDeal.payorMixes = connectedDeal?.payorMixIds.map(id => payorMixes?.find(pm => pm.id === id)?.name).join(', ');
            connectedDeal.state = connectedDeal?.stateId
                ? states?.find(s => s.id === connectedDeal.stateId)?.name
                : connectedDeal?.portfolioStateIds.map(id => states.find(s => s.id === id)?.name).join(', ');
            const dealFilesResponse = getFilesResponse?.data?.dealFiles;
            const sharedFilesResponse = getSharedFilesResponse?.data?.sharedFiles;
            const dealLinksResponse = getLinksResponse?.data?.dealLinks;
            const sharedLinksResponse = getSharedLinksResponse?.data?.sharedLinks;

            setDealDetails(connectedDeal);
            setDealFiles(
                sortFiles(
                    dealFilesResponse
                        .filter(
                            file => !sharedFilesResponse.find(sharedFile => sharedFile.id === file.id)
                        )
                        .concat(sharedFilesResponse)
                )
            );
            setReceivedFiles(sortFiles(getReceivedFilesResponse?.data?.sharedFiles));
            setDealLinks(
                sortLinks(
                    dealLinksResponse
                        .filter(
                            file => !sharedLinksResponse.find(sharedFile => sharedFile.id === file.id)
                        )
                        .concat(sharedLinksResponse)
                )
            );
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelUrl]);

    const updateDealStatus = () => {
        setDisabledDealManageModal(true);
        if (selectedDealStatus !== dealDetails.state)
            load(() =>
                Promise.all([
                    connectedDealsService.patchStage(dealDetails.id, dealDetails.sellerUserId, selectedDealStatus),
                    dealsFeedbackService.create({ dealId: dealDetails.id, reasonIds: feedbackReasonsIds, comment: feedbackComment }),
                    matchmakingService.run()
                ]).then(_ => {
                    setDealDetails(() => {
                        return { ...dealDetails, status: selectedDealStatus }
                    });
                    setSelectedTabId(2);
                    setIsVisible(false);
                    setShowUpdateDealStatusModal(false);
                }).catch(error => toast.handleError(error))
            );
        setDisabledDealManageModal(false);
    }

    const active = dealDetails?.status === MatchingState.MatchingAgreed;

    return (
        <div>
            <Modal.Header className="p-3 p-lg-4 w-100" closeButton onHide={() => setIsVisible(!isVisible)}>
                <h2 className="c-grey-700">Deal #{dealDetails?.id || 1}
                    <span
                        className="icon icon-info-purple cursor-pointer ms-2"
                        onClick={() => setShowEducationWrapper(prev => !prev)}></span>
                </h2>
            </Modal.Header>
            <div className="d-flex flex-column c-grey-700 h-100">
                {showEducationWrapper &&
                    <EducationSlider
                        setIsOpen={setShowEducationWrapper}
                        steps={
                            [
                                {
                                    number: 1,
                                    title: 'Info',
                                    description: `Keep the deal status updated in real time, to access more new matches.`
                                },
                                {
                                    number: 2,
                                    title: 'Items',
                                    description: `Welcome to your deal data vault, all deal items will appear here.
                                            Easily share, track, and manage deal documents 
                                            and links directly with sellers.`
                                }
                            ]
                        } />
                }
                {active &&
                    <>
                        {channelUrl &&
                            <div className="text-center cursor-pointer mt-3 mt-lg-4 mx-3 mx-lg-4">
                                {tabOptions.map(tab => (
                                    <span
                                        onClick={() => setSelectedTabId(tab.id)}
                                        className={`toggle w-50 d-inline-block py-2 ${selectedTabId === tab.id ? 'active' : ''}`}
                                        key={tab.id}>{tab.label}</span>
                                ))}
                            </div>
                        }
                        {selectedTabId === 1 &&
                            <div className="custom-scrollbar mh-100 px-3 px-lg-4">
                                <div className="d-flex justify-content-between align-items-center border-bottom">
                                    <h5 className="pt-3">Status</h5>
                                    <span
                                        className="cursor-pointer ff-i600 c-blue-500"
                                        onClick={() => setShowUpdateDealStatusModal(true)}>
                                        Update
                                    </span>
                                </div>
                                <div className="row py-3">
                                    {active && <div className="col-12 col-lg-5 mb-2 ff-i600 c-grey-700">Active</div>}
                                </div>

                                {/* {upcomingMeetings?.length > 0 &&
                                        <div className="pt-3">
                                            <ScheduledEvents upcomingMeetings={upcomingMeetings} />
                                        </div>
                                    } */}

                                <h5 className="border-bottom pt-3">Contact Details</h5>
                                <div className="row py-3 ff-i500">
                                    <span className="col-12 col-lg-4 mb-2 c-grey-450 text-s">Name</span>
                                    <span
                                        className="col-12 col-lg-8 mb-2 word-break">{dealDetails?.sellerFullName}</span>
                                    <span className="col-12 col-lg-4 mb-2 c-grey-450 text-s">Phone</span>
                                    <span className="col-12 col-lg-8 mb-2">{dealDetails?.sellerPhone}</span>
                                    <span className="col-12 col-lg-4 mb-2 c-grey-450 text-s">Email</span>
                                    <span className="col-12 col-lg-8 mb-2 text-break">{dealDetails?.sellerEmail}</span>
                                </div>

                                <h5 className="border-bottom pt-3">Overview</h5>
                                <div className="row py-3">
                                    <span className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Asset Type</span>
                                    <span
                                        className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">{dealDetails?.assetTypes}</span>
                                    <span className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Location</span>
                                    <span
                                        className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">{dealDetails?.state}</span>
                                    <span
                                        className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Number of Beds</span>
                                    <span
                                        className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">{dealDetails?.bedsRange || unfilledSpan()}</span>
                                    <span
                                        className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Current Occupancy</span>
                                    <span
                                        className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">{dealDetails?.currentOccupancy}</span>
                                    <span className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Price</span>
                                    <span className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">
                                        {dealDetails?.askingPrice
                                            ? `$${new Intl.NumberFormat('en-US').format(dealDetails?.askingPrice)}`
                                            : <span className="c-grey-450">Unpriced</span>}
                                    </span>
                                    <span className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Status</span>
                                    <span
                                        className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">{dealDetails?.organizationStatus}</span>
                                    {dealDetails?.organizationStatus === 'Active Operations' &&
                                        <>
                                            <span className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Financial Opportunity</span>
                                            <span
                                                className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">{dealDetails?.financialOpportunity || unfilledSpan()}</span>
                                        </>
                                    }
                                    <span
                                        className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Investment Type</span>
                                    <span className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">
                                        {dealDetails?.organizationStatus === 'Ground Up Development'
                                            ? 'Real Estate'
                                            : dealDetails?.investmentTypeName
                                                ? dealDetails?.investmentTypeName
                                                : unfilledSpan()
                                        }
                                    </span>
                                    {dealDetails?.organizationStatus === 'Ground Up Development' && dealDetails?.investmentTypeName !== 'Lease' &&
                                        <>
                                            <span className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Land Entitled: </span>
                                            <span className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">
                                                {dealDetails?.landEntitled === true
                                                    ? 'Yes'
                                                    : dealDetails?.landEntitled === false
                                                        ? 'No'
                                                        : unfilledSpan()
                                                }
                                            </span>
                                        </>
                                    }
                                    <span className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Payor Mix</span>
                                    <span
                                        className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">{dealDetails?.payorMixes || unfilledSpan()}</span>
                                    {dealDetails?.organizationStatus !== 'Ground Up Development' && dealDetails?.investmentTypeName !== 'Lease' &&
                                        <>
                                            <span className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Open to Owner Financing</span>
                                            <span className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700">
                                                {dealDetails?.openToOwnerFinancing === true
                                                    ? 'Yes'
                                                    : dealDetails?.openToOwnerFinancing === false
                                                        ? 'No'
                                                        : unfilledSpan()
                                                }
                                            </span>
                                        </>
                                    }
                                    <span className="col-12 col-lg-5 mb-2 ff-i500 c-grey-450 text-s">Additional Information</span>
                                    <span
                                        className="col-12 col-lg-7 mb-2 ff-i500 c-grey-700 word-break">{dealDetails?.additionalInfoTags || unfilledSpan()}</span>
                                </div>
                            </div>
                        }
                    </>
                }

                {selectedTabId === 2 &&
                    <DealDocumentsLinks
                        dealId={dealDetails?.id}
                        channelUrl={channelUrl}
                        recipientId={dealDetails?.sellerUserId}
                        dealFiles={dealFiles}
                        receivedFiles={receivedFiles}
                        setDealFiles={setDealFiles}
                        sortFiles={sortFiles}
                        sortLinks={sortLinks}
                        dealLinks={dealLinks}
                        setDealLinks={setDealLinks}
                        showDocument={true}
                        isSeller={false} />
                }
            </div>
            <DealManageModal
                show={showUpdateDealStatusModal}
                handleConfirmClick={updateDealStatus}
                handleClose={() => {
                    setShowUpdateDealStatusModal(prev => !prev);
                    setSelectedDealStatus(active ? 10 : 11);
                }}
                title="Update Deal status"
                confirmButton="Save changes"
                disabledConfirmButton={selectedDealStatus === dealDetails?.status || disabledDealManageModal || !isValidFeedback}>
                <div className="px-2">
                    <Select
                        isInheritHeight
                        options={dealStatus}
                        label={'Deal Status'}
                        keys={{ labelKey: 'name', valueKey: 'id' }}
                        onChange={(newStatus) => setSelectedDealStatus(newStatus.id)}
                        value={dealStatus.find(status => status.id === selectedDealStatus) || null}
                    />
                    {
                        selectedDealStatus === MatchingState.NotInterestedByBuyer &&
                        <DealFeedback setReasons={setFeedbackReasonsIds} setComment={setFeedbackComment}
                            setIsValid={setIsValidFeedback} />
                    }
                </div>
            </DealManageModal>
        </div>
    );
}

export default LeadInfoPanelBuyerSide;