const FormDataUtil = () => {
    const convert = (model, key = '', form = null) => {
        const formData = form || new FormData();
        // * File:
        if (model instanceof File) {
            formData.append(key.replace(/\[\]$/, ''), model, model.name);
            return formData;
        }

        // * Date:
        if (model instanceof Date) {
            formData.append(key, model.toISOString());
            return formData;
        }

        // * Array:
        if (model instanceof Array) {
            model.forEach(element => convert(element, `${key}[]`, formData));
            return formData;
        }

        // * Model object:
        if (typeof model === 'object') {
            for (const propertyName in model) {
                if (!model.hasOwnProperty(propertyName)) continue;
                const formKey = key ? `${key}[${propertyName}]` : propertyName;
                convert(model[propertyName], formKey, formData);
            }
            return formData;
        }

        // * Primitive type:
        formData.append(key, model);
        return formData;
    }

    return { convert };
}

export default FormDataUtil;