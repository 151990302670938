import axios from 'axios';
import { traceError } from './common/response-handlers';

function DealsFeedbackService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; }
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const get = (dealId) =>
        axios
            .get(`/deals/${dealId}/feedbacks`, config())
            .catch(error => traceError(error, `/deals/${dealId}/feedbacks`, 'GET'));

    const create = ({ dealId, reasonIds, comment }) =>
        axios
            .post(`/deals/${dealId}/feedbacks`, { reasonIds, comment }, config())
            .catch(error => traceError(error, `/deals/${dealId}/feedbacks`, 'POST'));

    const getReasons = () =>
        axios
            .get(`/deals/feedbacks/reasons`, config())
            .catch(error => traceError(error, `/deals/feedbacks/reasons`, 'GET'));

    return {
        init,
        dispose,
        get,
        create,
        getReasons
    };
}

export default DealsFeedbackService;