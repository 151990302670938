const format = {
    hour: 'numeric',
    minute: 'numeric',
};

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const toLocaleTime = (hour) => {
    const date = new Date(0, 0, 0, hour);
    return {
        en: date.toLocaleTimeString('en', format),
        uk: date.toLocaleTimeString('uk', format),
        hours: date.getHours()
    };
}

export const toUtcDate = (date) => {
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds())
};

export const getDateDifference = (datePast, dateNow) => {
    let delta = Math.abs(datePast - dateNow) / 1000;
    const days = Math.floor(delta / (60 * 60 * 24));
    delta -= days * 86400;
    let hours = Math.floor(delta / 3600) % 24;
    let minutes = Math.floor(delta / 60);
    return { days: days, hours: hours, minutes: minutes }
}

export const formatDateDifference = (datePast, dateNow) => {
    const dateDifference = getDateDifference(datePast, dateNow);
    const days = dateDifference.days;
    const hours = dateDifference.hours;
    if (days >= 7) return `${Math.floor(days / 7)} ${Math.floor(days / 7) > 1 ? 'weeks' : 'week'}`;
    if (days > 0) return `${days} d`;
    if (hours > 0) return `${hours} h`;
    return `${dateDifference.minutes !== 0 ? dateDifference.minutes : 1} min`;
}

export const getTimeRanges = (interval) => {
    const ranges = [];
    const date = new Date();

    for (let minutes = 0; minutes < 24 * 60; minutes = minutes + interval) {
        date.setHours(0);
        date.setMinutes(minutes);
        ranges.push({ en: date.toLocaleTimeString('en', format), uk: date.toLocaleTimeString('uk', format), hours: date.getHours() });
    }

    return ranges;
}

export const groupArrayByDayPropertyStartTime = (arr) => {
    var resultArr = {};
    for (var i = 0, len = arr.length; i < len; i++) {
        var dateStr = new Date(arr[i].startTime).toJSON().slice(0, 10);
        if (!resultArr[dateStr]) {
            resultArr[dateStr] = [];
            resultArr[dateStr].push(arr[i]);
        }
        else resultArr[dateStr].push(arr[i]);
    }
    return resultArr;
}