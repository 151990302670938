import { useState, useEffect, useRef } from 'react';

const NameInput = ({ name, setName, isValid, placeholder, forceValidation = false }) => {

    const nameRef = useRef();
    const [showNameError, setShowNameError] = useState(false);
    const [showLengthError, setShowLengthError] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);

    const nameErrorMessage = "Don’t use any special characters (e.g., @#$%^&*), digits or spaces before & after";
    const lengthErrorMessage = "Use less than 50 characters";
    const emptyErrorMessage = "This field can not be empty";

    const isValidName = (value) => /^[A-z ,.'-]+$/.test(value);
    const isValidLength = (value) => value.length < 50;
    const isNotEmpty = (value) => value.length > 0;

    useEffect(() => {
        if (!name && forceValidation) {
            nameRef.current.classList.toggle('invalid', true);
            setShowEmptyError(true);
        }

    }, [name, forceValidation])

    const reset = () => {
        setShowNameError(false);
        setShowLengthError(false);
        setShowEmptyError(false);
        nameRef.current.classList.toggle('invalid', false);
        nameRef.current.classList.toggle('valid', false);
    }

    const onChange = (value) => {
        setName(value);
        reset();

        if (!isNotEmpty(value)) {
            nameRef.current.classList.toggle('invalid', true);
            isValid(false);
            setShowEmptyError(true);
            return;
        }

        if (!isValidName(value)) {
            nameRef.current.classList.toggle('invalid', true);
            isValid(false);
            setShowNameError(true);
            return;
        }

        if (!isValidLength(value)) {
            nameRef.current.classList.toggle('invalid', true);
            isValid(false);
            setShowLengthError(true);
            return;
        }

        nameRef.current.classList.toggle('valid', true);
        isValid(true);
    }

    return (
        <>
            <input
                type="text"
                className="w-100 c-grey-700"
                placeholder={placeholder}
                value={name}
                onChange={(e) => onChange(e.target.value)}
                ref={nameRef} />
            {showEmptyError && <div className="text-start c-red-500 px-2 text-s">{emptyErrorMessage}</div>}
            {name && showNameError && <div className="text-start c-red-500 px-2 text-s">{nameErrorMessage}</div>}
            {name && showLengthError && <div className="text-start c-red-500 px-2 text-s">{lengthErrorMessage}</div>}
        </>
    );
}

export default NameInput;