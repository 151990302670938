import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { SendBirdProvider } from 'sendbird-uikit';
import 'sendbird-uikit/dist/index.css';

import useQueryParams from 'hooks/useQueryParams';
import useLoader from 'hooks/useLoader';

import { trackPageview } from 'services/common/analytic-trackers';
import ListingService from 'services/listing-service';
import AuthHandler from 'services/common/auth-handler';
import ChatService from 'services/chat-service';
import ContactsService from 'services/contacts-service';

import EmptyChatView from './components/EmptyChatView';
import SellerChannelsListWrapper from './components/Seller/SellerChannelsListWrapper';
import ChannelConversation from './components/ChannelConversation';

import LeadInfoPanel from 'components/organisms/LeadInfoPanel';
import useToast from 'hooks/useToast';
import 'styles/pages/_chat.scss';

import useEducationCenter, { SELLER_CHAT_EDUCATION } from 'hooks/useEducationCenter';
import EducationModal from 'components/molecules/education-system/EducationModal';
import Tooltip from 'components/atoms/Tooltip';

const listingService = ListingService();
const chatService = ChatService();
const authHandler = AuthHandler();
const contactsService = ContactsService();

const ChatSellerPage = () => {
    const { toast } = useToast();
    const query = useQueryParams();
    const history = useHistory();
    const { load } = useLoader();

    const userId = authHandler.getUserId();
    if (!userId) history.push('/secure', { redirectUrl: history.location.pathname + history.location.search });
    if (+userId === +process.env.REACT_APP_SEND_BIRD_SYSTEM_USER_ID) history.push('/1k-chats');
    const name = authHandler.getUser()?.name;

    const [showChannelSettings, setShowChannelSettings] = useState(false);
    const [activeDealsChats, setActiveDealsChats] = useState([]);
    const [dealName, setDealName] = useState("");

    const [currentChannelUrl, setCurrentChannelUrl] = useState(query.get('channel'));
    const [allChannels, setAllChannels] = useState([]);
    const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

    const selectedChannel = useMemo(
        () => allChannels.find(ch => ch.channel_url === currentChannelUrl),
        [allChannels, currentChannelUrl]
    );

    /* Education section start */
    const {
        getEducationItem,
        acceptEducation,
        rejectEducation,
        moveNext,
        moveLast } = useEducationCenter();
    const educationScope = SELLER_CHAT_EDUCATION;
    const tooltipsTotal = 3;
    const [educationOverview, setEducationOverview] = useState({});

    const onEducationAccepted = () => acceptEducation(educationScope, educationOverview, setEducationOverview);
    const onEducationRejected = () => rejectEducation(setEducationOverview);
    const onTooltipClicked = (order) => moveNext(educationScope, order, educationOverview, setEducationOverview);
    const onLastTooltipClicked = () => moveLast(educationScope, educationOverview, setEducationOverview);

    const showEducationalTooltip = order =>
        !educationOverview?.rejected
        && educationOverview?.modalShown
        && !educationOverview?.tooltipsShown
        && educationOverview?.order === order;

    /* Education section start */

    useEffect(() => {
        document.title = '1Konnection - Chat Seller';
        trackPageview();
        listingService.init();
        chatService.init();
        contactsService.init();

        setEducationOverview(getEducationItem(educationScope));

        if (userId) {
            load(() => Promise
                .all([
                    listingService.getAll(),
                    chatService.getAllGroupChannelsByMetadata(false),
                    contactsService.getAll()
                ]))
                .then(([listingResponse, groupsResponse, contactsResponse]) => {
                    const sl = listingResponse?.data?.listingEntities.map(l => l.listing);
                    let channels = groupsResponse.data.channels ?? [];
                    const contacts = contactsResponse.data.contacts
                    if (!channels.some(x => x.channel_url === currentChannelUrl)) setCurrentChannelUrl(undefined);
                    if (channels.length > 0) setAllChannels(channels);
                    channels.forEach(ch => ch.contact = contacts.find(contact => contact.userId === +ch.metadata.buyerId));

                    if (+userId !== +process.env.REACT_APP_SEND_BIRD_SYSTEM_USER_ID) {
                        const chat1kTeam = channels.find(ch => ch.metadata?.is1KTeamChat);
                        const dealsIds = channels.filter(ch => +userId === +ch.metadata?.sellerId).map(ch => +ch.metadata?.listingId);
                        const uniqueDealIds = [...new Set(dealsIds)];
                        const groupedChannelsByDeal = uniqueDealIds
                            .map(dealId => ({
                                id: dealId,
                                description: sl.find(deal => deal.id === 97)?.name || '',
                                channels: channels.filter(ch => +ch.metadata?.listingId === dealId),
                                is1KTeamChat: false,
                            }))
                            .filter(sl => sl.channels?.length > 0);

                        if (chat1kTeam) {
                            const channel1k = {
                                id: 0,
                                description: chat1kTeam.members.find(x => x.user_id !== userId).nickname,
                                channels: [chat1kTeam],
                                is1KTeamChat: true,
                            }
                            chat1kTeam?.unread_message_count > 0
                                ? groupedChannelsByDeal.unshift(channel1k)
                                : groupedChannelsByDeal.push(channel1k);
                        }
                        setActiveDealsChats(groupedChannelsByDeal);
                    }
                    else {
                        const sellers = channels.map(x => ({
                            id: x.members.find(member => member.user_id !== userId).user_id,
                            name: x.members.find(member => member.user_id !== userId).nickname
                        }));
                        const sellerIds = [...new Set(sellers.map(seller => seller.id))];
                        const groupedChannelsBySellers = sellerIds.map(sellerId => ({
                            id: sellerId,
                            description: sellers.find(seller => seller.id === sellerId).name,
                            channels: channels.filter(ch => !!ch.members.filter(c => +c.user_id === +sellerId).length),
                            is1KTeamChat: true,
                        }));
                        setActiveDealsChats(groupedChannelsBySellers);
                    }
                })

                .catch(error => { toast.handleError(error) })
                .finally(() => setIsAllDataLoaded(true));
        }

        return () => {
            listingService.dispose();
            chatService.dispose();
            contactsService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty("--sidebar-text-display", showChannelSettings ? 'none' : 'block');
        root?.style.setProperty("--sidebar-width", !showChannelSettings ? '250px' : '75px');

        return () => {
            root?.style.setProperty("--sidebar-text-display", 'block');
            root?.style.setProperty("--sidebar-width", "250px");
        }
    }, [showChannelSettings]);

    return (
        <>
            {isAllDataLoaded &&
                <>
                    {userId && name && activeDealsChats.length > 0 ?
                        <SendBirdProvider appId={process.env.REACT_APP_SEND_BIRD_ID} userId={userId} nickname={name}>
                            <div className="chat d-flex border-grey bc-white ff-i600">
                                <Tooltip
                                    show={showEducationalTooltip(0)}
                                    content="On the left panel, you’ll find all your contacts that you’ve started a chat with. For ease of organization, your chats are sorted by Deal, with each contact listed under the deal. Each chat history is attached to the deal. So, if you have separate chats with the same buyer, those chats (and the corresponding docs) will stay separate."
                                    secondaryContent={`Step 1/${tooltipsTotal}`}
                                    direction="right"
                                    bodyClasses={`channel-list d-md-flex ${currentChannelUrl ? 'display-none' : 'd-flex'}`}
                                    additionalClasses="d-none d-lg-block"
                                    ctaText="Next"
                                    ctaAction={() => onTooltipClicked(1)}>
                                    <Tooltip
                                        show={showEducationalTooltip(0)}
                                        content="On this page, you’ll find all your contacts that you’ve started a chat with. For ease of organization, your chats are sorted by Deal, with each contact listed under the deal. Each chat history is attached to the deal. So, if you have separate chats with the same buyer, those chats (and the corresponding docs) will stay separate."
                                        secondaryContent={`Step 1/${tooltipsTotal}`}
                                        direction="bottom"
                                        bodyClasses="d-lg-none"
                                        additionalClasses="d-block d-lg-none offset-top"
                                        ctaText="Next"
                                        ctaAction={() => onTooltipClicked(1)}>
                                    </Tooltip>
                                    <div className="sendbird-channel-list__body">
                                        <div className="d-flex ps-1 pt-1">
                                            <div className="d-flex align-items-center c-grey-550 text-decoration-none p-2 link cursor-pointer"
                                                onClick={() => history.goBack()}>
                                                <span className="icon icon-arrow-back-primary icon-grayscale me-2" />
                                                <span className="ff-i600">Go Back</span>
                                            </div>
                                        </div>
                                        <SellerChannelsListWrapper
                                            userId={userId}
                                            currentChannelUrl={currentChannelUrl}
                                            onChannelSelect={(newUrl) => setCurrentChannelUrl(newUrl)}
                                            listing={activeDealsChats}
                                            selectedChannel={selectedChannel}
                                            setDealName={setDealName}
                                        />
                                    </div>
                                </Tooltip>

                                <ChannelConversation
                                    currentChannelUrl={currentChannelUrl}
                                    showChannelSettings={selectedChannel?.metadata?.is1KTeamChat ? false : showChannelSettings}
                                    userId={userId}
                                    sellerView={true}
                                    setCurrentChannelUrl={setCurrentChannelUrl}
                                    setShowChannelSettings={() => setShowChannelSettings(!showChannelSettings)}
                                    selectedChannel={selectedChannel}
                                    dealName={dealName}
                                    messageEducation={
                                        {
                                            show: showEducationalTooltip(1),
                                            total: tooltipsTotal,
                                            moveNext: onTooltipClicked
                                        }
                                    }
                                    manageLeadEducation={
                                        {
                                            show: showEducationalTooltip(2),
                                            total: tooltipsTotal,
                                            moveNext: onLastTooltipClicked
                                        }
                                    }
                                    is1KTeamChat={selectedChannel?.metadata?.is1KTeamChat}
                                />
                                {currentChannelUrl && !selectedChannel?.metadata?.is1KTeamChat &&
                                    <div className={`channel-settings ${showChannelSettings ? 'd-block' : 'd-none'}`}>
                                        <LeadInfoPanel
                                            channelUrl={currentChannelUrl}
                                            onCloseClick={() => setShowChannelSettings(false)}
                                            selectedChannel={selectedChannel}
                                            userId={userId}
                                        />
                                    </div>
                                }
                            </div>
                            <EducationModal
                                isVisible={!educationOverview?.rejected && !educationOverview?.modalShown && (userId && name && activeDealsChats.length > 0)}
                                title={'Chat'}
                                paragraphs={
                                    [
                                        { text: 'Securely chat with your contacts and buyers that you’ve approved, cutting down the communication wait time from days to seconds. Our Chat Panel gives you quick access to your trusted contacts and instantaneous communication. Better yet? You can securely share docs without leaving the chat.' },
                                        { text: 'Let’s find out how to stay in touch on the go!', bold: true }
                                    ]}
                                handleAccept={onEducationAccepted}
                                handleClose={onEducationRejected} />
                        </SendBirdProvider>
                        : <EmptyChatView isSeller={true} />
                    }
                </>
            }
        </>
    );
}

export default ChatSellerPage;