import PropTypes from 'prop-types';
import { taskStatuses } from 'models/TasksTabs';
import dayjs from 'dayjs';
import setColorForDateTask from 'pages/Tasks/helpers/setColorForDateTask';
import setColorForFieldTask from 'pages/Tasks/helpers/setColorForFieldTask';
import setIconAlarmTask from 'pages/Tasks/helpers/setIconAlarmTask';
import setIconHomeTask from 'pages/Tasks/helpers/setIconHomeTask';
import setIconPersonTask from 'pages/Tasks/helpers/setIconPersonTask';

const Task = ({
    status,
    description,
    dealId,
    expirationDate,
    leadName,
    onEdit,
    onDelete,
    onChangeCheckbox,
    id,
    borderBottom = false,
    isLast = false
}) => {
    return (
        <div
            className={`d-flex px-3 ${borderBottom ? (isLast ? '' : 'border-bottom-grey-200') : 'border-radius border-grey-200'}`}
            style={{ padding: '13px', height: '110px' }}>
            <div>
                <input
                    type={'checkbox'}
                    name={'is-complete-task'}
                    checked={status === taskStatuses.archived}
                    className="mt-1 green-checkbox"
                    onChange={onChangeCheckbox}
                    style={{ height: '20px', width: '20px' }} />
            </div>

            <div className="px-3 d-block w-100" style={{ minWidth: '0' }}>
                {status === taskStatuses.archived &&
                    <>
                        {description.length > 50
                            ? <div
                                className="d-flex pe-1"
                                data-tooltip={description}
                                data-tooltip-bottom>
                                <s
                                    className="c-grey-300 d-inline-block text-truncate cursor-pointer"
                                    style={{ width: 'calc(100%)' }}>
                                    {description}
                                </s>
                            </div>
                            : <s
                                className="c-grey-300 d-inline-block text-truncate"
                                style={{ width: 'calc(100%)' }}>
                                {description}
                            </s>
                        }
                    </>
                }

                {status === taskStatuses.active &&
                    <>
                        {description.length > 50
                            ? <div
                                className="d-flex"
                                data-tooltip={description}
                                data-tooltip-bottom>
                                <span
                                    className="c-grey-700 d-inline-block text-truncate cursor-pointer"
                                    style={{ width: 'calc(100%)' }}>
                                    {description}
                                </span>
                            </div>
                            : <span
                                className="c-grey-700 d-inline-block text-truncate"
                                style={{ width: 'calc(100%)' }}>
                                {description}
                            </span>
                        }
                    </>
                }

                {status === taskStatuses.expired &&
                    <>
                        {description.length > 50
                            ? <div
                                className="d-flex"
                                data-tooltip={description}
                                data-tooltip-bottom>
                                <span
                                    className="c-grey-300 d-inline-block text-truncate cursor-pointer"
                                    style={{ width: 'calc(100%)' }}>
                                    {description}
                                </span>
                            </div>
                            : <span
                                className="c-grey-300 d-inline-block text-truncate"
                                style={{ width: 'calc(100%)' }}>
                                {description}
                            </span>
                        }
                    </>
                }

                {status === taskStatuses.expired && (
                    <p className="mb-0 mt-1 text-s c-grey-700 ff-i600">
                        <span className="c-red-500 ff-i600">Expired.&nbsp;</span>
                        <span className="c-blue-600 cursor-pointer" onClick={() => onEdit(id)}>Update?</span>
                    </p>
                )}

                <div className="d-flex align-items-center mt-1">
                    {leadName && (
                        <div className="d-flex align-items-center py-1 px-2 bc-grey-90 border-radius me-2 mw-50">
                            <span className={`icon ${setIconPersonTask(status)} me-1`} />
                            <span
                                className={`d-inline-block text-s ${setColorForFieldTask(status)} text-truncate`}
                                title={leadName}>
                                {leadName}
                            </span>
                        </div>
                    )}
                    {!!dealId && (
                        <div className="d-flex align-items-center py-1 px-2 bc-grey-90 border-radius me-2">
                            <span className={`icon ${setIconHomeTask(status)} me-1`} />
                            <span className={`text-s ${setColorForFieldTask(status)} text-truncate`}>Deal #{dealId}</span>
                        </div>
                    )}
                    <div className="d-flex align-items-center py-1 px-2 bc-grey-90 border-radius">
                        <span className={`icon ${setIconAlarmTask(status)} me-1`} />
                        <span className={`text-s ${setColorForDateTask(status)}`}>
                            {dayjs(expirationDate).format('DD/MM/YYYY')}
                        </span>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-grow-1 align-items-start justify-content-end">
                <span className="icon icon-pencil-grey cursor-pointer" onClick={() => onEdit(id)} />
                <span className="icon icon-trash-outline-grey ps-3 cursor-pointer" onClick={() => onDelete(id)} />
            </div>
        </div>
    );
};

Task.propTypes = {
    // status can be: ACTIVE | EXPIRED | ARCHIVED
    status: PropTypes.string.isRequired,
};

export default Task;
