import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { trackPageview } from 'services/common/analytic-trackers';
import AuthHandler from 'services/common/auth-handler';

const authHandler = AuthHandler();

const SurveyPage = () => {
    const history = useHistory();
    const userEmail = authHandler.getUserEmail();
    const url = history.location.state.typeFormUrl + (userEmail || 'anonymous user');

    useEffect(() => {
        document.title = '1Konnection - Survey';
        trackPageview();
    }, []);

    return (
        <div className="mt-2 mt-md-4">
            <div className="container mb-5 mt-4 mt-md-5">
                <button type="button"
                    className="btn btn-primary ff-i600 border-radius-sm px-4 py-2 mb-3 mt-5"
                    onClick={() => history.goBack()}>
                    <span className="pe-2">&#60;</span>
                    <span>Back</span>
                </button>
                <div className="bc-white box-shadow-3 border-radius-sm px-1">
                    <iframe
                        src={url}
                        title="Embed TypeForm"
                        width="100%"
                        style={{ minHeight: "700px" }}>
                    </iframe>
                </div>
            </div>
        </div>
    );
}

export default SurveyPage;