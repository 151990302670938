import { useHistory } from 'react-router-dom';

function ToolkitForIndustry({ isBcGrey = false }) {
    const history = useHistory();

    const box = {
        boxShadow: '0px 1.2px 3.6px rgba(101, 114, 139, 0.11), 0px 6.4px 14.4px rgba(101, 114, 139, 0.13)',
    };

    const redirectToRegister = () => history.push(`/secure/register`);

    return (
        <div id="toolkit" name="toolkit" className={`${isBcGrey ? 'bc-grey-90' : ''} py-4 py-md-5`}>
            <div className="container">
                <h1 className="text-brand-m text-center c-grey-900 scroll-observe reveal-bottom mb-5">A Toolkit Fit For Our Industry</h1>
                <div className="d-flex justify-content-between flex-wrap scroll-observe">
                    <div
                        className="p-4 bc-white flex-1-30 border-radius-medium mb-5 reveal-left cursor-pointer"
                        style={box}
                        onClick={() => redirectToRegister()}>
                        <h2 className="c-blue-600 text-center mb-2">Deal Talk</h2>
                        <p className="text-l c-grey-700 mb-5">
                            Our all-in-one communication suite allows buyers and sellers to effortlessly and seamlessly communicate in one platform.
                        </p>

                        <div className="border-top-grey-700 py-2">
                            <h5 className="c-grey-700 mt-1 mb-2">Top Features</h5>
                            <div className="d-flex align-items-center mb-2">
                                <span className="dot me-3 active"></span>
                                <span className="text-l c-grey-700">Deal Feedback</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <span className="dot me-3 active"></span>
                                <span className="text-l c-grey-700">Chat</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <span className="dot me-3 active"></span>
                                <span className="text-l c-grey-700">Broadcast Messages</span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="p-4 bc-white mb-2 flex-1-30 border-radius-medium mb-5 reveal-bottom cursor-pointer"
                        style={box}
                        onClick={() => redirectToRegister()}>
                        <h2 className="c-blue-600 text-center mb-2">Deal Control</h2>
                        <p className="text-l c-grey-700">
                            Manage access to your deals with a bird's eye view, keeping track of movement and important items, so you can focus on next steps to close more deals.
                        </p>

                        <div className="border-top-grey-700 py-2">
                            <h5 className="c-grey-700 mt-1 mb-2">Top Features</h5>
                            <div className="d-flex align-items-center mb-2">
                                <span className="dot me-3 active"></span>
                                <span className="text-l c-grey-700">Document Sharing</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <span className="dot me-3 active"></span>
                                <span className="text-l c-grey-700">Deal Management</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <span className="dot me-3 active"></span>
                                <span className="text-l c-grey-700">Pre-Vetting</span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="p-4 bc-white mb-2 flex-1-30 border-radius-medium mb-5 reveal-right cursor-pointer"
                        style={box}
                        onClick={() => redirectToRegister()}>
                        <h2 className="c-blue-600 text-center mb-2">Deal Power</h2>
                        <p className="text-l c-grey-700">
                            With our Deal Power features, you can supercharge your outreach efforts with AI and improve efficiencies to close more deals in less time.
                        </p>

                        <div className="border-top-grey-700 py-2">
                            <h5 className="c-grey-700 mt-1 mb-2">Top Features</h5>
                            <div className="d-flex align-items-center mb-2">
                                <span className="dot me-3 active"></span>
                                <span className="text-l c-grey-700">AI Matchmaking</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <span className="dot me-3 active"></span>
                                <span className="text-l c-grey-700">User Profiles</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <span className="dot me-3 active"></span>
                                <span className="text-l c-grey-700">Calendar & Scheduling</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <a className="c-blue-600 ff-m700 pb-2 text-l text-decoration-none border-blue-600-3" href="/secure/register">Get Early Access</a>
                </div>
            </div>
        </div>
    );
}

export default ToolkitForIndustry;