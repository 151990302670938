function AboutUs() {
    return (
        <div id="aboutUs" name="aboutUs" className="bc-grey-90 py-4 py-md-5">
            <h1 className="c-grey-900 text-brand-m text-center scroll-observe reveal-bottom">Who We Are</h1>
            <div className="container">
                <div className="row c-grey-700 px-md-5 scroll-observe justify-content-center">
                    <div className="col-12 col-md-9 pb-4 pb-md-0 order-1 order-md-2 reveal-right">
                        <div className="px-2 px-md-3 c-grey-900">
                            <div className="row mb-md-4 pb-3 pb-md-2">
                                <span className="text-l ff-i500">1Konnection is changing the way you connect, transact, and grow.</span>
                            </div>
                            <div className="row mb-md-4 pb-3 pb-md-2">
                                <span className="text-l ff-i500">Here’s why we started.</span>
                            </div>
                            <div className="row mb-md-4 pb-3 pb-md-2">
                                <span className="text-l ff-i500">Long-term Care Facilities are categorized as commercial real estate but due to their uniqueness they have to be sold in <span className="d-inline c-blue-600 ff-i600">a confidential manner.</span></span>
                            </div>
                            <div className="row mb-md-4 pb-3 pb-md-2">
                                <span className="text-l ff-i500">Understanding the need, 1Konnection created <span className="d-inline c-blue-600 ff-i600">a centralized platform</span> for discovering and managing your Senior Housing and Healthcare Real Estate deals.</span>
                            </div>
                            <div className="row mb-md-4 pb-3 pb-md-2">
                                <span className="text-l ff-i500">We provide a deal marketplace where <span className="d-inline c-blue-600 ff-i600">you remain confidential</span> and <span className="d-inline c-blue-600 ff-i600">gain the capability to expand your reach to on & off-market opportunities.</span> The first marketplace of its kind is revolutionizing how you perform your next transaction.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;