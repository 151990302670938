import { useEffect, useState } from 'react';
import modifyListingOptions from '../helpers/modifyListingOptions';
import ListingService from 'services/listing-service';
import useToast from 'hooks/useToast';
import modifyListingOptionsForFilter from '../helpers/modifyListingOptionsForFilter';

const listingService = ListingService();

const useGetDealOptions = () => {
    const toast = useToast();
    const [dealOptions, setDealOptions] = useState([]);
    const [dealOptionsForFilter, setDealOptionsForFilter] = useState([]);

    useEffect(() => {
        listingService.init();

        listingService
            .getAll()
            .then(response => {
                setDealOptions(modifyListingOptions(response.data.listingEntities));
                setDealOptionsForFilter(modifyListingOptionsForFilter(response.data.listingEntities));
            })
            .catch(toast.handleError);

        return () => {
            listingService.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        dealOptions,
        dealOptionsForFilter,
    };
};

export default useGetDealOptions;
