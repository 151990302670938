import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const MultiChoiceTypeahead = ({
    choices,
    onStatesChange,
    choosed = [],
    placeholder = 'State',
    disabled = false,
    showError = false,
    labelKey = 'name',
}) => {
    const onStateChange = selectedStates => {
        if (!selectedStates || selectedStates.length === 0) return;
        let state = selectedStates[0];
        if (choosed.some(s => s.id === state.id)) return;
        onStatesChange(prev => [...prev, state]);
    };

    const onStateRemove = id => onStatesChange(prev => prev.filter(s => s.id !== id));

    return (
        <div className="w-100">
            <Typeahead
                id="multi-choice-typehead"
                onChange={onStateChange}
                options={choices.filter(choice => choosed.every(state => state.id !== choice.id))}
                selected={[]}
                className={`dropdown ${showError ? 'invalid' : ''}`}
                labelKey={labelKey}
                disabled={disabled}
                placeholder={placeholder}
            >
                <span className="icon icon-arrow-right-grey icon-static img-rotate-90 pe-none"></span>
            </Typeahead>
            <div>
                {choosed.map(state => (
                    <span
                        key={state.id}
                        onClick={() => onStateRemove(state.id)}
                        className={`d-inline-block cursor-pointer border-radius-medium ff-i600 text-break text-s c-blue-1000 bc-blue-200 py-1 px-2 px-md-3 me-2 mt-2 ${
                            disabled ? 'bc-grey-100 c-grey-700' : ''
                        }`}
                    >
                        {state[labelKey]}
                        {!disabled && <span className="icon icon-cancel icon-medium align-middle"></span>}
                    </span>
                ))}
            </div>
        </div>
    );
};

MultiChoiceTypeahead.propTypes = {
    choices: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })
    ),
    onStatesChange: PropTypes.func,
    choosed: PropTypes.array,
};

export default MultiChoiceTypeahead;
