const Tabs = ({ tabs, activeTab, setActiveTab }) => {
    return (
        <div className="d-flex text-center cursor-pointer">
            {tabs.map(tab => (
                <span
                    className={`d-inline-block ff-i600 w-50 pb-2  ${
                        tab === activeTab ? 'border-bottom-grey-700-2 c-grey-700' : 'border-bottom-grey-200 c-grey-450'
                    }`}
                    style={{ ...transitionBg }}
                    onClick={() => {
                        setActiveTab(tab);
                    }}
                    key={tab}
                >
                    {tab}
                </span>
            ))}
        </div>
    );
};

const transitionBg = {
    transition: 'border-color 200ms ease-in',
};

export default Tabs;
