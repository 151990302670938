import { useState } from 'react';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import TasksService from 'services/tasks-service';
import useLoader from 'hooks/useLoader';
import modifyDataFromFormTask from '../helpers/modifyDataFromFormTask';
import useToast from 'hooks/useToast';
import taskSchema from '../../../validation-schemes/taskSchema';

const tasksService = TasksService();

const useEditTask = ({ tasks, handleFetchTasks }) => {
    const { load } = useLoader();
    const { toast } = useToast();
    const [selectedTask, setSelectedTask] = useState(null);
    const [isVisibleEditTaskModal, setIsVisibleEditTaskModal] = useState(false);

    const formikEdit = useFormik({
        initialValues: {
            description: selectedTask?.description ?? '',
            dueToDate: dayjs(selectedTask?.dueToDate).format('YYYY-MM-DD') ?? undefined,
            dealId: selectedTask?.dealId,
            leadId: selectedTask?.leadId,
        },
        validationSchema: taskSchema,
        enableReinitialize: true,
        onSubmit: values => {
            return load(() =>
                tasksService
                    .updateTask(selectedTask.id, modifyDataFromFormTask(values))
                    .then(handleFetchTasks)
                    .then(() => {
                        setIsVisibleEditTaskModal(false);
                        toast.success({ body: 'Information was successfully updated.' });
                    })
                    .catch(toast.handleError)
            );
        },
    });

    const handleEditTask = id => {
        setSelectedTask(tasks.find(task => task.id === id));
        setIsVisibleEditTaskModal(!isVisibleEditTaskModal);
    };

    return {
        formikEdit,
        handleEditTask,
        isVisibleEditTaskModal,
        setIsVisibleEditTaskModal,
    };
};

export default useEditTask;
