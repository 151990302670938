import { useState, useEffect } from 'react';
import useLoader from 'hooks/useLoader';
import { companyTypeOptions } from 'models/CompanyType';
import AdminService from 'services/admin-service';

const adminService = AdminService();

const VerificationUserDetails = ({ userCore, userTypes, assetTypes, states, timeZones, handleButtons }) => {
    const { load } = useLoader();
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        adminService.init();
        return () => adminService.dispose();
    }, []);

    useEffect(() => {
        load(() => adminService
            .getUserWaitingVerification(userCore.id)
            .then(response => setUserDetails(response.data.details)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>{!!userDetails &&
            <div className="container-fluid border-radius-bottom border-bottom-grey-200 border-right-grey-200 border-left-grey-200">
                <div className="row">
                    <div className="col-12 col-lg-6 border-right-grey-200-lg border-bottom-grey-200 py-2">
                        <h6 className="c-grey-700">Personal Information</h6>
                        <div className="px-3">
                            <div className="d-flex align-items-center">
                                <span className="ff-i500 c-grey-550">Email:&nbsp;</span>
                                <span className="ff-i600 c-grey-700 text-break">{userCore.email}</span>
                                <span className={`icon icon-${userDetails.email.verified ? 'success' : 'error'} icon-small ps-1`} />
                            </div>
                            <div>
                                <span className="ff-i500 c-grey-550">Phone:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userCore.phone}</span>
                            </div>
                            {!!userCore.timeZoneId &&
                                <div>
                                    <span className="ff-i500 c-grey-550">Time Zone:&nbsp;</span>
                                    <span className="ff-i600 c-grey-700">{timeZones.find(zone => zone.id === userCore.timeZoneId)?.name}</span>
                                </div>
                            }
                            <div>
                                <span className="ff-i500 c-grey-550">Registered Type:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userTypes.find(type => type.id === userCore.type)?.name}</span>
                            </div>
                            <div>
                                <span className="ff-i500 c-grey-550">Registered Date:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{new Date(userCore.registeredDate).toLocaleDateString()}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 border-bottom-grey-200 py-2">
                        <h6 className="c-grey-700">Metadata</h6>
                        <div className="px-3">
                            <div>
                                <span className="ff-i500 c-grey-550">Is Shadow:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userDetails.metadata.isShadow ? 'Yes' : 'No'}</span>
                            </div>
                            <div>
                                <span className="ff-i500 c-grey-550">Agreement Accepted:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userDetails.metadata.agreementAccepted ? 'Yes' : 'No'}</span>
                            </div>
                            <div>
                                <span className="ff-i500 c-grey-550">Skip Futher Matchings:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userCore.skipMatching ? 'Yes' : 'No'}</span>
                            </div>
                            {!!userDetails.metadata.dealsLimit &&
                                <div>
                                    <span className="ff-i500 c-grey-550">User Deals Limit:&nbsp;</span>
                                    <span className="ff-i600 c-grey-700">{userDetails.metadata.dealsLimit}</span>
                                </div>
                            }
                            <div>
                                <span className="ff-i500 c-grey-550">Turned Off Emails:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userDetails.metadata.noEmails ? 'Yes' : 'No'}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6 border-right-grey-200-lg border-bottom-grey-200 py-2">
                        <h6 className="c-grey-700 text-start">Company Details</h6>
                        {!!userDetails.company &&
                            <div className="px-3">
                                <div>
                                    <span className="ff-i500 c-grey-550">Name:&nbsp;</span>
                                    <span className="ff-i600 c-grey-700">{userDetails.company.name}</span>
                                </div>
                                <div>
                                    <span className="ff-i500 c-grey-550">Type:&nbsp;</span>
                                    <span className="ff-i600 c-grey-700">{companyTypeOptions.find(type => +type.id === userDetails.company.typeId)?.name}</span>
                                </div>
                                <div>
                                    <span className="ff-i500 c-grey-550">Job Title:&nbsp;</span>
                                    <span className="ff-i600 c-grey-700">{userDetails.company.jobTitle}</span>
                                </div>
                                {!!userDetails.company.website &&
                                    <div>
                                        <span className="ff-i500 c-grey-550">Website:&nbsp;</span>
                                        <span className="ff-i600 c-grey-700">{userDetails.company.website}</span>
                                    </div>
                                }
                                {!!userDetails.company.linkedInLink &&
                                    <div>
                                        <span className="ff-i500 c-grey-550">LindedIn:&nbsp;</span>
                                        <span className="ff-i600 c-grey-700">{userDetails.company.linkedInLink}</span>
                                    </div>
                                }
                            </div>
                        }
                        {!userDetails.company &&
                            <div className="text-center">
                                <span className="ff-i500 c-grey-450 px-2">User has not provide this information yet.</span>
                            </div>
                        }
                    </div>
                    <div className="col-12 col-lg-6 border-bottom-grey-200 py-2">
                        <h6 className="c-grey-700">Financial Background</h6>
                        {!!userDetails.funds &&
                            <div className="px-3">
                                <div>
                                    <span className="ff-i500 c-grey-550">Available Funds:&nbsp;</span>
                                    <span className="ff-i600 c-grey-700">{userDetails.funds.fundsAvailable}</span>
                                </div>
                                <div>
                                    <span className="ff-i500 c-grey-550">Is Financing Needed:&nbsp;</span>
                                    <span className="ff-i600 c-grey-700">{userDetails.funds.isFinancingNeeded ? 'Yes' : 'No'}</span>
                                </div>
                                <div>
                                    <span className="ff-i500 c-grey-550">Is 1031 Exchange:&nbsp;</span>
                                    <span className="ff-i600 c-grey-700">{userDetails.funds.is1031Exchange ? 'Yes' : 'No'}</span>
                                </div>
                            </div>
                        }
                        {!userDetails.funds &&
                            <div className="text-center">
                                <span className="ff-i500 c-grey-450 px-2">User has not provide this information yet.</span>
                            </div>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6 border-right-grey-200-lg border-bottom-grey-200 py-2">
                        <h6 className="c-grey-700">Statisticts</h6>
                        <div className="px-3">
                            <div>
                                <span className="ff-i500 c-grey-550">Buyer Criteria Amount:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userDetails.stats.buyerCriteriaAmount}</span>
                            </div>
                            <div>
                                <span className="ff-i500 c-grey-550">New Deals Amount:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userDetails.stats.newDealsAmount}</span>
                            </div>
                            <div>
                                <span className="ff-i500 c-grey-550">Pre-Vet Deals Amount:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userDetails.stats.preVetDealsAmount}</span>
                            </div>
                            <div>
                                <span className="ff-i500 c-grey-550">Active Deals Amount:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userDetails.stats.activeDealsAmount}</span>
                            </div>
                            <div>
                                <span className="ff-i500 c-grey-550">Active Associated Deals Amount:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userDetails.stats.activeAssociatedDealsAmount}</span>
                            </div>
                            <div>
                                <span className="ff-i500 c-grey-550">Deals limit:&nbsp;</span>
                                <span className="ff-i600 c-grey-700">{userDetails.stats.dealsLimit}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 border-bottom-grey-200 py-2">
                        <h6 className="c-grey-700">Communities</h6>
                        {!!userDetails.communities.length &&
                            <>
                                {userDetails.communities.map(community =>
                                    <div
                                        className="position-relative bc-grey-100 c-grey-550 ff-i600 border-grey border-radius px-3 py-2 pe-5 mb-2 "
                                        key={community.id}>
                                        <span>
                                            {community.assetTypeIds
                                                .filter(id => assetTypes.some(a => a.id === id))
                                                .map(id => assetTypes.find(a => a.id === id).name)
                                                .join(', ')}
                                        </span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span>{community.quantity}</span>
                                        <span>&nbsp;-&nbsp;</span>
                                        <span>
                                            {community.stateIds
                                                .filter(id => states.some(s => s.id === id))
                                                .map(id => states.find(s => s.id === id).name)
                                                .join(', ')}
                                        </span>
                                    </div>
                                )}
                            </>
                        }
                        {!userDetails.communities.length &&
                            <div className="text-center">
                                <span className="ff-i500 c-grey-450 px-2">User has not provide this information yet.</span>
                            </div>
                        }
                    </div>
                </div>

                <div className="row justify-content-around py-2">
                    <button
                        className="btn-secondary red w-25"
                        onClick={() => handleButtons(userCore.id, false, null)}>
                        <span>Deny</span>
                    </button>
                    <button
                        className="btn-secondary violet w-25"
                        onClick={() => handleButtons(userCore.id, null, true)}>
                        <span>Postpone</span>
                    </button>
                    <button
                        className="btn-primary green w-25"
                        onClick={() => handleButtons(userCore.id, true, null)}>
                        <span>Accept</span>
                    </button>
                </div>
            </div>
        }
        </>
    )
}

export default VerificationUserDetails;