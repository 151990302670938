import { useMemo } from 'react';

import UserChannelsWrapper from './UserChannelsWrapper';

const UserChannelsListWrapper = ({ userId, currentChannelUrl, onChannelSelect, channelsBySellers }) => {
    const displayChannelsList = useMemo(
        () => channelsBySellers.length > 0 && channelsBySellers.some(x => x.channels?.length > 0),
        [channelsBySellers]
    );

    return (
        <div>
            {displayChannelsList &&
                channelsBySellers.map(x => (
                    <UserChannelsWrapper
                        key={x.sellerId}
                        name={x.sellerName}
                        channels={x.channels}
                        userId={userId}
                        currentChannelUrl={currentChannelUrl}
                        onChannelSelect={onChannelSelect}
                    />
                ))}
        </div>
    );
};

export default UserChannelsListWrapper;
