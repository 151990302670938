import axios from "axios"
import { MatchingState } from "models/MatchingState"
import { getCache, traceError } from "services/common/response-handlers"
import { trackDealInterested, trackDealNotInterested } from "./common/analytic-trackers"

function MatchingsService() {
    let controller = null
    const init = () => (controller = new AbortController())
    const dispose = () => {
        if (controller) controller.abort()
        controller = null
    }
    const config = () => (controller && controller.signal ? { signal: controller.signal } : null)

    const getBuyerNewDeals = () => getAllByStates([6, 8])

    const getMatchingsStats = (state) =>
        axios
            .get(`/users/stats/matchings?includesState=${state}`)
            .catch((error) => traceError(error, `/api/users/stats/matchings?includesState=${state}`, "GET"))

    const getAllByStates = (stateIds) => {
        const stateIdsStr = stateIds.map((state) => "stateIds=" + state).join("&")
        return axios
            .get(`/matchings?${stateIdsStr}`, config())
            .catch((error) => traceError(error, "/matchings", "GET"))
    }

    const post = ({ buyerId, sellerId, dealId }) =>
        axios
            .post(`/matchings`, { buyerId, sellerId, dealId }, config())
            .catch((error) => traceError(error, `/matchings`, "POST"))

    const putLeadStages = (buyerId, associatedDeals) => axios
        .put(`/matchings/lead-stages?buyerId=${buyerId}`, { associatedDeals }, config())
        .catch(error => traceError(error, `/matchings/lead-stages`, 'PUT'));

    const submitBuyerApproval = (id) =>
        axios
            .post(`/matchings/${id}/buyer-approval`, null, config())
            .then((response) => {
                Promise.all([
                    getMatchingsStats(MatchingState.MatchingAgreed),
                    getMatchingsStats(MatchingState.WaitingBuyerVerification)
                ])
                    .then(([agreedResponse, savedResponse]) => {
                        trackDealInterested({
                            matchId: id,
                            count: agreedResponse?.data?.count + savedResponse?.data?.count
                        })
                    })
                return response
            })
            .catch((error) => traceError(error, `/matchings/${id}/buyer-approval`, "POST"))

    const submitBuyerDecline = (id, { reasonId, notes }) =>
        axios
            .post(`/matchings/${id}/buyer-decline`, { reasonId, notes }, config())
            .then((response) => {
                getMatchingsStats(MatchingState.DeclinedByBuyer).then((res) =>
                    trackDealNotInterested({ matchId: id, count: res?.data?.count })
                )
                return response
            })
            .catch((error) => traceError(error, `/matchings/${id}/buyer-decline`, "POST"))

    const getStates = () => getCache("/matchings/transition-states", controller)
    const getBuyerDeclineReasons = () => getCache("/matchings/buyer-decline-reasons", controller)
    const getDealStages = () => getCache("/matchings/deal-stages", controller)

    return {
        init,
        dispose,
        getMatchingsStats,
        getBuyerNewDeals,
        post,
        submitBuyerApproval,
        submitBuyerDecline,
        putLeadStages,
        getStates,
        getBuyerDeclineReasons,
        getDealStages
    }
}

export default MatchingsService
