export function preventDefault(event, action) {
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }
    if (action) action(event);
    return false;
}

const eventHelpers = {
    preventDefault
};

export default eventHelpers;