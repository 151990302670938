import { preventDefault } from 'services/common/event-helpers';
import Tooltip from 'components/atoms/Tooltip';

const DashboardTile = ({
    title,
    content,
    amount,
    img,
    themeColor,
    fontColor,
    numberColor,
    onCardClick,
    ctaText,
    ctaAction,
    ctaTheme,
    cta2Theme,
    mobileCtaTextColor,
    mobileCtaColor = "white",
    is2Cta = false,
    education,
    education2 }) => {

    const onCtaClick = event => preventDefault(event, _ => ctaAction());

    return (
        <div
            className={`row g-0 ${themeColor ? `bc-${themeColor}` : 'border-grey-200'} border-radius cursor-pointer mb-3`}
            onClick={onCardClick}
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/images/dashboards/' + img + '.svg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'right'
            }}>
            <div className={`col-8 ${is2Cta ? 'col-lg-6' : 'col-lg-8'}`}>
                <div className="ps-3 ps-lg-4 pt-4 pb-2">
                    <Tooltip
                        show={education2?.show(education2?.order)}
                        content={education2?.content}
                        secondaryContent={`Step ${education2?.order + 1}/${education2?.total}`}
                        direction="bottom-left"
                        additionalClasses="d-none d-lg-block"
                        ctaText={`${education2?.order + 1 === education2?.total ? 'Got It!' : 'Next'}`}
                        ctaAction={() => education2?.moveNext(education2?.order + 1)}>
                        <h2 className={`d-none d-lg-block c-${fontColor}`}>{title}</h2>
                    </Tooltip>
                    <Tooltip
                        show={education2?.show(education2?.order)}
                        content={education2?.content}
                        secondaryContent={`Step ${education2?.order + 1}/${education2?.total}`}
                        direction="bottom"
                        additionalClasses="d-block d-lg-none left-75 arrow-left"
                        ctaText={`${education2?.order + 1 === education2?.total ? 'Got It!' : 'Next'}`}
                        ctaAction={() => education2?.moveNext(education2?.order + 1)}>
                        <h5 className={`d-block d-lg-none c-${fontColor}`}>{title}</h5>
                    </Tooltip>
                    <span className={`ff-i500 c-${fontColor} d-none d-lg-block`}>{content}</span>
                    <h1 className={`c-${numberColor} mb-lg-2`} style={{ fontSize: "64px" }}>{amount}</h1>
                </div>
            </div>
            {is2Cta &&
                <div className="col-lg-2 d-none d-lg-flex justify-content-center align-items-center">
                    <div className="w-100 pe-3">
                        <button className={`btn-secondary btn-transparent ${cta2Theme} min-w-auto px-0 px-lx-1 ff-i600 w-100`}>
                            View All
                        </button>
                    </div>
                </div>
            }
            <div className="col-4 d-none d-lg-flex justify-content-center align-items-center">
                <div className="w-100 ps-2 pe-3">
                    <Tooltip
                        show={education?.show}
                        content="New property looking for a buyer? Add your new deal here."
                        secondaryContent={`Step 1/${education?.total}`}
                        direction="bottom"
                        ctaText="Next"
                        ctaAction={() => education?.moveNext(1)}>
                        <button
                            onClick={onCtaClick}
                            className={`btn-secondary ${ctaTheme} ff-i600 w-100`}>
                            {ctaText}
                        </button>
                    </Tooltip>
                </div>
            </div>
            <div className="col-4 d-block d-lg-none position-relative">
                <button className={`btn-sticky bc-${mobileCtaColor} border-0 ff-i600`}>
                    <span className={`c-${mobileCtaTextColor}`}>View</span>
                </button>
            </div>
        </div>
    );
}

export default DashboardTile;