import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { groupArrayByDayPropertyStartTime } from 'helpers/Time';

import useLoader from 'hooks/useLoader';

import { trackPageview } from 'services/common/analytic-trackers';
import ListingService from 'services/listing-service';
import CalendarsService from 'services/calendars-service';
import AuthHandler from 'services/common/auth-handler';

import ScheduledEvents from 'pages/Chat/components/settings/ScheduledEvents';
import DashboardEmptyPage from 'components/organisms/DashboardEmptyPage';
import DashboardTile from 'components/organisms/DashboardTile';
import DashboardEmptyTile from 'components/organisms/DashboardEmptyTile';
import useToast from 'hooks/useToast';
// import NotificationsTile from 'components/molecules/NotificationsTile';
// import { NotificationScope } from 'models/NotificationScope';
import useGetTasks from 'pages/Tasks/hooks/useGetTasks';
import useAddTask from 'pages/Tasks/hooks/useAddTask';
import useEditTask from 'pages/Tasks/hooks/useEditTask';
import useDeleteTask from 'pages/Tasks/hooks/useDeleteTask';
import useChangeTaskStatus from 'pages/Tasks/hooks/useChangeTaskStatus';
import useGetDealOptions from 'pages/Tasks/hooks/useGetDealOptions';
import useGetLeadOptions from 'pages/Tasks/hooks/useGetLeadOptions';
import AddTask from 'components/molecules/add-task/AddTask';
import EditTask from 'components/molecules/edit-task/EditTask';
import DeleteTask from 'components/molecules/delete-task/DeleteTask';
import Task from 'components/molecules/task/Task';
import { tasksTabsArray } from 'models/TasksTabs';
import filterTasksActiveAndExpired from 'pages/Tasks/helpers/filterTasksActiveAndExpired';
import EducationModal from 'components/molecules/education-system/EducationModal';
import useEducationCenter, { SELLER_DASHBOARD_EDUCATION } from 'hooks/useEducationCenter';
import Tooltip from 'components/atoms/Tooltip';

const listingService = ListingService();
const authHandler = AuthHandler();
const calendarsService = CalendarsService();

const DashboardPage = () => {
    const { toast } = useToast();
    const { load } = useLoader();

    const history = useHistory();
    const userId = authHandler.getUserId();
    const [listingsAmount, setListingsAmount] = useState(0);
    const [disableButton, setDisableButton] = useState(false);
    const [upcomingMeetings, setUpcomingMeetings] = useState([]);
    const [calendarConnected, setCalendarConnected] = useState();
    const [calendarStopped, setCalendarStopped] = useState(false);
    const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

    const { dealOptions } = useGetDealOptions();
    const { leadOptions } = useGetLeadOptions();
    const { tasks, handleFetchTasks } = useGetTasks({ selectedTab: tasksTabsArray[0] });
    const { isVisibleCreateTaskModal, setIsVisibleCreateTaskModal, formik } = useAddTask({ handleFetchTasks });
    const { isVisibleEditTaskModal, formikEdit, setIsVisibleEditTaskModal, handleEditTask } = useEditTask({
        tasks,
        handleFetchTasks,
    });
    const { isVisibleDeleteTaskModal, handleCloseDeleteTaskModal, handleOpenDeleteTaskModal, handleDeleteTask } =
        useDeleteTask({ handleFetchTasks });
    const { handleChangeStatusForTask } = useChangeTaskStatus({ handleFetchTasks });

    /* Education section start */
    const {
        getEducationItem,
        acceptEducation,
        rejectEducation,
        moveNext,
        moveLast } = useEducationCenter();
    const educationScope = SELLER_DASHBOARD_EDUCATION;
    const tooltipsTotal = 2;
    const [educationOverview, setEducationOverview] = useState({});

    const onEducationAccepted = () => acceptEducation(educationScope, educationOverview, setEducationOverview);
    const onEducationRejected = () => rejectEducation(setEducationOverview);
    const onTooltipClicked = (order) => moveNext(educationScope, order, educationOverview, setEducationOverview);
    const onLastTooltipClicked = () => moveLast(educationScope, educationOverview, setEducationOverview);

    const showEducationalTooltip = order =>
        !educationOverview?.rejected
        && educationOverview?.modalShown
        && !educationOverview?.tooltipsShown
        && educationOverview?.order === order;

    /* Education section end */

    useEffect(() => {
        document.title = '1Konnection - Seller Dashboard';
        trackPageview();
        listingService.init();
        calendarsService.init();
        setEducationOverview(getEducationItem(educationScope));

        calendarsService
            .getAccount()
            .then(response => {
                setCalendarConnected(true);
                setCalendarStopped(response.data.account.isStopped);
            })
            .catch(error => {
                toast.handleError(error);
                setCalendarConnected(false);
            });

        load(() =>
            Promise.all([
                listingService.getListingsStats(),
                calendarsService.getEvents(userId, null, new Date().toISOString(), null),
            ])
                .then(([getListingsStatsResponse, getEventsResponse]) => {
                    setListingsAmount(getListingsStatsResponse?.data?.count);

                    const groupedByDay = groupArrayByDayPropertyStartTime(getEventsResponse?.data.slice(0, 100));
                    setUpcomingMeetings(Object.values(groupedByDay));
                })
                .catch(error => toast.handleError(error))
                .finally(() => setIsAllDataLoaded(true))
        );

        return () => {
            listingService.dispose();
            calendarsService.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNewDeal = () => {
        setDisableButton(true);
        load(() =>
            listingService
                .createEmpty(+userId)
                .then(response => history.push(`/seller-dashboard/deals/${response?.data?.id}/new`))
                .catch(error => toast.handleError(error))
                .finally(setDisableButton(false))
        );
    };

    const onCalendarConnectClick = () =>
        calendarsService
            .getConnection('connector?redirectPath=seller-dashboard/meetings')
            .then(response => window.location.href = response.data.connectionUrl)
            .catch(error => toast.handleError(error));

    const doTasksExist = () =>
        tasks?.length > 0 && !!filterTasksActiveAndExpired(tasks)?.length;

    return (
        <>
            {isAllDataLoaded &&
                <div className="p-3 px-lg-5 py-lg-4 my-lg-3">
                    <h2 className="text-center text-lg-start c-grey-700 pb-4 pb-lg-3">Seller Dashboard</h2>
                    {(!listingsAmount && !doTasksExist()) && (
                        <DashboardEmptyPage
                            icon="social-plus"
                            header="Get deals done faster"
                            content="Start by adding your first deal."
                            ctaText="Add Deal"
                            ctaAction={createNewDeal}
                            ctaDisabled={disableButton}
                        />
                    )}
                    {(listingsAmount > 0 || doTasksExist()) && (
                        <div className="row">
                            <div className="col-12 col-xl-7">
                                <DashboardTile
                                    title="My Deals"
                                    content="Manage your deals and leads."
                                    amount={listingsAmount}
                                    themeColor="blue-500"
                                    fontColor="white"
                                    numberColor="white"
                                    img="background-figures"
                                    ctaText="Add Deal"
                                    ctaTheme="blue"
                                    is2Cta={true}
                                    cta2Theme="white"
                                    mobileCtaColor="white"
                                    mobileCtaTextColor="blue-500"
                                    onCardClick={() => history.push('/seller-dashboard/deals')}
                                    ctaAction={createNewDeal}
                                    education={
                                        {
                                            show: showEducationalTooltip(0),
                                            total: tooltipsTotal,
                                            moveNext: onTooltipClicked
                                        }
                                    }
                                />
                                <Tooltip
                                    show={showEducationalTooltip(0)}
                                    content="New property looking for a buyer? Add your new deal here."
                                    secondaryContent={`Step 1/${tooltipsTotal}`}
                                    direction="bottom"
                                    additionalClasses="d-lg-none"
                                    ctaText="Next"
                                    ctaAction={() => onTooltipClicked(1)}>
                                    <button
                                        className="d-lg-none d-block btn-secondary blue ff-i600 w-100 mb-4"
                                        onClick={createNewDeal}
                                        disabled={disableButton}
                                    >
                                        Add Deal
                                    </button>
                                </Tooltip>

                                {!calendarConnected &&
                                    <>
                                        <DashboardEmptyTile
                                            header="Connect Calendar"
                                            content="No more ping-pong communication for getting meetings scheduled with your buyers. Connect your calendar, set your availability, and your trusted contacts can meet with you on your terms."
                                            fontColor="grey-550"
                                            img="background-figures-green"
                                            isCta={true}
                                            ctaText="Connect"
                                            ctaTheme="green"
                                            ctaAction={onCalendarConnectClick}
                                        />
                                        <button
                                            className="d-lg-none d-block btn-secondary green ff-i600 w-100 mb-4"
                                            onClick={onCalendarConnectClick}
                                            disabled={disableButton}
                                        >
                                            Connect
                                        </button>
                                    </>
                                }
                                {calendarConnected && calendarStopped &&
                                    <>
                                        <DashboardEmptyTile
                                            header='Reconnect Calendar'
                                            content='Access to your calendar account has expired, please reconnect it.'
                                            fontColor='grey-550'
                                            img='background-figures-green'
                                            isCta={true}
                                            ctaText='Reconnect'
                                            ctaTheme='green'
                                            ctaAction={onCalendarConnectClick}
                                        />
                                        <button
                                            className='d-lg-none d-block btn-secondary green ff-i600 w-100 mb-4'
                                            onClick={onCalendarConnectClick}
                                            disabled={disableButton}>
                                            Reconnect
                                        </button>
                                    </>
                                }
                            </div>
                            <div className="col-12 col-xl-5">
                                {upcomingMeetings.length > 0 && (
                                    <div className="border-grey-200-lg border-radius py-2 px-lg-4 py-lg-4 mx-xl-2 mb-3">
                                        <ScheduledEvents
                                            upcomingMeetings={upcomingMeetings}
                                            title={'Meetings'}
                                            redirectTo={'/seller-dashboard/meetings'}
                                            isCta={true}
                                        />
                                    </div>
                                )}

                                <div className="border-grey-200-lg border-radius mb-4 py-2 px-lg-4 py-lg-4 mx-xl-2">
                                    <Tooltip
                                        show={showEducationalTooltip(1)}
                                        content="Stay on top of your day and organize your to-dos by date, buyer name, or deal name."
                                        secondaryContent={`Step 2/${tooltipsTotal}`}
                                        additionalClasses="arrow-left"
                                        direction="bottom"
                                        ctaText="Got it!"
                                        ctaAction={() => onLastTooltipClicked()}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="d-inline c-grey-700">Tasks</h4>
                                            <Link to="/seller-dashboard/tasks" className="text-decoration-none ff-i600 c-blue-500">
                                                View All
                                            </Link>
                                        </div>
                                    </Tooltip>
                                    <div>
                                        {!tasks?.length && (
                                            <div className="mt-2">
                                                <p className="text-center c-grey-700">You don't have active tasks</p>
                                            </div>
                                        )}
                                        {!!tasks?.length && (
                                            <div className="mt-3">
                                                {filterTasksActiveAndExpired(tasks)
                                                    ?.slice(0, 3)
                                                    ?.map((task, index, array) => (
                                                        <div key={task.id} className="mt-2">
                                                            <Task
                                                                id={task.id}
                                                                dealId={task.dealId}
                                                                status={task.status}
                                                                onEdit={handleEditTask}
                                                                onChangeCheckbox={() => handleChangeStatusForTask(task.id)}
                                                                description={task.description}
                                                                expirationDate={task.dueToDate}
                                                                onDelete={handleOpenDeleteTaskModal}
                                                                borderBottom={true}
                                                                isLast={array.length - 1 === index}
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                        <button
                                            onClick={() => setIsVisibleCreateTaskModal(true)}
                                            className="py-2 mt-3 d-flex align-items-center justify-content-center bc-inherit c-blue-500 border-radius w-100 border-blue-400"
                                        >
                                            <span className="icon icon-add-alert-primary me-2" />
                                            Add Task
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <EducationModal
                                isVisible={!educationOverview?.rejected && !educationOverview?.modalShown && listingsAmount > 0}
                                title={'Seller Dashboard'}
                                paragraphs={
                                    [
                                        { text: 'Your dashboard is the heartbeat of your business.From here, you can see your deals, tasks, and notifications.Consider this your new home screen to start your day.' },
                                        { text: 'Let’s take a look.', bold: true }
                                    ]}
                                handleAccept={onEducationAccepted}
                                handleClose={onEducationRejected} />

                            <AddTask
                                formik={formik}
                                dealOptions={dealOptions}
                                leadOptions={leadOptions}
                                isVisible={isVisibleCreateTaskModal}
                                handleClose={() => setIsVisibleCreateTaskModal(!isVisibleCreateTaskModal)}
                            />
                            <EditTask
                                formik={formikEdit}
                                dealOptions={dealOptions}
                                leadOptions={leadOptions}
                                isVisible={isVisibleEditTaskModal}
                                handleClose={() => setIsVisibleEditTaskModal(!isVisibleEditTaskModal)}
                            />
                            <DeleteTask
                                isVisible={isVisibleDeleteTaskModal}
                                handleSubmit={handleDeleteTask}
                                handleClose={handleCloseDeleteTaskModal}
                            />
                        </div>
                    )
                    }
                </div >
            }
        </>
    );
};

export default DashboardPage;
