import useBase64 from './useBase64';
import { EDUCATION_CENTER_KEY, EDUCATION_REJECTED_KEY } from './useLocalStorage';

export const BUYER_OVERVIEW_EDUCATION = 'buyer-overview';
export const BUYER_DASHBOARD_EDUCATION = 'buyer-dashboard';
export const BUYER_NEW_DEALS_EDUCATION = 'buyer-new-deals';
export const BUYER_DEALS_EDUCATION = 'buyer-deals';
export const BUYER_DEAL_EDUCATION = 'buyer-deal';
export const BUYER_CHAT_EDUCATION = 'buyer-chat';
export const BUYER_CRITERIA_EDUCATION = 'buyer-criteria';

export const SELLER_OVERVIEW_EDUCATION = 'seller-overview';
export const SELLER_DASHBOARD_EDUCATION = 'seller-dashboard';
export const SELLER_DEALS_EDUCATION = 'seller-deals';
export const SELLER_DEAL_EDUCATION = 'seller-deal';
export const SELLER_CONTACTS_EDUCATION = 'seller-contacts';
export const SELLER_CHAT_EDUCATION = 'seller-chat';
export const SELLER_MEETING_EDUCATION = 'seller-meeting';
export const SELLER_TASKS_EDUCATION = 'seller-tasks';

const useEducationCenter = () => {
    const { to, from } = useBase64();

    const getEducationItem = (name) => {
        const educationRejectedItem = localStorage.getItem(EDUCATION_REJECTED_KEY);
        if (educationRejectedItem)
            return {
                rejected: true
            }

        let educationItems = [];
        const educationCenterItem = localStorage.getItem(EDUCATION_CENTER_KEY);
        if (educationCenterItem) {
            educationItems = JSON.parse(from(educationCenterItem));

            const educationItem = educationItems.find(item => item.name === name);
            if (educationItem)
                return educationItem;
        }

        const educationEmptyState = {
            name: name,
            modalShown: false,
            tooltipsShown: false,
            order: 0
        }

        educationItems.push(educationEmptyState);
        localStorage.setItem(EDUCATION_CENTER_KEY, to(JSON.stringify(educationItems)));

        return educationEmptyState;
    }

    const setEducationItem = (name, value) => {
        const educationRejectedItem = localStorage.getItem(EDUCATION_REJECTED_KEY);
        if (educationRejectedItem)
            return;

        let educationItems = [];
        const educationCenterItem = localStorage.getItem(EDUCATION_CENTER_KEY);
        if (educationCenterItem) {
            educationItems = JSON.parse(from(educationCenterItem));

            const educationItemIndex = educationItems.findIndex(item => item.name === name);
            if (educationItemIndex >= 0) {
                educationItems[educationItemIndex] = value;
                localStorage.setItem(EDUCATION_CENTER_KEY, to(JSON.stringify(educationItems)));
                return;
            }
        }

        educationItems.push(value);
        localStorage.setItem(EDUCATION_CENTER_KEY, to(JSON.stringify(educationItems)));
    }

    const acceptEducation = (scope, state, setState) => {
        setState(prevState => ({
            ...prevState,
            modalShown: true
        }));

        let educationOverviewCopy = Object.assign({}, state);
        educationOverviewCopy.modalShown = true;
        setEducationItem(scope, educationOverviewCopy);
    }

    const rejectEducation = (setState) => {
        localStorage.setItem(EDUCATION_REJECTED_KEY, true);
        localStorage.removeItem(EDUCATION_CENTER_KEY);

        if (setState)
            setState(prevState => ({
                ...prevState,
                rejected: true
            }));
    }

    const moveNext = (scope, order, state, setState) => {
        setState(prevState => ({
            ...prevState,
            order: order
        }));

        let educationOverviewCopy = Object.assign({}, state);
        educationOverviewCopy.order = order;
        setEducationItem(scope, educationOverviewCopy);
    }

    const moveLast = (scope, state, setState) => {
        setState(prevState => ({
            ...prevState,
            tooltipsShown: true
        }));

        let educationOverviewCopy = Object.assign({}, state);
        educationOverviewCopy.tooltipsShown = true;
        setEducationItem(scope, educationOverviewCopy);
    }

    return {
        getEducationItem,
        setEducationItem,
        acceptEducation,
        rejectEducation,
        moveNext,
        moveLast
    }
}

export default useEducationCenter;