const DashboardEmptyPage = ({
    icon,
    header,
    content,
    content1,
    ctaText,
    ctaAction,
    ctaDisabled,
    ctaSecondaryText,
    ctaSecondaryAction,
    ctaSecondaryDisabled,
    ctaThirdShow = false,
    ctaThirdText,
    ctaThirdAction,
    showBorders = true,
}) => {
    return (
        <div className={`${showBorders ? 'border-grey-200' : ''} border-radius text-center py-5`}>
            <div className="py-lg-5">
                <div className="row mb-lg-4 mb-3">
                    <div className="col-12">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/dashboards/${icon}.svg`}
                            alt={icon}
                            style={{ maxHeight: '160px' }}
                        />
                    </div>
                </div>
                <h5 className="c-grey-700">{header}</h5>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-7 pt-2">
                        <p className={`ff-i500 c-grey-550 px-3 ${!content1 ? 'pb-lg-4' : ''}  pb-2`}>{content}</p>
                        <p className="ff-i500 c-grey-550 px-3 pb-lg-4 pb-2">{content1}</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-4 col-xl-3 px-5 px-md-0">
                        <button className="btn-primary w-100 ff-i600" disabled={ctaDisabled} onClick={ctaAction}>
                            {ctaText}
                        </button>
                    </div>
                </div>
                {ctaSecondaryAction && (
                    <div className="row justify-content-center mt-4">
                        <div className="col-12 col-lg-4 col-xl-3 px-5 px-md-0">
                            <button
                                className="btn-primary w-100 ff-i600"
                                disabled={ctaSecondaryDisabled}
                                onClick={ctaSecondaryAction}>
                                {ctaSecondaryText}
                            </button>
                        </div>
                    </div>
                )}
                {ctaThirdShow && (
                    <div className="row justify-content-center mt-4">
                        <div className="col-12 col-lg-4 col-xl-3 px-5 px-md-0">
                            <button
                                className="btn-secondary w-100 ff-i600"
                                onClick={ctaThirdAction}>
                                {ctaThirdText}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashboardEmptyPage;
