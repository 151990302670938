import dayjs from 'dayjs';
import { taskStatuses } from 'models/TasksTabs';

const setStatusForTask = (isCompleted, date) => {
    if (isCompleted) return taskStatuses.archived;
    const currentDay = dayjs();
    const isExpired = currentDay.isAfter(dayjs(date).format('YYYY-MM-DD'), 'date');
    if (isExpired) return taskStatuses.expired;
    return taskStatuses.active;
};

export default setStatusForTask;
