import { useEffect, useState, useRef } from 'react';

const MoneyInput = ({ price, setPrice, isValid = () => { }, placeholder, skipInitialization = false, allowUnprice = false, disabled = false }) => {
    const inputRef = useRef();

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (skipInitialization)
            return;

        if (price)
            onChange(price);

        if (disabled)
            reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    const error = 'Please insert price';

    const reset = () => {
        setShowError(false);
        inputRef.current.classList.toggle('border-red', false);
        inputRef.current.classList.toggle('border-green', false);
    }

    const onChange = (newPrice) => {
        const regexp = /^[0-9]+$/;

        if (allowUnprice && !newPrice) newPrice = 0;

        let value = (newPrice + '').replace(/,/g, '');

        if (!allowUnprice && (!value || +value === 0)) {
            inputRef.current.classList.toggle('border-red', true);
            setPrice(0);
            setShowError(true);
            isValid(false);
            return;
        }

        if (!regexp.test(value))
            return;

        if (disabled)
            return;

        reset();

        inputRef.current.classList.toggle('border-green', true);
        isValid(true);

        if (value.length >= 15) value = value.slice(0, -1);
        setPrice(+value);
    };

    return (
        <>
            <div ref={inputRef} className="d-flex align-items-center bc-white border-radius border-grey-200" style={{ height: '61px' }}>
                <div className={`d-flex align-items-center justify-content-center border-radius ff-i600 c-white ${(allowUnprice ? price < 0 : price === 0) ? 'bc-grey-450' : 'bc-blue-500'}`}
                    style={{
                        width: '59px',
                        height: '100%'
                    }}>
                    <span className="text-m">$</span>
                </div>
                <input className="ff-i600 c-grey-700 border-0 text-start ps-2 ms-1"
                    name="investment"
                    id="investmentInput"
                    inputMode="numeric"
                    pattern="[0-9]"
                    value={price === 0 ? '' : new Intl.NumberFormat('en-US').format(price)}
                    placeholder={placeholder}
                    onChange={e => onChange(e.target.value)}
                    disabled={disabled}
                    style={{
                        width: 'calc(100% - 59px)'
                    }} />
            </div>
            {showError && <div className="text-start c-red-500 px-2 text-s">{error}</div>}
        </>
    );
}

export default MoneyInput;