import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import taskSchema from 'validation-schemes/taskSchema';
import TasksService from 'services/tasks-service';
import modifyDataFromFormTask from '../helpers/modifyDataFromFormTask';
import useLoader from 'hooks/useLoader';
import useToast from 'hooks/useToast';

const tasksService = TasksService();

const useAddTask = ({ handleFetchTasks }) => {
    const { load } = useLoader();
    const { toast } = useToast();
    const [isVisibleCreateTaskModal, setIsVisibleCreateTaskModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            description: '',
            dueToDate: '',
            dealId: null,
            leadId: null
        },
        validationSchema: taskSchema,
        onSubmit: values => {
            return load(() =>
                tasksService
                    .createTask(modifyDataFromFormTask(values))
                    .then(handleFetchTasks)
                    .then(() => {
                        setIsVisibleCreateTaskModal(false);
                        formik.resetForm();
                    })
                    .then(() => toast.success({ body: 'Your information was successfully added.' }))
                    .catch(toast.handleError)
            );
        },
    });

    useEffect(() => {
        tasksService.init();

        return () => {
            tasksService.dispose();
        };
    }, []);

    return {
        formik,
        isVisibleCreateTaskModal,
        setIsVisibleCreateTaskModal,
    };
};

export default useAddTask;
