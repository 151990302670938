import { useEffect, useState } from 'react';
import Notification from './Notification';

const GroupedNotifications = ({ dealId, notifications, action, selectedDeal }) => {
    const [showDetails, setShowDetails] = useState(dealId === selectedDeal);
    const [newNotifications, setNewNotifications] = useState(0);

    useEffect(() => {
        setNewNotifications(notifications?.filter(x => x.processed === false).length);
    }, [notifications]);

    return (
        <>
            <div className={`d-flex flex-wrap cursor-pointer bc-white justify-content-between mt-3 w-100 p-3 
            border-grey-200 border-radius-top ${!showDetails ? 'border-radius' : 'border-bottom-0'}`}
                onClick={() => setShowDetails((prevState) => !prevState)}>
                <div className='d-flex w-75'>
                    <div className='d-flex align-items-center' style={{ width: '25px' }}>
                        <span className={`icon icon-arrow-right-grey me-1 ${showDetails ? 'img-rotate-90' : ''}`} style={{ height: '14px' }}></span>
                    </div>
                    <div className='c-grey-700 ff-i500'>Deal #{dealId}</div>
                </div>
                {
                    newNotifications > 0 &&
                    <div className='d-flex align-items-center text-s c-grey-450'>
                        <span className='c-blue-500 ff-i500'>{newNotifications}&nbsp;new</span>
                        <span className='dot bc-blue-500 ms-2 mt-1'></span>
                    </div>
                }
            </div>
            {
                showDetails &&
                <div>
                    {
                        notifications.map((notification, index, array) => {
                            return (
                                <Notification
                                    key={'deal-notification-' + index}
                                    notification={notification}
                                    last={index === array.length - 1}
                                    action={action} />
                            );
                        })
                    }
                </div>
            }
        </>);
}

export default GroupedNotifications;