const setCloseIcon = type => {
    switch (type) {
        case 'CRITICAL':
            return 'icon-close-white';
        case 'ERROR':
            return 'icon-close-red';
        case 'WARNING':
            return 'icon-close-yellow';
        case 'SUCCESS':
            return 'icon-close-green';
        case 'INFORMATION':
            return 'icon-close-black';
        case 'PROMOTIONAL':
            return 'icon-close-white';
        default:
            return 'icon-close-black';
    }
};

export default setCloseIcon;
