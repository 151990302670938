import sharedDateFileComparer from './sharedDateFileComparer';
import uploadedDateFileComparer from './uploadedDateFileComparer';

const sortFiles = (files = []) => [
    ...files.filter(f => f.sharedDate && f.pinned).sort(sharedDateFileComparer),
    ...files.filter(f => f.sharedDate && !f.pinned).sort(sharedDateFileComparer),
    ...files.filter(f => !f.sharedDate && f.pinned).sort(uploadedDateFileComparer),
    ...files.filter(f => !f.sharedDate && !f.pinned).sort(uploadedDateFileComparer),
];

export default sortFiles;