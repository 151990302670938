import { useHistory } from 'react-router-dom';

import AuthHandler from 'services/common/auth-handler';

const authHandler = AuthHandler();

const EmptyChatView = ({ isSeller }) => {
    const history = useHistory();

    const isAuthenticated = authHandler.isAuthenticated();
    const userEmail = authHandler.getUserEmail();

    // TODO: Remove map after full flows migration.
    const redirectMap = new Map([
        ['Buyer', { url: '/buyer-dashboard' }],
        ['Seller', { url: '/seller-dashboard' }],
        ['Buyer & Seller', { url: '/seller-dashboard' }],
        ['Broker - Selling', { url: '/seller-dashboard' }],
        ['Broker - Buying', { url: '/buyer-dashboard' }]
    ]);

    const maskEmail = () => userEmail?.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);
    const getRole = () => authHandler.getUser()?.identitytype;
    const getRedirectUrlToChat = () => isSeller ? '/seller-dashboard/chat' : '/buyer-dashboard/chat';

    return (
        <div className="container-fluid">
            <div className="row mt-5">
                <div className="col-12 text-center">
                    {isAuthenticated
                        ? <img src={`${process.env.PUBLIC_URL}/images/icons/circle-message-lock.svg`} alt="circle-message-lock" className="col-4 col-md-3 col-lg-2 my-4" />
                        : <img src={`${process.env.PUBLIC_URL}/images/icons/circle-email-lock.svg`} alt="circle-email-lock" className="col-4 col-md-3 col-lg-2 my-4" />
                    }
                    <h2 className="c-grey-900">{isAuthenticated ? "Conversation history is empty" : "Access denied. Set-up your password."}</h2>
                    <div className="row justify-content-center mt-1">
                        <div className="col-12 col-lg-6 ff-i500 c-grey-900">
                            {isAuthenticated
                                ? "Your conversations with sellers or buyers will be available directly after matching. Please, complete at least one deal or criteria to see your connections and start chatting."
                                : `To verify your email and set a password you need to click the link in the email that will be sent to ${maskEmail()}. After setting the password you will get access to messages.`
                            }
                        </div>
                    </div>
                    {isAuthenticated
                        ? <button className="btn-regular btn-lrg ff-i600 mt-4 col-lg-4"
                            onClick={() => history.push(`${redirectMap.get(getRole()).url}`)}>
                            Back to dashboard
                        </button>
                        : <button className="btn-regular btn-lrg ff-i600 mt-4 col-lg-4"
                            onClick={() => history.push(`/secure`, { redirectUrl: getRedirectUrlToChat() })}>
                            Back to login page
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

export default EmptyChatView;