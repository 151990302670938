// Changes to this object require DB updates.
const MatchingState = 
{
    MatchingFound: 1,
    Waiting1KonnectionReview: 2,
    DeclinedBy1Konnection: 3,
    WaitingSellerReview: 4,
    DeclinedBySeller: 5,
    WaitingBuyerReview: 6,
    DeclinedByBuyer: 7,
    WaitingBuyerSignature: 8,
    WaitingBuyerVerification: 9,
    MatchingAgreed: 10,
    NotInterestedByBuyer: 11,
    ListingSuspended: 12,
    ListingDeactivated: 13,
    CriteriaDeactivated: 14,
    NoLongerAvailable: 15
};
Object.freeze(MatchingState);
export { MatchingState };