export const PFA_SHOWN_KEY = 'pfa-shown';
export const FUNDS_SHOWN_KEY = 'funds-shown';
export const PFA_ACCEPTED_KEY = 'pfa-accepted';
export const FUNDS_AVAILABLE_KEY = 'funds-available';
export const EMAIL_VERIFIED_KEY = 'email-verified';
export const CRITERIA_EXISTS_KEY = 'criteria-exists';
export const COMPANY_VERIFIED_KEY = 'company-verified';
export const SENT_EMAIL_VERIFICATION_KEY = 'sent-email-verification';
export const COMPLETE_ACCOUNT_MODAL_CACHE_KEY = 'do-not-show-complete-account-modal';
export const MATCH_UNVERIFIED_DEAL_MODAL_CACHE_KEY = 'do-not-show-match-unverified-deal-info';
export const PROFILE_COMPLETED_DATE = 'profile-completed-date';
export const APP_PROMO_BANNER_KEY = 'app-promo-banner';

export const EDUCATION_CENTER_KEY = 'education-center';
export const EDUCATION_REJECTED_KEY = 'education-rejected';

export const IS_SHADOW_KEY = 'is-shadow'

const useLocalStorage = () => {
    const setWithExpiry = (key, value, ttl = 1000 * 60 * 60 * 24) => {
        const now = new Date();

        const item = {
            value: value,
            expiry: now.getTime() + ttl
        };

        localStorage.setItem(key, JSON.stringify(item));
    };

    const clearStorage = () => {
        window.localStorage.removeItem('badge-color');
        window.localStorage.removeItem(IS_SHADOW_KEY);
        window.localStorage.removeItem(EMAIL_VERIFIED_KEY);
        window.localStorage.removeItem(PFA_ACCEPTED_KEY);
        window.localStorage.removeItem(PFA_SHOWN_KEY);
        window.localStorage.removeItem(FUNDS_AVAILABLE_KEY);
        window.localStorage.removeItem(FUNDS_SHOWN_KEY);
        window.localStorage.removeItem(COMPANY_VERIFIED_KEY);
        window.localStorage.removeItem(CRITERIA_EXISTS_KEY);
        window.localStorage.removeItem(EMAIL_VERIFIED_KEY);
        window.localStorage.removeItem(COMPLETE_ACCOUNT_MODAL_CACHE_KEY);
        window.localStorage.removeItem(MATCH_UNVERIFIED_DEAL_MODAL_CACHE_KEY);
    }

    const getWithExpiry = (key) => {
        const itemJson = localStorage.getItem(key)
        if (!itemJson) {
            return null;
        }

        const item = JSON.parse(itemJson);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }

        return item.value;
    }

    return { setWithExpiry, getWithExpiry, clearStorage };
};

export default useLocalStorage;