import { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// import { groupArrayByDayPropertyStartTime } from 'helpers/Time';
import useLocalStorage, { APP_PROMO_BANNER_KEY, CRITERIA_EXISTS_KEY } from 'hooks/useLocalStorage';

// import ScheduledEvents from 'pages/Chat/components/settings/ScheduledEvents';
import DashboardEmptyPage from 'components/organisms/DashboardEmptyPage';
import DashboardEmptyTile from 'components/organisms/DashboardEmptyTile';
import DashboardTile from 'components/organisms/DashboardTile';
import MatchesModal from 'components/molecules/MatchesModal';
import AppPromoBanner from 'components/molecules/app-promo-banner/AppPromoBanner';

import useLoader from 'hooks/useLoader';
import { trackPageview } from 'services/common/analytic-trackers';

import CriteriaService from 'services/criteria-service';
import MatchingsService from 'services/matchings-service';
import AuthHandler from 'services/common/auth-handler';
import UsersService from 'services/users-service';
// import CalendarsService from 'services/calendars-service';
import MatchmakingService from 'services/matchmaking-service';

import glassAnimation from 'styles/lotties/magnifying_glass.json';
import confettiAnimation from 'styles/lotties/confetti.json';
import useToast from 'hooks/useToast';
import NewMatchesContext from 'contexts/NewMatchesContext';
// import NotificationsTile from 'components/molecules/NotificationsTile';
// import { NotificationScope } from 'models/NotificationScope';
import CompletenessTile from 'components/molecules/CompletnessTile';

import EducationModal from 'components/molecules/education-system/EducationModal';
import useEducationCenter, { BUYER_DASHBOARD_EDUCATION } from 'hooks/useEducationCenter';

const criteriaService = CriteriaService();
const matchingsService = MatchingsService();
const matchmakingService = MatchmakingService();
const usersService = UsersService();
const authHandler = AuthHandler();
// const calendarsService = CalendarsService();

const Dashboard = ({
    emailVerified,
    pfaAccepted,
    fundsAvailable,
    companyVerified,
    pfaAction,
    fundsAction,
    emailAction }) => {
    const { toast } = useToast();
    const history = useHistory();
    const { load } = useLoader();
    const userId = authHandler.getUserId();
    const matchesCriteriaId = history?.location?.state?.criteriaId;
    const { setNewMatchesAmount } = useContext(NewMatchesContext);

    const [isAuthenticated] = useState(authHandler.isAuthenticated());
    const [disableButton, setDisableButton] = useState(false);
    // const [newMatchesAmount, setNewMatchesAmount] = useState(0);
    const [newDealsAmount, setNewDealsAmount] = useState(0);
    const [activeDealsAmount, setActiveDealsAmount] = useState(0);
    const [buyerCriteriaAmount, setBuyerCriteriaAmount] = useState(0);
    const [showMatchesModal, setShowMatchesModal] = useState(false);
    const [showNoMatchesModal, setShowNoMatchesModal] = useState(false);
    // const [upcomingMeetings, setUpcomingMeetings] = useState([]);
    const [criteriaExists, setCriteriaExists] = useState(false);
    const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

    const [isShowAppModal, setIsShowAppModal] = useState(false);
    const { setWithExpiry, getWithExpiry } = useLocalStorage();

    /* Education section start */
    const {
        getEducationItem,
        acceptEducation,
        rejectEducation,
        moveNext,
        moveLast } = useEducationCenter();
    const educationScope = BUYER_DASHBOARD_EDUCATION;
    const tooltipsTotal = 3;
    const [educationOverview, setEducationOverview] = useState({});

    const onEducationAccepted = () => acceptEducation(educationScope, educationOverview, setEducationOverview);
    const onEducationRejected = () => rejectEducation(setEducationOverview);
    const onTooltipClicked = (order) => moveNext(educationScope, order, educationOverview, setEducationOverview);
    const onLastTooltipClicked = () => moveLast(educationScope, educationOverview, setEducationOverview);

    const showEducationalTooltip = order =>
        !educationOverview?.rejected
        && educationOverview?.modalShown
        && !educationOverview?.tooltipsShown
        && educationOverview?.order === order;

    /* Education section end */

    useEffect(() => {
        document.title = '1Konnection - Buyer Dashboard';
        trackPageview();
        usersService.init();
        criteriaService.init();
        matchingsService.init();
        // calendarsService.init();
        matchmakingService.init();

        setEducationOverview(getEducationItem(educationScope));

        const getUserStats = () =>
            usersService
                .getStats()
                .then(statsResponse => {
                    setNewDealsAmount(statsResponse?.data?.stats?.newDealsAmount);
                    setNewMatchesAmount(statsResponse?.data?.stats?.newDealsAmount);
                    setActiveDealsAmount(statsResponse?.data?.stats?.activeDealsAmount);
                    setBuyerCriteriaAmount(statsResponse?.data?.stats?.buyerCriteriaAmount);
                })
                .catch(error => toast.handleError(error))
                .finally(() => setIsAllDataLoaded(true));

        const getUserCriteria = () =>
            criteriaService
                .getAllCompleted(userId)
                .then(response => {
                    const criteriaExistsItem = localStorage.getItem(CRITERIA_EXISTS_KEY);
                    if (!criteriaExistsItem)
                        if (response?.data?.criteriaEntities?.length > 0) {
                            localStorage.setItem(CRITERIA_EXISTS_KEY, true);
                            setCriteriaExists(true);
                        }
                });

        if (isAuthenticated) {
            const criteriaExistsItem = localStorage.getItem(CRITERIA_EXISTS_KEY);
            if (!criteriaExistsItem)
                getUserCriteria();
            else
                setCriteriaExists(true);

            getUserStats();

            if (matchesCriteriaId)
                load(() =>
                    matchmakingService
                        .run()
                        .then(matchmakingResponse => {
                            if (matchmakingResponse?.data?.newMatches > 0) {
                                // setNewMatchesAmount(matchmakingResponse?.data?.newMatches);
                                setShowMatchesModal(true);
                                if (!getWithExpiry(APP_PROMO_BANNER_KEY))
                                    setIsShowAppModal(true);

                                getUserStats();
                            } else
                                setShowNoMatchesModal(true);
                        })
                        .catch(error => toast.handleError(error))
                        .finally(() => window.history.replaceState({}, document.title))
                );
            else
                load(() =>
                    matchmakingService
                        .run()
                        .then(matchmakingResponse => {
                            if (matchmakingResponse?.data?.newMatches > 0)
                                getUserStats();
                        })
                );
        }

        return () => {
            usersService.dispose();
            criteriaService.dispose();
            matchingsService.dispose();
            // calendarsService.dispose();
            matchmakingService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const createNewBuyingCriteria = () => {
        setDisableButton(true);
        load(() =>
            criteriaService.createEmpty(+userId))
            .then(response => history.push(`/buyer-dashboard/criteria/${response?.data?.id}/new`))
            .catch(error => toast.handleError(error))
            .finally(setDisableButton(false));
    }

    const navigateToAllCriteria = () => history.push('buyer-dashboard/criteria');
    const navigateToMyDeals = () => history.push('buyer-dashboard/deals');
    const navigateToNewMatches = () => history.push('buyer-dashboard/new-matches');

    const orderTile = useMemo(() => {
        if (newDealsAmount > 0 && (activeDealsAmount > 0 || activeDealsAmount === 0))
            return [0, 1, 2];
        if (newDealsAmount === 0 && activeDealsAmount === 0)
            return [1, 2, 0];
        if (newDealsAmount === 0 && activeDealsAmount > 0)
            return [1, 0, 2];
    }, [newDealsAmount, activeDealsAmount]);

    const closeAppPromoModal = () => {
        setWithExpiry(APP_PROMO_BANNER_KEY, true, 1000 * 14 * 60 * 60 * 24); // store for 2 week
        setIsShowAppModal(false);
    }

    return (
        <>
            {isAllDataLoaded &&
                <div className='p-3 px-lg-5 my-lg-3 py-lg-4'>
                    <h2 className='text-center text-lg-start c-grey-700 pb-4 pb-lg-3'>Buyer Dashboard</h2>
                    {(!!newDealsAmount || !!activeDealsAmount || !!buyerCriteriaAmount) &&
                        <div className='row'>
                            <div className='col-12 col-xl-7 mb-4 d-flex flex-column'>
                                {newDealsAmount === 0 &&
                                    <div className={`order-${orderTile[0]}`}>
                                        <DashboardEmptyTile
                                            img='background-figures-green'
                                            header='No new matches yet'
                                            content='You will receive an email when deals that match your criteria are identified, hang tight!'
                                            isCta={true}
                                            ctaText={'Add Buying Criteria'}
                                            ctaAction={createNewBuyingCriteria}
                                            ctaTheme='green'
                                            education={
                                                {
                                                    show: showEducationalTooltip,
                                                    total: tooltipsTotal,
                                                    moveNext: onTooltipClicked,
                                                    order: 1,
                                                    content: 'After entering your buying criteria, our AI matchmaking gets to work. In your New Matches panel, you’ll see deals from sellers that best align with your buying criteria. To protect seller confidentiality, these are listed in ranges. When you go through your suggested matches, you’ll let us know which ones you’re interested in and which ones aren’t for you.'
                                                }
                                            }
                                        />
                                        <button
                                            className='d-lg-none d-block btn-secondary green ff-i600 w-100 mb-3'
                                            disabled={disableButton}
                                            onClick={createNewBuyingCriteria}>
                                            Add Buying Criteria
                                        </button>
                                    </div>
                                }
                                {newDealsAmount > 0 &&
                                    <div className={`order-${orderTile[0]}`}>
                                        <DashboardTile
                                            title='New Matches'
                                            content='Review, approve and decline your new deal matches.'
                                            amount={newDealsAmount}
                                            themeColor='green-500'
                                            fontColor='white'
                                            numberColor='white'
                                            img='background-figures'
                                            ctaText='View New Matches'
                                            ctaTheme='green'
                                            mobileCtaTextColor='green-800'
                                            onCardClick={navigateToNewMatches}
                                            ctaAction={navigateToNewMatches}
                                            education2={
                                                {
                                                    show: showEducationalTooltip,
                                                    total: tooltipsTotal,
                                                    moveNext: onTooltipClicked,
                                                    order: 1,
                                                    content: 'After entering your buying criteria, our AI matchmaking gets to work. In your New Matches panel, you’ll see deals from sellers that best align with your buying criteria. To protect seller confidentiality, these are listed in ranges. When you go through your suggested matches, you’ll let us know which ones you’re interested in and which ones aren’t for you.'
                                                }
                                            } />
                                    </div>
                                }
                                {activeDealsAmount === 0 &&
                                    <div className={`order-${orderTile[1]}`}>
                                        <DashboardEmptyTile
                                            img='background-figures-blue'
                                            header='No active deals yet'
                                            content='Once you are introduced to a deal, you will be able to view deal and seller information.'
                                            education={
                                                {
                                                    show: showEducationalTooltip,
                                                    total: tooltipsTotal,
                                                    moveNext: onLastTooltipClicked,
                                                    order: 2,
                                                    content: 'If a seller thinks you’re a good fit, your match will move to the ‘’My Deals’ section. Now, you’ll be able to directly communicate with your seller and share docs in the Chat panel.'
                                                }
                                            } />
                                    </div>
                                }
                                {activeDealsAmount > 0 && newDealsAmount > 0 &&
                                    <div className={`order-${orderTile[1]}`}>
                                        <DashboardTile
                                            title='My Deals'
                                            content='View active deals to review deal and seller information.'
                                            amount={activeDealsAmount}
                                            fontColor='grey-550'
                                            numberColor='blue-500'
                                            img='background-figures-blue'
                                            ctaText='View Active Deals'
                                            ctaTheme='blue'
                                            mobileCtaColor='blue-500'
                                            mobileCtaTextColor='white'
                                            onCardClick={navigateToMyDeals}
                                            ctaAction={navigateToMyDeals}
                                            education2={
                                                {
                                                    show: showEducationalTooltip,
                                                    total: tooltipsTotal,
                                                    moveNext: onLastTooltipClicked,
                                                    order: 2,
                                                    content: 'If a seller thinks you’re a good fit, your match will move to the ’My Deals’ section. Now, you’ll be able to directly communicate with your seller and share docs in the Chat panel.'
                                                }
                                            } />
                                    </div>
                                }
                                {activeDealsAmount > 0 && newDealsAmount === 0 &&
                                    <div className={`order-${orderTile[1]}`}>
                                        <DashboardTile
                                            title='My Deals'
                                            content='View active deals to review deal and seller information.'
                                            amount={activeDealsAmount}
                                            themeColor='blue-500'
                                            fontColor='blue-100'
                                            numberColor='blue-100'
                                            img='background-figures'
                                            ctaText='View Active Deals'
                                            ctaTheme='blue'
                                            mobileCtaTextColor='blue-600'
                                            onCardClick={navigateToMyDeals}
                                            ctaAction={navigateToMyDeals}
                                            education2={
                                                {
                                                    show: showEducationalTooltip,
                                                    total: tooltipsTotal,
                                                    moveNext: onLastTooltipClicked,
                                                    order: 2,
                                                    content: 'If a seller thinks you’re a good fit, your match will move to the ’My Deals’ section. Now, you’ll be able to directly communicate with your seller and share docs in the Chat panel.'
                                                }
                                            } />
                                    </div>
                                }
                                {activeDealsAmount === 0 && newDealsAmount === 0 &&
                                    <div className={`order-${orderTile[2]}`}>
                                        <DashboardTile
                                            title='Buying Criteria'
                                            content='Add, edit and name your buying criteria.'
                                            amount={buyerCriteriaAmount}
                                            themeColor='violet'
                                            fontColor='white'
                                            numberColor='white'
                                            img='background-figures'
                                            ctaText='Add Buying Criteria'
                                            ctaTheme='violet'
                                            is2Cta={true}
                                            cta2Theme='white'
                                            mobileCtaTextColor='violet'
                                            onCardClick={navigateToAllCriteria}
                                            ctaAction={createNewBuyingCriteria}
                                            education2={
                                                {
                                                    show: showEducationalTooltip,
                                                    total: tooltipsTotal,
                                                    moveNext: onTooltipClicked,
                                                    order: 0,
                                                    content: 'Stop searching. Our AI matchmaking will do that for you. Set your buying criteria, and our platform will match you with the best options. Remember, you can create and name multiple criteria sets. That way, your results will be more accurate and higher quality!'
                                                }
                                            } />
                                        <button
                                            className='d-lg-none d-block btn-secondary violet ff-i600 w-100 mb-3'
                                            disabled={disableButton}
                                            onClick={createNewBuyingCriteria}>
                                            Add Buying Criteria
                                        </button>
                                    </div>
                                }
                                {(activeDealsAmount !== 0 || newDealsAmount !== 0) &&
                                    <div className={`order-${orderTile[2]}`}>
                                        <DashboardTile
                                            title='Buying Criteria'
                                            content='Add, edit and name your buying criteria.'
                                            amount={buyerCriteriaAmount}
                                            fontColor='grey-550'
                                            numberColor='violet'
                                            img='background-figures-purple'
                                            ctaText='Add Buying Criteria'
                                            ctaTheme='violet'
                                            is2Cta={true}
                                            cta2Theme='violet'
                                            mobileCtaColor='violet'
                                            mobileCtaTextColor='white'
                                            onCardClick={navigateToAllCriteria}
                                            ctaAction={createNewBuyingCriteria}
                                            education2={
                                                {
                                                    show: showEducationalTooltip,
                                                    total: tooltipsTotal,
                                                    moveNext: onTooltipClicked,
                                                    order: 0,
                                                    content: 'Stop searching. Our AI matchmaking will do that for you. Set your buying criteria, and our platform will match you with the best options. Remember, you can create and name multiple criteria sets. That way, your results will be more accurate and higher quality!'
                                                }
                                            } />
                                        <button
                                            className='d-lg-none d-block btn-secondary violet ff-i600 w-100 mb-3'
                                            disabled={disableButton}
                                            onClick={createNewBuyingCriteria}>
                                            Add Buying Criteria
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className='col-12 col-xl-5'>
                                {/* {upcomingMeetings.length > 0 &&
                                    <div className='border-grey-200-lg border-radius py-2 px-lg-4 py-lg-4 mx-xl-2 mb-3'>
                                        <ScheduledEvents
                                            upcomingMeetings={upcomingMeetings}
                                            title={'Meetings'}
                                            redirectTo={'/buyer-dashboard/meetings'}
                                            isCta={true} />
                                    </div>
                                } */}
                                {/*<NotificationsTile scope={NotificationScope.Buyer} />*/}

                                <CompletenessTile
                                    companyVerified={companyVerified}
                                    criteriaExists={criteriaExists}
                                    emailVerified={emailVerified}
                                    fundsAvailable={fundsAvailable}
                                    pfaAccepted={pfaAccepted}
                                    criteriaAction={createNewBuyingCriteria}
                                    pfaAction={pfaAction}
                                    fundsAction={fundsAction}
                                    emailAction={emailAction} />
                            </div>
                        </div>
                    }

                    {(!newDealsAmount && !activeDealsAmount && !buyerCriteriaAmount) &&
                        <div className="row">
                            <div className="col-12 col-xl-7">
                                <DashboardEmptyPage
                                    icon='social-plus'
                                    header='Match to deals you are interested in'
                                    content='Improve your match quality by adding up-to 3 unique buying criteria.'
                                    ctaText='Add Buying Criteria'
                                    ctaAction={createNewBuyingCriteria}
                                    ctaDisabled={disableButton}>
                                </DashboardEmptyPage>
                            </div>
                            <div className="col-12 col-xl-5">
                                <CompletenessTile
                                    companyVerified={companyVerified}
                                    criteriaExists={criteriaExists}
                                    emailVerified={emailVerified}
                                    fundsAvailable={fundsAvailable}
                                    pfaAccepted={pfaAccepted}
                                    criteriaAction={createNewBuyingCriteria}
                                    pfaAction={pfaAction}
                                    fundsAction={fundsAction}
                                    emailAction={emailAction} />
                            </div>
                        </div>

                    }
                    <MatchesModal
                        show={showMatchesModal}
                        ctaAction={navigateToNewMatches}
                        handleClose={() => setShowMatchesModal(false)}
                        header='You got a new match(es) for your criteria.'
                        content='View new deals to approve matches and connect to deals.'
                        ctaText='View New Deals'
                        animation={confettiAnimation} />

                    <MatchesModal
                        show={showNoMatchesModal}
                        ctaAction={createNewBuyingCriteria}
                        handleClose={() => setShowNoMatchesModal(false)}
                        header='No matched deals yet'
                        content='You will receive an email when deals that match your criteria are identified, hang tight!'
                        ctaText='Add Buying Criteria'
                        animation={glassAnimation} />

                    <AppPromoBanner
                        isVisible={isShowAppModal && !showMatchesModal}
                        handleClose={() => closeAppPromoModal()} />

                    <EducationModal
                        isVisible={
                            !educationOverview?.rejected
                            && !educationOverview?.modalShown
                            && (!showMatchesModal && !showNoMatchesModal && !isShowAppModal)
                            && (!!newDealsAmount || !!activeDealsAmount || !!buyerCriteriaAmount)}
                        title={'Buyer Dashboard'}
                        paragraphs={
                            [
                                { text: 'Your dashboard is the heartbeat of your business. From here, you can see your new matches, which deals you’ve already been matched to, and set your buying criteria. Also, get a quick view of your notifications to stay up to date.' },
                                { text: 'Let’s dive in a bit more!', bold: true }
                            ]}
                        handleAccept={onEducationAccepted}
                        handleClose={onEducationRejected} />
                </div>
            }
        </>
    );
}

export default Dashboard;