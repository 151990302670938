import axios from 'axios';
import { traceError } from 'services/common/response-handlers';

function CalendarsService() {
    let controller = null;
    const init = () => (controller = new AbortController());
    const dispose = () => {
        if (controller) controller.abort();
        controller = null;
    };
    const config = () => (controller && controller.signal ? { signal: controller.signal } : null);

    const getConnection = redirectPath =>
        axios
            .get(`/calendars/connection?redirectPath=${redirectPath}`, config())
            .catch(error => traceError(error, '/calendars/connection', 'GET'));

    const getAccount = userId => {
        const url = userId ? `?userId=${userId}` : '';
        return axios
            .get(`/calendars/account${url}`, config())
            .catch(error => traceError(error, '/calendars/account', 'GET'));
    };

    const createAccount = (id, token) =>
        axios
            .post('/calendars/account', { id, token }, config())
            .catch(error => traceError(error, '/calendars/account', 'POST'));

    const removeAccount = () =>
        axios.delete('/calendars/account', config()).catch(error => traceError(error, '/calendars/account', 'DELETE'));

    const getBusySlots = (userId, startTime, endTime) =>
        axios
            .get(`/calendars/free-busy?userId=${userId}&startTime=${startTime}&endTime=${endTime}`, config())
            .catch(error => traceError(error, '/calendars/free-busy', 'GET'));

    const createEvent = (dealId, hostUserId, guestUserId, startTime, endTime, location, guestUserPhoneNumber) =>
        axios
            .post(
                '/calendars/events',
                {
                    dealId,
                    hostUserId,
                    guestUserId,
                    startTime,
                    endTime,
                    location,
                    guestUserPhoneNumber
                },
                config()
            )
            .catch(error => traceError(error, '/calendars/events', 'POST'));

    const putEvent = (id, sellerId, startTime, endTime, location, buyerPhoneNumber, sellerPhoneNumber) =>
        axios
            .put(
                `/calendars/events/${id}?sellerId=${sellerId}`,
                {
                    startTime,
                    endTime,
                    location,
                    buyerPhoneNumber,
                    sellerPhoneNumber
                },
                config()
            )
            .catch(error => traceError(error, '/calendars/events', 'PUT'));

    const deleteEvent = (eventId, sellerId, reason) =>
        axios
            .delete(`/calendars/events/${eventId}?sellerId=${sellerId}`, { data: { reason: reason } }, config())
            .catch(error => traceError(error, '/calendars/events', 'DELETE'));

    const getEvents = (hostUserId, guestUserId = null, startTime = null, endTime = null, dealId = null) => {
        let url = `hostUserId=${hostUserId}`;
        if (guestUserId) url += `&guestUserId=${guestUserId}`;
        if (startTime) url += `&startTime=${startTime}`;
        if (endTime) url += `&endTime=${endTime}`;
        if (dealId) url += `&dealId=${dealId}`;
        return axios
            .get(`/calendars/events?${url}`, config())
            .catch(error => traceError(error, '/calendars/events', 'GET'));
    };

    const getAvailability = userId => {
        const url = userId ? `?userId=${userId}` : '';
        return axios
            .get(`/calendars/availability${url}`, config())
            .catch(error => traceError(error, '/calendars/account', 'GET'));
    };

    const getTimezones = () =>
        axios.get('/calendars/time-zones', config()).catch(error => traceError(error, '/calendars/time-zones', 'GET'));

    const postAvailability = (workingHours, timeZoneId) =>
        axios
            .post('/calendars/availability', { workingHours, timeZoneId }, config())
            .catch(error => traceError(error, '/calendars/availability', 'POST'));

    const getGuestEvents = (dealId = null) => {
        const params = dealId ? `?dealId=${dealId}` : '';
        return axios
            .get(`/calendars/guest-events${params}`, config())
            .catch(error => traceError(error, '/calendars/guest-events', 'GET'));
    };

    return {
        init,
        dispose,
        getConnection,
        getAccount,
        createAccount,
        removeAccount,
        getBusySlots,
        createEvent,
        putEvent,
        deleteEvent,
        getEvents,
        getAvailability,
        getTimezones,
        postAvailability,
        getGuestEvents,
    };
}

export default CalendarsService;
