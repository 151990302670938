import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PasswordInput from 'components/atoms/PasswordInput';
import InformModal from 'components/atoms/InformModal';
import { trackPageview } from 'services/common/analytic-trackers';
import UsersService from 'services/users-service';
import AuthService from 'services/auth-service';
import AuthHandler from 'services/common/auth-handler';
import useToast from 'hooks/useToast';
import useLoader from 'hooks/useLoader';

const usersService = UsersService();
const authService = AuthService();
const authHandler = AuthHandler();

const Security = () => {
    const history = useHistory();
    const { toast } = useToast();
    const { load } = useLoader();

    useEffect(() => {
        document.title = '1Konnection - Security';
        trackPageview();
        usersService.init();
        authService.init();

        return () => {
            usersService.dispose();
            authService.dispose();
        };
    }, []);

    const userEmail = authHandler.getUserEmail();
    const isAuthenticated = authHandler.isAuthenticated();
    if (!userEmail) history.push('/secure', { redirectUrl: '/settings/security' });

    const [emailSent, setEmailSent] = useState(false);

    const currentPasswordRef = useRef();
    const [currentPassword, setCurrentPassword] = useState('');
    const [currentPasswordShown, setCurrentPasswordShown] = useState(false);

    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isValidNewPassword, setIsValidNewPassword] = useState(false);
    const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
    const [showMatchPasswordErrorMessage, setShowMatchPasswordErrorMessage] = useState(false);

    const newPasswordRef = useRef();
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordShown, setNewPasswordShown] = useState(false);

    const confirmPasswordRef = useRef();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);

    useEffect(() => {
        setShowMatchPasswordErrorMessage(false);
        if (confirmPassword !== newPassword) {
            confirmPasswordRef.current.classList.toggle('invalid', true);
            setShowMatchPasswordErrorMessage(true);
            return;
        }
    }, [confirmPassword, newPassword]);

    const postNewPassword = () => authService.sendConfirmationEmail(userEmail).finally(() => setEmailSent(true));

    const reset = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        currentPasswordRef.current.classList.toggle('invalid', false);
        currentPasswordRef.current.classList.toggle('valid', false);
        newPasswordRef.current.classList.toggle('invalid', false);
        newPasswordRef.current.classList.toggle('valid', false);
        confirmPasswordRef.current.classList.toggle('invalid', false);
        confirmPasswordRef.current.classList.toggle('valid', false);
        setIsValidPassword(false);
    };

    const updatePassword = () => {
        load(() =>
            usersService
                .changePassword({ oldPassword: currentPassword, newPassword: newPassword })
                .then(() => {
                    toast.success({ body: 'Your Password was successfully changed.' });
                    reset();
                })
                .catch(error => {
                    toast.handleError(error);
                    currentPasswordRef.current.classList.toggle('invalid', true);
                })
        );
    };

    return (
        <>
            {!isAuthenticated && (
                <div
                    className={`row justify-content-center px-3 px-md-0 pb-3 pb-md-0 ${
                        !emailSent ? 'show' : 'collapse'
                    }`}
                >
                    <div className="col-12 text-center pt-4 pt-md-5">
                        <h4 className="c-grey-900 pb-4 pb-md-3">You haven't set your password yet</h4>
                        <div className="col-12 col-lg-8 offset-lg-2 text-center pb-4">
                            <p className="ff-i500 c-grey-450">
                                To verify your email and set a password you need to click the link in the email that
                                will be sent to {userEmail}
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mb-md-5 pb-md-3 px-2 c-grey-550">
                        <button className="btn-primary ff-i600 w-100" onClick={postNewPassword}>
                            Send Email
                        </button>
                    </div>
                </div>
            )}

            {isAuthenticated && (
                <div className="row justify-content-center pt-2 pt-md-4">
                    <div className="col-12 col-lg-10 col-xl-8">
                        <div className="py-3 position-relative">
                            <PasswordInput
                                passwordShown={currentPasswordShown}
                                setPasswordShown={setCurrentPasswordShown}
                                password={currentPassword}
                                setPassword={setCurrentPassword}
                                passwordRef={currentPasswordRef}
                                isValid={setIsValidPassword}
                                placeholder={'Current password'}
                            />
                        </div>
                        <div className="py-3 position-relative">
                            <PasswordInput
                                passwordShown={newPasswordShown}
                                setPasswordShown={setNewPasswordShown}
                                password={newPassword}
                                setPassword={setNewPassword}
                                passwordRef={newPasswordRef}
                                isValid={setIsValidNewPassword}
                                placeholder={'New password'}
                            />
                        </div>
                        <div className="py-3 position-relative">
                            <PasswordInput
                                passwordShown={confirmPasswordShown}
                                setPasswordShown={setconfirmPasswordShown}
                                password={confirmPassword}
                                setPassword={setConfirmPassword}
                                passwordRef={confirmPasswordRef}
                                isValid={setIsValidConfirmPassword}
                                placeholder={'Confirm your password'}
                            />

                            {showMatchPasswordErrorMessage && (
                                <span className="text-start c-red-500 px-2 text-s">
                                    Your confirmation password does not match.
                                </span>
                            )}
                        </div>
                        <div className="col-12 py-4">
                            <button
                                type="button"
                                className="btn-primary ff-i600 w-100"
                                onClick={updatePassword}
                                disabled={
                                    !isValidPassword ||
                                    !isValidNewPassword ||
                                    !isValidConfirmPassword ||
                                    newPassword !== confirmPassword
                                }
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <InformModal
                show={emailSent}
                handleClose={() => setEmailSent(false)}
                handleConfirmClick={() => history.push('/')}
                confirmButtonText="Back to the home page"
                messageHeader="Email verification"
                messageMainBody="Verification email sent"
                messageBody={`We've sent an email to ${userEmail} with a link to get back into your account.`}
                messageBottom="Didn't receive your verification or reset email?"
                bottomCtaText="Resend"
                handleBottomCta={postNewPassword}
            />
        </>
    );
};

export default Security;
