import * as yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

const taskSchema = yup.object().shape({
    description: yup.string().required('This field is required. Please add information.').max(500, 'Field must be at most 500 characters'),
    dueToDate: yup.date().required('This field is required. Please add information.').min(today, 'Date cannot be in the past')
});

export default taskSchema;
