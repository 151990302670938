import { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import CircleProgress from 'core/CircleProgress';

import useLoader from 'hooks/useLoader';

const UploadFilesPanel = ({
    show,
    setShow,
    shareFile,
    shareLink,
    uploadingFiles,
    setUploadingFiles,
    uploadingLinks,
    setUploadingLinks,
    progress,
    showProgress,
    disabledFileUpload,
    maxSizeOfFiles = 100,
    maxNumberOfFiles = 10 }) => {
    const { load } = useLoader();

    const [disabledUploadButton, setDisabledUploadButton] = useState(false);

    const [uploadFileToDeal, setUploadFileToDeal] = useState(false);
    const [uploadLinkToDeal, setUploadLinkToDeal] = useState(false);

    const [maxSize] = useState(maxSizeOfFiles * 1048576);
    const [dragEnterCount, setDragEnterCount] = useState(0);

    const onFilesChange = inputFiles => {
        let sizes = 0;
        let length = 0;
        let arrayFiles = [];
        uploadingFiles.forEach(file => sizes += file.size);
        Array.from(inputFiles).forEach(inputFile => {
            sizes += inputFile.size;
            if (inputFile.size >= (maxSize / maxNumberOfFiles)
                || sizes >= maxSize
                || ++length > maxNumberOfFiles)
                arrayFiles.push({ file: inputFile, isUploading: false, errorMessage: 'This file is too large. We support up to 10 MB.' });

            else arrayFiles.push({ file: inputFile, isUploading: true });
        });

        setUploadingFiles(arrayFiles);
    }

    const handleRemoveUploading = (setItems, index) =>
        setItems(prev => [
            ...prev.slice(0, index),
            ...prev.slice(index + 1, prev.length)
        ]);

    const [linkName, setLinkName] = useState('');
    const linkNameRef = useRef();
    const [showLinkNameEmptyError, setShowLinkNameEmptyError] = useState(false);
    const [showLinkNameLengthError, setShowLinkNameLengthError] = useState(false);
    const [isLinkNameValid, setIsLinkNameValid] = useState(false);

    const [linkUrl, setLinkUrl] = useState('');
    const linkUrlRef = useRef();
    const [showLinkUrlEmptyError, setShowLinkUrlEmptyError] = useState(false);
    const [showLinkUrlLengthError, setShowLinkUrlLengthError] = useState(false);
    const [showLinkUrlNotValidError, setShowLinkUrlNotValidError] = useState(false);
    const [isLinkUrlValid, setIsLinkUrlValid] = useState(false);

    const onChangeLinkName = event => {
        let inputValue = event.target.value;
        setLinkName(inputValue);

        setShowLinkNameEmptyError(false);
        setShowLinkNameLengthError(false);
        linkNameRef.current.classList.toggle('invalid', false);
        linkNameRef.current.classList.toggle('valid', false);

        if (!inputValue.length > 0) {
            linkNameRef.current.classList.toggle('invalid', true);
            setIsLinkNameValid(false);
            setShowLinkNameEmptyError(true);
            return;
        }
        if (inputValue.length > 100) {
            linkNameRef.current.classList.toggle('invalid', true);
            setIsLinkNameValid(false);
            setShowLinkNameLengthError(true);
            return;
        }

        linkNameRef.current.classList.toggle('valid', true);
        setIsLinkNameValid(true);
    }

    const onChangeLinkUrl = event => {
        let inputValue = event.target.value;
        setLinkUrl(inputValue);

        setShowLinkUrlEmptyError(false);
        setShowLinkUrlLengthError(false);
        setShowLinkUrlNotValidError(false);
        linkUrlRef.current.classList.toggle('invalid', false);
        linkUrlRef.current.classList.toggle('valid', false);

        if (!inputValue.startsWith('http')) {
            linkUrlRef.current.classList.toggle('invalid', true);
            setIsLinkUrlValid(false);
            setShowLinkUrlNotValidError(true);
            return;
        }
        if (!inputValue.length > 0) {
            linkUrlRef.current.classList.toggle('invalid', true);
            setIsLinkUrlValid(false);
            setShowLinkUrlEmptyError(true);
            return;
        }
        if (inputValue.length > 250) {
            linkUrlRef.current.classList.toggle('invalid', true);
            setIsLinkUrlValid(false);
            setShowLinkUrlLengthError(true);
            return;
        }

        linkUrlRef.current.classList.toggle('valid', true);
        setIsLinkUrlValid(true);
    }

    const addLinkToArr = () => {
        setUploadingLinks(prev =>
            [...prev,
            {
                id: prev.length + 1,
                linkName: linkName,
                url: linkUrl
            }]);
        setIsLinkNameValid(false);
        setIsLinkUrlValid(false);
        setLinkName('');
        setLinkUrl('');

        linkNameRef.current.classList.toggle('valid', false);
        linkUrlRef.current.classList.toggle('valid', false);
    }

    const uploadDocuments = () => {
        setDisabledUploadButton(true);
        load(() =>
            Promise
                .all([
                    !!uploadingFiles.length && shareFile(uploadingFiles, uploadFileToDeal),
                    !!uploadingLinks.length && shareLink(uploadingLinks, uploadLinkToDeal)
                ])
                .finally(() => {
                    setDisabledUploadButton(false);
                }));
    }

    return <>
        <Modal
            show={show}
            onHide={() => {
                setShow(false);
            }}
            className="modal-fullscreen-lg-down pe-0 pb-lg-0"
            contentClassName="border-radius-0 h-100"
            dialogClassName="m-0 ms-auto mw-600 h-100"
        >
            <div className="d-none d-md-flex">
                <Modal.Header className="p-3 p-md-4 w-100" closeButton>
                    <div className="d-flex w-100">
                        <span onClick={() => setShow(false)} className="icon icon-arrow-left cursor-pointer"></span>
                        <h2 className="c-grey-700 text-break ms-2">Upload</h2>
                    </div>
                </Modal.Header>
            </div>
            <div className="d-flex d-md-none">
                <Modal.Header className="d-flex flex-column p-3 p-md-4 w-100">
                    <div className="d-flex w-100">
                        <span onClick={() => setShow(false)} className="icon icon-arrow-left"></span>
                        <h2 className="c-grey-700 text-break ms-2">Upload</h2>
                    </div>
                </Modal.Header>
            </div>
            {/* <Modal.Header closeButton className="p-3 p-md-4">
                <h2 className="c-grey-700 text-break">Upload</h2>
            </Modal.Header> */}
            <Modal.Body className="custom-scrollbar full-h px-0 py-0 mb-lg-0" style={{ overflow: 'auto' }}>
                <div className="c-grey-700">
                    <div className="px-4 py-4">
                        <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
                            <h5>Upload File</h5>
                        </div>
                        <div className={`border-radius border-grey-200 w-100 mb-3 py-4 ${dragEnterCount ? 'border-blue-dashed bc-blue-100' : ''}`}
                            onDragEnter={() => {
                                setDragEnterCount(prev => prev + 1);
                            }}
                            onDragLeave={() => {
                                setDragEnterCount(prev => prev - 1);
                            }}
                            onDragOver={(e) => {
                                e.preventDefault();
                            }}
                            onDrop={(e) => {
                                e.preventDefault();
                                onFilesChange(e.dataTransfer.files);
                                setDragEnterCount(0);
                            }}>
                            <div className="text-center ff-i600 c-grey-700 text-m">Attach File</div>
                            <div className="text-center ff-i600 c-grey-700 text-m">
                                Drag and drop docs here or
                                <label className="ff-i600 c-blue-500 cursor-pointer"
                                    htmlFor="files">
                                    &nbsp;browse
                                </label>
                            </div>
                            <div className="text-center ff-i500 c-grey-450 text-m">We support up to 10 MB.</div>
                            <div className="d-flex justify-content-between px-4 mt-3">
                                <div className="d-flex align-items-center">
                                    <input
                                        type="checkbox"
                                        className="col-1 me-2"
                                        id="add-to-deal"
                                        checked={uploadFileToDeal}
                                        onChange={() => setUploadFileToDeal(prev => !prev)} />
                                    <label htmlFor="add-to-deal" className="ff-i600 c-grey-700 text-s">Save To Data Vault</label>
                                </div>
                            </div>
                        </div>
                        <input id="files"
                            type="file"
                            multiple
                            className="d-none"
                            disabled={disabledFileUpload}
                            onChange={e => { onFilesChange(e.target.files); e.target.value = ''; }} />

                        {uploadingFiles.map((uploadingFile, index) =>
                            <div key={index} className="d-flex justify-content-between align-items-center border-grey border-radius px-3 py-2 mb-2">
                                <span>
                                    <span className="ff-i500 c-grey-700 text-s">{uploadingFile.file.name}</span>
                                    {!uploadingFile.isSuccess &&
                                        <span className="d-block ff-i500 c-red-500 text-s">
                                            {uploadingFile.errorMessage}
                                        </span>
                                    }
                                </span>
                                {showProgress && uploadingFile.isUploading
                                    ? <CircleProgress strokeWidth="3"
                                        sqSize="24"
                                        percentage={progress} />
                                    : <>
                                        {uploadingFile.isSuccess
                                            ? <span className="icon icon-approve-green-sm"></span>
                                            : <span className="icon icon-cancel cursor-pointer" onClick={() => handleRemoveUploading(setUploadingFiles, index)}></span>
                                        }
                                    </>
                                }
                            </div>
                        )}

                        <div className="d-flex align-items-center justify-content-between border-bottom mt-5 mb-3">
                            <h5>Upload Link</h5>
                        </div>
                        <div className="border-radius border-grey-200 w-100 mb-3 py-4 px-4">
                            <input
                                type="text"
                                ref={linkNameRef}
                                className="w-100"
                                placeholder="Name to display"
                                value={linkName}
                                maxLength={100}
                                onChange={(e) => onChangeLinkName(e)} />
                            {showLinkNameEmptyError && <div className="text-start c-red-500 text-s px-2">This field cannot be empty</div>}
                            {showLinkNameLengthError && <div className="text-start c-red-500 text-s px-2">Use less than 100 characters</div>}
                            <input
                                type="text"
                                ref={linkUrlRef}
                                className="w-100 mt-3"
                                placeholder="Insert a link"
                                value={linkUrl}
                                maxLength={250}
                                onChange={(e) => onChangeLinkUrl(e)} />
                            {showLinkUrlEmptyError && <div className="text-start c-red-500 text-s px-2">This field cannot be empty</div>}
                            {showLinkUrlLengthError && <div className="text-start c-red-500 text-s px-2">Use less than 250 characters</div>}
                            {showLinkUrlNotValidError && <div className="text-start c-red-500 text-s px-2">Please provide valid link</div>}
                            <div className="d-flex justify-content-between mt-3">
                                <div className="d-flex align-items-center">
                                    <input
                                        type="checkbox"
                                        className="col-1 me-2"
                                        id="add-to-deal-link"
                                        checked={uploadLinkToDeal}
                                        onChange={() => setUploadLinkToDeal(prev => !prev)} />
                                    <label htmlFor="add-to-deal-link" className="ff-i600 c-grey-700 text-s">Save To Data Vault</label>
                                </div>
                                <div className="mt-3 mt-md-0">
                                    <button
                                        className="btn-secondary w-100 px-5"
                                        disabled={!isLinkNameValid || !isLinkUrlValid}
                                        onClick={() => addLinkToArr()}>
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        {uploadingLinks.map((uploadingLink, index) =>
                            <div key={index} className="d-flex justify-content-between align-items-center border-grey border-radius px-3 py-2 mb-2">
                                <span className="ff-i500 c-grey-700 text-s">{uploadingLink.linkName}</span>
                                <span className="icon icon-cancel cursor-pointer" onClick={() => handleRemoveUploading(setUploadingLinks, index)}></span>
                            </div>
                        )}
                        {!!(uploadingFiles.length || uploadingLinks.length) &&
                            <button
                                className="btn-primary blue w-100 mt-4"
                                onClick={uploadDocuments}
                                disabled={disabledUploadButton}>Upload</button>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>
}

export default UploadFilesPanel;