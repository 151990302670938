import { useEffect, useState } from 'react';
import DealsFeedbackService from 'services/deals-feedback-service';

const dealsFeedbackService = DealsFeedbackService();
const DealFeedback = ({ setReasons, setComment, setIsValid }) => {

    const [otherReasonId, setOtherReasonId] = useState(0);
    const [rejectionReasons, setRejectionsReasons] = useState([]);
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [additionalComment, setAdditionalComment] = useState('');

    useEffect(() => {
        setReasons(selectedReasons);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReasons])

    useEffect(() => {
        if (selectedReasons.includes(otherReasonId) && !additionalComment) {
            setIsValid(false);
            return;
        }

        if (selectedReasons.length === 0 && !additionalComment) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalComment, selectedReasons])

    useEffect(() => {
        dealsFeedbackService.init();

        dealsFeedbackService
            .getReasons()
            .then((response) => {
                setRejectionsReasons(response.data.reasons);
                setOtherReasonId(response.data.reasons.find((reason => reason.name === 'Other')).id);
            })

        return () => {
            dealsFeedbackService.dispose();
        }
    }, [])

    const handleOnChangeReasons = (id, isChecked = false) =>
        setSelectedReasons(prev => isChecked ? prev.filter(cid => cid !== id) : [...prev, id]);

    const onCommentChange = (e) => {
        setAdditionalComment(e.target.value);
        setComment(e.target.value);
    }

    return (
        <>
            <p className="ff-i600 c-grey-700 mt-4">Why are you declining this deal?</p>
            {
                rejectionReasons.map(reason => {
                    return (
                        <div key={reason.id} className="d-flex">
                            <input
                                type="checkbox"
                                className="col-1 me-3"
                                checked={selectedReasons.includes(reason.id)}
                                onChange={() => handleOnChangeReasons(reason.id, selectedReasons.includes(reason.id))}
                            />
                            <p className="c-grey-700">{reason.description}</p>
                        </div>
                    );
                })
            }
            <p className="ff-i600 c-grey-700 mt-3">Additional comment</p>
            <textarea
                className="ff-i500 p-3 pe-4 w-100"
                maxLength="500"
                rows="4"
                style={{ minHeight: "100px", maxHeight: "300px" }}
                placeholder="Describe your experience with this deal"
                value={additionalComment}
                onChange={onCommentChange}>
            </textarea>
        </>
    );
}

export default DealFeedback;