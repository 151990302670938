import axios from 'axios';
import { traceError } from 'services/common/response-handlers';

function AuthService() {
    let controller = null;
    const init = () => (controller = new AbortController());
    const dispose = () => {
        if (controller) controller.abort();
        controller = null;
    };
    const config = () => (controller && controller.signal ? { signal: controller.signal } : null);

    const register = ({ firstName = null, lastName = null, email, phone, CountryCode, type, password }) =>
        axios
            .post('/auth/register', { firstName, lastName, email, phone, CountryCode, type, password }, config())
            .catch(error => traceError(error, '/auth/register', 'POST'));

    const login = ({ email, password, rememberMe }) =>
        axios
            .post('/auth/login', { email, password, rememberMe }, config())
            .catch(error => traceError(error, '/auth/login', 'POST'));

    const logout = () =>
        axios.post('/auth/logout', null, config()).catch(error => traceError(error, '/auth/logout', 'POST'));

    const sendConfirmationEmail = email =>
        axios
            .post('/auth/confirmation', { email }, config())
            .catch(error => traceError(error, '/auth/confirmation', 'POST'));

    const forgotPassword = email =>
        axios
            .post('/auth/forgot-password', { email }, config())
            .catch(error => traceError(error, '/auth/forgot-password', 'POST'));

    const validateResetToken = token =>
        axios
            .post('/auth/validate-reset-token', { token }, config())
            .catch(error => traceError(error, '/auth/validate-reset-token', 'POST'));

    const setupPassword = ({ token, password }) =>
        axios
            .post('/auth/setup-password', { token, password }, config())
            .catch(error => traceError(error, '/auth/setup-password', 'POST'));

    const simulation = ({ userEmail, userId }) => {
        let request = {};
        if (userEmail) request.userEmail = userEmail;
        if (userId) request.userId = +userId;

        return axios
            .post('/auth/simulation', request, config())
            .catch(error => traceError(error, '/auth/simulation', 'POST'));
    };

    return {
        init,
        dispose,
        register,
        login,
        logout,
        sendConfirmationEmail,
        forgotPassword,
        validateResetToken,
        setupPassword,
        simulation,
    };
}

export default AuthService;
