import { taskStatuses } from 'models/TasksTabs';

const filterTasksActiveAndExpired = tasks => {
    return tasks
        .map(task => {
            if (task.status === taskStatuses.archived) return null;
            return task;
        })
        .filter(task => !!task);
};

export default filterTasksActiveAndExpired;
