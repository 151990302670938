import axios from 'axios';
import { traceError } from './common/response-handlers';

function DealsLeadsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getAll = (dealId, userId) => {
        let queryParam = userId ? `?userId=${userId}` : ''
        return axios
            .get(`/deals/${dealId}/leads${queryParam}`, config())
            .catch(error => traceError(error, `/deals/${dealId}/leads${queryParam}`, 'GET'));
    }

    const patchLeadStatus = (dealId, data) => axios
        .patch(`/deals/${dealId}/leads/stage`, data, config())
        .catch(error => traceError(error, `/deals/${dealId}/leads/stage`, 'PATCH'));

    return {
        init,
        dispose,
        getAll,
        patchLeadStatus
    };
}

export default DealsLeadsService;