import { useState } from "react";
import { useHistory } from 'react-router';

import { SendBirdConsts } from 'models/SendBirdConsts';
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";

const BuyerChannelPreview = ({ userId, channel, onChannelSelect, selectedChannel }) => {
    const history = useHistory();

    const [removeLabel, setRemoveLabel] = useState(false);
    const sendbirdContext = useSendbirdStateContext();
    const sb = sendBirdSelectors.getSdk(sendbirdContext);

    if (sb && sb.GroupChannel && sb.getConnectionState() === SendBirdConsts.ConnectionStateOpen && channel.channel_url && !removeLabel)
        sb.GroupChannel.getChannel(channel.channel_url, (response) => {
            if (response?.lastMessage?._sender?.userId === userId)
                setRemoveLabel(true);
        });

    return (
        <div
            onClick={() => { onChannelSelect(channel.channel_url); history.push(`?channel=${channel.channel_url}`); }}
            className={`row border-bottom-blue-100 p-3 c-grey-550 ${channel?.channel_url === selectedChannel?.channel_url && 'bc-grey-100'}`}
            style={channelBox}>
            <div className="col-7 ff-i600 text-truncate">{channel?.metadata?.is1KTeamChat ? channel?.last_message?.message || channel?.last_message?.file?.name : `Deal #${channel.metadata?.listingId}`}</div>
            <div className="col-5 text-end ff-i500 p-0 my-auto" style={{ fontSize: "14px" }}>
                {channel?.last_message?.type !== SendBirdConsts.MessageTypeAdmin && channel?.last_message?.user?.user_id !== userId &&
                    <div className={`position-relative ${removeLabel ? 'd-none' : ''}`}>
                        <span className="bc-green-500 px-2 py-1 border-radius-medium c-white">Your turn</span>
                        {channel?.unread_message_count > 0 &&
                            <span className="position-absolute dot bc-blue-500 align-self-start"
                                style={{
                                    height: '13px', width: '13px', bottom: '18px', right: '-2px', border: '2px solid #F3F6FC'
                                }} />
                        }
                    </div>
                }
                {channel?.last_message?.custom_type === SendBirdConsts.MessageCustomTypeAdminApi && channel?.last_message?.type === SendBirdConsts.MessageTypeAdmin &&
                    <div className={`position-relative ${removeLabel ? 'd-none' : ''}`}>
                        <span className="bc-blue-500 px-2 py-1 border-radius-medium c-white">Start Chat</span>
                        {channel?.unread_message_count > 0 &&
                            <span className="position-absolute dot bc-blue-500 align-self-start"
                                style={{
                                    height: '13px', width: '13px', bottom: '18px', right: '-2px', border: '2px solid #F3F6FC'
                                }} />
                        }
                    </div>
                }
            </div>
        </div>
    );
}

const channelBox = {
    borderBottom: '1px solid #F3F6FC',
    transition: 'background-color 200ms ease-in',
    cursor: 'pointer'
}

export default BuyerChannelPreview;