import { useState } from 'react';
import PhoneInput from './PhoneInput';
import dayjs from 'dayjs';
import establishEndTimeOfMeeting from 'utils/establish-end-time-of-meeting';
import formatDateForMeeting from 'utils/format-date-for-meeting';

const SetLocationForMeetingModal = ({
    meetingLength,
    meetingDatetime,
    dealMemberName,
    dealNumber,
    currentUser,
    location,
    setLocation,
    phone,
    setPhone,
}) => {
    const [, setIsValid] = useState(false);
    const startMeetingTime = formatDateForMeeting(meetingDatetime);
    const endMeetingTime = formatDateForMeeting(establishEndTimeOfMeeting({ meetingLength, meetingDatetime }));
    return (
        <section className="p-3">
            <div className="text-center">
                <h1 className="fw-semibold mb-4">{meetingLength} minutes</h1>
                <span className="d-block">
                    {currentUser?.name} / {dealMemberName} Deal #{dealNumber} Call
                </span>
                <span className="d-block">
                    {startMeetingTime} - {endMeetingTime}
                </span>
                <span>{dayjs(meetingDatetime).format('MM/DD/YYYY')}</span>
            </div>
            <textarea
                className="w-100 p-3 my-4"
                placeholder="Add a locaiton (an address, Google Meet, Zoom )"
                value={location}
                onChange={event => setLocation(event.target.value)}
            />
            <PhoneInput
                number={phone}
                setNumber={setPhone}
                isValid={setIsValid}
                placeholder={'Phone'}
                showIcon={true}
            />
        </section>
    );
};

export default SetLocationForMeetingModal;
