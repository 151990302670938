import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const InformModal = ({
    show,
    handleClose,
    messageMainBody,
    messageHeader,
    messageBody,
    messageBottom,
    bottomCtaText,
    handleBottomCta,
    handleConfirmClick,
    confirmButtonText = 'Continue' }) => {
    return (
        <Modal show={show} onHide={handleClose} centered size="lg" className="c-grey-700 pb-lg-0">
            <Modal.Header closeButton className="p-3 p-md-4">
                <Modal.Title className="ps-md-2">
                    <h5 className="ff-i600">{messageHeader}</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-3 pt-md-4 pb-2 mt-3 text-center">
                <h4 className="c-grey-900">{messageMainBody}</h4>
            </Modal.Body>
            <Modal.Body className="p-0 pb-5 text-center">
                <p className="ff-i500 c-grey-450 px-2">{messageBody}</p>
                <div className="pt-2 pb-4 py-md-4 flex-fill">
                    <div className="row justify-content-center">
                        <div className="col-5">
                            <button
                                className="btn-primary ff-i600 w-100"
                                onClick={handleConfirmClick}>{confirmButtonText}</button>
                        </div>
                    </div>
                </div>
                <span className="ff-i500 c-grey-450">
                    {messageBottom}&nbsp;
                    <a href="#/" onClick={handleBottomCta} className="text-decoration-none">{bottomCtaText}</a>
                </span>
            </Modal.Body>
        </Modal>
    );
}

InformModal.propTypes = {
    show: PropTypes.bool.isRequired,
    messageHeader: PropTypes.string,
    messageMainBody: PropTypes.string,
    messageBody: PropTypes.string,
    confirmButtonText: PropTypes.string,
    handleClose: PropTypes.func.isRequired
}

export default InformModal;