import axios from 'axios';
import { traceError } from './common/response-handlers';

const ORIGIN_TOKEN_KEY = 'origin-token';

function NotificationsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; }
    const config = headers => ({ ...headers, signal: controller && controller.signal ? controller.signal : null });

    const get = (dealId, scope) => axios
        .get(`/notifications`, {
            params: {
                ...(dealId ? { dealId } : {}),
                ...(scope ? { scope } : {})
            }
        }, config())
        .catch(error => traceError(error, `/notifications`, 'GET'));

    const getSettings = (type = null) => axios
        .get(`/notifications/settings`, { type }, config())
        .catch(error => traceError(error, '/notifications/settings', 'GET'));

    const getTree = () => axios
        .get('/notifications/tree', config())
        .catch(error => traceError(error, `/notifications/tree`, 'GET'))

    const getNew = () => axios
        .get(`/notifications/new`, config())
        .catch(error => traceError(error, `/notifications/new`, 'GET'));

    const postSettings = ({ typeIds, frequency }) => axios
        .post(`/notifications/settings`, { typeIds, frequency }, config())
        .catch(error => traceError(error, `/notifications/settings`, 'POST'));

    const patchProcess = ({ type, isSeller, dealId = null }) => {
        const isSimulation = window.localStorage.getItem(ORIGIN_TOKEN_KEY);
        if (isSimulation) return Promise.resolve();
        else return axios
            .patch(`/notifications/process`, { type, isSeller, dealId: dealId ? +dealId : null }, config())
            .catch(error => traceError(error, `/notifications/process`, 'PATCH'));
    }

    const deleteNewMessage = (channelUrl) => axios
        .delete(`/notifications/${channelUrl}/new-message`, null, config())
        .catch(error => traceError(error, `/notifications/${channelUrl}/new-message`, 'DELETE'));

    const deleteSettings = (typeIds) => axios
        .delete(`/notifications/settings`, { data: { typeIds: typeIds } }, config())
        .catch(error => traceError(error, `/notifications/settings`, 'DELETE'));

    const postReachedDealLimitSms = () => axios
        .post(`/notifications/reached-deal-limit`, null,  config())
        .catch(error => traceError(error, `/notifications/reached-deal-limit`, 'POST'));

    return {
        init,
        dispose,
        get,
        getTree,
        getNew,
        getSettings,
        postSettings,
        patchProcess,
        deleteNewMessage,
        deleteSettings,
        postReachedDealLimitSms
    }
}

export default NotificationsService;