import PropTypes from 'prop-types';
import 'styles/pages/_meetings.scss'

const dateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };

const UpcomingMeetings = ({ upcomingMeetings = [] }) => {
    return (
        <>
            {upcomingMeetings.length > 0 &&
                <div className="text-s ff-i500 c-grey-550 mb-3 day-of-the-year">{new Intl.DateTimeFormat('en-US', dateTimeFormatOptions).format(new Date(upcomingMeetings[0].startTime))}</div>
            }
            {upcomingMeetings.map(meeting =>
                <div className="d-flex mb-2" key={meeting.id}>
                    <div className="col-2">
                        <div className="c-grey-700 ff-i600">{new Date(meeting.startTime).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })}</div>
                        <div className="c-grey-550 ff-i500 text-s">{meeting.durationInMinutes} min</div>
                    </div>
                    <div className="col-10 c-blue-1000 ff-i600 bc-blue-100 border-radius overflow-hidden position-relative meeting-title">{meeting.title}</div>
                </div>
            )}
        </>
    );
}

UpcomingMeetings.propTypes = {
    upcomingMeetings: PropTypes.array
}

export default UpcomingMeetings;