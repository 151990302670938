import axios from 'axios';
import { traceError } from './common/response-handlers';

function AdminBroadcastService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; }
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getMessages = () => axios
        .get(`/admin/broadcast`, config())
        .catch(error => traceError(error, `/admin/broadcast`, 'GET'));

    const postMessageToAllUsers = (message, isShadowMode) => axios
        .post('/admin/broadcast/all-users', { message, isShadowMode }, config())
        .catch(error => traceError(error, `/admin/broadcast/all-users`, 'POST'));

    const postMessageByUserTypes = (message, isShadowMode, type) => axios
        .post('/admin/broadcast/persona/user-types', { message, isShadowMode, type }, config())
        .catch(error => traceError(error, `/admin/broadcast/persona/user-types`, 'POST'));

    const postMessageBySellerTypes = (message, isShadowMode, type) => axios
        .post('/admin/broadcast/persona/seller-types', { message, isShadowMode, type }, config())
        .catch(error => traceError(error, `/admin/broadcast/persona/seller-types`, 'POST'));

    const postMessageByBuyerTypes = (message, isShadowMode, type) => axios
        .post('/admin/broadcast/persona/buyer-types', { message, isShadowMode, type }, config())
        .catch(error => traceError(error, `/admin/broadcast/persona/buyer-types`, 'POST'));

    const postMessageByCriteriaTypes = (message, isShadowMode, isActive) => axios
        .post('/admin/broadcast/persona/criteria-types', { message, isShadowMode, isActive }, config())
        .catch(error => traceError(error, `/admin/broadcast/persona/criteria-types`, 'POST'));

    const postMessageByDealTypes = (message, isShadowMode, isActive) => axios
        .post('/admin/broadcast/persona/deal-types', { message, isShadowMode, isActive }, config())
        .catch(error => traceError(error, `/admin/broadcast/persona/deal-types`, 'POST'));

    const postMessageByReachedDealsLimit = (message, isShadowMode) => axios
        .post('/admin/broadcast/persona/reached-deals-limit', { message, isShadowMode }, config())
        .catch(error => traceError(error, `/admin/broadcast/persona/reached-deals-limit`, 'POST'));

    const postMessageToNoCompletedProfile = (message, isShadowMode) => axios
        .post('/admin/broadcast/persona/no-completed-profile', { message, isShadowMode }, config())
        .catch(error => traceError(error, `/admin/broadcast/persona/no-completed-profile`, 'POST'));

    const postMessageByEmails = (message, isShadowMode, userIds) => axios
        .post('/admin/broadcast/emails', { message, isShadowMode, userIds }, config())
        .catch(error => traceError(error, `/admin/broadcast/emails`, 'POST'));

    return {
        init,
        dispose,
        getMessages,
        postMessageToAllUsers,
        postMessageByUserTypes,
        postMessageBySellerTypes,
        postMessageByBuyerTypes,
        postMessageByCriteriaTypes,
        postMessageByDealTypes,
        postMessageByReachedDealsLimit,
        postMessageToNoCompletedProfile,
        postMessageByEmails
    };
}

export default AdminBroadcastService;