import { useContext } from 'react';
import AppContext from 'contexts/AppContext';

const Loader = () => {
    const context = useContext(AppContext);

    return (
        <>
            {context?.loading &&
                <div className="loader">
                    <img alt="loader" className="loader-image" src={`${process.env.PUBLIC_URL}/images/loader-double-ring.gif`} />
                </div>
            }
        </>
    );
}

export default Loader;