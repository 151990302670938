import { useEffect } from 'react';
import { trackPageview } from 'services/common/analytic-trackers';
import AuthHandler from 'services/common/auth-handler';

const authHandler = AuthHandler();

const ContactUsPage = () => {
    const userEmail = authHandler.getUserEmail();
    useEffect(() => {
        document.title = '1Konnection - Contact Us';
        trackPageview();
    }, []);

    return (
        <div className="mt-2 mt-md-4">
            <div className="container mt-3">
                <div className="bc-white px-1">
                    <iframe
                        src={`https://1konnection.typeform.com/to/qz3rivWL#email=${userEmail || 'anonymous user'}`}
                        title="contact-us-typeform"
                        width="100%"
                        style={{ minHeight: "600px" }}>
                    </iframe>
                </div>
            </div>
        </div>
    );
}

export default ContactUsPage;