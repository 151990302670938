import { Route, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import AuthHandler from 'services/common/auth-handler';

const authHandler = AuthHandler();

const AuthRoute = ({ component: Component, ...rest }) => {
    const location = useLocation();

    return (
        <Route {...rest} render={(props) => (
            authHandler.isAuthenticated()
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/secure',
                        state: { redirectUrl: location.pathname }
                    }}
                />
        )} />
    )
};

export default AuthRoute;