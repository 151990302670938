import { useEffect } from 'react';
import TasksService from 'services/tasks-service';
import useLoader from 'hooks/useLoader';
import useToast from 'hooks/useToast';

const tasksService = TasksService();

const useChangeTaskStatus = ({ handleFetchTasks }) => {
    const { load } = useLoader();
    const { toast } = useToast();

    const handleChangeStatusForTask = id => {
        return load(() =>
            tasksService
                .changeStatus(id)
                .then(handleFetchTasks)
                .then(() => {
                toast.success({ body: "Information was successfully updated." });
            })
        );
    };

    useEffect(() => {
        tasksService.init();

        return () => {
            tasksService.dispose();
        };
    }, []);

    return {
        handleChangeStatusForTask,
    };
};

export default useChangeTaskStatus;
