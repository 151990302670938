function PlatformFlows({ scrollToRegister, info }) {
    return (
        <div id="howItWorks" name="howItWorks" className="bc-white py-4">
            <h1 className="c-grey-900 text-brand-m text-center scroll-observe reveal-bottom">
                How It Works
            </h1>
            <div className="container mt-4 my-lg-5 c-grey-700">
                <div className="px-md-5">
                    <div className="row gx-4 align-items-center scroll-observe">
                        <div className="col-12 col-md-6 reveal-left">
                            <div className="row gx-2 gx-md-3">
                                <div className="col-4 d-flex">
                                    <img
                                        loading="lazy"
                                        className="w-100 p-2 p-md-3 align-self-center"
                                        src={`${process.env.PUBLIC_URL}/images/landing/social-1.svg`}
                                        alt="social"
                                        onClick={scrollToRegister} />
                                </div>
                                <div className="col-8 align-self-center">
                                    <div className="pe-md-5">
                                        <h2 className="mb-3">Create your account</h2>
                                        <p className="text-l ff-i500">{info.text1}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 reveal-right">
                            <div className="p-3 pt-1 border-radius border-grey-200">
                                <img
                                    loading="lazy"
                                    className="d-block mx-auto pb-1"
                                    src={`${process.env.PUBLIC_URL}/images/landing/top-speaker-cam.svg`}
                                    alt="top-speaker-cam"
                                    onClick={scrollToRegister} />
                                <img
                                    loading="lazy"
                                    className="d-none d-md-inline w-100 border-radius"
                                    src={`${process.env.PUBLIC_URL}/images/landing/registration-desktop.gif`}
                                    alt="registration-desktop"
                                    onClick={scrollToRegister} />
                                <img
                                    loading="lazy"
                                    className="d-inline d-md-none w-100"
                                    src={`${process.env.PUBLIC_URL}/images/landing/registration-mobile.gif`}
                                    alt="registration-mobile"
                                    onClick={scrollToRegister} />
                            </div>
                        </div>
                    </div>
                    <div className="row gx-4 align-items-center mt-5 pt-md-4 scroll-observe">
                        <div className="col-12 col-md-6 order-2 order-md-1 reveal-left">
                            <div className="p-3 pt-1 border-radius border-grey-200">
                                <img
                                    loading="lazy"
                                    className="d-block mx-auto pb-1"
                                    src={`${process.env.PUBLIC_URL}/images/landing/top-speaker-cam.svg`}
                                    alt="top-speaker-cam"
                                    onClick={scrollToRegister} />
                                <img
                                    loading="lazy"
                                    className="d-none d-md-inline w-100 border-radius"
                                    src={`${process.env.PUBLIC_URL}/images/landing/add-deal-desktop.gif`}
                                    alt="deal-desktop"
                                    onClick={scrollToRegister} />
                                <img
                                    loading="lazy"
                                    className="d-inline d-md-none w-100 border-radius"
                                    src={`${process.env.PUBLIC_URL}/images/landing/add-deal-mobile.gif`}
                                    alt="deal-mobile"
                                    onClick={scrollToRegister} />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 order-1 order-md-2 reveal-right">
                            <div className="row">
                                <div className="col-4 d-flex">
                                    <img
                                        loading="lazy"
                                        className="w-100 p-2 p-md-3 align-self-center"
                                        src={`${process.env.PUBLIC_URL}/images/landing/social-2.svg`}
                                        alt="social-2"
                                        onClick={scrollToRegister} />
                                </div>
                                <div className="col-8 align-self-center">
                                    <div className="pe-md-5">
                                        <h2 className="mb-3">{info.headerText2}</h2>
                                        <p className="text-l ff-i500">Give us the details, so we can find you the perfect match.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-4 align-items-center mt-5 pt-md-4 scroll-observe">
                        <div className="col-12 col-md-6 reveal-left">
                            <div className="row">
                                <div className="col-4 d-flex">
                                    <img
                                        loading="lazy"
                                        className="w-100 p-2 p-md-3 align-self-center"
                                        src={`${process.env.PUBLIC_URL}/images/landing/social-3.svg`}
                                        alt="social-3"
                                        onClick={scrollToRegister} />
                                </div>
                                <div className="col-8 align-self-center">
                                    <div className="pe-md-5">
                                        <h2 className="mb-3">Get Konnected!</h2>
                                        <p className="text-l ff-i500">Get introduced to your matches! Keep the deal going with our management tools, so you can close faster.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 reveal-right">
                            <div className="p-3 pt-1 border-radius border-grey-200">
                                <img
                                    loading="lazy"
                                    className="d-block mx-auto pb-1"
                                    src={`${process.env.PUBLIC_URL}/images/landing/top-speaker-cam.svg`}
                                    alt="top-speaker-cam"
                                    onClick={scrollToRegister} />
                                <img
                                    loading="lazy"
                                    className="d-none d-md-inline w-100 border-radius"
                                    src={`${process.env.PUBLIC_URL}/images/landing/get-konnected-desktop.gif`}
                                    alt="get-konnected-desktop"
                                    onClick={scrollToRegister} />
                                <img
                                    loading="lazy"
                                    className="d-inline d-md-none w-100 border-radius"
                                    src={`${process.env.PUBLIC_URL}/images/landing/get-konnected-mobile.gif`}
                                    alt="get-konnected-mobile"
                                    onClick={scrollToRegister} />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex w-100 justify-content-center mt-5">
                        <button
                            onClick={() => window.open('https://meetings.hubspot.com/sales-1konnection/demo-link','_blank')}
                            type="button"
                            style={{ background: 'linear-gradient(180deg, #83BFFF 0%, #377DFF 100%)' }}
                            className="col-lg-4 col-8 btn-primary green text-m text-center py-3 ff-m700">
                            Schedule a Demo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlatformFlows;