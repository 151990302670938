import Select from 'components/atoms/select/Select';
import dayjs from 'dayjs';

const FormTask = ({ formik, dealOptions, leadOptions }) => {

    const onDealChange = (value) => {
        formik.setFieldValue('dealId', value?.id);
        formik.setTouched({ ...formik.touched, dealId: true });
        if (!value || !formik.values?.leadId) return;

        const lead = leadOptions?.find(lead => lead.id === formik.values?.leadId);
        if (!lead?.dealIds.includes(value.id))
            formik.setFieldValue('leadId', null);
    }

    const onLeadChange = (value) => {
        formik.setFieldValue('leadId', value?.id);
        formik.setTouched({ ...formik.touched, leadId: true });
        if (!value || formik.values?.dealId) return;
        const deals = dealOptions?.filter(deal => leadOptions?.find(lead => lead.id === formik.values?.leadId)?.dealIds?.includes(deal.id));
        if (deals?.length === 1)
            formik.setFieldValue('dealId', deals[0].id);
        else
            formik.setFieldValue('dealId', null);
    }

    const getDealOptions = () => {
        const deals = dealOptions?.filter(deal => leadOptions?.find(lead => lead.id === formik.values?.leadId)?.dealIds?.includes(deal.id));
        if (deals?.length > 0) return deals;
        return dealOptions;
    }

    const getLeadOptions = () => {
        if (formik.values?.dealId)
            return leadOptions?.filter(lead => lead?.dealIds.includes(formik.values?.dealId))
        return leadOptions;
    }

    return (
        <>
            <div className="pb-3 position-relative">
                <textarea
                    name="description"
                    className="w-100 p-3"
                    onChange={(e) => {
                        formik.setFieldValue('description', e.target.value);
                        formik.setTouched({...formik.touched, description: true})
                    }}
                    value={formik.values.description}
                    onBlur={formik.handleBlur}
                    placeholder="Title. What should we remind you to do?"
                />
                {formik.touched['description'] && !!formik.errors['description'] && (
                    <span className="position-absolute bottom-0 start-0 ps-2 text-s c-red-600">
                        {formik.errors['description']}
                    </span>
                )}
            </div>
            <div className="pb-4 position-relative">
                <span className="d-block c-grey-700 text-regular pb-2">Due date</span>
                <input
                    type="date"
                    className="w-100 p-3"
                    min={dayjs().format('YYYY-MM-DD')}
                    name={'dueToDate'}
                    onChange={(e) => {
                        formik.setFieldValue('dueToDate', e.target.value);
                        formik.setTouched({...formik.touched, dueToDate: true})
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.dueToDate}
                />
                <span
                    style={{ transform: "translateY(4px) rotate(90deg)" }}
                    className="icon icon-arrow-right-grey icon-static pe-none" />
                {!!formik.errors['dueToDate'] && (
                    <span className="position-absolute bottom-0 start-0 ps-2 text-s c-red-600">
                        {formik.errors['dueToDate']}
                    </span>
                )}
            </div>
            <div>
                <span className="d-block c-grey-700 text-regular pb-2">Associated deal</span>
                <Select
                    placeholder='Select deal(s)'
                    isClearable
                    options={getDealOptions()}
                    value={dealOptions?.find(deal => deal.id === formik.values?.dealId) || null}
                    onChange={value => onDealChange(value)}
                    keys={{ valueKey: 'id', labelKey: 'label' }}
                />
            </div>
            <div className="pb-5">
                <span className="d-block c-grey-700 text-regular pb-2">Associated lead</span>
                <Select
                    placeholder='Select lead(s)'
                    isClearable
                    options={getLeadOptions()}
                    value={leadOptions?.find(lead => lead.id === formik.values?.leadId) || null}
                    onChange={value => onLeadChange(value)}
                    keys={{ valueKey: 'id', labelKey: 'label' }}
                />
            </div>
        </>
    );
};

export default FormTask;
