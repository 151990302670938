import { useEffect, useState, lazy, Suspense } from 'react';
import { Route, useLocation, Switch } from 'react-router-dom';

import AppContext from 'contexts/AppContext';
import { ProfileContext, useProfileProvider } from 'contexts/ProfileContext'

import Header from 'core/Header';
import Footer from 'core/Footer';
import Loader from 'core/Loader';

import BuyerDashboardLayoutPage from 'pages/Criteria/BuyerDashboardLayoutPage';

import SellerDashboardLayoutPage from 'pages/Listing/SellerDashboardLayoutPage';

import SettingsPage from 'pages/Setting/SettingsPage';

import SurveyPage from 'pages/SurveyPage';
import CalendarConnector from 'components/organisms/CalendarConnector';

import Login from 'pages/Secure/Login';
import Register from 'pages/Secure/Register';
import VerifyEmailPage from 'pages/Secure/VerifyEmailPage';
import SetNewPassword from 'pages/Secure/SetNewPassword';
import ResetPassword from 'pages/Secure/ResetPassword';
import ForgotPassword from 'pages/Secure/ForgotPassword';
import SetNewPasswordVerifyEmail from 'pages/Secure/SetNewPasswordVerifyEmail';
import EmailHandlerPage from 'pages/Secure/EmailHandlerPage';
import RegisterHandlerPage from 'pages/Secure/RegisterHandlerPage';
import HandlerPage from 'pages/Secure/HandlerPage';

import LandingPage from 'pages/Landing/LandingPage';

import AuthHandler from 'services/common/auth-handler';
import ContactUsPage from 'pages/ContactUsPage';
import AuthRoute from 'guards/AuthRoute';
import NotificationsPage from 'pages/Notifications/NotificationsPage';
import useAddToast from './hooks/useAddToast';
import ToastContext from './contexts/ToastContext';
import UserNameContext from './contexts/UserNameContext';
import NewMatchesContext from 'contexts/NewMatchesContext';
import { NotificationsProvider } from 'contexts/NotificationsContext';
import Toasts from './components/molecules/Toasts';
import AdminLayoutPage from 'pages/Admin/AdminLayoutPage';
import ChatSystemUser from 'pages/Chat/ChatSystemUser';

const PrivacyPage = lazy(() => import('pages/PrivacyPage'));
const TermsPage = lazy(() => import('pages/TermsPage'));
const HelpCenter = lazy(() => import('pages/HelpCenter/HelpCenter'));
const HelpCenterPage = lazy(() => import('pages/HelpCenter/HelpCenterPage'));

const PlatformFeeAgreementPage = lazy(() => import('pages/PlatformFeeAgreementPage'));
const BrokersLandingPage = lazy(() => import('pages/Landing/BrokersLandingPage'));
const BuyersLandingPage = lazy(() => import('pages/Landing/BuyersLandingPage'));
const ContactSalesLandingPage = lazy(() => import('pages/Landing/ContactSalesLandingPage'));
const SellersLandingPage = lazy(() => import('pages/Landing/SellersLandingPage'));
const Styleguide = lazy(() => import('pages/Styleguide/Styleguide'));

const MaintenancePage = lazy(() => import('pages/Error/MaintenancePage'));
const ForbiddenPage = lazy(() => import('pages/Error/ForbiddenPage'));
const NotFoundPage = lazy(() => import('pages/Error/NotFoundPage'));
const ServerErrorResponsesPage = lazy(() => import('pages/Error/ServerErrorResponsesPage'));

function App() {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const appSettings = { loading, setLoading };
    const {
        isFulfilled,
        setFulfilled,
        isAssetFulfilled,
        setAssetFulfilled,
        isNoCommunities,
        setNoCommunities,
        isFundsFulfilled,
        setFundsFulfilled
    } = useProfileProvider();

    const authHandler = AuthHandler();
    const userId = authHandler.getUserId() ?? null;
    const userEmail = authHandler.getUserEmail();
    const user = authHandler.getUser();
    const userType = user?.identitytype;

    useEffect(() => {
        if (window.hj && !!userId && !!userEmail)
            window.hj('identify', +userId, {
                email: userEmail,
                userType: userType
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, userEmail, userType]);

    const { addToast, toasts } = useAddToast();
    const [updatedUserName, setUpdatedUserName] = useState(null);
    const [newMatchesAmount, setNewMatchesAmount] = useState(0);

    return (
        <ToastContext.Provider value={{ toasts, addToast }}>
            <NotificationsProvider>
                <ProfileContext.Provider value={{
                    isFulfilled, setFulfilled,
                    isAssetFulfilled, setAssetFulfilled,
                    isNoCommunities, setNoCommunities,
                    isFundsFulfilled, setFundsFulfilled
                }}>
                    <AppContext.Provider value={appSettings}>
                        <UserNameContext.Provider value={{ updatedUserName, setUpdatedUserName }}>
                            <NewMatchesContext.Provider value={{ newMatchesAmount, setNewMatchesAmount }}>
                                <Loader />
                                <div className="header">
                                    <Header />
                                </div>
                                <Suspense fallback={
                                    <div className="loader">
                                        <img alt="loader" className="loader-image" src={`${process.env.PUBLIC_URL}/images/loader-double-ring.gif`} />
                                    </div>
                                }>
                                    <div className="main">
                                        <Switch>
                                            <Route path="/brokers" component={BrokersLandingPage} />
                                            <Route path="/sellers" component={SellersLandingPage} />
                                            <Route path="/buyers" component={BuyersLandingPage} />
                                            <Route path="/contact-sales" component={ContactSalesLandingPage} />
                                            <Route path="/privacy" component={PrivacyPage} />
                                            <Route path='/bpfa' component={PlatformFeeAgreementPage} />
                                            <Route path="/terms" component={TermsPage} />
                                            <Route path="/help-center" exact component={HelpCenterPage} />
                                            <Route path="/help-center" component={HelpCenter} />

                                            <AuthRoute path="/buyer-dashboard" component={BuyerDashboardLayoutPage} />

                                            <AuthRoute path="/seller-dashboard" component={SellerDashboardLayoutPage} />

                                            <AuthRoute path='/settings' component={SettingsPage} />
                                            <Route path='/connector' component={CalendarConnector} />
                                            <Route path='/survey' component={SurveyPage} />
                                            <Route path='/contact-us' component={ContactUsPage} />
                                            <AuthRoute path='/notifications' component={NotificationsPage} />

                                            <Route path="/secure" exact component={Login} />
                                            <Route path="/secure/register" component={Register} />
                                            <Route path='/secure/verify-email' component={VerifyEmailPage} />
                                            <Route path="/secure/setup-password"
                                                render={() => <ResetPassword procedureName="Set your password" />} />
                                            <Route path="/secure/set-new-password" component={SetNewPassword} />
                                            <Route path="/secure/forgot-password" component={ForgotPassword} />
                                            <Route path="/secure/reset-password"
                                                render={() => <ResetPassword procedureName="Reset your password" />} />
                                            <Route path="/secure/set-password" component={SetNewPasswordVerifyEmail} />
                                            <Route path="/secure/email-handler" component={EmailHandlerPage} />
                                            <Route path="/secure/register-handler" component={RegisterHandlerPage} />
                                            <Route path="/secure/handler" component={HandlerPage} />

                                            <AuthRoute path="/admin" component={AdminLayoutPage} />
                                            <AuthRoute path="/1k-chats" component={ChatSystemUser} />

                                            <Route path="/styleguide" component={Styleguide} />

                                            <Route path="/" exact component={LandingPage} />

                                            <Route path="/maintenance" exact component={MaintenancePage} />
                                            <Route path="/forbidden" exact component={ForbiddenPage} />
                                            <Route path="/server-error" exact component={ServerErrorResponsesPage} />
                                            <Route path="*" exact component={NotFoundPage} />
                                        </Switch>
                                    </div>
                                </Suspense>
                                {
                                    (location.pathname === '/'
                                        || location.pathname === '/brokers'
                                        || location.pathname === '/sellers'
                                        || location.pathname === '/buyers')
                                    && <Footer />
                                }
                                <Toasts toasts={toasts} />
                            </NewMatchesContext.Provider>
                        </UserNameContext.Provider>
                    </AppContext.Provider>
                </ProfileContext.Provider>
            </NotificationsProvider>
        </ToastContext.Provider>
    );
}

export default App;