import { useEffect, useState } from 'react';

import useLoader from 'hooks/useLoader';
import useToast from 'hooks/useToast';

import AdminService from 'services/admin-service';
import UsersService from 'services/users-service';
import StatesService from 'services/states-service';
import OrganizationsService from 'services/organizations-service';
import CalendarsService from 'services/calendars-service';

import EmptyState from 'components/atoms/empty-state/EmptyState';
import VerificationUserDetails from './VerificationUserDetails';

const adminService = AdminService();
const usersService = UsersService();
const statesService = StatesService();
const organizationsService = OrganizationsService();
const calendarsService = CalendarsService();

const tabs = [
    { id: 1, label: 'Pending', value: 9 },
    { id: 2, label: 'Pre-vet', value: 2 }
];

const VerificationUsers = () => {
    const { load } = useLoader();
    const { toast } = useToast();

    const [userTypes, setUserTypes] = useState([]);
    const [assetTypes, setAssetTypes] = useState([]);
    const [states, setStates] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [verificationUsers, setVerificationUsers] = useState([]);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [selectedUserId, setSelectedUserId] = useState(0);
    
    useEffect(() => {
        adminService.init();
        usersService.init();
        statesService.init();
        organizationsService.init();
        calendarsService.init();

        load(() => Promise
            .all([
                usersService.getTypes(),
                organizationsService.getAssetTypes(),
                statesService.getAll(),
                calendarsService.getTimezones(),
                adminService.getUsersWaitingVerification(selectedTab.value),
            ])
            .then(([userTypesResponse, assetTypesResponse, statesResponse, timeZoneResponse, usersResponse]) => {
                setUserTypes(userTypesResponse?.userTypes || []);
                let assets = (assetTypesResponse?.assetTypes || [])
                    .map((element, index) => ({ element, index }))
                    .sort((second, first) => !(first.index % 2) - !(second.index % 2))
                    .map(value => value.element);
                setAssetTypes(assets);
                setStates(statesResponse?.states || []);
                setTimeZones(timeZoneResponse?.data || []);
                const users = usersResponse?.data?.users || [];
                setVerificationUsers(users);
                if (users && users.length > 0) setSelectedUserId(users[0].user.id);
            })
            .catch(error => toast.handleError(error)));

        return () => {
            adminService.dispose();
            usersService.dispose();
            statesService.dispose();
            organizationsService.dispose();
            calendarsService.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTabs = option => {
        setSelectedTab(option);
        load(() => adminService
            .getUsersWaitingVerification(option.value)
            .then(response => {
                const users = response?.data?.users || [];
                setVerificationUsers(users);
                if (users && users.length > 0) setSelectedUserId(users[0].user.id);
            }));
    };

    const handleButtons = (userId, verified, postpone) =>
        load(() => adminService
            .postUserVerification(userId, verified, postpone)
            .then(() => {
                adminService
                    .getUsersWaitingVerification(selectedTab.value)
                    .then(response => {
                        const users = response?.data?.users || [];
                        setVerificationUsers(users);
                        if (users && users.length > 0) setSelectedUserId(users[0].user.id);
                    });
                    toast.success({ body: 'User verification completed.' });
                })
                .catch(error => toast.handleError(error)));
    
    const dateDiffInDays = date => {
        const MS_PER_DAY = 1000 * 60 * 60 * 24;
        const match = new Date(date);
        const now = new Date();
        const diffTime = Math.abs(now - match);
        return Math.ceil(diffTime / MS_PER_DAY);
    }

    return (
        <div className="p-3">
            <div className="row gx-3">
                <div className="col-12">
                    <div className="px-3 px-lg-5 py-4">
                        <h4 className="c-grey-700">Users Verification</h4>
                        <div className="col-12">
                            <div className="d-flex justify-content-center justify-content-lg-start flex-wrap border-bottom-grey-200">
                                {tabs.map(option => (
                                    <div
                                        key={option.id}
                                        className={`btn-tab d-flex align-items-center${
                                            selectedTab.id === option.id ? ' active' : ''
                                        }`}
                                        onClick={() => handleTabs(option)}>
                                        <span className="ff-i600">{option.label}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {!!verificationUsers.length && (
                            <>
                                {verificationUsers.map(vu => (
                                    <div key={vu.user.id}>
                                        <div
                                            className={`container-fluid mt-3 border-grey cursor-pointer${
                                                selectedUserId !== vu.user.id ? ' border-radius' : ' border-radius-top'
                                            }`}
                                            onClick={() =>
                                                setSelectedUserId(selectedUserId !== vu.user.id ? vu.user.id : null)
                                            }>
                                            <div className="d-flex justify-content-between align-items-center py-3">
                                                <span className="d-flex align-items-center">
                                                    <span
                                                        className={`icon icon-arrow-right-grey mx-3${
                                                            selectedUserId === vu.user.id ? ' img-rotate-90' : ''
                                                        }`}
                                                    ></span>
                                                    <span className="text-m ff-i600 c-grey-700 me-3">#{vu.user.id}</span>
                                                    <span className="text-m ff-i600 c-grey-700 me-3">{vu.user.fullName}</span>
                                                </span>
                                                <span className="">
                                                    <span className="text-m ff-i500 c-grey-550 me-3">Waiting {dateDiffInDays(vu.date)} days</span>
                                                    <span className="text-m ff-i500 c-grey-550 me-3">Attempt: {vu.attempt}</span>
                                                </span>
                                            </div>
                                        </div>
                                        {selectedUserId === vu.user.id && (
                                            <VerificationUserDetails
                                                userCore={vu.user}
                                                userTypes={userTypes}
                                                assetTypes={assetTypes}
                                                states={states}
                                                timeZones={timeZones}
                                                handleButtons={handleButtons} />
                                        )}
                                    </div>
                                ))}
                            </>
                        )}

                        {!verificationUsers.length && (
                            <div className="mt-5">
                                <EmptyState
                                    icon={'icon-tasks-empty-state'}
                                    label={'There no users waiting 1Konnection verification.'} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificationUsers;
