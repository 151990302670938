import Testimonials from 'pages/Landing/components/Testimonials';

const OneKonnectionPromo = () => {
    return (
        <div>
            <div className="d-flex flex-lg-row flex-column justify-content-between px-5 pb-3">
                <div className="d-flex justify-content-md-start justify-content-center mt-5 mt-lg-4 flex-wrap c-grey-900">
                    <div className="d-flex justify-content-center text-lg-start text-center flex-column mx-auto pe-4 pt-2">
                        <h1 className="lh-normal">$2 Billion+</h1>
                        <span className="text-l lh-normal">Deal Volume</span>
                    </div>
                    <div className="d-flex justify-content-center text-lg-start text-center flex-column mx-auto px-4 pt-2">
                        <h1 className="lh-normal">600+</h1>
                        <span className="text-l lh-normal">Brokers</span>
                    </div>
                    <div className="d-flex justify-content-center text-lg-start text-center flex-column mx-auto ps-lg-5 pt-2">
                        <h1 className="lh-normal">12,000+</h1>
                        <span className="text-l lh-normal">Owners/Operators</span>
                    </div>
                </div>

                <div className="d-block d-md-none">
                    <Testimonials />
                </div>

                <div className="d-flex pt-4 flex-wrap">
                    <div className="d-flex px-3 px-lg-5 mx-auto">
                        <img alt="plains" src={`${process.env.PUBLIC_URL}/images/partners/plains-grey.svg`} />
                    </div>
                    <div className="d-flex px-3 px-lg-5 mx-auto">
                        <img alt="senior-living-investment-brokerage" src={`${process.env.PUBLIC_URL}/images/partners/senior-living-investment-brokerage-grey.svg`} />
                    </div>
                    <div className="d-flex px-3 px-lg-5 mx-auto">
                        <img alt="walker-and-dunlop" src={`${process.env.PUBLIC_URL}/images/partners/walker-and-dunlop-grey.svg`} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OneKonnectionPromo;