import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

const PasswordInput = ({
    passwordShown,
    setPasswordShown,
    password,
    setPassword,
    passwordRef,
    isValid,
    autocomplete = 'on',
    placeholder = 'Password',
    forceValidation = false,
    showIcon = true }) => {
    const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState(false);

    const isValidPassword = (value) => !(value.length === 0 || value.length < 8 || value.length > 64);

    useEffect(() => {
        if (!password && forceValidation) {
            passwordRef.current.classList.toggle('invalid', true);
            setShowPasswordErrorMessage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceValidation])

    const onPasswordChange = (value) => {
        if (isValidPassword(value)) {
            passwordRef.current.classList.toggle('invalid', false);
            passwordRef.current.classList.toggle('valid', true);
            setShowPasswordErrorMessage(false);
            isValid(true);
        }
        else {
            passwordRef.current.classList.toggle('valid', false);
            passwordRef.current.classList.toggle('invalid', true);
            setShowPasswordErrorMessage(true);
            isValid(false);
        }

        setPassword(value);
    }

    return (
        <>
            <span className="w-100 position-relative">
                <input
                    type={passwordShown ? "text" : "password"}
                    className={`w-100 ${showIcon ? 'icon-left' : ''} icon-right`}
                    style={showIcon ? { backgroundImage: `url(${process.env.PUBLIC_URL}/images/icons/lock.svg)` } : {}}
                    placeholder={placeholder}
                    minLength="8"
                    maxLength="64"
                    autoComplete={autocomplete}
                    value={password}
                    onChange={(e) => onPasswordChange(e.target.value)}
                    ref={passwordRef} />
                <span
                    className={`d-none d-md-block icon ${!passwordShown ? 'icon-eye-closed' : 'icon-eye-opened'} icon-medium icon-static icon-unselectable`}
                    onClick={() => setPasswordShown(!passwordShown)}>
                </span>
                <span
                    className="d-block d-md-none c-blue-500 text-xs text-start px-2 pt-2"
                    onClick={() => setPasswordShown(!passwordShown)}>
                    {!passwordShown ? 'Show password' : 'Hide password'}
                </span>
            </span>
            {
                showPasswordErrorMessage &&
                <div className="text-start c-red-500 px-2 text-s">
                    Your password must be at least 8 characters long and not more than 64
                </div>
            }
        </>
    );
}

PasswordInput.propTypes = {
    passwordShown: PropTypes.bool,
    password: PropTypes.string,
    setPassword: PropTypes.func,
    setPasswordShown: PropTypes.func
}

export default PasswordInput;