import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useQueryParams from 'hooks/useQueryParams';
import { IS_SHADOW_KEY, EMAIL_VERIFIED_KEY, SENT_EMAIL_VERIFICATION_KEY } from 'hooks/useLocalStorage';

import { trackPageview, trackLogin, trackVerificationEmail } from 'services/common/analytic-trackers';
import AuthHandler from 'services/common/auth-handler';
import UserEmailsService from 'services/user-emails-service';
import UsersService from 'services/users-service';

const authHandler = AuthHandler();
const userEmailsService = UserEmailsService();
const usersService = UsersService();

const EmailHandlerPage = () => {
    const history = useHistory();
    const query = useQueryParams();

    const redirectUrlParam = query.get('redirect-url');
    const accessTokenParam = query.get('access-token');
    const setPasswordParam = query.get('set-password');

    const parseRedirectUrl = () => {
        try {
            return decodeURIComponent(redirectUrlParam || '/');
        } catch (_) {
            return '/';
        }
    };

    const loginUser = () => {
        authHandler.authenticated(accessTokenParam);
        usersService
            .getIsShadow()
            .then(response => response.data.isShadow && localStorage.setItem(IS_SHADOW_KEY, true));
        trackLogin({ method: 'Email' });
    };

    useEffect(() => {
        document.title = '1Konnection - Email Handler';
        trackPageview();
        userEmailsService.init();
        usersService.init();
        let redirectUrl = parseRedirectUrl();
        if (accessTokenParam) {
            // * AUTHENTICATION *
            if (authHandler.isAuthenticated()) {
                const currentUserId = +authHandler.getUserId();
                const tokenUserId = +authHandler.parseToken(accessTokenParam).sid;
                if (currentUserId !== tokenUserId) {
                    history.push('/forbidden');
                    return;
                }
            } else loginUser();

            // * VERIFICATION *
            userEmailsService
                .getPrimary()
                .then(response => {
                    let verified = response?.data?.verified ?? false;
                    if (!verified) {
                        userEmailsService
                            .patchPrimary()
                            .then(_ => {
                                trackVerificationEmail();
                                localStorage.setItem(EMAIL_VERIFIED_KEY, true);
                                localStorage.removeItem(SENT_EMAIL_VERIFICATION_KEY);

                                // * REDIRECTION *
                                if (setPasswordParam === 'true') {
                                    history.push({
                                        pathname: '/secure/set-password',
                                        state: { redirectUrl: redirectUrl }
                                    });
                                    return;
                                }
                                history.push(redirectUrl);
                            })
                            .catch(_ => history.push(redirectUrl));
                    } else history.push(redirectUrl);
                })
                .catch(_ => history.push(redirectUrl));
        }
        else history.push(redirectUrl);

        return () => {
            userEmailsService.dispose();
            usersService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="loader">
            <img
                alt="loader"
                className="loader-image"
                src={`${process.env.PUBLIC_URL}/images/loader-double-ring.gif`} />
        </div>
    );
};

export default EmailHandlerPage;