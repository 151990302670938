// Changes to this object require Backend updates.
const SendBirdConsts = 
{
    ConnectionStateOpen: 'OPEN',
    MessageTypeAdmin: 'ADMM',
    MessageCustomTypeAdminApi: 'notice',
    MessageCustomTypeScheduleEvent: 'schedule-event',
    MessageCustomTypeCalendarEvent: 'sent-event',
    MessageCustomTypeCalendarEventCanceled: 'sent-event-cancel'
};
Object.freeze(SendBirdConsts);
export { SendBirdConsts };