import useCreatePagesArray from './hooks/useCreatePagesArray';

const Paginate = ({ totalItems, itemsPerPage = 10, onChangePage, currentPage }) => {
    const { pagesNumber } = useCreatePagesArray({ totalItems, itemsPerPage });

    const onPrev = () => {
        if (+currentPage === 1) return;
        onChangePage(+currentPage - 1);
        window.scrollTo(0, 0);
    };

    const onNext = () => {
        if (+currentPage === totalItems) return;
        onChangePage(+currentPage + 1);
        window.scrollTo(0, 0);
    };

    return (
        <div className="d-flex align-items-center justify-content-center">
            <button
                disabled={currentPage === 1}
                onClick={onPrev}
                className={`d-flex align-items-center btn-secondary pagination-button border-0 px-4 py-2 ff-i600 ms-3 ${
                    currentPage !== 1 && 'active c-blue-500'
                }`}
            >
                <span
                    className={`icon icon-arrow-right-blue img-rotate-180 ${currentPage === 1 && 'icon-grayscale'}`}
                />
                <span className="ms-2">Previous</span>
            </button>
            <div className="d-flex align-items-center gap-2">
                {pagesNumber?.map(page => {
                    if (
                        page === 1 ||
                        page === pagesNumber.length ||
                        page === currentPage ||
                        (currentPage - page <= 1 && page - currentPage <= 1)
                    )
                        return (
                            <button
                                key={page}
                                onClick={() => {
                                    onChangePage(page);
                                    window.scrollTo(0, 0);
                                }}
                                className={` ${
                                    currentPage === page ? 'btn-primary' : 'btn-borderless  c-grey-550'
                                } ff-i500 d-flex align-items-center justify-content-center `}
                                style={{ width: '30px', height: '30px' }}
                            >
                                {page}
                            </button>
                        );
                    if (currentPage - page === 2 || page - currentPage === 2) return <div>...</div>;
                    return <></>;
                })}
            </div>
            <button
                disabled={currentPage === pagesNumber.length}
                onClick={onNext}
                className={`d-flex align-items-center btn-secondary pagination-button border-0 px-4 py-2 ff-i600 ms-3 link ${
                    currentPage !== pagesNumber.length && 'active c-blue-500'
                }`}
            >
                <span className="me-2">Next</span>
                <span
                    className={`icon icon-arrow-right-blue ${currentPage === pagesNumber.length && 'icon-grayscale'}`}
                />
            </button>
            <p className="m-0 ms-5 c-grey-550">
                Shown {currentPage * itemsPerPage - itemsPerPage + 1}-
                {totalItems < itemsPerPage * currentPage ? totalItems : itemsPerPage * currentPage} of {totalItems}{' '}
                items
            </p>
        </div>
    );
};

export default Paginate;
