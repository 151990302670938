import { createContext, useContext, useState } from 'react';

export const ProfileContext = createContext();

export const useProfileProvider = () => {
    const [isFulfilled, setFulfilled] = useState(true);
    const [isAssetFulfilled, setAssetFulfilled] = useState(true);
    const [isFundsFulfilled, setFundsFulfilled] = useState(true);
    const [isNoCommunities, setNoCommunities] = useState(true);

    return {
        isFulfilled,
        setFulfilled,
        isAssetFulfilled,
        setAssetFulfilled,
        isNoCommunities,
        setNoCommunities,
        isFundsFulfilled,
        setFundsFulfilled
    };
};

export const useProfile = () => {
    const {
        isFulfilled,
        setFulfilled,
        isAssetFulfilled,
        setAssetFulfilled,
        isNoCommunities,
        setNoCommunities,
        isFundsFulfilled,
        setFundsFulfilled } = useContext(ProfileContext);
    return {
        isFulfilled,
        setFulfilled,
        isAssetFulfilled,
        setAssetFulfilled,
        isNoCommunities,
        setNoCommunities,
        isFundsFulfilled,
        setFundsFulfilled
    };
};