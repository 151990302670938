import axios from 'axios';
import { traceError } from './common/response-handlers';

function TasksService() {
    let controller = null;
    const init = () => (controller = new AbortController());
    const dispose = () => {
        if (controller) controller.abort();
        controller = null;
    };
    const config = () => (controller && controller.signal ? { signal: controller.signal } : null);

    const getTasks = () => axios.get(`/tasks`, config()).catch(error => traceError(error, `/tasks`, 'GET'));

    const createTask = data => axios.post(`/tasks`, data, config()).catch(error => traceError(error, `/tasks`, 'POST'));

    const updateTask = (id, data) =>
        axios.put(`/tasks/${id}`, data, config()).catch(error => traceError(error, `/tasks/${id}`, 'GET'));

    const deleteTask = id =>
        axios.delete(`/tasks/${id}`, config()).catch(error => traceError(error, `/tasks`, 'DELETE'));

    const changeStatus = id =>
        axios
            .patch(`/tasks/${id}/state`, config())
            .catch(error => traceError(error, `/tasks/${id}/state`, 'PATCH'));

    return {
        init,
        dispose,
        getTasks,
        createTask,
        updateTask,
        deleteTask,
        changeStatus,
    };
}

export default TasksService;
