import PropTypes from 'prop-types';

const EmptyState = ({ icon, label, button, description }) => {
    return (
        <div className="text-center">
            <span className={`icon ${icon}`} />
            <h4 className="c-grey-700 mb-3">{label}</h4>
            {description && <p className="c-grey-550">{description}</p>}
            {button?.label && (
                <div className="mt-4">
                    <button color="primary" className="btn-primary ff-i600 px-5 me-2" onClick={button.onClick}>
                        {button.label}
                    </button>
                </div>
            )}
        </div>
    );
};

EmptyState.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    button: PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }),
};

export default EmptyState;
