import { useState, useRef } from 'react';

const SharedLinks = ({
    isSeller,
    dealLinks,
    linkName,
    setLinkName,
    linkUrl,
    setLinkUrl,
    selectedLinks,
    setSelectedLinks,
    uploadLinkToDeal,
    setUploadLinkToDeal,
    shareLink,
    disableShareButton }) => {

    const linkNameRef = useRef();
    const [showLinkNameEmptyError, setShowLinkNameEmptyError] = useState(false);
    const [showLinkNameLengthError, setShowLinkNameLengthError] = useState(false);
    const [isLinkNameValid, setIsLinkNameValid] = useState(false);

    const linkUrlRef = useRef();
    const [showLinkUrlEmptyError, setShowLinkUrlEmptyError] = useState(false);
    const [showLinkUrlLengthError, setShowLinkUrlLengthError] = useState(false);
    const [isLinkUrlValid, setIsLinkUrlValid] = useState(false);


    const onChangeLinkName = event => {
        let inputValue = event.target.value;
        setLinkName(inputValue);

        setShowLinkNameEmptyError(false);
        setShowLinkNameLengthError(false);
        linkNameRef.current.classList.toggle('invalid', false);
        linkNameRef.current.classList.toggle('valid', false);

        if (!inputValue.length > 0) {
            linkNameRef.current.classList.toggle('invalid', true);
            setIsLinkNameValid(false);
            setShowLinkNameEmptyError(true);
            return;
        }
        if (inputValue.length > 100) {
            linkNameRef.current.classList.toggle('invalid', true);
            setIsLinkNameValid(false);
            setShowLinkNameLengthError(true);
            return;
        }

        linkNameRef.current.classList.toggle('valid', true);
        setIsLinkNameValid(true);
    }

    const onChangeLinkUrl = event => {
        let inputValue = event.target.value;
        setLinkUrl(inputValue);

        setShowLinkUrlEmptyError(false);
        setShowLinkUrlLengthError(false);
        linkUrlRef.current.classList.toggle('invalid', false);
        linkUrlRef.current.classList.toggle('valid', false);

        if (!inputValue.length > 0) {
            linkUrlRef.current.classList.toggle('invalid', true);
            setIsLinkUrlValid(false);
            setShowLinkUrlEmptyError(true);
            return;
        }
        if (inputValue.length > 250) {
            linkUrlRef.current.classList.toggle('invalid', true);
            setIsLinkUrlValid(false);
            setShowLinkUrlLengthError(true);
            return;
        }

        linkUrlRef.current.classList.toggle('valid', true);
        setIsLinkUrlValid(true);
    }

    const handleOnChangeLinks = (id, isChecked = false) =>
        setSelectedLinks(prev => isChecked ? prev.filter(cid => cid !== id) : [...prev, id]);
    const selectAll = (setSelectedItems, items) =>
        setSelectedItems(prev => prev.length > 0 ? [] : items.map(item => item.id));

    const isDisableShareButton = !isLinkNameValid || !isLinkUrlValid || disableShareButton;

    return (
        <>
            {isSeller &&
                <>
                    <div className="justify-content-between align-items-center mb-3">
                        <input
                            type="text"
                            ref={linkNameRef}
                            className="w-100"
                            placeholder="Name to display"
                            value={linkName}
                            maxLength={100}
                            onChange={(e) => onChangeLinkName(e)} />
                        {showLinkNameEmptyError && <div className="text-start c-red-500 text-s px-2">This field cannot be empty</div>}
                        {showLinkNameLengthError && <div className="text-start c-red-500 text-s px-2">Use less than 100 characters</div>}
                        <input
                            type="text"
                            ref={linkUrlRef}
                            className="w-100 mt-3"
                            placeholder="Insert a link"
                            value={linkUrl}
                            maxLength={250}
                            onChange={(e) => onChangeLinkUrl(e)} />
                        {showLinkUrlEmptyError && <div className="text-start c-red-500 text-s px-2">This field cannot be empty</div>}
                        {showLinkUrlLengthError && <div className="text-start c-red-500 text-s px-2">Use less than 250 characters</div>}
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between pt-2 pb-3 mb-2">
                        {isSeller &&
                            <div className="d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    className="col-1 me-2"
                                    id="add-to-deal"
                                    checked={uploadLinkToDeal}
                                    onChange={() => setUploadLinkToDeal(prev => !prev)} />
                                <label htmlFor="add-to-deal" className="ff-i600 c-grey-700 text-s">Add to my links</label>
                            </div>
                        }
                        <div className="mt-3 mt-md-0">
                            <button
                                className="btn-primary ff-i600 w-100"
                                disabled={isDisableShareButton}
                                onClick={() => shareLink(setIsLinkNameValid, setIsLinkUrlValid)}>
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className="icon icon-send-white me-2"></span>
                                    <span>Share</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </>
            }

            {!!dealLinks.length &&
                <>
                    {isSeller &&
                        <>
                            <h5 className="c-grey-700 border-bottom-grey-200 pb-2 mb-2">
                                My Links
                            </h5>
                            <div className="d-flex align-items-center border-radius-small bc-grey-90 py-2 px-3">
                                <input
                                    type="checkbox"
                                    className={`col-1 me-3 ${selectedLinks.length !== dealLinks.length ? 'unfold' : ''}`}
                                    checked={selectedLinks.length > 0}
                                    onChange={() => selectAll(setSelectedLinks, dealLinks)} />
                                <span className="col-5 col-sm-6 ff-i500 c-grey-550 text-s">Linkname</span>
                                <div className="d-sm-none col-6 text-end">
                                    <span className="ff-i500 c-grey-550 text-s me-3">Date shared</span>
                                </div>
                                <div className="d-none d-sm-flex col-sm-5">
                                    <span className="col-6 text-center ff-i500 c-grey-550 text-s">Date</span>
                                    <span className="col-6 text-center ff-i500 c-grey-550 text-s">Shared</span>
                                </div>
                            </div>
                            <div className="py-2 mt-2 pt-1 px-3">
                                {dealLinks.map(link =>
                                    <div key={link.id} className="d-flex align-items-center pb-4">
                                        <input
                                            type="checkbox"
                                            className="col-1 me-3"
                                            checked={selectedLinks.includes(link.id)}
                                            onChange={() => handleOnChangeLinks(link.id, selectedLinks.includes(link.id))} />
                                        <div className="col-5 col-sm-6">
                                            <a className="ff-i500 c-blue-600 text-s text-break"
                                                href={link.link.startsWith('https://') ? link.link : `https://${link.link}`}
                                                target="_blank"
                                                rel="noreferrer">
                                                {link.name}
                                            </a>
                                            {link.pinned && <span className="icon icon-pinned-blue ms-2"></span>}
                                        </div>
                                        <div className="d-sm-none col-6 text-end">
                                            <span className="ff-i500 c-grey-550 text-s me-3">{link.sharedDate ? new Date(link.sharedDate).toLocaleDateString() : "--"}</span>
                                            <span className={`icon icon-success me-3 ${!link.sharedDate ? 'icon-grayscale' : ''}`}></span>
                                        </div>
                                        <div className="d-none d-sm-flex col-sm-5">
                                            <span className="col-6 text-center ff-i500 c-grey-450 text-s">{link.sharedDate ? new Date(link.sharedDate).toLocaleDateString() : "--"}</span>
                                            <div className="col-6 d-flex justify-content-center">
                                                <span className={`icon icon-success ${!link.sharedDate ? 'icon-grayscale' : ''}`}></span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    }
                    {!isSeller &&
                        <>
                            <h5 className="c-grey-700 border-bottom-grey-200 pb-2 mb-2">
                                Received
                            </h5>
                            <div className="d-flex align-items-center border-radius-small bc-grey-90 py-2 px-3">
                                <span className="col-8 ff-i500 c-grey-550 text-s">Linkname</span>
                                <span className="col-4 text-end ff-i500 c-grey-550 text-s">Date</span>
                            </div>
                            <div className="py-2 mt-2 pt-1 px-3">
                                {dealLinks.map(link =>
                                    <div key={link.id} className="d-flex align-items-center pb-4">
                                        <div className="col-8">
                                            <a className="ff-i500 c-blue-600 text-s text-break"
                                                href={link.link.startsWith('https://') ? link.link : `https://${link.link}`}
                                                target="_blank"
                                                rel="noreferrer">
                                                {link.name}
                                            </a>
                                        </div>
                                        <span className="col-4 text-end ff-i500 c-grey-450 text-s">{new Date(link.sharedDate).toLocaleDateString()}</span>
                                    </div>
                                )}
                            </div>
                        </>
                    }
                </>
            }

            {!dealLinks.length && (
                <div className="text-center mt-5">
                    <span className="icon icon-empty-links"></span>
                    <div className="ff-i600 c-grey-550 text-m">You don't have any links.</div>
                </div>
            )}
        </>
    );
}

export default SharedLinks;