function MemoryCache() {
    const data = {};
    const self = this;

    this.set = (key, value) => data[key] = value;
    this.has = key => data.hasOwnProperty(key);
    this.get = key => data[key];
    this.getOrDefault = (key, defaultValue) => self.has(key) ? data[key] : defaultValue;
    this.remove = key => delete data[key];

    this.keys = () => {
        var keys = [];
        for (var key in data)
            if (data.hasOwnProperty(key))
                keys.push(key);
        return keys;
    };

    this.clear = () => {
        for (var key in data)
            if (data.hasOwnProperty(key))
                self.remove(key);
    };
}

export default MemoryCache;