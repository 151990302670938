import { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import analyticsService from 'services/common/analytic-trackers';
import AuthService from 'services/auth-service';
import NameInput from 'components/atoms/NameInput';
import EmailInput from 'components/atoms/EmailInput';
import PhoneInput from 'components/atoms/PhoneInput';
import PasswordInput from 'components/atoms/PasswordInput';
import useToast from 'hooks/useToast';
import { SENT_EMAIL_VERIFICATION_KEY } from 'hooks/useLocalStorage';
import { UserRole } from 'models/UserRole';
import useLoader from 'hooks/useLoader';

const authService = AuthService();

function LandingRegisterForm({ userTypes, userRole, description }) {
    const { toast } = useToast();
    const { load } = useLoader();
    const history = useHistory();

    useEffect(() => {
        authService.init();
        return () => authService.dispose();
    }, []);

    useEffect(() => {
        if (userTypes && userTypes.length && userRole) {
            let userType = userTypes.find(type => type.name.toLowerCase().includes(userRole));
            setRole('' + userType?.id);
        }
    }, [userTypes, userRole]);

    if (userTypes && userTypes.length > 0 && userTypes.some(x => x.id === 3))
        userTypes.push(
            userTypes.splice(
                userTypes.findIndex(x => x.id === 3),
                1
            )[0]
        );

    const getUserType = id => userTypes.find(type => type.id === +id);

    const [triggerValidation, setTriggerValidation] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [isValidFirstName, setIsValidFirstName] = useState(false);

    const [lastName, setLastName] = useState('');
    const [isValidLastName, setIsValidLastName] = useState(false);

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const emailRef = useRef();
    const [emailError, setEmailError] = useState('');

    const [phone, setPhone] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(false);
    const phoneRef = useRef();

    const [role, setRole] = useState('');
    const rolePlaceholderRef = useRef();

    const newPasswordRef = useRef();
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordShown, setNewPasswordShown] = useState(false);

    const [agreeWithPolicies, setAgreeWithPolicies] = useState(false);

    const confirmPasswordRef = useRef();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);

    const [isValidNewPassword, setIsValidNewPassword] = useState(false);
    const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
    const [showMatchPasswordErrorMessage, setShowMatchPasswordErrorMessage] = useState(false);

    useEffect(() => {
        setShowMatchPasswordErrorMessage(false);
        if (confirmPassword !== newPassword) {
            confirmPasswordRef.current.classList.toggle('invalid', true);
            setShowMatchPasswordErrorMessage(true);
            return;
        }

        if (isValidConfirmPassword) {
            confirmPasswordRef.current.classList.toggle('invalid', false);
            confirmPasswordRef.current.classList.toggle('valid', true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmPassword, newPassword]);

    const registerRef = useRef();
    const handleOnClickRegister = () => {
        setTriggerValidation(true);

        if (
            !isValidFirstName ||
            !isValidLastName ||
            !isValidEmail ||
            !isValidPhone ||
            !role ||
            !isValidNewPassword ||
            !isValidConfirmPassword ||
            newPassword !== confirmPassword ||
            !agreeWithPolicies
        ) {
            return;
        }
        if (registerRef.current) registerRef.current.setAttribute('disabled', 'disabled');
        load(() =>
            authService
                .register({ firstName, lastName, email, phone, type: +role, password: newPassword, CountryCode: '+1' })
                .then(_ => {
                    let method = 'Landing - Main';
                    switch (userRole) {
                        case UserRole.Broker:
                            method = 'Landing - Brokers';
                            break;
                        case UserRole.Buyer:
                            method = 'Landing - Buyers';
                            break;
                        case UserRole.Seller:
                            method = 'Landing - Sellers';
                            break;
                        default:
                            break;
                    }
                    analyticsService.trackSignUp({ method });
                    toast.success({ body: 'You have been successfully registered.' });
                    localStorage.removeItem(SENT_EMAIL_VERIFICATION_KEY);
                    history.push('/secure/verify-email', { email: email });
                })
                .catch(error => {
                    if (!error || !error.response) return;
                    if (error.response.status === 409) {
                        analyticsService.trackExistingUser();
                        history.push('/secure');
                        return;
                    }
                    toast.handleError(error);
                    Object.keys(error.response?.data?.errors).map(err => {
                        if (err.toLowerCase() === 'email') {
                            emailRef.current.className += ' c-red-500';
                            setEmailError(
                                error.response?.data?.errors[err]?.length > 0
                                    ? error.response?.data?.errors[err][0]
                                    : error?.response?.data?.title
                            );
                        }
                        if (err.toLowerCase() === 'phone') phoneRef.current.className += ' c-red-500';
                        return null;
                    });
                })
                .finally(() => registerRef.current && registerRef.current.removeAttribute('disabled'))
        );
    };

    return (
        <div className="landing-register-form register-block bg-white px-1 px-md-3 py-3 border-radius-medium">
            <div className="container p-3">
                <div className="row ff-i600 text-start justify-content-center">
                    <div className="col-12 pb-3 text-center">
                        <Link to="/secure/register" className="text-decoration-none">
                            <h1 className="c-green-600">Get Free Access</h1>
                        </Link>
                    </div>
                    <div className="row pb-4 gx-3">
                        <div className="col-6">
                            <NameInput
                                name={firstName}
                                setName={setFirstName}
                                isValid={setIsValidFirstName}
                                placeholder="First Name"
                                forceValidation={triggerValidation}
                            />
                        </div>
                        <div className="col-6">
                            <NameInput
                                name={lastName}
                                setName={setLastName}
                                isValid={setIsValidLastName}
                                placeholder="Last Name"
                                forceValidation={triggerValidation}
                            />
                        </div>
                    </div>

                    <div className="row gx-3">
                        <div className="col-12 pb-4">
                            <EmailInput
                                email={email}
                                setEmail={setEmail}
                                isValid={setIsValidEmail}
                                placeholder="Email"
                                forceValidation={triggerValidation}
                                emailRef={emailRef}
                                showIcon={false}
                            />
                            {emailError && <div className="text-start c-red-500 px-2 mt-2">{emailError}</div>}
                        </div>
                    </div>

                    <div className="row gx-3">
                        <div className="col-12 col-md-12 pb-4">
                            <PhoneInput
                                number={phone}
                                setNumber={setPhone}
                                isValid={setIsValidPhone}
                                placeholder="Mobile Number"
                                forceValidation={triggerValidation}
                                showIcon={true}
                                countryCode="+1"
                            />
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-12 col-md-12 pb-4">
                            <DropdownButton
                                id="select-role-dropdown"
                                name="role"
                                align="end"
                                title={
                                    <span className="d-flex justify-content-between align-items-center">
                                        {(role && getUserType(role)?.name) || (
                                            <span
                                                className="p-holder c-grey-450 me-3 text-truncate"
                                                ref={rolePlaceholderRef}
                                            >
                                                Buying, Selling or Broker?
                                            </span>
                                        )}
                                        <span
                                            className="icon icon-arrow-right-grey align-middle img-rotate-90"
                                            style={{ width: '7px' }}
                                        ></span>
                                    </span>
                                }
                                onSelect={setRole}
                                className={`p-3 primary ${!role && triggerValidation ? 'invalid' : ''} ${
                                    role ? 'valid' : ''
                                }`}
                            >
                                {userTypes.map(type => (
                                    <Dropdown.Item eventKey={type.id} key={type.id}>
                                        {type.name}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                    </div>
                    <div className="row pb-4 gx-3">
                        <div className="col-12 col-md-6 pb-4 pb-md-0">
                            <PasswordInput
                                passwordShown={newPasswordShown}
                                setPasswordShown={setNewPasswordShown}
                                password={newPassword}
                                setPassword={setNewPassword}
                                passwordRef={newPasswordRef}
                                autocomplete="new-password"
                                isValid={setIsValidNewPassword}
                                placeholder={'Password'}
                                forceValidation={triggerValidation}
                                showIcon={false}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <PasswordInput
                                passwordShown={confirmPasswordShown}
                                setPasswordShown={setconfirmPasswordShown}
                                password={confirmPassword}
                                setPassword={setConfirmPassword}
                                passwordRef={confirmPasswordRef}
                                autocomplete="new-password"
                                isValid={setIsValidConfirmPassword}
                                placeholder={'Confirm Password'}
                                forceValidation={triggerValidation}
                                showIcon={false}
                            />

                            {showMatchPasswordErrorMessage && isValidConfirmPassword && (
                                <span className="text-start c-red-500 px-2 text-s">Password does not match.</span>
                            )}
                        </div>
                    </div>

                    <div
                        className={`row pb-4 gx-3 ${
                            triggerValidation && !agreeWithPolicies ? 'c-red-500' : 'c-grey-450'
                        }`}
                    >
                        <div className="col-1">
                            <input
                                type="checkbox"
                                id="remember-me"
                                onChange={() => setAgreeWithPolicies(!agreeWithPolicies)}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="remember-me" className="ff-i500">
                                I agree with the
                                <Link className="text-decoration-none ms-1" to="/terms">
                                    Terms of Service
                                </Link>
                                <span> and </span>
                                <Link className="text-decoration-none" to="/privacy">
                                    Privacy Policy
                                </Link>
                            </label>
                        </div>
                    </div>

                    <div className="col-12 c-grey-450 ff-i500 text-center">
                        <button
                            type="button"
                            style={{ background: 'linear-gradient(180deg, #38CB89 0%, #02B369 100%)' }}
                            className="btn-primary green text-m py-3 w-100 ff-m700"
                            onClick={handleOnClickRegister}
                            ref={registerRef}
                        >
                            Start Free Trial
                        </button>
                        <div className="mt-3 text-s">
                            <p className="ff-i500 c-grey-450 text-center">
                                <span>
                                    Already have an account?
                                    <Link to="/secure" className="text-decoration-none ms-1">
                                        Login
                                    </Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingRegisterForm;
