import { useEffect, useState } from 'react';
import NotificationsService from 'services/notifications-service';
import { trackPageview } from 'services/common/analytic-trackers';
import Notification from './components/Notification';
import GroupedNotifications from './components/GroupedNotifications';
import { useHistory } from 'react-router-dom';
import useToast from 'hooks/useToast';
import useLoader from 'hooks/useLoader';
import Select from 'components/atoms/select/Select';

const notificationsService = NotificationsService();
const NotificationsPage = () => {
    const { toast } = useToast();
    const { load } = useLoader();
    const history = useHistory();
    const sortOptions = [
        { id: 0, name: 'Deal' },
        { id: 1, name: 'Date' }
    ];

    const [notifications, setNotifications] = useState([]);
    const [sortOption, setSortOption] = useState(0);
    const selectedDealId = history?.location?.state?.dealId || 0;

    const groupBy = (list, keyGetter) => {
        if (list.length === 0) return;
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    const sortByDateDesc = (item1, item2) => new Date(item2.createdDate) - new Date(item1.createdDate);

    const process = (redirectUrl) => {
        history.push(redirectUrl);
    }

    useEffect(() => {
        notificationsService.init();
        document.title = '1Konnection - Notifications';
        trackPageview();

        load(() => notificationsService
            .get()
            .then(response => {
                if (response?.data?.notifications?.length > 0)
                    setNotifications(response.data.notifications
                        .sort(sortByDateDesc).filter(notification => !!notification?.dealId));
            })
            .catch(error => toast.handleError(error))
        );

        return () => {
            notificationsService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='container px-0 px-md-3 mb-md-5 py-5'>
            <div className='d-none d-md-flex justify-content-between align-items-center mb-3'>
                <h2 className='text-center c-grey-700'>Notifications</h2>
                {notifications.length > 0 &&
                    <div className='mb-2'>
                        <Select
                            value={sortOptions.find(option => option.id === sortOption) || null}
                            onChange={(newValue) => setSortOption(+newValue.id)}
                            keys={{valueKey: "id", labelKey: "name"}}
                            options={sortOptions} controlText={'Sort by:'} isInheritHeight
                        />
                    </div>
                }
            </div>

            <h2 className='d-block d-md-none text-center c-grey-700 mb-3'>Notifications</h2>

            {notifications?.length === 0 &&
                <div className='py-5 mx-3 mx-md-0 border-grey-200 border-radius'>
                    <div className='d-flex flex-column align-items-center'>
                        <img className='mx-auto' src='/images/dashboards/empty-mailbox.svg' alt='no-notifications' />
                        <h5 className='text-center c-grey-700 mb-2'>You don't have any notifications yet.</h5>
                        <div className='w-75 mb-2'>
                            <p className='c-grey-550 text-center'>Check for them later.</p>
                        </div>
                    </div>
                </div>
            }

            {(sortOption === 0 && notifications?.length > 0) &&
                [...groupBy(notifications, notification => notification.dealId)]
                    .sort((deal1, deal2) =>
                        deal2[1].filter(x => !x.processed).length - deal1[1].filter(x => !x.processed).length)
                    ?.map(([dealId, notifications]) => {
                        return (
                            <GroupedNotifications
                                key={`grouped-notifications-${dealId}`}
                                selectedDeal={selectedDealId}
                                dealId={dealId}
                                notifications={notifications.sort(sortByDateDesc)}
                                action={process} />
                        );
                    })
            }

            {
                (sortOption === 1 && notifications?.length > 0) &&
                notifications?.map((notification, index, array) => {
                    return (
                        <Notification
                            key={`notification-${index}`}
                            notification={notification}
                            first={index === 0}
                            last={index === array.length - 1}
                            action={process} />
                    );
                })
            }
        </div >
    );
}

export default NotificationsPage;