import { useState } from 'react';

import CircleProgress from 'core/CircleProgress';

const SharedFiles = ({
    isSeller,
    dealFiles,
    uploadingFiles,
    receivedFiles,
    setUploadingFiles,
    selectedFiles,
    setSelectedFiles,
    uploadFileToDeal,
    setUploadFileToDeal,
    disabledFileUpload,
    showProgress,
    progress,
    disableShareButton,
    shareFiles,
    maxSizeOfFiles = 100,
    maxNumberOfFiles = 10 }) => {
    const [maxSize] = useState(maxSizeOfFiles * 1048576);
    const [dragEnterCount, setDragEnterCount] = useState(0);

    const onFilesChange = inputFiles => {
        let sizes = 0;
        let length = uploadingFiles.length;
        let arrayFiles = [];
        uploadingFiles.forEach(file => sizes += file.size);
        Array.from(inputFiles).forEach(inputFile => {
            sizes += inputFile.size;
            if (inputFile.size >= (maxSize / maxNumberOfFiles)
                || sizes >= maxSize
                || ++length > maxNumberOfFiles)
                arrayFiles.push({ file: inputFile, isUploading: false, errorMessage: 'This file is too large. We support up to 10 MB.' });

            else arrayFiles.push({ file: inputFile, isUploading: true });
        });
        setUploadingFiles(arrayFiles);
    }

    const handleRemoveUploadingFiles = index =>
        setUploadingFiles(prev => [
            ...prev.slice(0, index),
            ...prev.slice(index + 1, prev.length)
        ]);

    const handleOnChangeFiles = (id, isChecked = false) =>
        setSelectedFiles(prev => isChecked ? prev.filter(cid => cid !== id) : [...prev, id]);
    const selectAll = (setSelectedItems, items) =>
        setSelectedItems(prev => prev.length > 0 ? [] : items.map(item => item.id));
    const fileUrl = (containerName, directory, name) =>
        `${process.env.REACT_APP_BLOB_STORAGE}/${containerName}/${directory}/${encodeURIComponent(name)}`;

    return (
        <>
            <div className={`border-radius border-grey-200 w-100 mb-3 py-4 ${dragEnterCount ? 'border-blue-dashed bc-blue-100' : ''}`}
                onDragEnter={() => {
                    setDragEnterCount(prev => prev + 1);
                }}
                onDragLeave={() => {
                    setDragEnterCount(prev => prev - 1);
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                }}
                onDrop={(e) => {
                    e.preventDefault();
                    onFilesChange(e.dataTransfer.files);
                    setDragEnterCount(0);
                }}>
                <div className="text-center ff-i600 c-grey-700 text-m">SHARED NEW</div>
                <div className="text-center ff-i600 c-grey-700 text-m">
                    Drag and drop docs here or
                    <label className="ff-i600 c-blue-500 cursor-pointer"
                        htmlFor="files">
                        &nbsp;browse
                    </label>
                </div>
                <div className="text-center ff-i500 c-grey-450 text-m">We support up to 10 MB.</div>
            </div>

            <input id="files"
                type="file"
                multiple
                className="d-none"
                disabled={disabledFileUpload}
                onChange={e => { onFilesChange(e.target.files); e.target.value = ''; }} />

            {uploadingFiles.length > 0 &&
                <>
                    {uploadingFiles.map((uploadingFile, index) =>
                        <div key={index} className="d-flex justify-content-between align-items-center border-grey border-radius px-3 py-2 mb-2">
                            <span>
                                <span className="ff-i500 c-grey-700 text-s">{uploadingFile.file.name}</span>
                                {!uploadingFile.isSuccess &&
                                    <span className="d-block ff-i500 c-red-500 text-s">
                                        {uploadingFile.errorMessage}
                                    </span>
                                }
                            </span>
                            {showProgress && uploadingFile.isUploading
                                ? <CircleProgress strokeWidth="3"
                                    sqSize="24"
                                    percentage={progress} />
                                : <>
                                    {uploadingFile.isSuccess
                                        ? <span className="icon icon-approve-green-sm"></span>
                                        : <span className="icon icon-cancel cursor-pointer" onClick={() => handleRemoveUploadingFiles(index)}></span>
                                    }
                                </>
                            }
                        </div>
                    )}
                    <div className="d-flex flex-column flex-md-row justify-content-between pt-2 pb-3 mb-2">
                        {isSeller &&
                            <div className="d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    className="col-1 me-2"
                                    id="add-to-deal"
                                    checked={uploadFileToDeal}
                                    onChange={() => setUploadFileToDeal(prev => !prev)} />
                                <label htmlFor="add-to-deal" className="ff-i600 c-grey-700 text-s">Add to my documents</label>
                            </div>
                        }
                        <div className="mt-3 mt-md-0">
                            <button
                                className="btn-primary ff-i600 w-100"
                                disabled={disableShareButton}
                                onClick={() => shareFiles(uploadingFiles)}>
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className="icon icon-send-white me-2"></span>
                                    <span>Share</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </>
            }

            {!!dealFiles.length &&
                <>
                    {isSeller &&
                        <>
                            <h5 className="c-grey-700 border-bottom-grey-200 pb-2 mb-2">
                                My Documents
                            </h5>
                            <div className="d-flex align-items-center border-radius-small bc-grey-90 py-2 px-3">
                                <input
                                    type="checkbox"
                                    className={`col-1 me-3 ${selectedFiles.length !== dealFiles.length ? 'unfold' : ''}`}
                                    checked={selectedFiles.length > 0}
                                    onChange={() => selectAll(setSelectedFiles, dealFiles)} />
                                <span className="col-5 col-sm-6 ff-i500 c-grey-550 text-s">Filename</span>
                                <div className="d-sm-none col-6 text-end">
                                    <span className="ff-i500 c-grey-550 text-s me-3">Date shared</span>
                                </div>
                                <div className="d-none d-sm-flex col-sm-5">
                                    <span className="col-6 text-center ff-i500 c-grey-550 text-s">Date</span>
                                    <span className="col-6 text-center ff-i500 c-grey-550 text-s">Shared</span>
                                </div>
                            </div>
                            <div className="py-2 mt-2 px-3">
                                {dealFiles.map(file =>
                                    <div key={file.id} className="d-flex align-items-center pb-4">
                                        <input
                                            type="checkbox"
                                            className="col-1 me-3"
                                            checked={selectedFiles.includes(file.id)}
                                            onChange={() => handleOnChangeFiles(file.id, selectedFiles.includes(file.id))} />
                                        <div className="col-5 col-sm-6">
                                            <span className="ff-i500 c-grey-700 text-s text-break">{file.name}</span>
                                            <span
                                                className="icon icon-download-grey cursor-pointer ms-2"
                                                onClick={() => window.location.replace(`${fileUrl(file.containerName, file.directory, file.name)}`)}></span>
                                            {file.pinned && <span className="icon icon-pinned-blue ms-2"></span>}
                                        </div>
                                        <div className="d-sm-none col-6 text-end">
                                            <span className="ff-i500 c-grey-550 text-s me-3">{file.sharedDate ? new Date(file.sharedDate).toLocaleDateString() : "--"}</span>
                                            <span className={`icon icon-success me-3 ${!file.sharedDate ? 'icon-grayscale' : ''}`}></span>
                                        </div>
                                        <div className="d-none d-sm-flex col-sm-5">
                                            <span className="col-6 text-center ff-i500 c-grey-450 text-s">{file.sharedDate ? new Date(file.sharedDate).toLocaleDateString() : "--"}</span>
                                            <div className="col-6 d-flex justify-content-center">
                                                <span className={`icon icon-success ${!file.sharedDate ? 'icon-grayscale' : ''}`}></span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    }

                    {!isSeller &&
                        <>
                            <h5 className="c-grey-700 border-bottom-grey-200 pb-2 mb-2">
                                Shared
                            </h5>
                            <div className="d-flex align-items-center border-radius-small bc-grey-90 py-2 px-3">
                                <span className="col-8 ff-i500 c-grey-550 text-s">Filename</span>
                                <span className="col-4 text-end ff-i500 c-grey-550 text-s">Date</span>
                            </div>
                            <div className="py-2 mt-2 pt-1 px-3">
                                {dealFiles.map(file =>
                                    <div key={file.id} className="d-flex align-items-center pb-4">
                                        <div className="col-8">
                                            <span className="ff-i500 c-grey-700 text-s text-break">{file.name}</span>
                                            <span
                                                className="icon icon-download-grey cursor-pointer ms-2 ps-1"
                                                onClick={() => window.location.replace(`${fileUrl(file.containerName, file.directory, file.name)}`)}></span>
                                        </div>
                                        <span className="col-4 text-end ff-i500 c-grey-450 text-s">{new Date(file.sharedDate).toLocaleDateString()}</span>
                                    </div>
                                )}
                            </div>
                        </>
                    }
                </>
            }

            {!!receivedFiles.length &&
                <>
                    <h5 className="c-grey-700 border-bottom-grey-200 pb-2 mb-2">
                        Received
                    </h5>
                    <div className="d-flex align-items-center border-radius-small bc-grey-90 py-2 px-3">
                        <span className="col-8 ff-i500 c-grey-550 text-s">Filename</span>
                        <span className="col-4 text-end ff-i500 c-grey-550 text-s">Date</span>
                    </div>
                    <div className="py-2 mt-2 px-3">
                        {receivedFiles.map((file, index) =>
                            <div key={index} className="d-flex align-items-center pb-4">
                                <div className="col-8">
                                    <span className="ff-i500 c-grey-700 text-s text-break">{file.name}</span>
                                    <span
                                        className="icon icon-download-grey cursor-pointer ms-2 ps-1"
                                        onClick={() => window.location.replace(`${fileUrl(file.containerName, file.directory, file.name)}`)}></span>
                                </div>
                                <span className="col-4 text-end ff-i500 c-grey-450 text-s">{new Date(file.sharedDate).toLocaleDateString()}</span>
                            </div>
                        )}
                    </div>
                </>
            }

            {!dealFiles.length && !receivedFiles.length && (
                <div className="text-center mt-5">
                    <span className="icon icon-empty-files"></span>
                    <div className="ff-i600 c-grey-550 text-m">
                        You don't have any documents.
                    </div>
                </div>
            )}
        </>
    );
}

export default SharedFiles;