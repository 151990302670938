import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import useLoader from 'hooks/useLoader';
import useToast from 'hooks/useToast';
import { SENT_EMAIL_VERIFICATION_KEY } from 'hooks/useLocalStorage';

import UserEmailsService from 'services/user-emails-service';

const userEmailsService = UserEmailsService();

const VerifyEmailPage = () => {
    const history = useHistory();
    const { toast } = useToast();
    const { load } = useLoader();
    const email = history?.location?.state?.email || '';

    const [isVerificationSend, setIsVerificationSend] = useState();

    useEffect(() => {
        document.title = '1Konnection - Verify Your Email';
        userEmailsService.init();

        if (!email) history.push('/not-found');

        setIsVerificationSend(window.localStorage.getItem(SENT_EMAIL_VERIFICATION_KEY));

        return () => userEmailsService.dispose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendVerified = () =>
        load(() => userEmailsService
            .postEmailVerification(email)
            .then(_ => {
                const localStorageStatus = '1';
                window.localStorage.setItem(SENT_EMAIL_VERIFICATION_KEY, localStorageStatus);
                setIsVerificationSend(localStorageStatus);
                toast.success({ body: 'Email was successfully send.' });
            })
            .catch(error => toast.handleError(error)));

    const renderSendEmailVerification = () => {
        switch (isVerificationSend) {
            case '1':
                return <span className="text-s ff-i500 c-grey-300 ps-1">
                    <Link className="text-decoration-none ff-i500" to="/contact-us">Contact us.</Link>
                </span>
            default:
                return <span
                    className="text-s ff-i600 c-blue-500 cursor-pointer"
                    onClick={sendVerified}>
                    &nbsp;Resend
                </span>
        }
    }

    return (
        <div className="d-flex justify-content-center mt-2 mt-md-5 pt-5">
            <div className="col-12 col-lg-5 text-center px-4 mt-md-5">
                <span className="icon icon-welcome-email" />
                <div className="mb-5">
                    <h2 className="ff-m700 c-grey-700">You've successfully registered.</h2>
                    <h2 className="ff-m700 c-grey-700">Check your email to complete the process.</h2>
                </div>
                <div className="ff-i500 c-grey-550 mb-1">
                    We've sent an email to&nbsp;
                    <span className="ff-i500 c-grey-900 text-break">{email}.&nbsp;</span>
                    Not yours?&nbsp;
                    <Link className="text-decoration-none ff-i500" to="/contact-us">Contact us.</Link>
                </div>
                <div className="ff-i500 c-grey-550 mb-4 pb-2">
                    You're almost there! Click the verification link in your inbox.
                </div>
                <div className="ff-i700 c-grey-550 mb-4">
                    You’re minutes away from closing your next big deal.
                </div>
                <div className="ff-i500 c-grey-550 text-s">
                    Didn't get the email?{renderSendEmailVerification()}
                </div>
            </div>
        </div>
    )
}

export default VerifyEmailPage;