import { useState } from 'react';
import PropTypes from 'prop-types';

const dateTimeFormatOptionsHourMinutes = { hour: 'numeric', minute: 'numeric' };

const PickTimeSlot = ({ datetimeSlot, activeTimeSlot, setSelectedTimeSlot, onClickConfirm }) => {
    const timeSlot = useState(new Intl.DateTimeFormat('en-US', dateTimeFormatOptionsHourMinutes).format(datetimeSlot));

    return (
        <div className="ff-i600 mt-2">
            <button className={`btn-white ${datetimeSlot === activeTimeSlot ? '' : 'w-100'}`}
                onClick={() => setSelectedTimeSlot(datetimeSlot)}
                style={{ paddingBlock: '7px', width: '49%' }}>{timeSlot}</button>
            {datetimeSlot === activeTimeSlot &&
                <button className="btn-regular" style={{ width: '49%', marginLeft: '2%' }}
                    onClick={() => onClickConfirm(datetimeSlot)}>Confirm</button>
            }
        </div>
    );
}

PickTimeSlot.propTypes = {
    setSelectedTimeSlot: PropTypes.func.isRequired,
    onClickConfirm: PropTypes.func.isRequired
}

export default PickTimeSlot;