import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import PasswordInput from 'components/atoms/PasswordInput';
import EmailInput from 'components/atoms/EmailInput';
import { trackPageview } from 'services/common/analytic-trackers';
import AuthService from 'services/auth-service';
import AuthHandler from 'services/common/auth-handler';

const authService = AuthService();
const authHandler = AuthHandler();

function ResetPassword({ procedureName }) {
    const { toast } = useToast();
    const history = useHistory();
    const query = useQueryParams();
    const [expired, setExpired] = useState(false);
    const [token, setToken] = useState();

    useEffect(() => {
        document.title = '1Konnection - Reset Password';
        trackPageview();
        authService.init();

        const tokenQuery = query.get('token');
        if (!tokenQuery) {
            history.push('/secure');
            return;
        }
        authService
            .validateResetToken(tokenQuery)
            .then(() => setToken(tokenQuery))
            .catch(_ => setExpired(true));

        return () => authService.dispose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const passwordRef = useRef();
    const [password, setPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);

    const confirmationRef = useRef();
    const [confirmation, setConfirmation] = useState('');
    const [confirmationShown, setConfirmationShown] = useState(false);
    const [confirmationDoesNotMatchErrorMessage, setConfirmationDoesNotMatchErrorMessage] = useState(false);

    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isValidConfirmation, setIsValidConfirmation] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);

    useEffect(() => {
        setConfirmationDoesNotMatchErrorMessage(false);
        confirmationRef.current.classList.toggle('invalid', false);
        if (confirmation !== password) {
            confirmationRef.current.classList.toggle('invalid', true);
            setConfirmationDoesNotMatchErrorMessage(true);
            return;
        }
    }, [confirmation, password]);

    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const postNewPassword = () => {
        authService
            .sendConfirmationEmail(email)
            .catch(error => toast.handleError(error))
            .finally(() => setEmailSent(true));
    }

    const onContinueClick = () => {
        authService
            .setupPassword({ token, password })
            .then(result => {
                if (authHandler.isAuthenticated()) {
                    const user = authHandler.getUser();
                    const userType = user?.identitytype;
                    const redirectUrl = userType === 'Buyer' || userType === 'Broker - Buying'
                        ? '/buyer-dashboard'
                        : '/seller-dashboard';
                    history.push(redirectUrl);
                    return;
                }
                history.push(`/secure?email=${result.data.email}`);
            })
            .catch(error => {
                toast.handleError(error);
                history.push('/');
            });
    }

    return (
        <div className="container mb-md-5 py-5">
            <div className={`row mt-md-5 pb-5 mx-2 mx-md-0 border-radius-small collapse ${!expired ? 'show' : ''}`}>
                <div className="col-12 text-center pt-4 pt-md-5">
                    <h4 className="c-grey-900 pb-md-2">{procedureName}</h4>
                </div>

                <div className="col-12 col-md-6 offset-md-3 text-center mt-2 py-3 px-3 px-md-2 position-relative">
                    <PasswordInput
                        passwordShown={passwordShown}
                        setPasswordShown={setPasswordShown}
                        password={password}
                        setPassword={setPassword}
                        isValid={setIsValidPassword}
                        passwordRef={passwordRef} />
                </div>

                <div className="col-12 col-md-6 offset-md-3 text-center py-3 px-3 px-md-2 position-relative">
                    <PasswordInput
                        passwordShown={confirmationShown}
                        setPasswordShown={setConfirmationShown}
                        password={confirmation}
                        setPassword={setConfirmation}
                        isValid={setIsValidConfirmation}
                        passwordRef={confirmationRef}
                        placeholder="Confirm your password" />
                    {confirmation && confirmationDoesNotMatchErrorMessage &&
                        <div className="text-start c-red-500 px-2 text-s">
                            Your confirmation password does not match
                        </div>
                    }
                </div>
                <div className="col-12 col-md-6 offset-md-3 mt-4 pb-md-3 px-3 px-md-2">
                    <button
                        className="btn-primary ff-i600 w-100"
                        onClick={onContinueClick}
                        disabled={!isValidConfirmation || !isValidPassword || password !== confirmation}>Continue</button>
                </div>
            </div>

            <div className={`row mt-md-5 pb-5 mx-2 mx-md-0 border-radius-small collapse ${expired && !emailSent ? 'show' : ''}`}>
                <div className="col-12 text-center pt-4 pt-md-5 px-3 px-md-2">
                    <h4 className="  c-grey-900 pb-md-2">Set password session expired</h4>
                    <p className="ff-i500 c-grey-450 px-4 text-center">
                        1Konnection cares about your security. To protect your privacy setting password session has short term expiration.
                    </p>
                    <p className="ff-i500 c-grey-450 px-4 text-center">
                        The link you have used is already expired, enter your email and click "Send" to receive a new email for setting a password.
                    </p>
                </div>
                <div className="col-12 col-md-6 offset-md-3 text-center mt-4 pt-md-2 px-3 px-md-2">
                    <EmailInput email={email} setEmail={setEmail} isValid={setIsValidEmail} placeholder="Your Email" />
                </div>
                <div className="col-12 col-md-6 offset-md-3 mb-md-5 mt-4 pt-3 pb-md-3 px-3 px-md-2">
                    <button
                        className="btn-primary ff-i600 w-100"
                        onClick={postNewPassword}
                        disabled={!isValidEmail}>Send</button>
                </div>
            </div>

            <div className={`row mt-md-5 pb-5 mx-2 mx-md-0 border-radius-small collapse ${emailSent ? 'show' : ''}`}>
                <div className="col-12 text-center pt-4 pt-md-5">
                    <h4 className="  c-grey-900 pb-md-2">Email sent</h4>
                    <p className="ff-i500 c-grey-450 px-4 text-center">
                        We've sent an email to {email} with a link to get back into your account.
                    </p>
                </div>
                <div className="col-12 col-md-6 offset-md-3 mt-md-2 mb-md-5 pb-md-3 px-3 px-md-2">
                    <button
                        className="btn-primary ff-i600 w-100"
                        onClick={() => history.push('/')}>Back to the home page</button>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;