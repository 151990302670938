import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DealManageModal = ({
    show,
    handleConfirmClick,
    handleClose,
    title,
    children,
    disabledConfirmButton = false,
    confirmButton = 'Save',
}) => {
    return (
        <Modal show={show} onHide={handleClose} centered size="lg" className="pb-lg-0">
            <Modal.Header closeButton className="p-3 p-md-4">
                <Modal.Title className="ff-i700 c-grey-700 ps-md-2">
                    <h5 className="ff-i600">{title}</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mb-4 py-4 px-3 px-md-4 modal-body">{children}</Modal.Body>
            <Modal.Footer className="py-4">
                <div className="flex-fill">
                    <div className="row justify-content-center">
                        <div className="col-6 col-md-4">
                            <button className="btn-secondary ff-i600 w-100 me-2" onClick={handleClose}>
                                Cancel
                            </button>
                        </div>
                        <div className="col-6 col-md-4">
                            <button
                                color="primary"
                                disabled={disabledConfirmButton}
                                className="btn-primary ff-i600 w-100 me-2"
                                onClick={handleConfirmClick}
                            >
                                {confirmButton}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

DealManageModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    handleConfirmClick: PropTypes.func,
    title: PropTypes.string,
    confirmButton: PropTypes.string,
    disabledConfirmButton: PropTypes.bool,
};

export default DealManageModal;