import axios from 'axios';
import { traceError } from './common/response-handlers';

import FormDataUtil from 'utils/form-data.util';

function ContactsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getAll = () => axios
        .get(`/contacts`, config())
        .catch(error => traceError(error, `/contacts`, 'GET'));

    const post = ({ firstName, lastName, email, phone, jobTitle, companyName, sourceId, dealIds }) => axios
        .post(`/contacts`, { firstName, lastName, email, phone, jobTitle, companyName, sourceId, dealIds }, config())
        .catch(error => traceError(error, `/contacts`, 'POST'));

    const put = ({ id, firstName, lastName, email, phone, jobTitle, companyName }) => axios
        .put(`/contacts/${id}`, { firstName, lastName, email, phone, jobTitle, companyName }, config())
        .catch(error => traceError(error, `/contacts/${id}`, 'PUT'));

    const patchNote = (id, data) => axios
        .patch(`/contacts/${id}/notes`, data, config())
        .catch(error => traceError(error, `/contacts/${id}/notes`, 'PATCH'));

    const postPreviewDocument = (file) => axios
        .post(`/contacts/import/preview`, FormDataUtil().convert({ file }), config())
        .catch(error => traceError(error, `/contacts/import/preview`, 'POST'));

    const postImportDocument = ({ file, headersMap }) => axios
        .post('/contacts/import', FormDataUtil().convert({ file, headersMap }), config())
        .catch(error => traceError(error, `/contacts/import`, 'POST'));

    return {
        init,
        dispose,
        getAll,
        post,
        put,
        patchNote,
        postPreviewDocument,
        postImportDocument
    };
}

export default ContactsService;