import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getDateDifference } from 'helpers/Time';
import { PROFILE_COMPLETED_DATE } from 'hooks/useLocalStorage';

import Pie from 'components/atoms/Pie';

const CompletenessTile = ({
    emailVerified,
    pfaAccepted,
    fundsAvailable,
    criteriaExists,
    companyVerified,
    criteriaAction,
    pfaAction,
    fundsAction,
    emailAction }) => {
    const history = useHistory();

    const [percentage, setPercentage] = useState(0);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const completedDate = localStorage.getItem(PROFILE_COMPLETED_DATE);

        if (completedDate) {
            const daysDifference = getDateDifference(completedDate, new Date().getTime())?.days;
            if (daysDifference >= 2)
                return;
        }

        const completness = [emailVerified, pfaAccepted, fundsAvailable, criteriaExists, companyVerified];
        const completed = completness.filter(value => value === true).length;

        setPercentage(completed * (100 / completness.length));

        if (completed === completness.length && !completedDate)
            localStorage.setItem(PROFILE_COMPLETED_DATE, new Date().getTime());

        setShow(true);

    }, [emailVerified, pfaAccepted, fundsAvailable, criteriaExists, companyVerified]);

    return (
        <>
            {show &&
                <div className="border-grey-200 border-radius py-2 py-lg-4 mx-xl-2">
                    <div className="d-flex flex-column justify-content-center px-lg-4 mx-xl-2 align-items-center">
                        <div className="mb-3">
                            <Pie percentOfCompleteness={percentage} />
                        </div>

                        <h4 className="c-grey-700 ff-i700 mb-2">Profile Strength</h4>
                        <p className="text-s text-center c-grey-550 px-2 px-lg-0">
                            The strength of your profile determines how well we are able to find the right deals for you. Completing parts of your profile will increase the strength.
                        </p>
                    </div>

                    <div className="border-top-grey-150 py-3">
                        <div className="px-lg-4 px-3 ">
                            <p className="text-s c-grey-550">Profile Checklist</p>
                            <div className="d-flex align-items-center pb-4">
                                <input type="checkbox" checked={pfaAccepted} readOnly />
                                {
                                    !pfaAccepted
                                        ? <span
                                            className="c-blue-500 ff-i600 ps-4 cursor-pointer c-blue-600-hover"
                                            onClick={() => pfaAction()}>Buyer platform fee agreement</span>
                                        : <span className="c-grey-700 ff-i600 ps-4">Buyer platform fee agreement</span>
                                }
                            </div>
                            <div className="d-flex align-items-center pb-4">
                                <input type="checkbox" checked={fundsAvailable} readOnly />
                                {
                                    !fundsAvailable
                                        ? <span
                                            className="c-blue-500 ff-i600 ps-4 cursor-pointer c-blue-600-hover"
                                            onClick={() => fundsAction()}>Financial background</span>
                                        : <span className="c-grey-700 ff-i600 ps-4">Financial background</span>
                                }
                            </div>
                            <div className="d-flex align-items-center pb-4">
                                <input type="checkbox" checked={criteriaExists} readOnly />
                                {
                                    !criteriaExists
                                        ? <span
                                            className="c-blue-500 ff-i600 ps-4 cursor-pointer c-blue-600-hover"
                                            onClick={() => criteriaAction()}>Buyer criteria</span>
                                        : <span className="c-grey-700 ff-i600 ps-4">Buyer criteria</span>
                                }
                            </div>
                            <div className="d-flex align-items-center pb-4">
                                <input type="checkbox" checked={companyVerified} readOnly />
                                {
                                    !companyVerified
                                        ? <span
                                            className="c-blue-500 ff-i600 ps-4 cursor-pointer c-blue-600-hover"
                                            onClick={() => history.push('/buyer-dashboard/profile')}>Company profile</span>
                                        : <span className="c-grey-700 ff-i600 ps-4">Company profile</span>
                                }
                            </div>
                            <div className="d-flex align-items-center">
                                <input type="checkbox" checked={emailVerified} readOnly />
                                {
                                    !emailVerified
                                        ? <span
                                            className="c-blue-500 ff-i600 ps-4 cursor-pointer c-blue-600-hover"
                                            onClick={() => emailAction()}>Email verification</span>
                                        : <span className="c-grey-700 ff-i600 ps-4">Email verification</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default CompletenessTile;