import { useState, useEffect } from 'react';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import Paginate from 'components/atoms/paginate/Paginate';


// params:
// columns: {
//     title: string - name of column (optional)
//     key: string - item data name (required)
//     type: strng || JSX.element - text | currency | component - type of cell content  (required)
//     grid: string - custom grid for column (optional)
//     component: JSX.element(optional)
//     emptyState: string - empty cell content (optional)
//     options: {
//         head: string - for now it's info bage header (optional)
//         cell: string - for now it's info bage cell (optional)
//      }
//  data: array of table data
// }

const List = ({ columns, data, paginate = false, itemsPerPage = 10, hasHorizontalScrollbar = false }) => {
    const [pagesNumber, setPagesNumber] = useState([1]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [showSearchKey, setShowSearchKey] = useState(null);
    const [openedDropDown, setOpenedDropDown] = useState(false);

    useEffect(() => {
        setPagesNumber(
            Array(Math.ceil(data.length / itemsPerPage))
                .fill(null)
                .map((_, i) => i + 1)
        );
    }, [data, itemsPerPage]);

    useEffect(() => {
        setCurrentPage(1)
    }, [data]);

    useEffect(() => {
        setPaginatedData(data.slice(currentPage * itemsPerPage - itemsPerPage, itemsPerPage * currentPage));
    }, [pagesNumber, currentPage, itemsPerPage, data]);

    const renderItem = ({ item, type, data, component, emptyState, info, index }) => {
        if (type === 'currency')
            return (
                <span className="text-xm">
                    {(item && `$${new Intl.NumberFormat('en-US').format(item)}`) || (
                        <span className="c-grey-450 ">{emptyState}</span>
                    )}
                </span>
            );
        if (type === 'text') {
            if (info && !item)
                return (
                    <div className="d-flex align-items-center text-xm">
                        <div className="">{item ? item : <div className="c-grey-450">{emptyState}</div>}</div>
                        <div
                            className="icon icon-info-purple cursor-pointer ms-2"
                            onClick={() => {
                                alert('available in future');
                            }}
                        ></div>
                    </div>
                );
            return (
                <>
                    {item
                        ? (<div className="w-break text-xm">{item}</div>)
                        : (<div className="c-grey-450 text-xm">{emptyState}</div>)
                    }
                </>
            );
        }
        if (type === 'component') return <>{item ? component(item, data, index) : component(emptyState, data, index)}</>;
    };

    const renderHeader = ({ title, info, filter, key }) => {
        if (info?.type === 'info')
            return (
                <div className="d-flex align-items-center">
                    <div className="">{title}</div>
                    <span
                        className="ms-2"
                        data-tooltip={info?.text || ''}
                        data-tooltip-violet>
                        <span className="icon icon-info-purple" />
                    </span>
                </div>
            );
        if (info?.type === 'checkAll') {
            return (
                <div className="d-flex align-items-center">
                    <input
                        type="checkbox"
                        className={`col-1 me-3 ${info.selected.length !== info.data.length ? 'unfold' : ''}`}
                        checked={info.selected.length > 0}
                        onChange={info.action}
                    />
                    <div className="">{title}</div>
                </div>
            );
        }
        if (filter?.type === 'sort') {
            return (
                <div
                    className={`d-flex align-items-center cursor-pointer `}
                    onClick={e => {
                        filter.onChange(!filter.value);
                    }}
                >
                    <div
                        className={`${filter.value ? 'bc-blue-200' : ''
                            }  d-flex align-items-center px-1 px-xl-2 py-2 ms-n1 border-radius`}
                    >
                        <div className={`me-1 text-nowrap text-xm  ${filter.value ? ' c-blue-500' : 'c-grey-550'}`}>
                            {title}
                        </div>
                        <span
                            className={`icon icon-${filter.value ? 'sort-blue' : 'sort'}`}
                            style={{ width: 18, height: 18 }}
                        ></span>
                    </div>
                </div>
            );
        }
        if (filter?.type === 'search') {
            return (
                <div
                    className={`d-flex align-items-center cursor-pointer`}
                    onClick={e => {
                        if (showSearchKey) return;
                        setShowSearchKey(filter.id);
                    }}
                    onBlur={() => {
                        setShowSearchKey(null);
                    }}
                >
                    <div
                        className={`${filter.value.length ? 'bc-blue-200' : ''
                            }  d-flex align-items-center px-1 px-xl-2 py-2 ms-n1 border-radius`}
                    >
                        <div
                            className={`me-1 text-nowrap text-xm  ${filter.value.length || showSearchKey === filter.id ? ' c-blue-500' : 'c-grey-550'
                                }`}
                            onClick={e => {
                                e.stopPropagation();
                                if (showSearchKey) return;
                                setShowSearchKey(filter.id);
                            }}
                        >
                            {title}
                        </div>
                        <span
                            onClick={e => {
                                filter.value.length && e.stopPropagation();
                                filter.onChange('');
                            }}
                            className={`icon icon-${filter.value.length
                                ? 'cross-blue'
                                : showSearchKey === filter.id
                                    ? 'search-blue'
                                    : 'search-grey'
                                }`}
                            style={{ width: 18, height: 18 }}
                        ></span>
                    </div>
                    {showSearchKey === key && (
                        <div className="position-absolute" style={{ width: '335px', height: '40px', top: '80% ' }}>
                            <div className="position-relative"></div>
                            <input
                                id={filter.id}
                                autoFocus={true}
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                                type="text"
                                className="position-absolute  start-o  py-2 px-3"
                                style={{ width: '335px' }}
                                onChange={e => filter.onChange(e.target.value)}
                                value={filter.value}
                                placeholder={filter.placeholder}
                            />
                            <span
                                className="icon icon-search-input position-absolute"
                                style={{ top: '0', right: '0', height: 42 }}
                            ></span>
                        </div>
                    )}
                </div>
            );
        }
        if (filter?.type === 'filter') {
            return (
                <div className={`d-flex align-items-center cursor-pointer ms-n1 `} onClick={e => { }}>
                    <div className={`${filter.selected ? 'bc-blue-200' : ''}  d-flex align-items-center border-radius`}>
                        <DropdownButton
                            id="select-dropdown"
                            title={
                                <span
                                    className={`ff-i500 d-flex justify-content-between align-items-center ${filter.selected ? 'bc-blue-200' : 'bc-grey-90'}`}>
                                    <span className={`ff-i500 text-xm  ${openedDropDown || filter.selected ? 'c-blue-500' : 'c-grey-550'}`}>
                                        {title}
                                    </span>
                                    <span className={`ms-1 icon icon-${filter.selected ? 'cross-blue' : openedDropDown ? 'filter-list-blue' : 'filter-list'} `}
                                        style={{ width: '15px', height: '15px' }}
                                        onClick={e => {
                                            openedDropDown && e.stopPropagation();
                                            filter.onChange(null);
                                        }}></span>
                                </span>}
                            onSelect={val => {
                                filter.onChange(val);
                            }}
                            onClick={() => setOpenedDropDown(!openedDropDown)}
                            onBlur={() => setOpenedDropDown(false)}
                            className={`d-flex align-items-center ${filter.selected ? 'bc-blue-200' : ''} px-1 p-xl-2 py-2 `}>
                            {filter.options.map((option, idx) => {
                                return (
                                    <Dropdown.Item eventKey={option} key={idx} className="p-0">
                                        <div className={`${option === filter.selected ? 'bc-blue-500 c-white' : ''} w-100 px-3 py-2`} >
                                            {option}
                                        </div>
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>
            );
        }
        return <>{title}</>;
    };


    return (
        <div className="border-radius-top d-none d-lg-block">
            <table className={"w-100 border-radius-top " + (hasHorizontalScrollbar ? 'd-xxl-table d-block' : '')}
                style={hasHorizontalScrollbar
                    ? { overflowX: 'auto', overflowY: 'hidden' }
                    : {}}>
                <thead className="c-grey-550  bc-grey-90 position-relative">
                    <tr className="border-radius-top">
                        {columns?.map(({ title, info, grid, filter, key }, idx) => (
                            <th className={` ff-i500 c-grey-550  ${grid ? grid : ''}`} key={idx}>
                                {renderHeader({ title, info: info?.head, filter, key })}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="">
                    {paginatedData?.map((item, rowIdx) => (
                        <tr className="border-top-grey-150 " key={rowIdx}>
                            {columns?.map(({ grid, key, component, type, emptyState, info }, idx) => {
                                return (
                                    <td className={`${grid ? grid : ''} ff-i500 c-grey-700 `} key={idx}>
                                        {renderItem({
                                            item: item[key],
                                            type,
                                            data: item.supportData,
                                            component,
                                            emptyState,
                                            info: info?.cell,
                                            index: rowIdx
                                        })}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
            {pagesNumber.length > 1 && paginate && (
                <div className='mt-2'>
                    <Paginate totalItems={data.length} itemsPerPage={itemsPerPage} onChangePage={setCurrentPage} currentPage={currentPage} />
                </div>
            )}
        </div>
    );
};

export default List;