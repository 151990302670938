const getLeadsOptions = leads => {
    return leads
        .map((lead) => {
            return {
                id: lead.id,
                label: lead.fullName || lead.email,
                dealIds: lead.dealIds
            };
        });
};

export default getLeadsOptions;