const formatMessageForMeeting = ({dateTimeFormatOptionsHourMinutes, startTime, endTime}) => {
    let message = `Date: ${new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit'
    }).format(endTime)}\n`;
    message += `Time: ${new Intl.DateTimeFormat('en-US', dateTimeFormatOptionsHourMinutes).format(startTime)}`;
    message += ` - ${new Intl.DateTimeFormat('en-US', dateTimeFormatOptionsHourMinutes).format(endTime)} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;
    return message
}
export default formatMessageForMeeting