import dayjs from 'dayjs';

const NoteView = ({ handleOnClickAddNote, note, handleDelete, handleEdit, createdDateNote, emptyStateText }) => {
    if(note) {
        return (
            <div>
                <p>{note}</p>
                <span className="c-grey-450 text-s">Edited: {dayjs(createdDateNote).format('MM/DD/YYYY')}</span>
                <div className="row justify-content-center mt-4">
                    <div className="col-6 col-md-5">
                        <button
                            onClick={handleEdit}
                            className="d-flex border-radius justify-content-center bc-inherit border-blue-600 align-items-center c-blue-600 w-100 py-2"
                        >
                            <span className="icon icon-pencil-primary me-2" />
                            Edit Note
                        </button>
                    </div>
                    <div className="col-6 col-md-5">
                        <button
                            className="d-flex border-radius justify-content-center bc-inherit border-red align-items-center c-red-300 w-100 py-2"
                            onClick={handleDelete}
                        >
                            <span className="icon icon-delete-red me-2" />
                            Remove Note
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="text-center c-grey-700">
            <span className="icon icon-document-with-search"/>
            <span className="d-block">{emptyStateText}</span>
            <span className="d-block">Add them below, all notes will be visible only for you.</span>
            <div className="d-flex justify-content-center mt-3">
                <button
                    className="bc-inherit px-3 py-2 border-radius d-flex align-items-center border-blue-600 c-blue-500"
                    onClick={handleOnClickAddNote}
                >
                    <span className="icon icon-document-with-plus-primary me-2"/>
                    Add Note
                </button>
            </div>
        </div>
    );
};

export default NoteView;
