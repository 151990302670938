import { useContext } from 'react';
import ToastContext from 'contexts/ToastContext';
import { ToasterType } from 'models/ToasterType';

const INFO_TITLE = 'Oops. Something went wrong:';
const ERROR_TITLE = 'Oops. Something went wrong:';
const WARNING_TITLE = 'Oops. Something went wrong:'
const SUCCESS_TITLE = 'Operation successful.';

const useToast = () => {
    const { addToast } = useContext(ToastContext);

    let toast = {};

    const showSuccess = ({ title = SUCCESS_TITLE, body }) =>
        addToast({
            type: ToasterType.Success,
            title: title,
            body: body
        });

    const showWarning = ({ title = WARNING_TITLE, body }) =>
        addToast({
            type: ToasterType.Warning,
            title: title,
            body: body
        });

    const showError = ({ title = ERROR_TITLE, body }) =>
        addToast({
            type: ToasterType.Error,
            title: title,
            body: body
        });

    const showInfo = ({ title = INFO_TITLE, body }) =>
        addToast({
            type: ToasterType.Info,
            title: title,
            body: body
        });

    const handleError = (error) => {
        if (!error || !error.response) return;
        const status = error.response.status;
        if (status === 400 || status === 401 || status === 408 || status === 409) showWarning({ body: error.response.data?.title })
        if (status === 422) showError({ body: error.response.data?.title });
    };

    toast.success = showSuccess;
    toast.warning = showWarning;
    toast.error = showError;
    toast.handleError = handleError;
    toast.info = showInfo;

    return { toast };
};

export default useToast;