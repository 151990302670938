import { useHistory } from 'react-router-dom';

// import NotificationsService from 'services/notifications-service';

import Tooltip from 'components/atoms/Tooltip';
// const notificationsService = NotificationsService();

const ChatHeader = ({
    userId,
    sellerView,
    setCurrentChannelUrl,
    toggleChannelSettings,
    selectedChannel,
    showChannelSettings,
    manageLeadEducation,
    is1KTeamChat,
    dealName
}) => {
    const FAQS_PATH = '/help-center';
    const history = useHistory();

    return (
        <Tooltip
            show={manageLeadEducation?.show}
            content="Can’t remember a thousand details at once? Don’t worry, you don’t have to. In the chat info panel, quickly get more information about who you’re chatting with, add notes for your internal use, and see a list of documents you’ve shared. You can also pin important links and documents in case the conversation goes on for a while."
            secondaryContent={`Step 3/${manageLeadEducation?.total}`}
            direction="bottom-right"
            additionalClasses="arrow-right z-index-1000"
            ctaText="Got it!"
            ctaAction={() => { manageLeadEducation?.moveNext(3) }}>
            {!is1KTeamChat && (
                <div className="ms-lg-1 ps-4 py-3 border-bottom-grey-200 d-flex align-items-center position-relative">
                    <span
                        className="pe-3 d-inline d-lg-none cursor-pointer"
                        onClick={() => setCurrentChannelUrl(undefined)}>
                        &#8592;
                    </span>
                    <div className="w-100">
                        <h4 className="text-center c-grey-700 ff-i600 w-100 word-break">
                            {selectedChannel?.contact?.fullName || selectedChannel?.contact?.email}
                        </h4>
                        <div className="text-center c-blue-600">
                            <span
                                onClick={() => {
                                    if (sellerView)
                                        return history.push(
                                            `/seller-dashboard/deals/${selectedChannel?.metadata.listingId}`
                                        );
                                    return history.push(
                                        `/buyer-dashboard/deals/${selectedChannel?.metadata.listingId}`
                                    );
                                }}
                                className="cursor-pointer text-m"
                            >
                                Deal #{selectedChannel?.metadata.listingId}
                            </span>
                        </div>
                    </div>
                    {!showChannelSettings && (
                        <button
                            className={`position-absolute end-0 btn-mdm ff-i600 d-none d-lg-block ms-auto me-5 ${showChannelSettings ? 'btn-white' : 'btn-regular'
                                }`}
                            onClick={toggleChannelSettings}
                        >
                            {sellerView ? "Manage Lead" : "Manage Deal"}
                        </button>
                    )}
                    <img
                        className="d-lg-none ms-auto me-3"
                        onClick={toggleChannelSettings}
                        src={`${process.env.PUBLIC_URL}/images/chats/show-deal-items.svg`}
                        alt="deal-items"
                    />
                </div>
            )}
            {is1KTeamChat && (
                <div className="d-flex flex-column p-4 justify-content-center text-center w-100 pt-2 pb-1 border-bottom-grey-200 position-relative">
                    <span
                        className="pe-3 d-inline d-lg-none cursor-pointer position-absolute"
                        onClick={() => setCurrentChannelUrl(undefined)}
                    >
                        &#8592;
                    </span>
                    <span className="ff-i500 c-grey-550 text-xl">
                        {userId === process.env.REACT_APP_SEND_BIRD_SYSTEM_USER_ID ? selectedChannel?.members?.find(member => member.user_id !== userId)?.nickname : '1Konnection'}
                    </span>
                    <span className="ff-i500 c-blue-500 cursor-pointer" style={{ fontSize: '18px' }} onClick={() => history.push(FAQS_PATH)}>Help</span>
                </div>
            )}
        </Tooltip>
    );
};

//const fs18 = { fontSize: '18px' };

export default ChatHeader;
