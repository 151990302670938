const setGeneralStyles = type => {
    switch (type) {
        case 'CRITICAL':
            return 'bc-red-500 c-white';
        case 'ERROR':
            return 'bc-red-100 border-dashed-red c-red-500';
        case 'WARNING':
            return 'bc-yellow-25 border-dashed-yellow c-yellow-500';
        case 'SUCCESS':
            return 'bc-green-25 border-dashed-green c-green-500';
        case 'INFORMATION':
            return 'border-dashed-grey';
        case 'PROMOTIONAL':
            return 'bc-blue-500 c-white';
        default:
            return 'bc-inherit';
    }
};

export default setGeneralStyles;
