import { useState } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';

const hoursOptions = [
    { label: "15 minutes", value: 15, icon: "icon-clock" },
    { label: "30 minutes", value: 30, icon: "icon-clock-30" },
    { label: "45 minutes", value: 45, icon: "icon-clock-45" },
    { label: "Other", value: null, icon: "icon-clock-60" },
];

const selectOptions = [
    { label: "90 minutes", value: 90 },
    { label: "2 hours", value: 120 },
    { label: "4 hours", value: 240 },
    { label: "8 hours", value: 480 },
];

const ChooseMeetingModalContent = ({ setMeetingLength }) => {
    const [isVisibleSelect, setIsVisibleSelect] = useState(false);
    const [valueSelect, setValueSelect] = useState("30 minutes");
    return (
        <div className="row">
            {hoursOptions.map((option) => {
                return (
                    <div key={option.value} className="col-12 col-lg-6 mb-3 mb-lg-4">
                        <div className="d-flex px-3 px-md-5 align-items-center justify-content-start radio-btn border-radius mx-auto"
                            onClick={() => {
                                setValueSelect(option.label);
                                if (!option.value) {
                                    setMeetingLength(0);
                                    return setIsVisibleSelect(true);
                                }
                                setIsVisibleSelect(false);
                                return setMeetingLength(option.value)
                            }}>
                            <input
                                id={`choose-meeting-length-${option.label}`}
                                type="radio"
                                className="me-2 cursor-pointer"
                                name={option.label}
                                onChange={() => {
                                    if (!option.value) {
                                        setMeetingLength(0);
                                        return setIsVisibleSelect(true);
                                    }
                                    setIsVisibleSelect(false);
                                    return setMeetingLength(option.value)
                                }}
                                checked={valueSelect === option.label}
                            />
                            <span className={`icon ${option.icon} ms-2`}></span>
                            <label htmlFor={`choose-meeting-length-${option.label}`} className="ms-1 cursor-pointer">{option.label}</label>
                        </div>
                    </div>
                )
            })
            }
            {isVisibleSelect && (
                <div className="col-12 d-flex justify-content-center">
                    <div className="w-100 px-lg-5">
                        <Typeahead
                            id='basic-typeahead-single'
                            onChange={(val) => setMeetingLength(val[0]?.value)}
                            options={selectOptions}
                            className={`dropdown w-100 my-2`}
                            labelKey='label'
                            placeholder={'Other event duration'}>
                            <span className="icon icon-arrow-right-grey icon-static img-rotate-90 pe-none" />
                        </Typeahead>
                    </div>
                </div>
            )
            }
        </div>
    );
};

ChooseMeetingModalContent.propTypes = {
    setMeetingLength: PropTypes.func.isRequired
}

export default ChooseMeetingModalContent;