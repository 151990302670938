import axios from 'axios';
import { traceError } from './common/response-handlers';

function AdminService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; }
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getUsersEmails = (emailTerm, isVerifiedNull) => {
        const queryParam = `${isVerifiedNull ? '&verified=' : ''}`
        return axios
            .get(`/admin/users/emails?emailTerm=${emailTerm}${queryParam}`, config())
            .catch(error => traceError(error, `/admin/users/emails?emailTerm=${emailTerm}`, 'GET'));
    }

    const postUserEmailVerification = emailId => axios
        .post(`/admin/users/emails/${emailId}/verification`, null, config())
        .catch(error => traceError(error, `/admin/users/emails/${emailId}/verification`, 'POST'));

    const getUsersWaitingVerification = stateId => axios
        .get(`/admin/verification/users?state=${stateId}`, config())
        .catch(error => traceError(error, '/admin/verification/users', 'GET'));

    const getUserWaitingVerification = userId => axios
        .get(`/admin/verification/users/${userId}`, config())
        .catch(error => traceError(error, `/admin/verification/users/${userId}`, 'GET'));

    const postUserVerification = (userId, verified, postpone) => axios
        .post('/admin/verification/users', { userId, verified, postpone }, config())
        .catch(error => traceError(error, '/admin/verification/users', 'POST'));

    return {
        init,
        dispose,
        getUsersEmails,
        postUserEmailVerification,
        getUsersWaitingVerification,
        getUserWaitingVerification,
        postUserVerification
    };
}

export default AdminService;