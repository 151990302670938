import { useState, useEffect } from 'react';
import useToast from 'hooks/useToast';
import UsersService from 'services/users-service';
import EmailInput from 'components/atoms/EmailInput';
import useLoader from 'hooks/useLoader';

const usersService = UsersService();

const UserMetadata = () => {
    useEffect(() => {
        usersService.init();

        return () => usersService.dispose();
    }, []);

    const { load } = useLoader();
    const { toast } = useToast();
    const [userId, setUserId] = useState(0);
    const [userEmail, setUserEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [userMetadata, setUserMetadata] = useState(null);
    const [noEmails, setNoEmails] = useState(false);
    const [isShadow, setIsShadow] = useState(false);
    const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);
    const [dealsLimit, setDealsLimit] = useState(0);

    const onSearchClick = e => {
        e.target.disabled = true;
        load(() =>
            usersService
                .getMetadata(userEmail)
                .then(response => {
                    setUserId(response?.data?.userId)
                    setNoEmails(response?.data?.userMetadata?.noEmails);
                    setIsShadow(response?.data?.userMetadata?.isShadow);
                    setIsAgreementAccepted(response?.data?.userMetadata?.agreementAccepted);
                    setDealsLimit(response?.data?.userMetadata?.dealsLimit);

                    setUserMetadata({
                        noEmails: response?.data?.userMetadata?.noEmails,
                        isShadow: response?.data?.userMetadata?.isShadow,
                        isAgreementAccepted: response?.data?.userMetadata?.agreementAccepted,
                        dealsLimit: response?.data?.userMetadata?.dealsLimit
                    });
                })
                .catch(error => {
                    toast.handleError(error);
                    setUserMetadata(null);
                })
                .finally(e.target.disabled = false)
        );
    }

    const onNoEmailChange = e => setNoEmails(e);

    const onIsShadowChange = e => setIsShadow(e);

    const onIsAgreementAcceptedChange = e => setIsAgreementAccepted(e);

    const onDealsLimitChange = (newLimit) => {
        const regexp = /^[0-9]+$/;

        let value = (newLimit + '').replace(/,/g, '');

        if (!regexp.test(value)) {
            setDealsLimit(0);
            return;
        }

        setDealsLimit(+value);
    };

    const onUpdateClick = e => {
        e.target.disabled = true;
        load(() =>
            Promise.all([
                usersService.patchMetadata(userId, { noEmails, isShadow, dealsLimit }),
                usersService.patchAgreementAccepted({ agreementAccepted: isAgreementAccepted })
            ])
                .then(() => {
                    setUserMetadata({
                        noEmails: noEmails,
                        isShadow: isShadow,
                        isAgreementAccepted: isAgreementAccepted,
                        dealsLimit: dealsLimit
                    });
                    toast.success({ body: `Metadata for the user - ${userEmail} has been updated.` });
                })
                .catch(error => toast.handleError(error))
                .finally(e.target.disabled = false)
        );
    }

    return (
        <div className="p-3">
            <div className="row gx-3">
                <div className="col-12">
                    <div className="px-3 px-lg-5 py-4">
                        <h4 className="c-grey-700">User Metadata</h4>
                        <p className="c-grey-550 ff-i600 pb-3">
                            Enter user e-mail to get the information.
                        </p>
                        <div className="row">
                            <div className="col-12 col-lg-8">
                                <EmailInput
                                    email={userEmail}
                                    setEmail={setUserEmail}
                                    placeholder="Enter user email"
                                    isValid={setIsValidEmail} />
                            </div>
                            <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                <button
                                    className="btn-secondary blue ff-i600 w-100 py-3"
                                    disabled={!isValidEmail}
                                    onClick={e => onSearchClick(e)}>
                                    Search
                                </button>
                            </div>

                            {userMetadata &&
                                <div className="col-12 mt-4">
                                    <p className="c-grey-550 ff-i600">
                                        Click on the toggle to change the property.
                                    </p>
                                    <div className="d-lg-flex align-items-center pt-3">
                                        <div className="col-12 col-lg-8">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <span className="col-5 c-grey-550 ff-i600 text-m">&bull; No Emails</span>
                                                <span className="d-flex align-items-center">
                                                    <label className="custom-switch ms-3">
                                                        <input
                                                            onChange={e => onNoEmailChange(e.target.checked)}
                                                            type="checkbox"
                                                            checked={noEmails} />
                                                        <span className="slider"></span>
                                                    </label>
                                                    {noEmails
                                                        ? <span className="c-green-500 ms-2">ON</span>
                                                        : <span className="c-red ms-2">OFF</span>
                                                    }
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-start align-items-center mt-3">
                                                <span className="col-5 c-grey-550 ff-i600 text-m">&bull; Is Shadow</span>
                                                <span className="d-flex align-items-center">
                                                    <label className="custom-switch ms-3">
                                                        <input
                                                            onChange={e => onIsShadowChange(e.target.checked)}
                                                            type="checkbox"
                                                            checked={isShadow}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                    {isShadow
                                                        ? <span className="c-green-500 ms-2">ON</span>
                                                        : <span className="c-red ms-2">OFF</span>
                                                    }
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-start align-items-center mt-3">
                                                <span className="col-5 c-grey-550 ff-i600 text-m">&bull; Agreement Accepted</span>
                                                <span className="d-flex align-items-center">
                                                    <label className="custom-switch ms-3">
                                                        <input
                                                            onChange={e => onIsAgreementAcceptedChange(e.target.checked)}
                                                            type="checkbox"
                                                            checked={isAgreementAccepted}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                    {isAgreementAccepted
                                                        ? <span className="c-green-500 ms-2">ON</span>
                                                        : <span className="c-red ms-2">OFF</span>
                                                    }
                                                </span>
                                            </div>

                                            <div className="row d-flex justify-content-start align-items-center mt-3">
                                                <span className="col-5 c-grey-550 ff-i600 text-m">&bull; Deals Limit</span>
                                                <div className="col-lg-6 col-12">
                                                    <input
                                                        className="w-100"
                                                        onChange={e => onDealsLimitChange(e.target.value)}
                                                        value={dealsLimit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <button
                                                className="btn-primary w-100 mt-3 mt-lg-0"
                                                onClick={e => onUpdateClick(e)}
                                                disabled={
                                                    userMetadata?.noEmails === noEmails
                                                    && userMetadata?.isShadow === isShadow
                                                    && userMetadata?.isAgreementAccepted === isAgreementAccepted
                                                    && userMetadata?.dealsLimit === dealsLimit
                                                }>
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserMetadata;