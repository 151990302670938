import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { sendBirdSelectors, useSendbirdStateContext } from 'sendbird-uikit';

import useQueryParams from 'hooks/useQueryParams';

import CalendarsService from 'services/calendars-service';
import ChatService from 'services/chat-service';
import AuthHandler from 'services/common/auth-handler';

import ConfirmModal from 'components/atoms/ConfirmModal';
import ChooseMeetingModalContent from 'components/atoms/ChooseMeetingModalContent';
import ChooseMeetingDatetimeModal from 'components/organisms/ChooseMeetingDatetimeModal';
import SetLocationForMeetingModal from 'components/atoms/SetLocationForMeetingModal';
import establishEndTimeOfMeeting from 'utils/establish-end-time-of-meeting';
import formatMessageForMeeting from 'utils/format-message-for-meeting';
import { SendBirdConsts } from 'models/SendBirdConsts';
import useToast from 'hooks/useToast';
import useLoader from 'hooks/useLoader';

const calendarsService = CalendarsService();
const authHandler = AuthHandler();
const chatService = ChatService();

const dateTimeFormatOptionsHourMinutes = { hour: 'numeric', minute: 'numeric' };

const MessageInput = ({
    userId,
    channel,
    selectedChannel,
    disabled,
    setShowChannelSettings,
    showChannelSettings,
    is1KTeamChat,
    onMsgSentCallback,
    sellerView }) => {
    const sendbirdContext = useSendbirdStateContext();
    const sendUserMessage = sendBirdSelectors.getSendUserMessage(sendbirdContext);
    const sb = sendBirdSelectors.getSdk(sendbirdContext);

    const { load } = useLoader();
    const { toast } = useToast();
    const query = useQueryParams();
    const history = useHistory();
    const [sellerCalendarConnected, setSellerCalendarConnected] = useState(false);
    const [sellerCalendarStopped, setSellerCalendarStopped] = useState(false);

    const [showChooseMeetingLengthModal, setShowChooseMeetingLengthModal] = useState(false);
    const [showChooseMeetingDatetimeModal, setShowChooseMeetingDatetimeModal] = useState(false);
    const [meetingLength, setMeetingLength] = useState(0);
    const [meetingDatetime, setMeetingDatetime] = useState(new Date().toJSON().slice(0, 10));
    const [showChooseMeetingLocationModal, setShowChooseMeetingLocationModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [location, setLocation] = useState('');
    const [phone, setPhone] = useState('');

    const [inputText, setInputText] = useState("");

    const [isOpenMenu, setIsOpenMenu] = useState(null);

    useEffect(() => {
        calendarsService.init();
        chatService.init();

        setCurrentUser(authHandler.getUser());

        return () => {
            calendarsService.dispose();
            chatService.dispose();
        }
    }, []);

    useEffect(() => {
        setInputText('');
    }, [channel]);

    useEffect(() => {
        if (query.get('meeting')) {
            setShowChooseMeetingLengthModal(true);
            query.delete('meeting');
            history.replace({
                search: query.toString(),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        if (sellerView)
            calendarsService
                .getAccount()
                .then(response => {
                    setSellerCalendarStopped(response?.data?.account?.isStopped);
                    setSellerCalendarConnected(true);
                })
                .catch(error => {
                    toast.handleError(error);
                    setSellerCalendarConnected(false);
                });
        else calendarsService
            .getAccount(selectedChannel?.metadata?.sellerId)
            .then(response => {
                setSellerCalendarStopped(response?.data?.account?.isStopped);
                setSellerCalendarConnected(true);
            })
            .catch(error => {
                toast.handleError(error);
                setSellerCalendarConnected(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChannel])

    const onClickSellerIntegrateCalendar = () =>
        calendarsService
            .getConnection(`connector?redirectPath=seller-dashboard/chat`)
            .then(response => (window.location.href = response.data.connectionUrl))
            .catch(error => toast.handleError(error));

    const handleCreateEventWithLocation = () => {
        load(() =>
            calendarsService
                .createEvent(
                    +selectedChannel?.metadata?.listingId,
                    +selectedChannel?.metadata?.sellerId,
                    +userId,
                    selectedDate,
                    establishEndTimeOfMeeting({ meetingDatetime: selectedDate, meetingLength }),
                    location,
                    phone.toString()
                )
                .then(response => {
                    chatService
                        .postMessage(
                            selectedChannel?.channel_url,
                            formatMessageForMeeting({
                                dateTimeFormatOptionsHourMinutes,
                                startTime: selectedDate,
                                endTime: establishEndTimeOfMeeting({ meetingDatetime: selectedDate, meetingLength }),
                            }),
                            SendBirdConsts.MessageCustomTypeCalendarEvent,
                            response.data
                        )
                        .catch(error => toast.handleError(error));
                })
                .catch(error => {
                    toast.handleError(error);
                    setShowChooseMeetingLocationModal(false);
                })
                .finally(() => {
                    setShowChooseMeetingLocationModal(false);
                })
        );
    };

    const handleChange = (event) => setInputText(event.target.value);

    const onSendUserMessage = () => {
        const params = new sb.UserMessageParams();
        params.message = inputText;
        setInputText("");
        if (inputText.length){
            onMsgSentCallback();
            sendUserMessage(channel.url, params);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter')
            onSendUserMessage();
    };

    const addPadding =
        (!sellerView && !(!sellerCalendarConnected || sellerCalendarStopped))
        || (sellerView && (!sellerCalendarConnected || sellerCalendarStopped))

    return (
        <>
            <div className={`position-relative w-100`}>
                <div className="position-relative">
                    {!is1KTeamChat &&
                        <>
                            <div
                                className="position-absolute h-100 d-none d-lg-flex align-items-center justify-content-center cursor-pointer end-0"
                                style={{ paddingRight: 95 }}>
                                {sellerView && !sellerCalendarConnected &&
                                    <span className="ff-i700 c-grey-450 cursor-pointer"
                                        onClick={onClickSellerIntegrateCalendar}>Integrate calendar</span>
                                }
                                {!sellerView && sellerCalendarConnected && !sellerCalendarStopped &&
                                    <span className="ff-i700 c-grey-450 cursor-pointer"
                                        onClick={() => { setShowChooseMeetingLengthModal(true) }}>Schedule meeting</span>
                                }
                                {sellerView && sellerCalendarConnected && sellerCalendarStopped &&
                                    <span className="ff-i700 c-grey-450 cursor-pointer"
                                        onClick={onClickSellerIntegrateCalendar}>Reconnect calendar</span>
                                }
                            </div>
                            {addPadding &&
                                <div
                                    onClick={() => setIsOpenMenu(!isOpenMenu)}
                                    className="position-absolute h-100 d-flex d-lg-none align-items-center justify-content-center cursor-pointer end-0"
                                    style={{ paddingRight: 58 }}>
                                    <span className={`icon icon-burger-menu${!isOpenMenu ? ' icon-grayscale' : ''}`} style={{ width: 24 }}></span>
                                </div>
                            }
                            <div
                                onClick={() => setShowChannelSettings(true)}
                                className={`position-absolute h-100${!addPadding ? ' d-flex' : ' d-none d-lg-flex'} align-items-center justify-content-center cursor-pointer end-0`}
                                style={{ paddingRight: 58 }}>
                                <span className={`icon${showChannelSettings ? ' icon-attach-blue' : ' icon-attach'}`} style={{ width: 24 }} />
                            </div>
                        </>
                    }
                    <div
                        onClick={onSendUserMessage}
                        className={`position-absolute h-100 d-flex align-items-center justify-content-center cursor-pointer end-0`}
                        style={{ paddingRight: 18 }}>
                        <span className={`icon ${inputText ? 'icon-send-message-blue' : 'icon-send-message'}`} style={{ width: 22 }} />
                    </div>
                    <input
                        type="text"
                        maxLength={5000}
                        value={inputText}
                        disabled={disabled}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Write a message..."
                        className="w-100 d-block border-radius-0"
                        style={{ paddingRight: window.innerWidth > 992 && !is1KTeamChat && addPadding ? 260 : 100 }}
                    />
                </div>
            </div>

            {isOpenMenu !== null &&
                <div className="d-lg-none">
                    {isOpenMenu &&
                        <div
                            className='underlay'
                            onClick={() => setIsOpenMenu(false)} />
                    }
                    <div className={`mobile-sidebar ${isOpenMenu ? 'slide-in' : 'slide-out'}`}
                        style={{ height: 170 }}>
                        <h5 className="text-center c-grey-700 pt-3">More</h5>
                        <div className='px-5'>
                            <div className="ff-i500 c-grey-700 py-3 cursor-pointer"
                                onClick={() => { setShowChannelSettings(true); setIsOpenMenu(false); }}>
                                Attach Document
                            </div>
                            {sellerView && !sellerCalendarConnected &&
                                <div className="ff-i500 c-grey-700 py-3 cursor-pointer"
                                    onClick={() => { onClickSellerIntegrateCalendar(); setIsOpenMenu(false); }}>Integrate calendar</div>
                            }
                            {!sellerView && sellerCalendarConnected && !sellerCalendarStopped &&
                                <div className="ff-i500 c-grey-700 py-3 cursor-pointer"
                                    onClick={() => { setShowChooseMeetingLengthModal(true); setIsOpenMenu(false); }}>Schedule meeting</div>
                            }
                            {sellerView && sellerCalendarConnected && sellerCalendarStopped &&
                                <div className="ff-i500 c-grey-700 py-3 cursor-pointer"
                                    onClick={() => { onClickSellerIntegrateCalendar(); setIsOpenMenu(false); }}>Reconnect calendar</div>
                            }
                        </div>
                        <div
                            className='d-flex justify-content-center py-3'
                            onClick={() => setIsOpenMenu(false)}>
                            <div className='grey-line' />
                        </div>
                    </div>
                </div>
            }

            {showChooseMeetingLengthModal && (
                <ConfirmModal
                    show={showChooseMeetingLengthModal}
                    messageHeader="Choose your meeting length"
                    messageBody={<ChooseMeetingModalContent setMeetingLength={setMeetingLength} />}
                    confirmButtonText="Send"
                    rejectButtonText="Cancel"
                    handleConfirmClick={() => {
                        setShowChooseMeetingLengthModal(false);
                        setShowChooseMeetingDatetimeModal(true);
                        if (meetingLength === 0) setMeetingLength(30);
                    }}
                    handleClose={() => setShowChooseMeetingLengthModal(false)}
                />
            )}
            {showChooseMeetingDatetimeModal && (
                <ChooseMeetingDatetimeModal
                    show={showChooseMeetingDatetimeModal}
                    meetingLength={meetingLength}
                    meetingDatetime={meetingDatetime}
                    setMeetingDatetime={setMeetingDatetime}
                    handleClose={() => setShowChooseMeetingDatetimeModal(false)}
                    sellerId={selectedChannel?.metadata?.sellerId}
                    listingId={selectedChannel?.metadata?.listingId}
                    buyerId={userId}
                    channelUrl={selectedChannel?.channel_url}
                    setSelectedDate={setSelectedDate}
                    setShowChooseMeetingLocationModal={setShowChooseMeetingLocationModal}
                />
            )}
            {showChooseMeetingLocationModal && (
                <ConfirmModal
                    show={showChooseMeetingLocationModal}
                    handleConfirmClick={handleCreateEventWithLocation}
                    confirmButtonText={'Send'}
                    rejectButtonText={'Cancel'}
                    messageHeader={'Choose a date and time'}
                    messageBody={
                        <SetLocationForMeetingModal
                            meetingDatetime={selectedDate}
                            meetingLength={meetingLength}
                            dealMemberName={selectedChannel?.contact?.fullName}
                            dealNumber={selectedChannel?.metadata?.listingId}
                            currentUser={currentUser}
                            location={location}
                            setLocation={setLocation}
                            phone={phone}
                            setPhone={setPhone}
                        />
                    }
                    disableCta={!phone && !location}
                    handleClose={() => setShowChooseMeetingLocationModal(false)}
                />
            )}
        </>
    );
}

export default MessageInput;
