import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useQueryParams from 'hooks/useQueryParams';
import { SENT_EMAIL_VERIFICATION_KEY } from 'hooks/useLocalStorage';

import { trackPageview, trackSignUp, trackExistingUser } from 'services/common/analytic-trackers';
import AuthService from 'services/auth-service';
import AuthHandler from 'services/common/auth-handler';

const authService = AuthService();
const authHandler = AuthHandler();

const RegisterHandlerPage = () => {
    const history = useHistory();
    const query = useQueryParams();

    const firstName = query.get('firstName');
    const lastName = query.get('lastName');
    const email = query.get('email');
    const phoneNumber = query.get('phone');
    const type = query.get('type');
    const password = query.get('password');

    useEffect(() => {
        document.title = '1Konnection - Register Handler';
        trackPageview();

        if (!firstName || !lastName || !email || !phoneNumber || isNaN(parseInt(type))) {
            history.push('/');
            return;
        }

        if (authHandler.isAnonymous())
            authService
                .register({ firstName, lastName, email, phone: phoneNumber, type: +type, password: password })
                .then(_ => {
                    trackSignUp({ method: 'Marketing' });
                    localStorage.removeItem(SENT_EMAIL_VERIFICATION_KEY);
                    history.push('/secure/verify-email', { email: email });
                })
                .catch(error => {
                    if (error?.response?.status === 409) {
                        trackExistingUser({ method: 'Marketing' });
                        history.push('/secure');
                        return;
                    }

                    history.push('/');
                    return;
                });
        else {
            trackExistingUser({ method: 'Marketing' });
            const role = authHandler.getUser()?.identitytype;
            if (role === 'Broker - Buying') {
                window.location.href = 'https://1konnection.typeform.com/to/vgrfgndC';
                return;
            }
            const path = role === 'Buyer'
                ? '/buyer-dashboard'
                : '/seller-dashboard';

            history.push(path);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="loader">
            <img alt="loader" className="loader-image" src={`${process.env.PUBLIC_URL}/images/loader-double-ring.gif`} />
        </div>
    );
}

export default RegisterHandlerPage;