import { Link } from 'react-router-dom';
import UpcomingMeetings from "components/molecules/UpcomingMeetings";

const ScheduledEvents = ({ upcomingMeetings, redirectTo, title = "Upcoming Meetings", isCta = false }) => {
    return (
        <div>
            <div className="d-flex justify-content-between border-bottom">
                <h4 className="c-grey-700">{title}</h4>
                {(isCta && upcomingMeetings.length > 0) &&
                    <Link
                        className="text-decoration-none ff-i600 c-blue-500"
                        to={redirectTo}>
                        View All
                    </Link>
                }
            </div>
            <div className="py-3 custom-scrollbar max-h-40">
                {upcomingMeetings.map((meetings, index) =>
                    <UpcomingMeetings key={index} upcomingMeetings={meetings} />
                )}
                {upcomingMeetings.length === 0 &&
                    <h5 className="mt-4 pt-2 text-center ff-i600 c-grey-700 text-m mt-2">You don't have any upcoming meetings.</h5>
                }
            </div>
        </div>
    );
}

export default ScheduledEvents;