import { useEffect, useState } from 'react';

import ListingService from 'services/listing-service';
import SharedFiles from 'components/molecules/SharedFiles';
import SharedLinks from 'components/molecules/SharedLinks';
import useToast from 'hooks/useToast';

const listingService = ListingService();

const DealDocumentsLinks = ({
    dealId,
    channelUrl,
    isSeller,
    recipientId,
    dealFiles,
    receivedFiles,
    setDealFiles,
    sortFiles,
    sortLinks,
    dealLinks,
    setDealLinks,
    showDocument = true,
}) => {
    const { toast } = useToast();
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [uploadItemToDeal, setUploadItemToDeal] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const [disableShareButton, setDisableShareButton] = useState(false);
    const [disabledFileUpload, setDisabledFileUpload] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [isDocument, setIsDocument] = useState(showDocument);

    const [linkName, setLinkName] = useState('');
    const [linkUrl, setLinkUrl] = useState('');
    const [selectedLinks, setSelectedLinks] = useState([]);

    const [showPinnedMessage, setShowPinnedMessage] = useState(0);

    useEffect(() => {
        listingService.init();

        return () => listingService.dispose();
    }, []);

    const isDisableShareButton = (items, selectedItems) =>
        !!items.filter(item => selectedItems.includes(item.id) && item.sharedDate).length;

    const onShareButtonClick = () =>
        isDocument
            ? listingService
                .shareFiles(channelUrl, +recipientId, selectedFiles)
                .then(response => {
                    let updateFiles = dealFiles.map(file => {
                        if (response.data.fileIds.includes(file.id)) return { ...file, sharedDate: new Date() };

                        return file;
                    });
                    setDealFiles(sortFiles(updateFiles));
                })
                .catch(error => toast.handleError(error))
                .finally(() => setSelectedFiles([]))
            : listingService
                .shareLinks(channelUrl, +recipientId, selectedLinks)
                .then(response => {
                    let updateLinks = dealLinks.map(link => {
                        if (response.data.linkIds.includes(link.id)) return { ...link, sharedDate: new Date() };

                        return link;
                    });
                    setDealLinks(sortLinks(updateLinks));
                })
                .catch(error => toast.handleError(error))
                .finally(() => setSelectedLinks([]));

    const onPinButtonClick = () =>
        isDocument
            ? listingService
                .updateFilesPin(selectedFiles)
                .then(() => {
                    let updateFiles = dealFiles.map(file => {
                        if (selectedFiles.includes(file.id)) return { ...file, pinned: !file.pinned };

                        return file;
                    });
                    setDealFiles(sortFiles(updateFiles));
                    setShowPinnedMessage(selectedFiles.length);
                    setTimeout(() => setShowPinnedMessage(0), 2000);
                })
                .catch(error => toast.handleError(error))
                .finally(() => setSelectedFiles([]))
            : listingService
                .updateLinksPin(selectedLinks)
                .then(() => {
                    let updateLinks = dealLinks.map(link => {
                        if (selectedLinks.includes(link.id)) return { ...link, pinned: !link.pinned };

                        return link;
                    });
                    setDealLinks(sortLinks(updateLinks));
                    setShowPinnedMessage(selectedLinks.length);
                    setTimeout(() => setShowPinnedMessage(0), 2000);
                })
                .catch(error => toast.handleError(error))
                .finally(() => setSelectedLinks([]));

    const shareFiles = uploadFiles => {
        let correctFiles = uploadFiles
            .filter(file => file.isUploading || file.isSuccess)
            .map(correctFile => correctFile.file);
        if (correctFiles.length > 0) {
            setShowProgress(true);
            setDisableShareButton(true);
            setDisabledFileUpload(true);
            listingService
                .uploadDealFiles(
                    dealId,
                    correctFiles,
                    isSeller ? uploadItemToDeal : null,
                    +recipientId,
                    channelUrl,
                    event => {
                        setProgress(Math.round((100 * event.loaded) / event.total));
                    }
                )
                .then(response => {
                    let responseFiles = response.data.filesResponse;
                    setUploadingFiles(prev => [...responseFiles, ...prev.filter(file => !file.isUploading)]);
                    setTimeout(() => {
                        responseFiles.forEach(fileResponse => {
                            if (fileResponse.isSuccess) {
                                setUploadingFiles(prev => prev.filter(file => !file.isSuccess));
                                setDealFiles(prev =>
                                    sortFiles([...prev, { ...fileResponse.file, sharedDate: new Date() }])
                                );
                            }
                        });
                    }, 3000);
                })
                .catch(error => {
                    toast.handleError(error);
                    let errorFiles = correctFiles.map(resFile => {
                        return {
                            file: resFile,
                            errorMessage: (
                                <>
                                    File could not be uploaded.
                                    <span
                                        className="cursor-pointer border-bottom-red-500"
                                        onClick={() => onRetryUploadClick(resFile)}
                                    >
                                        &nbsp;Retry
                                    </span>
                                </>
                            ),
                            isSuccess: false,
                        };
                    });
                    setUploadingFiles(prev => [
                        ...errorFiles,
                        ...prev.filter(uploadingFile => !uploadingFile.isUploading),
                    ]);
                })
                .finally(() => {
                    setProgress(0);
                    setShowProgress(false);
                    setDisableShareButton(false);
                    setDisabledFileUpload(false);
                });
        }
    };

    const onRetryUploadClick = file => {
        setUploadingFiles(prev => [
            { file: file, isUploading: true },
            ...prev.filter(uploadingFile => uploadingFile.file.name !== file.name),
        ]);
        shareFiles([{ file: file, isUploading: true }]);
    };

    const shareLink = (setIsLinkNameValid, setIsLinkUrlValid) => {
        setDisableShareButton(true);
        listingService
            .createLink(dealId, linkName, linkUrl, uploadItemToDeal, +recipientId, channelUrl)
            .then(response => {
                let newLink = {
                    id: response.data.id,
                    name: linkName,
                    link: linkUrl,
                    pinned: false,
                    sharedDate: new Date(),
                };
                setDealLinks(prev => sortLinks([...prev, newLink]));
                setIsLinkNameValid(false);
                setIsLinkUrlValid(false);
                setLinkName('');
                setLinkUrl('');
            })
            .catch(error => toast.handleError(error))
            .finally(() => setDisableShareButton(false));
    };

    return (
        <>
            <div className="text-center cursor-pointer mt-3 mb-4 px-3 px-lg-4">
                <span
                    className={`d-inline-block w-50 pb-2 ${!isDocument ? 'border-bottom-grey-200 c-grey-450' : 'border-bottom-grey-700 c-grey-700'
                        }`}
                    style={{ ...transitionBg }}
                    onClick={() => setIsDocument(true)}
                >
                    Documents
                </span>
                <span
                    className={`d-inline-block w-50 pb-2 ${!isDocument ? 'border-bottom-grey-700 c-grey-700' : 'border-bottom-grey-200 c-grey-450'
                        }`}
                    style={{ ...transitionBg }}
                    onClick={() => setIsDocument(false)}
                >
                    Links
                </span>
            </div>

            {showPinnedMessage > 0 && (
                <div className="px-4">
                    <div className="d-flex align-items-center border-radius-small border-dashed-green bc-green-25 px-4 py-2 mb-3">
                        <span className="icon icon-success"></span>
                        <span className="c-green-500 ff-i500 ps-2">
                            Files were pinned/unpinned
                        </span>
                    </div>
                </div>
            )} 

            <div className="custom-scrollbar mh-100 px-3 px-lg-4">
                {isDocument && (
                    <SharedFiles
                        isSeller={isSeller}
                        dealFiles={dealFiles}
                        uploadingFiles={uploadingFiles}
                        setUploadingFiles={setUploadingFiles}
                        receivedFiles={receivedFiles}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        uploadFileToDeal={uploadItemToDeal}
                        setUploadFileToDeal={setUploadItemToDeal}
                        disabledFileUpload={disabledFileUpload}
                        showProgress={showProgress}
                        progress={progress}
                        disableShareButton={disableShareButton}
                        shareFiles={shareFiles}
                    />
                )}
                {!isDocument && (
                    <SharedLinks
                        isSeller={isSeller}
                        dealLinks={dealLinks}
                        linkName={linkName}
                        setLinkName={setLinkName}
                        linkUrl={linkUrl}
                        setLinkUrl={setLinkUrl}
                        selectedLinks={selectedLinks}
                        setSelectedLinks={setSelectedLinks}
                        uploadLinkToDeal={uploadItemToDeal}
                        setUploadLinkToDeal={setUploadItemToDeal}
                        shareLink={shareLink}
                        disableShareButton={disableShareButton}
                    />
                )}
            </div>

            {isSeller && (
                <div className="mt-auto py-3 border-top">
                    <span className="d-flex justify-content-center ff-i600 c-grey-700 text-m">
                        {isDocument ? (
                            <>
                                {selectedFiles.length > 0
                                    ? `${selectedFiles.length} ${selectedFiles.length > 1 ? 'files' : 'file'} selected`
                                    : 'Select files to activate actions'}
                            </>
                        ) : (
                            <>
                                {selectedLinks.length > 0
                                    ? `${selectedLinks.length} ${selectedLinks.length > 1 ? 'links' : 'link'} selected`
                                    : 'Select links to activate actions'}
                            </>
                        )}
                    </span>
                    <div className="d-flex justify-content-center mt-3">
                        <button
                            className="btn-primary disabled-secondary d-flex justify-content-center align-items-center me-3"
                            onClick={onShareButtonClick}
                            disabled={
                                isDocument
                                    ? !selectedFiles.length || isDisableShareButton(dealFiles, selectedFiles)
                                    : !selectedLinks.length || isDisableShareButton(dealLinks, selectedLinks)
                            }
                        >
                            <span className="icon icon-send-white me-2"></span>
                            <span className="ff-i600">Share</span>
                        </button>
                        <button
                            className="btn-primary disabled-secondary d-flex justify-content-center align-items-center"
                            onClick={onPinButtonClick}
                            disabled={isDocument ? !selectedFiles.length : !selectedLinks.length}
                        >
                            <span className="icon icon-pinned-white me-2"></span>
                            <span className="ff-i600">Pin</span>
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

const transitionBg = {
    transition: 'border-color 200ms ease-in',
};

export default DealDocumentsLinks;
