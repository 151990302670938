import { useEffect, useState } from 'react';
import getLeadsOptions from '../helpers/getLeadsOptions';
import LeadsService from 'services/leads-service';
import useToast from 'hooks/useToast';

const leadsService = LeadsService();

const useGetLeadOptions = () => {
    const toast = useToast();
    const [leadOptions, setLeadOptions] = useState([]);

    useEffect(() => {
        leadsService.init();

        leadsService
            .getAll()
            .then(response => setLeadOptions(getLeadsOptions(response.data.leads)))
            .catch(toast.handleError);

        return () => {
            leadsService.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        leadOptions
    };
};

export default useGetLeadOptions;