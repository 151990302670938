import { Modal } from 'react-bootstrap';

const DeleteTask = ({ isVisible, handleClose, handleSubmit }) => {
    return (
        <Modal show={isVisible} centered onHide={handleClose} size="lg">
            <Modal.Header className="px-4" closeButton>
                <Modal.Title>
                    <h5 className="c-grey-700 ff-i600">Remove Task</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="min-h-50vh d-flex align-items-center justify-content-center">
                    <div className='text-center text-l c-grey-700'>
                        <span className='d-block'>
                            This reminder will be completely deleted and will disappear.
                        </span>
                        <span className='d-block'>
                            This action cannot be undone. Are you sure?
                        </span>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="py-3">
                <div className="flex-fill">
                    <div className="row justify-content-center">
                        <div className="col-6 col-md-4">
                            <button className="btn-secondary ff-i600 w-100 me-2" onClick={handleClose}>
                                Cancel
                            </button>
                        </div>
                        <div className="col-6 col-md-4">
                            <button
                                type={'submit'}
                                color="primary"
                                className="btn-primary ff-i600 w-100 me-2"
                                onClick={handleSubmit}
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteTask;
