import { getCache } from 'services/common/response-handlers';

function TestimonialsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; }
    const getAll = () => getCache('/testimonials', controller);

    return {
        init,
        dispose,
        getAll
    };
}

export default TestimonialsService;