import { useEffect, useRef } from 'react';

const Tooltip = ({
    show,
    additionalClasses,
    bodyClasses = 'w-100',
    children,
    direction,
    animations = 'from-fade',
    content,
    secondaryContent,
    ctaText,
    ctaAction }) => {

    const tooltipRef = useRef(null);

    const onTooltipClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        ctaAction();
    }

    useEffect(() => {
        if (show && animations)
            tooltipRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return (
        <div className={`edu-tooltip-wrapper ${bodyClasses}`}>
            {children}
            {show && (
                <div
                    className={`edu-tooltip ${additionalClasses || ""} border-radius ${animations} ${direction || "right"}`}
                    onClick={e => onTooltipClick(e)}
                    ref={tooltipRef}>
                    {secondaryContent && <span className="d-block text-s pb-2">{secondaryContent}</span>}
                    <p className="ff-i600">{content}</p>
                    <button className="btn-secondary w-100">
                        <span className="c-violet-600">{ctaText}</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
