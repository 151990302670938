import { useMemo, useState } from 'react';
import BuyerChannelsWrapper from '../Buyer/BuyerChannelsWrapper';

const BuyerChannelsListWrapper = ({ userId, currentChannelUrl, onChannelSelect, channelsBySellers, selectedChannel }) => {
    const displayChannelsList = useMemo(
        () => channelsBySellers.length > 0 && channelsBySellers.some(x => x.channels?.length > 0),
        [channelsBySellers]
    );

    const [currentIndex, setCurrentIndex] = useState(0);

    return (
        <div>
            {displayChannelsList &&
                channelsBySellers.map((x, index) =>
                    <BuyerChannelsWrapper
                        key={x.sellerId}
                        name={x.sellerName}
                        channels={x.channels}
                        userId={userId}
                        index={index}
                        setCurrentIndex={setCurrentIndex}
                        currentChannelUrl={currentChannelUrl}
                        onChannelSelect={onChannelSelect}
                        openDetails={index === currentIndex}
                        selectedChannel={selectedChannel}
                    />
                )
            }
        </div>
    );
}

export default BuyerChannelsListWrapper;