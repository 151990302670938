import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bc-white w-100 border-top-grey">
            <div className="container">
                <div className="row align-items-center c-grey-550 ff-i600 p-2">
                    <div className="col-12 col-md-4 col-xl-3 order-last order-xl-first text-start text-md-end text-xl-start ff-i500 p-2">
                        <span className="text-s">Copyright © {(new Date()).getFullYear()} 1Konnection</span>
                    </div>

                    <div className="col-12 col-xl-4 row">
                        <div className="col-12 col-md p-2 d-flex justify-content-start justify-content-md-center justify-content-xl-center">
                            <a href="mailto:info@1konnection.com" className="d-flex align-items-center text-decoration-none c-grey-550">
                                <span className="icon icon-mail me-1"></span>
                                <span className="text-s">info@1konnection.com</span>
                            </a>
                        </div>

                        <div className="col-12 col-md p-2 d-flex justify-content-start justify-content-md-end justify-content-xl-center">
                            <a href="https://www.linkedin.com/company/1konnection/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="d-flex align-items-center text-decoration-none c-grey-550">
                                <span className="icon icon-linked-in me-1"></span>
                                <span className="text-s">Konnect on LI</span>
                            </a>
                        </div>
                    </div>

                    <div className="col-12 col-md-8 col-xl text-start text-xl-end ps-lg-0 p-2">
                        <Link className="text-decoration-none c-grey-550 ff-i500 text-s" to="/help-center">FAQs</Link>
                        <span className='px-1'>•</span>
                        <Link className="text-decoration-none c-grey-550 ff-i500 text-s" to="/privacy">Privacy Policy</Link>
                        <span className='px-1'>•</span>
                        <Link className="text-decoration-none c-grey-550 ff-i500 text-s" to="/terms">Terms of Service</Link>
                        <span className='px-1'>•</span>
                        <Link className="text-decoration-none c-grey-550 ff-i500 text-s" to="/bpfa">BPFA</Link>
                        <span className='px-1'>•</span>
                        <a
                            href={`${process.env.PUBLIC_URL}/files/1Konnection-Terms-of-Use.pdf`}
                            target="blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none c-grey-550 ff-i500 text-s"
                        >Terms of Use
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;