import { useEffect } from 'react';
import { Switch, useRouteMatch, useLocation, useHistory, Redirect } from 'react-router-dom';
import AuthHandler from 'services/common/auth-handler';
import { trackPageview } from 'services/common/analytic-trackers';
import UserSimulation from './components/UserSimulation';
import UserMetadata from './components/UserMetadata';
import AddMatch from './components/AddMatch';
import BroadcastMessage from './components/BroadcastMessage';
import AuthRoute from 'guards/AuthRoute';

import Sidebar from './components/Sidebar';
import VerificationEmail from './components/VerificationEmail';
import VerificationUsers from './components/VerificationUsers/VerificationUsers';

const authHandler = AuthHandler();

const AdminLayoutPage = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    if (!authHandler.isAdmin() && !authHandler.isSimulationEnabled()) history.push('/secure');

    useEffect(() => {
        document.title = '1Konnection - Admin';
        trackPageview();
    }, []);

    return (
        <div className="d-flex">
            <Sidebar />
            {location.state?.showMobileSideBar !== true && (
                <div style={{ marginBottom: 60 }} className="w-100 mb-lg-0">
                    <Switch>
                        <AuthRoute path={`${path}/metadata`} component={UserMetadata} />
                        <AuthRoute path={path} exact component={UserSimulation} />
                        <AuthRoute path={`${path}/add-match`} component={AddMatch} />
                        <AuthRoute path={`${path}/message-broadcasting`} component={BroadcastMessage} />
                        <AuthRoute path={`${path}/verification-email`} component={VerificationEmail} />
                        <AuthRoute path={`${path}/verification-users`} component={VerificationUsers} />
                        <Redirect from={`${path}/**/`} to="/not-found" />
                    </Switch>
                </div>
            )}
        </div>
    );
}

export default AdminLayoutPage;