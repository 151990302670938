const setNecessaryIcon = type => {
    switch (type) {
        case 'CRITICAL':
            return 'icon-critical';
        case 'ERROR':
            return 'icon-cancel-circle-red';
        case 'WARNING':
            return 'icon-warning-yellow-medium';
        case 'SUCCESS':
            return 'icon-success';
        case 'INFORMATION':
            return 'icon-info-black';
        case 'PROMOTIONAL':
            return 'icon-info-white';
        default:
            return 'icon-info-black';
    }
};

export default setNecessaryIcon;
