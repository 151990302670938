import axios from 'axios';
import { traceError } from './common/response-handlers';

function MatchmakingService() {
    let controller = null;
    const init = () => (controller = new AbortController());
    const dispose = () => {
        if (controller) controller.abort();
        controller = null;
    };
    const config = () => (controller && controller.signal ? { signal: controller.signal } : null);

    const run = () =>
        axios
            .post('/matchmaking/run', config())
            .catch(error => traceError(error, `/matchmaking/run`, 'POST'));

    return {
        init,
        dispose,
        run
    };
}

export default MatchmakingService;