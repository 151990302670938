import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import delayImitation from 'utils/delayImitation';

const useAddToast = (dismissTime = 6000) => {
    const [toastQueue, setToastQueue] = useState([]);
    const [showMap, setShowMap] = useState({});

    const showMapRef = useRef(showMap);

    useEffect(() => {
        showMapRef.current = showMap;
    }, [showMap]);

    const toasts = useMemo(
        () =>
            toastQueue.map((toast) => ({
                ...toast,
                show: showMap[toast.id],
                setShow: (show) => setShowMap({ ...showMap, [toast.id]: show }),
            })),
        [toastQueue, showMap],
    );

    const autoDismiss = useCallback(
        async (id) => {
            await delayImitation(dismissTime);
            setShowMap({ ...showMapRef.current, [id]: false });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setShowMap],
    );

    const addToast = useCallback(
        (toast) => {
            const id = new Date().getTime();
            setToastQueue((prevToastQueue) => [...prevToastQueue, { ...toast, id, dismissTime }]);
            setShowMap((prevShowMap) => {
                return { ...prevShowMap, [id]: true };
            });
            if (!toast?.remain) {
                autoDismiss(id);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [toastQueue, setToastQueue, showMap, setShowMap],
    );

    return {
        addToast, toasts
    };
};

export default useAddToast;