const useIntersectionObserver = () => {
    const REVEALED = 'revealed';
    const hasIOSupport = !!window.IntersectionObserver;

    const scrollTrigger = (selector, options = {}) => {
        let elements = document.querySelectorAll(selector);
        elements = Array.from(elements);
        if (hasIOSupport) elements.forEach(el => addObserver(el, options));
        else elements.forEach(el => el.classList.add(REVEALED));
    }
    const addObserver = (element, options) => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                entry.target.classList.toggle(REVEALED, entry.isIntersecting);
                if (entry.isIntersecting) observer.unobserve(entry.target);
            });
        }, options);
        observer.observe(element);
    }

    return { scrollTrigger };
};

export default useIntersectionObserver;