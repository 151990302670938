import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useToast from 'hooks/useToast';
import useQueryParams from 'hooks/useQueryParams';
import { EMAIL_VERIFIED_KEY, SENT_EMAIL_VERIFICATION_KEY, IS_SHADOW_KEY } from 'hooks/useLocalStorage';

import { trackPageview, trackLogin, trackVerificationEmail } from 'services/common/analytic-trackers';
import AuthHandler from 'services/common/auth-handler';
import UserEmailsService from 'services/user-emails-service';
import UsersService from 'services/users-service';

const authHandler = AuthHandler();
const userEmailsService = UserEmailsService();
const usersService = UsersService();

const HandlerPage = () => {
    const history = useHistory();
    const query = useQueryParams();
    const { toast } = useToast();

    const redirectUrlParam = query.get('redirect-url');
    const accessTokenParam = query.get('access-token');
    const verificationEmailParam = query.get('verification-email');

    const parseRedirectUrl = () => {
        try {
            return decodeURIComponent(redirectUrlParam || '/');
        } catch (_) {
            return '/';
        }
    };

    const loginUser = () => {
        authHandler.authenticated(accessTokenParam);
        usersService.getIsShadow()
            .then(response => response.data.isShadow && localStorage.setItem(IS_SHADOW_KEY, true));
        trackLogin({ method: 'Email' });
    };

    const getPath = role => {
        let path = role === 'Buyer'
            ? '/buyer-dashboard'
            : '/seller-dashboard';

        return path;
    };

    const redirectTo = (tokenUserRole, redirectUrl) => {
        if (tokenUserRole === 'Broker - Buying')
            window.location.href = 'https://1konnection.typeform.com/to/vgrfgndC';
        else history.push(redirectUrl);
    }

    useEffect(() => {
        document.title = '1Konnection - Security Handler';
        trackPageview();
        userEmailsService.init();
        usersService.init();

        let redirectUrl = parseRedirectUrl();

        if (accessTokenParam) {
            if (authHandler.isAnonymous()) loginUser();
            else if (authHandler.isRegistered()) {
                const currentUserId = +authHandler.getUserId();
                const tokenUserId = +authHandler.parseToken(accessTokenParam).sid;
                if (currentUserId !== tokenUserId) {
                    history.push('/forbidden');
                    return;
                }
                else if (authHandler.justRegistered()) loginUser();
            }
            if (verificationEmailParam) {
                const tokenUserEmail = authHandler.parseToken(accessTokenParam).email;
                const tokenUserRole = authHandler.parseToken(accessTokenParam).identitytype;
                redirectUrl = redirectUrl === '/' ? getPath(tokenUserRole) : redirectUrl;
                userEmailsService
                    .getAll(tokenUserEmail)
                    .then(response => {
                        const emailItem = response?.data?.userEmails?.find(emailItem => emailItem.email === tokenUserEmail);
                        if (!emailItem.verified)
                            userEmailsService
                                .patch(emailItem.id)
                                .then(_ => {
                                    trackVerificationEmail();
                                    localStorage.setItem(EMAIL_VERIFIED_KEY, true);
                                    localStorage.removeItem(SENT_EMAIL_VERIFICATION_KEY);
                                    redirectTo(tokenUserRole, redirectUrl);
                                })
                                .catch(error => {
                                    toast.handleError(error);
                                    history.push('/');
                                });
                        else redirectTo(tokenUserRole, redirectUrl);
                    })
                    .catch(error => {
                        toast.handleError(error);
                        history.push('/');
                    });
            }
        }
        if (!verificationEmailParam)
            history.push(redirectUrl);

        return () => {
            userEmailsService.dispose();
            usersService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="loader">
            <img
                alt="loader"
                className="loader-image"
                src={`${process.env.PUBLIC_URL}/images/loader-double-ring.gif`}
            />
        </div>
    );
};

export default HandlerPage;
