import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';

import useLoader from 'hooks/useLoader';
import useToast from 'hooks/useToast';
import addNoteSchema from 'validation-schemes/addNoteSchema';
import { groupArrayByDayPropertyStartTime } from 'helpers/Time';

import { trackAssociateDealAdd } from 'services/common/analytic-trackers';
import LeadsService from 'services/leads-service';
import DealsLeadsService from 'services/deals-leads-service';
import ListingService from 'services/listing-service';
import ContactsService from 'services/contacts-service';
import CalendarsService from 'services/calendars-service';
import MatchingsService from 'services/matchings-service';

import Tabs from 'components/atoms/Tabs';
import MultiChoiceTypeahead from 'components/atoms/MultiChoiceTypeahead';
import Select from 'components/atoms/select/Select';
import DealManageModal from 'components/molecules/DealManageModal';
import Note from 'components/molecules/note/Note';
import LeadDocument from 'components/molecules/LeadDocument';
import ScheduledEvents from 'pages/Chat/components/settings/ScheduledEvents';
import EditContact from 'pages/Listing/components/deal-details/EditContact';

const leadsService = LeadsService();
const matchingsService = MatchingsService();
const dealsLeadsService = DealsLeadsService();
const listingService = ListingService();
const contactsService = ContactsService();
const calendarsService = CalendarsService();

const uploadedDateFileComparer = (prev, next) => new Date(next.uploadedDate) - new Date(prev.uploadedDate);
const sharedDateFileComparer = (prev, next) => new Date(next.sharedDate) - new Date(prev.sharedDate);
const sortFiles = (files = []) => [
    ...files.filter(f => f.sharedDate && f.pinned).sort(sharedDateFileComparer),
    ...files.filter(f => f.sharedDate && !f.pinned).sort(sharedDateFileComparer),
    ...files.filter(f => !f.sharedDate && f.pinned).sort(uploadedDateFileComparer),
    ...files.filter(f => !f.sharedDate && !f.pinned).sort(uploadedDateFileComparer),
];

const createdDateLinkComparer = (prev, next) => new Date(next.createdDate) - new Date(prev.createdDate);
const sharedDateLinkComparer = (prev, next) => new Date(next.sharedDate) - new Date(prev.sharedDate);
const sortLinks = (links = []) => [
    ...links.filter(f => f.sharedDate && f.pinned).sort(sharedDateLinkComparer),
    ...links.filter(f => f.sharedDate && !f.pinned).sort(sharedDateLinkComparer),
    ...links.filter(f => !f.sharedDate && f.pinned).sort(createdDateLinkComparer),
    ...links.filter(f => !f.sharedDate && !f.pinned).sort(createdDateLinkComparer),
];

const LeadInfoPanel = ({
    channelUrl,
    onCloseClick,
    selectedChannel,
    userId,
    channels,
    selectContactChannels,
    reFetchLeads,
    reFetchDeal,
    currentContact,
    deals,
    setContacts
}) => {
    const { toast } = useToast();
    const { load } = useLoader();

    const history = useHistory();

    const [buyerId, setBuyerId] = useState();
    const [contactDetails, setContactDetails] = useState(currentContact);
    const [selectedTabGeneralOrNote, setSelectedTabGeneralOrNote] = useState('General');
    const [dealFiles, setDealFiles] = useState([]);
    const [receivedFiles, setReceivedFiles] = useState([]);
    const [dealLinks, setDealLinks] = useState([]);
    const [showUpdateLeadStatus, setShowUpdateLeadStatus] = useState(false);

    const [associatedDeals, setAssociatedDeals] = useState([]);
    const [selectedDeals, setSelectedDeals] = useState([]);
    const [showUpdateContact, setShowUpdateContact] = useState(false);
    const [firstName, setFirstName] = useState(contactDetails?.firstName);
    const [lastName, setLastName] = useState(contactDetails?.lastName);
    const [phoneNumber, setPhoneNumber] = useState(contactDetails?.phone);
    const [email, setEmail] = useState(contactDetails?.email);
    const [jobTitle, setJobTitle] = useState(contactDetails?.jobTitle);
    const [companyName, setCompanyName] = useState(contactDetails?.companyName);
    const [isChangedFields, setIsChangedFields] = useState(false);
    const [stages, setStages] = useState([]);
    const [dealId, setDealId] = useState();

    const [showAddDealAssociate, setShowAddDealAssociate] = useState(false);
    const [filterDeals, setFilterDeals] = useState([]);

    const [documents, setDocuments] = useState([]);
    const [upcomingMeetings, setUpcomingMeetings] = useState([]);

    useEffect(() => {
        leadsService.init();
        dealsLeadsService.init();
        matchingsService.init();
        listingService.init();
        contactsService.init();
        calendarsService.init();

        return () => {
            leadsService.dispose();
            dealsLeadsService.dispose();
            matchingsService.dispose();
            listingService.dispose();
            contactsService.dispose();
            calendarsService.dispose();
        };
    }, []);

    useEffect(() => {
        setFirstName(contactDetails?.firstName);
        setLastName(contactDetails?.lastName);
        setPhoneNumber(contactDetails?.phone);
        setEmail(contactDetails?.email);
        setJobTitle(contactDetails?.jobTitle);
        setCompanyName(contactDetails?.companyName);
    }, [contactDetails])

    useEffect(() => {
        const dealIdMetadata = selectedChannel?.metadata?.listingId;
        const buyerIdMetadata = selectedChannel?.metadata?.buyerId;
        setDealId(dealIdMetadata);
        setBuyerId(buyerIdMetadata);
        setUpcomingMeetings([]);
        if (!!channelUrl) {
            load(() =>
                leadsService
                    .get({ dealIdMetadata, buyerIdMetadata })
                    .then(response => {
                        const lead = response?.data?.leads?.find(
                            lead => lead?.userId === +buyerIdMetadata
                        );
                        if (!!lead) {
                            setContactDetails(lead);
                            Promise
                                .all([
                                    leadsService.getDeals(lead.userId),
                                    listingService.getAll(),
                                    leadsService.getStages()
                                ])
                                .then(([
                                    associateDealsResponse,
                                    dealResponse,
                                    leadStagesResponse
                                ]) => {
                                    const deals = dealResponse?.data?.listingEntities.map(deal => deal.listing);
                                    const filteredDeals = associateDealsResponse.data?.deals.map(d => {
                                        let deal = deals.find(currentDeal => currentDeal.id === d.dealId);
                                        let dealStage = leadStagesResponse.data.stages.find(stage => stage.id === d.leadStageId);
                                        if (!!deal && !!dealStage) return { ...d, name: deal.name, stageName: dealStage.name };
                                        return d;
                                    });
                                    setAssociatedDeals(filteredDeals);
                                    setStages(leadStagesResponse.data.stages);
                                })
                                .catch(error => toast.handleError(error))
                        }
                    })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelUrl, selectedChannel]);

    const openChat = id => {
        const channel = channels.find(c => +c.metadata.buyerId === id);
        history.push(`/seller-dashboard/chat?channel=${channel?.channel_url}`);
    };

    useEffect(() => {
        if (dealId)
            load(() => Promise
                .all([
                    listingService.getFiles(dealId),
                    listingService.getLinks(dealId),
                    listingService.getSharedFiles({
                        id: dealId,
                        ownerId: userId,
                        recipientId: +buyerId,
                    }),
                    listingService.getSharedFiles({
                        id: dealId,
                        ownerId: +buyerId,
                        recipientId: userId,
                    }),
                    listingService.getSharedLinks({ id: dealId, ownerId: userId, recipientId: +buyerId }),
                    calendarsService.getEvents(userId, +buyerId, new Date().toISOString(), null)
                ])
                .then(([
                    getFilesResponse,
                    getLinksResponse,
                    getSharedFilesResponse,
                    getReceivedFilesResponse,
                    getSharedLinksResponse,
                    getEventsResponse
                ]) => {
                    const dealFilesResponse = getFilesResponse?.data?.dealFiles;
                    const sharedFilesResponse = getSharedFilesResponse?.data?.sharedFiles;
                    const dealLinksResponse = getLinksResponse?.data?.dealLinks;
                    const sharedLinksResponse = getSharedLinksResponse?.data?.sharedLinks;
                    setDealFiles(
                        sortFiles(
                            dealFilesResponse
                                .filter(
                                    file => !sharedFilesResponse.find(sharedFile => sharedFile.id === file.id)
                                )
                                .concat(sharedFilesResponse)
                        )
                    );
                    setReceivedFiles(sortFiles(getReceivedFilesResponse?.data?.sharedFiles));
                    setDealLinks(
                        sortLinks(
                            dealLinksResponse
                                .filter(
                                    file => !sharedLinksResponse.find(sharedFile => sharedFile.id === file.id)
                                )
                                .concat(sharedLinksResponse)
                        )
                    );

                    const groupedByDay = groupArrayByDayPropertyStartTime(getEventsResponse?.data.slice(0, 100));
                    setUpcomingMeetings(Object.values(groupedByDay));
                })
                .catch(error => toast.handleError(error)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealId, buyerId]);

    useEffect(() => setDocuments(sortFiles([...dealFiles, ...dealLinks])), [dealFiles, dealLinks]);

    const formikNote = useFormik({
        initialValues: {
            notes: '',
            toastBody: '',
        },
        validationSchema: addNoteSchema,
        onSubmit: values => {
            load(() =>
                contactsService
                    .patchNote(contactDetails.id, values)
                    .then(() => {
                        contactDetails.notes = values.notes;
                    })
                    .then(() => {
                        toast.success({
                            body: values.toastBody ? values.toastBody : 'Your note was successfully created.',
                        });
                        formikNote.resetForm();
                    })
                    .catch(toast.handleError)
            );
        },
    });

    const handleEditNote = () =>
        formikNote.setValues({ notes: contactDetails.notes, toastBody: 'Your note was successfully edited.' });

    const handleDeleteNote = () =>
        load(() =>
            contactsService
                .patchNote(contactDetails.id, { notes: '' })
                .then(() => delete contactDetails.notes)
                .then(() => toast.success({ title: 'Operation successful', body: 'Your note successfully deleted.' }))
                .catch(toast.handleError)
        );

    const getLeadResource = sourceId => {
        switch (sourceId) {
            case 1:
                return '1K Generated';
            default:
                return 'Personal Lead';
        }
    };

    const updateContactInfo = (firstName, lastName, email, phone, companyName, jobTitle) =>
        load(() =>
            contactsService
                .put({ id: contactDetails.id, firstName, lastName, email, phone: phone, companyName, jobTitle })
                .then(_ => {
                    contactDetails.firstName = firstName;
                    contactDetails.lastName = lastName;
                    contactDetails.fullName = (firstName + ' ' + lastName).trim();
                    contactDetails.email = email;
                    contactDetails.phone = phone;
                    contactDetails.companyName = companyName;
                    contactDetails.jobTitle = jobTitle;

                    setFirstName(firstName);
                    setLastName(lastName);
                    setEmail(email);
                    setPhoneNumber(phone);
                    setJobTitle(jobTitle);
                    setCompanyName(companyName);
                    toast.success({ body: 'Contact was successfully updated' });
                })
                .catch(error => toast.handleError(error))
        );

    const updateLeadStage = () =>
        load(() =>
            leadsService
                .putDeals(
                    contactDetails?.userId,
                    selectedDeals.map(deal => {
                        return { dealId: deal.dealId, leadStageId: deal.leadStageId };
                    })
                )
                .then(_ => {
                    setAssociatedDeals(selectedDeals);
                    // Todo: refactor
                    reFetchDeal();
                    reFetchLeads();
                    toast.success({ body: 'Lead Status was successfully updated' });
                    reFetchLeads && reFetchLeads();
                })
                .catch(error => toast.handleError(error))
        );

    const addDealAssociate = () => {
        setSelectedDeals([]);
        if (showAddDealAssociate) setShowAddDealAssociate(false);
        else {
            setFilterDeals(
                deals.filter(deal => !contactDetails?.dealIds?.concat(contactDetails?.associatedDealIds)?.includes(deal.id) && deal.verified && !deal.deactivated)
            );
            setShowAddDealAssociate(true);
        }
    };

    // Todo: Update Method
    const updateAssociateDeal = () =>
        load(() =>
            leadsService
                .postDeals(
                    contactDetails?.id,
                    selectedDeals.map(deal => deal.id)
                )
                .then(() => {
                    const newContact = contactDetails;
                    newContact.associatedDealIds = contactDetails.associatedDealIds.concat(selectedDeals.map(deal => deal.id));
                    newContact.allDealIds = contactDetails.allDealIds.concat(selectedDeals.map(deal => { return { id: deal.id, isAssociation: true } }))
                    newContact.inviteSent = !!contactDetails?.userId;
                    setContacts(prev => [...prev.filter(contactItem => contactItem.id !== contactDetails.id), newContact]);
                    toast.success({ body: 'Associate was successfully updated' });
                    trackAssociateDealAdd();
                    handleClose();
                })
                .catch(error => toast.handleError(error)));

    const selectName = () => {
        const deal = associatedDeals?.find(deal => deal?.dealId === parseInt(dealId));
        if (deal)
            return {
                ...deal,
                name: `${deal?.name}, #${deal?.dealId}`
            }
        return null;
    }

    return (
        <div className="d-flex flex-column h-100 c-grey-700">
            <div className="d-none d-md-flex">
                <Modal.Header className="p-3 w-100" closeButton onHide={onCloseClick}>
                    <div className="d-flex justify-content-between w-100">
                        {(contactDetails?.fullName || contactDetails?.email) &&
                            <>
                                <h1 className="text-break me-2">{contactDetails?.fullName || contactDetails?.email}</h1>
                            </>
                        }
                        {!!(buyerId && channels?.length) &&
                            <span className="d-flex align-items-center ff-i600 c-blue-600 cursor-pointer mx-3" onClick={() => {
                                associatedDeals.length > 1
                                    ? selectContactChannels(+buyerId)
                                    : openChat(+buyerId);
                            }}>
                                <span className="icon icon-chat-primary me-2"></span>
                                Chat
                            </span>
                        }
                    </div>
                </Modal.Header>
            </div>
            <div className="d-flex d-md-none">
                <Modal.Header className="d-flex flex-column p-3 w-100" onHide={onCloseClick}>
                    <div className="d-flex w-100">
                        <span onClick={() => onCloseClick()} className="icon icon-arrow-left"></span>
                        <h3 className="text-break c-grey-900 mx-auto">{contactDetails?.fullName || contactDetails?.email}</h3>
                    </div>
                    {!!contactDetails?.companyName && (
                        <span className="text-s c-grey-450">{contactDetails?.companyName}</span>
                    )}
                    <div className="d-flex mt-4">
                        <a href={`tel:${contactDetails?.phone}`}>
                            <span className="icon icon-phone-in-circle mx-3" />
                        </a>
                        <span
                            onClick={() => {
                                associatedDeals.length > 1
                                    ? selectContactChannels(+buyerId)
                                    : openChat(+buyerId);
                            }}
                            className="icon icon-chat-in-circle mx-3" />
                    </div>
                </Modal.Header>
            </div>

            <div className="custom-scrollbar mh-100 px-3 px-lg-4 pt-4 py-lg-0">
                <div className="mb-4 mt-3">
                    <Tabs
                        tabs={['General', 'Data Vault', 'Notes']}
                        activeTab={selectedTabGeneralOrNote}
                        setActiveTab={setSelectedTabGeneralOrNote}
                    />
                </div>
                {selectedTabGeneralOrNote === 'General' && (
                    <>
                        {!!associatedDeals.length && (
                            <div className="d-flex align-items-center justify-content-between border-bottom-md pt-md-4 pb-md-2">
                                <h4>Connected Deals</h4>
                                <span className="cursor-pointer ff-i600 c-blue-500"
                                    onClick={() => {
                                        setShowUpdateLeadStatus(true);
                                        setSelectedDeals(associatedDeals);
                                    }}>
                                    Update
                                </span>
                            </div>
                        )}
                        {!!associatedDeals.length && (
                            <>
                                <div className="d-none d-md-block">
                                    <div className="row custom-scrollbar py-3 justify-content-between">
                                        <div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="c-grey-450 text-s mb-2">Deal</p>
                                                </div>
                                                <div className="col-3">
                                                    <p className="c-grey-450 text-s mb-2">Match Date</p>
                                                </div>
                                                <div className="col-3">
                                                    <p className="c-grey-450 text-s mb-2">Lead Status</p>
                                                </div>
                                            </div>
                                            {associatedDeals.map(deal => (
                                                <div
                                                    key={`destop-${deal.dealId}`}
                                                    className="row">
                                                    <div className="col-6">
                                                        <span
                                                            className="ff-i500 c-blue-1000 cursor-pointer mb-2"
                                                            onClick={() => history.push('/seller-dashboard/deals/' + deal.dealId)}>
                                                            <span>{deal.dealName}</span> <span className="c-grey-300">#{deal.dealId}</span>
                                                        </span>
                                                    </div>
                                                    <div className="col-3">
                                                        <span className="ff-i500 mb-2">
                                                            {new Date(deal.createdDate).toLocaleDateString()}
                                                        </span>
                                                    </div>
                                                    <div className="col-3">
                                                        <span className="ff-i500 mb-2">
                                                            {deal.stageName}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-block d-md-none">
                                    {associatedDeals.map((deal, index) => (
                                        <div
                                            key={`mobile-${deal.dealId}`}
                                            className={`${index + 1 === associatedDeals.length ? '' : 'border-bottom'} pt-4 pb-2`}>
                                            <div className="pb-2">
                                                <span className="c-grey-450 me-1">Deal:</span>
                                                <span
                                                    className="ff-i500 c-blue-1000 cursor-pointer mb-2"
                                                    onClick={() => history.push('/seller-dashboard/deals/' + deal.dealId)}>
                                                    <span>{deal.dealName}</span> <span className="c-grey-300">#{deal.dealId}</span>
                                                </span>
                                            </div>
                                            <div className="pb-2">
                                                <span className="c-grey-450 me-1">Match Date:</span>
                                                <span className="ff-i500 mb-2">
                                                    {new Date(deal.createdDate).toLocaleDateString()}
                                                </span>
                                            </div>
                                            <div className="pb-2">
                                                <span className="c-grey-450 me-1">Lead Status:</span>
                                                <span className="ff-i500 mb-2">
                                                    {deal.stageName}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {(!!associatedDeals?.length || !!deals?.length) &&
                                    <div className="d-flex cursor-pointer pb-4 pb-md-0" onClick={() => addDealAssociate()}>
                                        <span className="icon icon-plus-transparent"></span>
                                        <span className="c-blue-600 text-s ps-2">Connect Lead to New Deal</span>
                                    </div>
                                }
                            </>
                        )}
                        {(!associatedDeals.length ?? !!deals?.length) && (
                            <div className="text-center">
                                <h6 className="ff-i600 my-3">
                                    You don't have any connected deals with this contact. By adding new,
                                    we'll send an invitation to join 1Konnection.
                                </h6>
                                <div className="d-flex cursor-pointer" onClick={() => addDealAssociate()}>
                                    <span className="icon icon-plus-transparent"></span>
                                    <span className="c-blue-600 text-s ps-2">Connect Lead to New Deal</span>
                                </div>
                            </div>
                        )}

                        {upcomingMeetings.length > 0 && (
                            <div className="mt-4">
                                <ScheduledEvents
                                    upcomingMeetings={upcomingMeetings}
                                    title={'Meetings'}
                                    redirectTo={'/seller-dashboard/meetings'}
                                    isCta={true}
                                />
                            </div>
                        )}

                        <div className="d-flex align-items-center justify-content-between border-bottom mt-5 pb-2">
                            <h4>Contact Information</h4>
                            <span className="cursor-pointer ff-i600 c-blue-500"
                                onClick={() => setShowUpdateContact(true)}>
                                Edit
                            </span>
                        </div>
                        <div className="py-3">
                            {!!contactDetails?.fullName && (
                                <div className="d-flex flex-column flex-md-row align-items-md-center">
                                    <p className="c-grey-450 col-4 text-s mb-1 mb-md-3">Name</p>
                                    <p className="text-break">{contactDetails.fullName}</p>
                                </div>
                            )}
                            {!!contactDetails?.phone && (
                                <div className="d-flex flex-column flex-md-row align-items-md-center">
                                    <p className="c-grey-450 col-4 text-s mb-1 mb-md-3">Phone</p>
                                    <p>{contactDetails.phone}</p>
                                </div>
                            )}
                            <div className="d-flex flex-column flex-md-row align-items-md-center">
                                <p className="c-grey-450 col-4 text-s mb-1 mb-md-3">Email</p>
                                <p className="text-break">{contactDetails?.email}</p>
                            </div>
                            {!!contactDetails?.companyName && (
                                <div className="d-flex flex-column flex-md-row align-items-md-center">
                                    <p className="c-grey-450 col-4 text-s mb-1 mb-md-3">Company Name</p>
                                    <p className="text-break">{contactDetails.companyName}</p>
                                </div>
                            )}
                            {!!contactDetails?.jobTitle && (
                                <div className="d-flex flex-column flex-md-row align-items-md-center">
                                    <p className="c-grey-450 col-4 text-s mb-1 mb-md-3">Job Title</p>
                                    <p className="text-break">{contactDetails.jobTitle}</p>
                                </div>
                            )}
                            <div className="d-flex flex-column flex-md-row align-items-md-center">
                                <p className="c-grey-450 col-4 text-s mb-1 mb-md-3">Lead Resource</p>
                                <p>{getLeadResource(contactDetails?.sourceId)}</p>
                            </div>
                            <div className="d-flex flex-column flex-md-row align-items-md-center">
                                <p className="c-grey-450 col-4 text-s mb-1 mb-md-3">Registered on 1K?</p>
                                <p>
                                    {contactDetails?.userId
                                        ? 'Yes'
                                        : contactDetails?.inviteSent === true
                                            ? 'No, invitation was sent.'
                                            : 'No'
                                    }
                                </p>
                            </div>
                        </div>
                    </>
                )}
                {selectedTabGeneralOrNote === 'Notes' && (
                    <Note
                        formik={formikNote}
                        handleDelete={handleDeleteNote}
                        handleEdit={handleEditNote}
                        note={contactDetails.notes}
                        createdDateNote={contactDetails.notesModifyDate}
                        emptyStateText={'You don’t have note for this contact.'}
                    />
                )}

                {selectedTabGeneralOrNote === 'Data Vault' && (
                    <>
                        <Select
                            options={associatedDeals.map(deal => {
                                return { ...deal, name: `${deal.name}, #${deal.dealId}` }
                            })}
                            placeholder="Select deal"
                            onChange={value => setDealId(value?.dealId)}
                            keys={{ labelKey: 'name', valueKey: 'dealId' }}
                            value={selectName()} />
                        <LeadDocument
                            dealId={dealId}
                            channelUrl={channelUrl}
                            recipientId={buyerId}
                            documents={documents}
                            dealFiles={dealFiles}
                            receivedFiles={receivedFiles}
                            setDealFiles={setDealFiles}
                            sortFiles={sortFiles}
                            sortLinks={sortLinks}
                            dealLinks={dealLinks}
                            setDealLinks={setDealLinks}
                        />
                    </>
                )}
            </div>

            {
                showUpdateContact &&
                <EditContact show={showUpdateContact} handleClose={() => {
                    setIsChangedFields(false);
                    setShowUpdateContact(false);
                }}>
                    <div className="mh-100 px-3 custom-scrollbar pb-3">
                        <label className="w-100 mt-4 mb-2">
                            <span className="ff-i600 c-grey-700">First Name</span>
                            <input
                                type="text"
                                className="c-grey-700 ff-i500 my-2 w-100"
                                placeholder="First Name"
                                maxLength="50"
                                value={firstName}
                                onChange={event => {
                                    setIsChangedFields(true);
                                    setFirstName(event.target.value);
                                }}
                            />
                        </label>
                        <label className="w-100 mb-2">
                            <span className="ff-i600 c-grey-700">Last Name</span>
                            <input
                                type="text"
                                className="c-grey-700 ff-i500 my-2 w-100"
                                placeholder="Last Name"
                                maxLength="50"
                                value={lastName}
                                onChange={event => {
                                    setIsChangedFields(true);
                                    setLastName(event.target.value);
                                }}
                            />
                        </label>

                        <label className="w-100 mb-2">
                            <span className="ff-i600 c-grey-700">Phone Number</span>
                            <input
                                type="tel"
                                className="c-grey-700 ff-i500 my-2 w-100"
                                placeholder="Phone Number"
                                maxLength="50"
                                value={phoneNumber}
                                onChange={event => {
                                    setIsChangedFields(true);
                                    setPhoneNumber(event.target.value);
                                }}
                                label="label"
                            />
                        </label>
                        <label className="w-100 mb-2">
                            <span className="ff-i600 c-grey-700">Email</span>
                            {(!!contactDetails?.userId || !!contactDetails?.associatedDealIds?.length || !!contactDetails?.dealIds?.length) &&
                                <span
                                    className="ms-2"
                                    data-tooltip="You cannot edit the email because it is registered on 1Konnection."
                                    data-tooltip-violet-right>
                                    <span className="icon icon-lock-violet icon-small"></span>
                                </span>
                            }
                            <input
                                type="email"
                                className="c-grey-700 ff-i500 my-2 w-100"
                                placeholder="Email"
                                maxLength="50"
                                value={email}
                                onChange={event => {
                                    setIsChangedFields(true);
                                    setEmail(event.target.value);
                                }}
                                disabled={!!contactDetails?.userId || !!contactDetails?.contact?.length || !!contactDetails?.dealIds?.length}
                            />
                        </label>
                        <label className="w-100 mb-2">
                            <span className="ff-i600 c-grey-700">Company Name</span>
                            <input
                                type="text"
                                className="c-grey-700 ff-i500 my-2 w-100"
                                placeholder="Company Name"
                                maxLength="50"
                                value={companyName}
                                onChange={event => {
                                    setIsChangedFields(true);
                                    setCompanyName(event.target.value);
                                }}
                            />
                        </label>
                        <label className="w-100 mb-2">
                            <span className="ff-i600 c-grey-700">Job Title</span>
                            <input
                                type="text"
                                className="c-grey-700 ff-i500 my-2 w-100"
                                placeholder="Job Title"
                                maxLength="50"
                                value={jobTitle}
                                onChange={event => {
                                    setIsChangedFields(true);
                                    setJobTitle(event.target.value);
                                }}
                            />
                        </label>
                    </div>
                    <div className="px-3">
                        <button
                            color="primary"
                            disabled={!isChangedFields}
                            className="btn-primary ff-i600 w-100"
                            onClick={() => {
                                updateContactInfo(firstName, lastName, email, phoneNumber, companyName, jobTitle);
                                setShowUpdateContact(false);
                            }}>
                            Save
                        </button>
                    </div>

                </EditContact>
            }

            {
                showUpdateLeadStatus && (
                    <DealManageModal
                        show={showUpdateLeadStatus}
                        title="Update Lead Status"
                        confirmButton="Update"
                        handleConfirmClick={() => {
                            updateLeadStage();
                            setShowUpdateLeadStatus(false);
                        }}
                        handleClose={() => setShowUpdateLeadStatus(false)}
                    >
                        {selectedDeals.map(deal => (
                            <div key={deal.id} className={`d-lg-flex align-items-lg-center justify-content-lg-between mt-4 ${[7, 8, 9].includes(deal.leadStageId) ? "pointer-events-none" : ""}`}>
                                <h6 className="w-75 ff-i600 c-grey-700 m-0">
                                    #{deal.dealId} {deal.name}
                                </h6>
                                <DropdownButton
                                    id="select-dropdown"
                                    name="status"
                                    title={
                                        <span className={`ff-i500 d-flex justify-content-between align-items-center ${[7, 8, 9].includes(deal.leadStageId) ? "bc-grey-90" : ""}`}>
                                            <span className="ff-i600 c-grey-700 ps-2">{deal.stageName}</span>
                                            <span
                                                className="icon icon-arrow-right-grey img-rotate-90 ms-3"
                                                style={{ width: '7px', height: '15px' }}
                                            />
                                        </span>
                                    }
                                    onSelect={value => {
                                        const updatedStatus = selectedDeals.map(s => {
                                            return s.dealId === deal.dealId
                                                ? {
                                                    ...s,
                                                    leadStageId: stages.find(stage => stage.name === value).id,
                                                    stageName: value,
                                                }
                                                : s;
                                        });
                                        setSelectedDeals(updatedStatus);
                                    }}
                                    className={`primary w-100 ${[7, 8, 9].includes(deal.leadStageId) ? "bc-grey-90" : ""}`}
                                >
                                    {stages.map(status => (
                                        <>
                                            <Dropdown.Item eventKey={status.name} key={status.id}>
                                                {status.name}
                                            </Dropdown.Item>
                                        </>
                                    ))}
                                </DropdownButton>
                            </div>
                        ))}
                    </DealManageModal>
                )
            }
            {showAddDealAssociate && (
                <DealManageModal
                    show={showAddDealAssociate}
                    title="Add new Associated Deal(s)"
                    confirmButton="Add to deals"
                    disabledConfirmButton={!selectedDeals.length}
                    handleConfirmClick={() => {
                        updateAssociateDeal();
                        setShowAddDealAssociate(false);
                    }}
                    handleClose={() => setShowAddDealAssociate(false)}
                >
                    <div className="ff-i600 c-grey-700 mb-2">Deals</div>
                    <MultiChoiceTypeahead
                        placeholder="Add new Associated Deal(s)"
                        choices={filterDeals.map(deal => {
                            return {
                                id: deal.id,
                                name: `#${deal.id} ${deal.name}`,
                            };
                        })}
                        onStatesChange={setSelectedDeals}
                        choosed={selectedDeals}
                    />
                </DealManageModal>
            )
            }
        </div >
    );
};

export default LeadInfoPanel;
