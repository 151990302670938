import axios from 'axios';
import { traceError } from './common/response-handlers';

function CompanyProfileService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getCompanyInformation = () => axios
        .get(`/users/company`, config())
        .catch(error => traceError(error, `/users/company`, 'GET'));

    const createCompanyInformation = ({ name, typeId, webSite, jobTitle, linkedInLink }) => axios
        .post(`/users/company`, { name, typeId, webSite, jobTitle, linkedInLink }, config())
        .catch(error => traceError(error, `/users/company`, 'POST'));

    const updateCompanyInformation = ({ name, typeId, webSite, jobTitle, linkedInLink }) => axios
        .put(`/users/company`, { name, typeId, webSite, jobTitle, linkedInLink }, config())
        .catch(error => traceError(error, `/users/company`, 'PUT'));

    return {
        init,
        dispose,
        getCompanyInformation,
        createCompanyInformation,
        updateCompanyInformation
    };
}

export default CompanyProfileService;