const ToasterType =
{
    Error: 'error',
    Warning: 'warning',
    Info: 'info',
    Success: 'success',
    Negative: 'negative'
};

Object.freeze(ToasterType);
export { ToasterType };