import axios from 'axios';
import { traceError } from './common/response-handlers';

function UserEmailsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getAll = email => {
        const queryParam = email ? `?email=${email}` : '';
        return axios
            .get(`/users/emails${queryParam}`, config())
            .catch(error => traceError(error, `/users/emails${queryParam}`, 'GET'));
    }

    const getPrimary = _ => axios
        .get('/users/emails/primary', config())
        .catch(error => traceError(error, '/users/emails/primary', 'GET'));

    const postEmailVerification = email => axios
        .post('/users/emails/send-verification', { email }, config())
        .catch(error => traceError(error, '/users/emails/send-verification', 'POST'));

    const patch = id => axios
        .patch(`/users/emails/${id}/verification`, null, config())
        .catch(error => traceError(error, `/users/emails/${id}/verification`, 'PATCH'));

    const patchPrimary = _ => axios
        .patch('/users/emails/primary/verification', null, config())
        .catch(error => traceError(error, '/users/emails/primary/verification', 'PATCH'));

    return {
        init,
        dispose,
        getAll,
        getPrimary,
        postEmailVerification,
        patch,
        patchPrimary
    };
}

export default UserEmailsService;