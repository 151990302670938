import establishPaddingLeftProperty from './establishPaddingLeftProperty';

const defineCustomStyles = ({ isDisabled, isError, isIcon, isControlText }) => ({
    dropdownIndicator: provided => ({
        ...provided,
        width: 40,
        color: 'rgba(113, 128, 150, 1)',
    }),
    indicatorSeparator: provided => ({
        ...provided,
        borderWidth: 0,
        backgroundColor: isError ? 'rgba(254 226 226, 1)' : 'rgba(209 213 219, 1)',
    }),
    singleValue: provided => ({
        ...provided,
        fontWeight: 500,
        color: '#515c70',
        lineHeight: '1.25rem',
    }),
    input: provided => ({
        ...provided,
        fontSize: 14,
        lineHeight: '1.25rem',
        '& input:focus': {
            boxShadow: 'none',
        },
    }),
    menu: provided => ({
        ...provided,
        marginTop: 0,
        border: 'none',
        borderRadius: 8,
        boxShadow: '0px 0px 18px -3px rgba(0,0,0,0.19)',
    }),
    option: provided => ({
        ...provided,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 24,
        fontWeight: 500,
        lineHeight: '1.30rem',
        border: 0,
        margin: 0,
        '&:hover': {
            color: 'white',
            backgroundColor: '#377DFF',
        },
    }),
    control: (provided, state) => {
        const borderColor = state.isFocused && !isError ? '#83BFFF' : isError ? '#F7222E' : '#DBDFE5';

        const handleBackgroundColor = () => {
            if (isDisabled) return 'rgb(249 250 251)';
            return 'rgba(209 213 219, 1)';
        };

        return {
            ...provided,
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
            borderWidth: 1,
            borderRadius: '0.5rem',
            padding: 4,
            fontSize: 14,
            margin: 0,
            backgroundColor: handleBackgroundColor(),
            borderColor,
            '&:hover': {
                borderColor: state.isFocused && !isError ? '#83BFFF' : '',
            },
        };
    },
    menuList: base => ({
        ...base,
        padding: 0,
        borderRadius: 8,
    }),
    indicatorsContainer: base => ({
        ...base,
        height: 46,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999, margin: 0, padding: 0 }),
    valueContainer: base => ({
        ...base,
        paddingLeft: establishPaddingLeftProperty({ isIcon, isControlText }),
        padding: 0,
    }),
});

export default defineCustomStyles;
