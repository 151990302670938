import axios from 'axios';
import { traceError } from 'services/common/response-handlers';
import FormDataUtil from 'utils/form-data.util';
import {
    trackListingStart,
    trackListingFinish,
    trackListingDeactivate
} from './common/analytic-trackers';

function ListingService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; }
    const config = headers => ({ ...headers, signal: controller && controller.signal ? controller.signal : null });

    const getListingsStats = completed => axios
        .get(`/users/stats/listings${completed ? '?completed=true' : ''}`)
        .catch(error => traceError(error, `/api/users/stats/listings${completed ? '?completed=true' : ''}`, 'GET'));

    const getAll = () => axios
        .get('/selling-listing', config())
        .catch(error => traceError(error, '/selling-listing', 'GET'));

    const getAllAdmin = () => axios
        .get('/selling-listing/admin', config())
        .catch(error => traceError(error, '/selling-listing/admin', 'GET'));

    const get = id => axios
        .get(`/selling-listing/${id}`, config())
        .catch(error => traceError(error, `/selling-listing/${id}`, 'GET'));

    const createEmpty = userId => axios
        .post('/selling-listing/empty', { userId }, config())
        .then(response => {
            getListingsStats().then(res => trackListingStart({ count: res?.data?.count }));
            return response;
        })
        .catch(error => traceError(error, '/selling-listing/empty', 'POST'));

    const create = listing => axios
        .post('/selling-listing', listing, config())
        .catch(error => traceError(error, '/selling-listing', 'POST'));

    const rename = (id, name) => axios
        .patch(`/selling-listing/${id}/name`, { name }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/name`, 'PATCH'));

    const updateAssetTypes = (id, assetTypeIds) => axios
        .patch(`/selling-listing/${id}/asset-types`, { assetTypeIds }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/asset-types`, 'PATCH'));

    const updateOrganizationStatus = (id, status) => axios
        .patch(`/selling-listing/${id}/organization-status`, { status }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/organization-status`, 'PATCH'));

    const updateFinancialOpportunity = (id, opportunityId) => axios
        .patch(`/selling-listing/${id}/financial-opportunity`, { opportunityId }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/financial-opportunity`, 'PATCH'));

    const updateOwnerFinancing = (id, openToOwnerFinancing) => axios
        .patch(`/selling-listing/${id}/owner-financing`, { openToOwnerFinancing }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/owner-financing`, 'PATCH'));

    const updateLandEntitled = (id, landEntitled) => axios
        .patch(`/selling-listing/${id}/land-entitled`, { landEntitled }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/land-entitled`, 'PATCH'));

    const updateInvestmentType = (id, investmentTypeId) => axios
        .patch(`/selling-listing/${id}/investment-type`, { investmentTypeId }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/investment-type`, 'PATCH'));

    const updateState = (id, stateId) => axios
        .patch(`/selling-listing/${id}/location`, { stateId }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/location`, 'PATCH'));

    const updatePrice = (id, askingPrice) => axios
        .patch(`/selling-listing/${id}/asking-price`, { askingPrice }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/asking-price`, 'PATCH'));

    const updateAdditionalInfo = (id, additionalInfo, additionalInfoTagIds) => axios
        .patch(`/selling-listing/${id}/additional-info`, { additionalInfo, additionalInfoTagIds }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/additional-info`, 'PATCH'));

    const updateUnitsNumber = (id, unitsNumber) => axios
        .patch(`/selling-listing/${id}/units-number`, { unitsNumber }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/units-number`, 'PATCH'));

    const updateBedsNumber = (id, bedsNumber) => axios
        .patch(`/selling-listing/${id}/beds-number`, { bedsNumber }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/beds-number`, 'PATCH'))

    const updateBedsOccupancy = (id, bedsOccupancy) => axios
        .patch(`/selling-listing/${id}/beds-occupancy`, { bedsOccupancy }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/beds-occupancy`, 'PATCH'));

    const updatePayorMixes = (id, payorMixIds) => axios
        .patch(`/selling-listing/${id}/payor-mixes`, { payorMixIds }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/payor-mixes`, 'PATCH'));

    const updateBuyersApproval = (id, buyersApprovalEnabled) => axios
        .patch(`/selling-listing/${id}/buyers-approval`, { buyersApprovalEnabled }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/buyers-approval`, 'PATCH'));

    const savePrevetBuyers = (id, buyersApprovalEnabled, deniedMatchingIds = [], approvedMatchingIds = []) => {
        const data = {
            buyersApprovalEnabled,
            deniedMatchingIds,
            approvedMatchingIds
        };
        return axios
            .post(`/selling-listing/${id}/approve-prevet`, data, config())
            .catch(error => traceError(error, `/selling-listing/${id}/approve-prevet`, 'POST'));
    }

    const complete = id => axios
        .patch(`/selling-listing/${id}/complete`, config())
        .then(response => {
            getListingsStats(true).then(res => trackListingFinish({ count: res?.data?.count }));
            return response;
        })
        .catch(error => traceError(error, `/selling-listing/${id}/complete`, 'PATCH'));

    const deactivate = (id, deactivated) => axios
        .patch(`/selling-listing/${id}/deactivate/${deactivated}`, null, config())
        .catch(error => traceError(error, `/selling-listing/${id}/deactivate/${deactivated}`, 'PATCH'));

    const remove = id => axios
        .delete(`/selling-listing/${id}`, config())
        .catch(error => traceError(error, `/selling-listing/${id}`, 'DELETE'));

    const createLink = (id, name, url, dealBased, recipientId, channelUrl) => axios
        .post(`/selling-listing/${id}/links`, { name, url, dealBased, recipientId, channelUrl }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/links`, 'POST'));

    const createLinks = (id, links, dealBased, recipientId, channelUrl) => axios
        .post(`/selling-listing/${id}/links/bulk`, { links, dealBased, recipientId, channelUrl }, config())
        .catch(error => traceError(error, `/selling-listing/${id}/links/bulk`, 'POST'));

    const getLinks = id => axios
        .get(`/selling-listing/${id}/links`, config())
        .catch(error => traceError(error, `/selling-listing/${id}/links`, 'GET'));

    const updateLinksPin = linkIds => axios
        .patch(`/selling-listing/links/pin`, { linkIds }, config())
        .catch(error => traceError(error, `/selling-listing/links/pin`, 'PATCH'));

    const removeLinks = linkIds => axios
        .delete(`/selling-listing/links`, { data: { linkIds: linkIds } }, config())
        .catch(error => traceError(error, `/selling-listing/links`, 'DELETE'));

    const uploadDealFiles = (id, files, dealBased, recipientId, channelUrl, onUploadProgress) => {
        const data = FormDataUtil().convert({ files, dealBased, recipientId, channelUrl });
        return axios.post(`/selling-listing/${id}/files`, data, config({
            headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress,
        })).catch(error => traceError(error, `/selling-listing/${id}/files`, 'POST'));
    }

    const getFiles = id => axios
        .get(`/selling-listing/${id}/files`, config())
        .catch(error => traceError(error, `/selling-listing/${id}/files`, 'GET'));

    const getMatchings = id => axios
        .get(`/selling-listing/${id}/matchings`, config())
        .catch(error => traceError(error, `/selling-listing/${id}/matchings`, 'GET'));


    const removeFiles = fileIds => axios
        .delete(`/selling-listing/files`, { data: { fileIds: fileIds } }, config())
        .catch(error => traceError(error, `/selling-listing/files`, 'DELETE'));

    const updateFilesPin = fileIds => axios
        .patch(`/selling-listing/files/pin`, { fileIds }, config())
        .catch(error => traceError(error, `/selling-listing/files/pin`, 'PATCH'));

    const shareFiles = (channelUrl, recipientId, fileIds) => axios
        .post(`/selling-listing/files/share`, { channelUrl, recipientId, fileIds }, config())
        .catch(error => traceError(error, `/selling-listing/files/share`, 'POST'));

    const getSharedFiles = ({ id, ownerId, recipientId }) => {
        let queryParams = `ownerId=${ownerId}&recipientId=${recipientId}${!!id ? `&id=${id}` : ''}`;
        return axios
            .get(`/selling-listing/files/share?${queryParams}`, config())
            .catch(error => traceError(error, `/selling-listing/${id}/files/share`, 'GET'));
    }

    const shareLinks = (channelUrl, recipientId, linkIds) => axios
        .post(`/selling-listing/links/share`, { channelUrl, recipientId, linkIds }, config())
        .catch(error => traceError(error, `/selling-listing/links/share`, 'POST'));

    const getSharedLinks = ({ id, ownerId, recipientId }) => {
        let queryParams = `ownerId=${ownerId}&recipientId=${recipientId}${!!id ? `&id=${id}` : ''}`;
        return axios
            .get(`/selling-listing/links/share?${queryParams}`, config())
            .catch(error => traceError(error, `/selling-listing/${id}/links/share`, 'GET'));
    }

    const postBroadcast = (dealId, message, recipientIds, fileIds, linkIds) => axios
        .post(`/selling-listing/broadcast`, { dealId, message, recipientIds, fileIds, linkIds }, config())
        .catch(error => traceError(error, `/selling-listing/broadcast`, 'POST'));

    const getDealStages = () => axios
        .get('/selling-listing/stages')
        .catch(error => traceError(error, '/selling-listing/stages', 'GET'));

    const postDealStage = (id, data) => axios
        .patch(`/selling-listing/${id}/stages`, data)
        .then(() => {
            if (data.stageId === 2)
                trackListingDeactivate({ isDeactivated: false });
            if (data.stageId === 7)
                trackListingDeactivate({ isDeactivated: true });
        })
        .catch(error => traceError(error, `/selling-listing/${id}/stages`, 'PATCH'));

    const editSellingListing = ({
        id,
        name,
        buyersApprovalEnabled,
        askingPrice,
        completed,
        verified,
        suspended,
        deactivated,
        investmentCategory,
        assetTypeIds,
        organizationStatusId,
        financialOpportunityId,
        investmentType,
        landEntitled,
        openToOwnerFinancing,
        unitsNumber,
        bedsNumber,
        currentBedsOccupancy,
        currentUnitsOccupancy,
        payorMixIds,
        stateId,
        portfolioStateIds,
        additionalInfo,
        additionalInfoTagIds,
    }) =>
        axios
            .put(
                `/selling-listing/${id}`,
                {
                    name,
                    buyersApprovalEnabled,
                    askingPrice,
                    completed,
                    verified,
                    suspended,
                    deactivated,
                    investmentCategory,
                    assetTypeIds,
                    organizationStatusId,
                    financialOpportunityId,
                    investmentType,
                    landEntitled,
                    openToOwnerFinancing,
                    unitsNumber,
                    bedsNumber,
                    currentBedsOccupancy,
                    currentUnitsOccupancy,
                    payorMixIds,
                    stateId,
                    portfolioStateIds,
                    additionalInfo,
                    additionalInfoTagIds,
                },
                config()
            )
            .catch(error => traceError(error, `/selling-listing/${id}`, 'PUT'));

    const pauseLeads = (dealId, isPaused, reasonId, comment) => axios
        .patch(`/selling-listing/${dealId}/pause-leads`, { isPaused, reasonId, comment }, config())
        .catch(error => traceError(error, `/selling-listing/${dealId}/pause-leads`, 'PATCH'));

    return {
        init,
        dispose,
        getAll,
        getAllAdmin,
        getListingsStats,
        get,
        createEmpty,
        create,
        rename,
        updateAssetTypes,
        updateOrganizationStatus,
        updateFinancialOpportunity,
        updateOwnerFinancing,
        updateLandEntitled,
        updateInvestmentType,
        updateState,
        updatePrice,
        getMatchings,
        updateAdditionalInfo,
        updateUnitsNumber,
        updateBedsNumber,
        updateBedsOccupancy,
        updatePayorMixes,
        updateBuyersApproval,
        complete,
        deactivate,
        remove,
        savePrevetBuyers,
        createLink,
        createLinks,
        getLinks,
        updateLinksPin,
        removeLinks,
        uploadDealFiles,
        getFiles,
        removeFiles,
        updateFilesPin,
        shareFiles,
        getSharedFiles,
        shareLinks,
        getSharedLinks,
        postBroadcast,
        editSellingListing,
        pauseLeads,
        getDealStages,
        postDealStage,
    };
}

export default ListingService;