import { getCache } from 'services/common/response-handlers';

function OrganizationsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; }
    
    const getAssetTypes = () => getCache('/organizations/asset-types', controller);
    const getStatuses = () => getCache('/organizations/statuses', controller);
    const getFinancialOpportunities = () => getCache('/organizations/financial-opportunities', controller);
    const getOperatorsAvailabilities = () => getCache('/organizations/operators-availabilities', controller);
    const getInvestmentCategories = () => getCache('/organizations/investment-categories', controller);
    const getBuyingInvestmentTypes = () => getCache('/organizations/buying-investment-types', controller);
    const getSellingInvestmentTypes = () => getCache('/organizations/selling-investment-types', controller);
    const getPayorMixes = () => getCache('/organizations/payor-mixes', controller);
    const getAdditionalInfoTags = () => getCache('/organizations/additional-info-tags', controller);

    return {
        init,
        dispose,
        getAssetTypes,
        getStatuses,
        getFinancialOpportunities,
        getOperatorsAvailabilities,
        getInvestmentCategories,
        getBuyingInvestmentTypes,
        getSellingInvestmentTypes,
        getPayorMixes,
        getAdditionalInfoTags
    };
}

export default OrganizationsService;