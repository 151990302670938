import { useState } from 'react';

import useLoader from 'hooks/useLoader';

import UsersService from 'services/users-service';

const SearchSystemChannels = ({ searchByEmail, setCurrentChannelUrl, usersChannel, setUsersChannel }) => {
    const usersService = UsersService();

    const { load } = useLoader();

    const [inputEmail, setInputEmail] = useState('');
    const [inputFullName, setFullName] = useState('');

    const onClickSearchByEmail = () => {
        load(() =>
            usersService.getSystemChannelsByEmail(inputEmail).then(resp => {
                setUsersChannel(resp.data);
                setCurrentChannelUrl(undefined);
            })
        );
    };

    const onClickSearchByUsername = () => {
        load(() =>
            usersService.getSystemChannelsByName(inputFullName).then(resp => {
                setUsersChannel(resp.data);
                setCurrentChannelUrl(undefined);
            })
        );
    };

    const clearUsersChannels = () => {
        setCurrentChannelUrl(null);
        setInputEmail('');
        setFullName('');
        setUsersChannel([]);
    };

    return (
        <div>
            <div className="p-2">Enter at least 3 characters for the search</div>
            {searchByEmail ? (
                <div className="p-2 border-bottom-grey-200">
                    <div className="row">
                        <div className="col-10">
                            <input
                                type="text"
                                className="c-grey-700 w-100"
                                placeholder="Email"
                                value={inputEmail}
                                onChange={e => setInputEmail(e.target.value)}
                            />
                        </div>
                        <div className="col-2 m-auto">
                            <span className="icon icon-cancel cursor-pointer" onClick={clearUsersChannels}></span>
                        </div>
                    </div>
                    <button
                        className="btn-regular ff-i600 mt-2"
                        disabled={inputEmail?.length < 3}
                        onClick={onClickSearchByEmail}
                    >
                        Search
                    </button>
                </div>
            ) : (
                <div className="p-2 border-bottom-grey-200">
                    <div className="row">
                        <div className="col-10">
                            <input
                                type="text"
                                className="c-grey-700 w-100"
                                placeholder="Name"
                                value={inputFullName}
                                onChange={e => setFullName(e.target.value)}
                            />
                        </div>
                        <div className="col-2 m-auto">
                            <span className="icon icon-cancel cursor-pointer" onClick={clearUsersChannels}></span>
                        </div>
                    </div>
                    <button
                        className="btn-regular ff-i600 mt-2"
                        disabled={inputFullName?.length < 3}
                        onClick={onClickSearchByUsername}
                    >
                        Search
                    </button>
                </div>
            )}
            {usersChannel.map(user => (
                <div
                    className="p-2 c-grey-900 border-bottom-grey-200 cursor-pointer"
                    onClick={() => setCurrentChannelUrl(user.systemChannelUrl)}
                    key={user.systemChannelUrl}
                >
                    {user.fullName}
                </div>
            ))}
        </div>
    );
};

export default SearchSystemChannels;
