import { useMemo, useState } from 'react';
import SellerChannelsWrapper from './SellerChannelsWrapper';

const SellerChannelsListWrapper = ({ userId, currentChannelUrl, onChannelSelect, listing, selectedChannel, setDealName }) => {
    const displayListing = useMemo(
        () => listing.length > 0 && listing.some(x => x.channels?.length > 0),
        [listing]
    );

    const [currentIndex, setCurrentIndex] = useState(0);

    return (
        <div>
            {displayListing &&
                listing.map((sl, index) =>
                    <SellerChannelsWrapper
                        key={!sl.is1KTeamChat ? `sl-${sl.id}` : sl.id}
                        name={!sl.is1KTeamChat ? `Deal #${sl.id}` : sl.description}
                        description={sl.description}
                        channels={sl.channels}
                        userId={userId}
                        currentChannelUrl={currentChannelUrl}
                        onChannelSelect={(url) => {
                            setDealName(sl.description)
                            onChannelSelect(url)
                        }}
                        index={index}
                        setCurrentIndex={setCurrentIndex}
                        openDetails={index === currentIndex}
                        selectedChannel={selectedChannel}
                    />
                )
            }
        </div>
    );
}

export default SellerChannelsListWrapper;