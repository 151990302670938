import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalWindow } from 'react-bootstrap';

export const Modal = ({ isVisible, setIsVisible, header, body, footer, backdrop = false, size = 'md', ...props }) => {
    return (
        <ModalWindow
            show={isVisible}
            onHide={() => setIsVisible(false)}
            centered
            style={{ border: 'none' }}
            backdrop={backdrop ? 'static' : 'false'}
            keyboard={false}
            size={size}
            {...props}
        >
            {header && <section className="border-bottom-grey-150">{header}</section>}
            {body && <section className="border-bottom-grey-150">{body}</section>}
            {footer && <section>{footer}</section>}
        </ModalWindow>
    );
};

Modal.propTypes = {
    isVisible: PropTypes.bool,
    setIsVisible: PropTypes.func,
    header: PropTypes.node,
    backdrop: PropTypes.bool,
    body: PropTypes.node,
    footer: PropTypes.node,
    size: PropTypes.oneOf(['lg', 'md', 'sm']),
};
