import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import PickTimeSlot from 'components/atoms/PickTimeSlot';
import { SendBirdConsts } from 'models/SendBirdConsts';
import useToast from 'hooks/useToast';

import CalendarsService from 'services/calendars-service';
import ChatService from 'services/chat-service';
import useLoader from 'hooks/useLoader';

const calendarsService = CalendarsService();
const chatService = ChatService();

const dateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' };
const dateTimeFormatOptionsHourMinutes = { hour: 'numeric', minute: 'numeric' };

const ChooseMeetingDatetimeModal = ({
    show,
    meetingLength,
    meetingDatetime,
    setMeetingDatetime,
    handleClose,
    sellerId,
    listingId,
    buyerId,
    channelUrl,
    eventDetail,
    rescheduleMeeting = false,
    chatChannel,
    updateRescheduledMeeting,
    setShowChooseMeetingLocationModal,
    setSelectedDate,
    reFetchMeetings = () => { }
}) => {
    const { toast } = useToast();
    const { load } = useLoader();
    const formatDateTime = date => new Intl.DateTimeFormat('en-US', dateTimeFormatOptions).format(date) + 'th';
    const format = 'MM/DD/YYYY';

    const [isCheckedAddPhoneAndLocation, setIsCheckedAddPhoneAndLocation] = useState(false);
    const [schedules, setSchedules] = useState([{ days: [] }]);
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [displayChosenDate, setDisplayChosenDate] = useState(formatDateTime(new Date(meetingDatetime)));
    const [sellerFreeDatetimeSlots, setSellerFreeDatetimeSlots] = useState([]);
    const [timeSlots24Format, setTimeSlots24Format] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
    const [selectedDay, setSelectedDay] = useState();

    const getMonth = (month = dayjs().month()) => {
        month = Math.floor(month);
        const year = dayjs().year();
        const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
        let currentMonthCount = -firstDayOfTheMonth;
        const daysMatrix = new Array(5).fill([]).map(() => {
            return new Array(7).fill(null).map(() => {
                currentMonthCount++;
                return dayjs(new Date(year, month, currentMonthCount));
            });
        });
        return daysMatrix;
    };

    const [currentMonth, setCurrentMonth] = useState(getMonth());

    useEffect(() => {
        setCurrentMonth(getMonth(currentMonthIdx));
    }, [currentMonthIdx]);

    const generateTimeSlots24Format = () => {
        let timeSlots = [];
        let tt = 0;

        for (let i = 0; tt < 24 * 60; i++) {
            let hh = Math.floor(tt / 60);
            let mm = tt % 60;
            timeSlots[i] = { hours: hh, minutes: mm };
            tt = tt + 30;
        }
        return timeSlots;
    };

    useEffect(() => {
        calendarsService.init();
        chatService.init();

        setTimeSlots24Format(generateTimeSlots24Format());

        calendarsService
            .getAvailability(sellerId)
            .then(response => {
                const availability = response.data.workingHours;
                const timeZone = response.data.timeZone;
                if (timeZone) setTimezone(timeZone.name);
                if (availability.length > 0)
                    setSchedules(
                        availability.map(schedule => ({
                            id: schedule.id,
                            days: schedule.days,
                            from: schedule.from,
                            to: schedule.to,
                        }))
                    );
            })
            .catch(error => toast.handleError(error));
        return () => {
            calendarsService.dispose();
            chatService.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAvailabilityFrom = () => {
        const fromHoursInitValue = [6];
        if (!schedules) return fromHoursInitValue;
        const timeSlot = schedules
            .filter(x => !!x.days && x.days?.some(d => d === dayjs(selectedDay).day()))
            .map(value => value.from);
        if (timeSlot.length > 0) return timeSlot;
        return fromHoursInitValue;
    };

    const getAvailabilityTo = () => {
        const toHoursInitValue = [18];
        if (!schedules) return toHoursInitValue;
        const timeSlot = schedules
            .filter(x => !!x.days && x.days?.some(d => d === dayjs(selectedDay).day()))
            ?.map(value => value.to);
        if (timeSlot.length > 0) return timeSlot;
        return toHoursInitValue;
    };

    useEffect(() => {
        const chosenDate = new Date(meetingDatetime);
        const chosenNextDay = new Date(chosenDate);
        chosenNextDay.setDate(chosenDate.getDate() + 1);
        setDisplayChosenDate(formatDateTime(chosenDate));

        load(() =>
            calendarsService
                .getBusySlots(sellerId, chosenDate.toISOString(), chosenNextDay.toISOString())
                .then(response => {
                    const busySellerSlots = response?.data?.timeSlots.map(slot => {
                        return {
                            start: new Date(slot.startTime),
                            end: new Date(slot.endTime),
                        };
                    });
                    const timeSlotsFormat24 = timeSlots24Format.map(
                        slot =>
                            new Date(
                                Date.UTC(
                                    chosenDate.getFullYear(),
                                    chosenDate.getMonth(),
                                    chosenDate.getDate(),
                                    slot.hours,
                                    slot.minutes
                                )
                            )
                    );

                    const fromHoursArray = getAvailabilityFrom();
                    const toHoursArray = getAvailabilityTo();

                    const resultDatetimeSlots = timeSlotsFormat24
                        .map(x => {
                            let res = null;
                            fromHoursArray.forEach((hFrom, index) => {
                                if (x.getHours() >= hFrom && x.getHours() < toHoursArray[index]) {
                                    res = x;
                                }
                            });
                            return res;
                        })
                        .filter(time => !!time)
                        .filter(
                            x =>
                                !busySellerSlots.some(
                                    slot => slot.end.getTime() > x.getTime() && slot.start.getTime() <= x.getTime()
                                )
                        );

                    const availableHoursBetweenAvailabilityUser = toHoursArray.map(hTo => {
                        let res = [];
                        resultDatetimeSlots.forEach(day => {
                            const addedTime = dayjs(day).add(meetingLength, 'minute').get('hours');
                            if (addedTime <= hTo && !res.includes(day)) {
                                res.push(day);
                            }
                        });
                        return res;
                    });

                    const flatArrayByNecessaryHours = availabilities => {
                        return availabilities.reduce((acc, currentValue, index) => {
                            if (index === 0) return acc;
                            if (!currentValue || !currentValue.length) return acc;
                            let previous = availabilities[index - 1];
                            let last = previous[previous.length - 1];
                            let firstIndex = availabilities[index].findIndex(time =>
                                dayjs(last).add(meetingLength, 'minute').isBefore(time)
                            );
                            let result = availabilities[index].slice(firstIndex);
                            return acc.concat(result);
                        }, availabilities[0]);
                    };

                    const findUniqMinutes = (availabilities, options) => {
                        return options.map(val => {
                            let res = [];
                            availabilities.forEach(availableValue => {
                                const availableVal = dayjs(availableValue)
                                    .set('hours', val)
                                    .set('minutes', 0)
                                    .set('seconds', 0);
                                const curValue = dayjs(availableValue).add(meetingLength, 'minute');
                                if (!curValue.isAfter(availableVal) && !res.includes(availableValue)) {
                                    res.push(availableValue);
                                }
                            });
                            return res;
                        });
                    };

                    const filteredOptions = flatArrayByNecessaryHours(
                        findUniqMinutes(flatArrayByNecessaryHours(availableHoursBetweenAvailabilityUser), toHoursArray)
                    );

                    if (!busySellerSlots.length) {
                        return setSellerFreeDatetimeSlots(filteredOptions);
                    }

                    const availableHoursBetweenMeetingsUser = busySellerSlots.map((busyTime, index) => {
                        let res = [];
                        filteredOptions.forEach(day => {
                            const addedTime = dayjs(day).add(meetingLength, 'minute');
                            const startMeeting = dayjs(busyTime.start);
                            const endMeeting = dayjs(busyTime.end);
                            if (addedTime.isSame(startMeeting) || addedTime.isBefore(startMeeting)) {
                                if (!res.includes(day)) {
                                    return res.push(day);
                                }
                            }
                            const lastItem = index === busySellerSlots.length - 1;
                            if (
                                (lastItem && dayjs(day).isSame(endMeeting)) ||
                                (lastItem && dayjs(day).isAfter(endMeeting))
                            ) {
                                return res.push(day);
                            }
                        });
                        return res;
                    });
                    setSellerFreeDatetimeSlots(flatArrayByNecessaryHours(availableHoursBetweenMeetingsUser));
                })
                .catch(error => toast.handleError(error))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingDatetime]);

    const isDayAvailable = day => {
        const nowDay = dayjs().format(format);
        const currentDay = day.format(format);
        return (
            (!!!schedules.some(x => !!x.days && x.days.length > 0) ||
                !!schedules.some(x => x.days.some(d => d === dayjs(day).day()))) &&
            (dayjs(currentDay).isAfter(nowDay) || dayjs(currentDay).isSame(nowDay + 1))
        );
    };

    const setDate = day => {
        if (!isDayAvailable(day)) return;
        const currentDay = day.format(format);
        setMeetingDatetime(currentDay);
        setSelectedDay(day);
    };

    const handlePrevMonth = () => {
        if (dayjs().month() < currentMonthIdx) setCurrentMonthIdx(currentMonthIdx - 1);
    };

    const handleNextMonth = () => {
        setCurrentMonthIdx(currentMonthIdx + 1);
    };

    const getDayClass = day => {
        if (dayjs(selectedDay).isSame(day)) return 'c-white bc-blue-500 rounded-full';
        if (isDayAvailable(day)) return 'c-blue-600 bc-blue-100 rounded-full';
        return 'btn-disable';
    };

    const onClickConfirmMeeting = datetimeSlot => {
        if (isCheckedAddPhoneAndLocation) {
            setSelectedDate(datetimeSlot);
            handleClose();
            return setShowChooseMeetingLocationModal(true);
        }

        const startTime = datetimeSlot;
        const endTime = new Date(new Date(datetimeSlot).setMinutes(startTime.getMinutes() + meetingLength));

        if (!rescheduleMeeting) {
            return load(() =>
                calendarsService
                    .createEvent(+listingId, +sellerId, +buyerId, startTime, endTime)
                    .then(response => {
                        let message = `Date: ${new Intl.DateTimeFormat('en-US', {
                            month: 'numeric',
                            day: 'numeric',
                            year: '2-digit',
                        }).format(endTime)}\n`;
                        message += `Time: ${new Intl.DateTimeFormat('en-US', dateTimeFormatOptionsHourMinutes).format(
                            startTime
                        )}`;
                        message += ` - ${new Intl.DateTimeFormat('en-US', dateTimeFormatOptionsHourMinutes).format(
                            endTime
                        )} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;

                        chatService
                            .postMessage(
                                channelUrl,
                                message,
                                SendBirdConsts.MessageCustomTypeCalendarEvent,
                                response.data
                            )
                            .then()
                            .catch(error => toast.handleError(error))
                            .finally(_ => handleClose());
                    })
                    .then()
                    .catch(error => {
                        toast.handleError(error);
                        handleClose();
                    })
                    .finally(() => reFetchMeetings())
            );
        }
        if (rescheduleMeeting && eventDetail) {
            eventDetail.startTime = startTime;
            eventDetail.endTime = endTime;
            eventDetail.durationInMinutes = meetingLength;

            return load(() =>
                calendarsService
                    .putEvent(eventDetail.id, sellerId, startTime.toISOString(), endTime.toISOString())
                    .then(_ => {
                        if (chatChannel && chatChannel.channel_url) {
                            let message = 'Meeting was rescheduled\n';
                            message += `Date: ${new Intl.DateTimeFormat('en-US', {
                                month: 'numeric',
                                day: 'numeric',
                                year: '2-digit',
                            }).format(endTime)}\n`;
                            message += `Time: ${new Intl.DateTimeFormat(
                                'en-US',
                                dateTimeFormatOptionsHourMinutes
                            ).format(startTime)}`;
                            message += ` - ${new Intl.DateTimeFormat('en-US', dateTimeFormatOptionsHourMinutes).format(
                                endTime
                            )} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;

                            chatService
                                .postMessage(
                                    chatChannel.channel_url,
                                    message,
                                    SendBirdConsts.MessageCustomTypeCalendarEvent,
                                    eventDetail.id
                                )
                                .then()
                                .catch(error => toast.handleError(error))
                                .finally(() => handleClose());
                        }
                    })
                    .then()
                    .catch(error => {
                        toast.handleError(error);
                        handleClose();
                    })
                    .finally(() => updateRescheduledMeeting(eventDetail))
            );
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered size="lg" className="c-grey-700 pb-lg-0">
            <Modal.Header closeButton className="p-3 p-md-4 pb-md-3">
                <Modal.Title className="ps-md-2 h5 ff-i600">Choose a date and time</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-4 text-center">
                <h5 className="mb-3" style={{ fontSize: '26px' }}>
                    {meetingLength} minutes
                </h5>
                <div className="row border-radius border-grey mx-2 mx-md-4">
                    <div className="col-12 col-lg-6 py-2 p-md-4 pe-lg-5">
                        <div className="pt-2">
                            <div className="row align-items-center g-0 mb-3">
                                <div className="col-10 text-start ff-i600 c-grey-700">
                                    {dayjs(new Date(dayjs().year(), currentMonthIdx)).format('MMMM YYYY')}
                                </div>
                                <div className="col-1">
                                    <button className="btn-transparent-round" onClick={handlePrevMonth}>
                                        <span className="icon icon-arrow-right-blue img-rotate-180 pe-none"></span>
                                    </button>
                                </div>
                                <div className="col-1">
                                    <button className="btn-transparent-round" onClick={handleNextMonth}>
                                        <span className="icon icon-arrow-right-blue pe-none"></span>
                                    </button>
                                </div>
                            </div>
                            <div className="row g-0">
                                {currentMonth[0].map((day, i) => (
                                    <div key={i} className="col text-s py-1 text-center">
                                        {day.format('ddd')}
                                    </div>
                                ))}
                            </div>
                            {currentMonth.map((row, index) => (
                                <div className="row g-0" key={`row-of-days-${index}`}>
                                    {row.map((day, idx) => (
                                        <div key={idx} className="col mb-2">
                                            <button
                                                onClick={() => {
                                                    setDate(day);
                                                }}
                                                className={`btn-transparent-round text-s rounded-circle ${getDayClass(
                                                    day
                                                )}`}
                                            >
                                                {day.format('D')}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 c-grey-700 ff-i600 py-4">
                        {!selectedDay && (
                            <div className="d-flex align-items-center justify-content-center h-100 py-4 border-left-grey-200-lg border-top-grey-200-sm">
                                <span>Select a date to view available times.</span>
                            </div>
                        )}
                        {selectedDay && (
                            <div className="border-left-grey-200-lg border-top-grey-200-sm ps-lg-4 h-100">
                                <div className="text-start pt-4">{displayChosenDate}</div>
                                <div className="mt-3 px-2 px-md-0" style={{ maxHeight: '220px', overflowY: 'auto' }}>
                                    {sellerFreeDatetimeSlots?.length === 0 && (
                                        <p className="text-start">
                                            Unfortunately there are no slots available. Please try changing the duration
                                            or contact seller
                                        </p>
                                    )}
                                    {sellerFreeDatetimeSlots?.map(datetimeSlot => (
                                        <PickTimeSlot
                                            key={datetimeSlot}
                                            datetimeSlot={datetimeSlot}
                                            activeTimeSlot={selectedTimeSlot}
                                            setSelectedTimeSlot={setSelectedTimeSlot}
                                            onClickConfirm={onClickConfirmMeeting}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="ff-i600 c-grey-550 d-flex flex-column flex-md-row  align-items-center justify-content-center justify-content-md-between px-4 pt-3">
                    <div className="d-flex align-items-center mt-2">
                        <input
                            id="add-phone-number-and-location"
                            type="checkbox"
                            className="me-2"
                            onChange={() => setIsCheckedAddPhoneAndLocation(!isCheckedAddPhoneAndLocation)}
                            checked={isCheckedAddPhoneAndLocation}
                        />
                        <label htmlFor="add-phone-number-and-location">Add my phone number and location</label>
                    </div>
                    <div className="mt-2 d-flex align-items-center">
                        <span className="icon icon-international me-1"></span>
                        <span>{timezone}</span>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

ChooseMeetingDatetimeModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setMeetingDatetime: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    sellerId: PropTypes.string.isRequired,
    rescheduleMeeting: PropTypes.bool,
};

export default ChooseMeetingDatetimeModal;
