import { useState, useEffect } from 'react';
import Testimonial from 'components/molecules/Testimonial';
import TestimonialsService from 'services/testimonials-service';
import 'styles/pages/_testimonials.scss'
import useToast from 'hooks/useToast';

const testimonialsService = TestimonialsService();

function Testimonials({ userRole }) {
    const { toast } = useToast();
    const [testimonials, setTestimonials] = useState([]);
    const [firstIndex, setFirstIndex] = useState(0);
    const [lastIndex, setLastIndex] = useState(1);
    const [progressTime, setProgressTime] = useState(0);

    useEffect(() => {
        let seconds = 0;
        const intervalId = setInterval(() => {
            seconds = seconds + 1;
            setProgressTime(prev => prev + 1);
        }, 1000);

        testimonialsService.init();
        testimonialsService
            .getAll()
            .then(({ testimonials = [] }) => {
                setProgressTime(0);
                setTestimonials(
                    !userRole ? testimonials : testimonials.filter(t => t.authorRole === userRole.toUpperCase())
                );
            })
            .catch(error => toast.handleError(error));
        return () => {
            testimonialsService.dispose();
            clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole]);

    const previousTestimonial = () => {
        if (testimonials.length === 1)
            return;

        if (firstIndex === 0) {
            setFirstIndex(testimonials.length - 1);
            setLastIndex(testimonials.length);
            return;
        }

        setFirstIndex((firstIndex <= testimonials.length - 1) && firstIndex > 0 ? firstIndex - 1 : testimonials.length);
        setLastIndex((lastIndex <= testimonials.length - 1) && lastIndex > 0 ? lastIndex - 1 : testimonials.length);
    }

    const nextTestimonial = () => {
        if (testimonials.length === 1)
            return;

        if (lastIndex === testimonials.length) {
            setFirstIndex(0);
            setLastIndex(1);
            return;
        }

        setFirstIndex(firstIndex < testimonials.length - 1 ? firstIndex + 1 : testimonials.length);
        setLastIndex(lastIndex < testimonials.length - 1 ? lastIndex + 1 : testimonials.length);
    }

    useEffect(() => {
        if (progressTime > 0 && testimonials.length > 1 && progressTime % 10 === 0) nextTestimonial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progressTime]);

    return (
        <div id="testimonials" name="testimonials" className="bc-white" style={{ paddingBlock: '20px' }}>
            <div className="container">
                <div className="row">
                    {testimonials.filter((_, index) => index === firstIndex).map((item) => {
                        return (
                            <div className="col-12 mb-4 testimonial-tile" key={item.id}>
                                <Testimonial
                                    authorImageUrl={(item.authorImageUrl) ? `${process.env.PUBLIC_URL}${item.authorImageUrl}` : `${process.env.PUBLIC_URL}/images/testimonials/default.png`}
                                    authorRole={item.authorRole}
                                    message={item.message}
                                    author={item.author}
                                    authorJob={item.authorJob} />
                            </div>
                        );
                    })}
                </div>
                {
                    testimonials.length > 1 &&
                    <div className="d-none d-md-block">
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                {
                                    testimonials.map((_, index) => {
                                        return (
                                            <span key={index} className={`dot me-3 ${index === firstIndex ? "active" : ""}`}>
                                                {index === firstIndex && svgCircle()}
                                            </span>);
                                    })
                                }
                            </div>

                            <div className="col d-flex justify-content-end">
                                <button
                                    className="btn arrows"
                                    onClick={() => { previousTestimonial(); setProgressTime(0); }}
                                    style={{ ...arrowsStyles, ...arrowsDesktopStyles }}>&larr;</button>
                                <button
                                    className="btn arrows"
                                    onClick={() => { nextTestimonial(); setProgressTime(0); }}
                                    style={{ ...arrowsStyles, ...arrowsDesktopStyles }}>&rarr;</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    testimonials.length > 1 &&
                    <div className="d-block d-md-none">
                        <div className="row">
                            <div className="col d-flex justify-content-start">
                                <button
                                    className="btn arrows"
                                    onClick={() => { previousTestimonial(); setProgressTime(0); }}
                                    style={arrowsStyles}>&larr;</button>
                            </div>
                            <div className="col d-flex align-items-center justify-content-center">
                                {
                                    testimonials.map((_, index) => {
                                        return (
                                            <span key={index} className={`dot me-3 ${index === firstIndex ? "active" : ""}`}>
                                                {index === firstIndex && svgCircle()}
                                            </span>);
                                    })
                                }
                            </div>

                            <div className="col d-flex justify-content-end">
                                <button
                                    className="btn arrows"
                                    onClick={() => { nextTestimonial(); setProgressTime(0); }}
                                    style={arrowsStyles}>&rarr;</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

const arrowsStyles = {
    border: "none",
    boxShadow: "none",
    fontSize: "22px"
};

const arrowsDesktopStyles = {
    paddingLeft: "12px",
    paddingRight: "12px"
}

const svgCircle = () => <svg><ellipse stroke="#377dff" fill="none" cx="9" cy="9" rx="8" ry="8"></ellipse></svg>;

export default Testimonials;