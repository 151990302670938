import { useEffect, useRef, useState } from 'react';

function useTimeout(callback, delay) {
    const timeoutRef = useRef(null);
    const callbackRef = useRef();
    const [run, setRun] = useState(false);

    // Remember the latest callback.
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);
    
    // Handle immediate trigger and forget.
    useEffect(() => {
        if (!run) return;
        setRun(false);
        if(!timeoutRef.current) return;
        callbackRef.current();
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
    }, [run]);

    const set = () => {
        if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
    };
    const stop = () => {
        if (!timeoutRef.current) return;
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
    };
    const trigger = () => setRun(true);

    return [set, stop, trigger];
}

export default useTimeout;
