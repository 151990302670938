import { Modal } from 'components/atoms/Modal';
import { useState } from 'react';

import MoneyInput from 'components/atoms/MoneyInput';
import ToggleSwitch from 'components/atoms/ToggleSwitch';

const FundsModal = ({ isVisible, handleClose, handleAccept }) => {

    const [capitalAvailable, setCapitalAvailable] = useState(0);
    const [financingCheckbox, setFinancingCheckbox] = useState(false);
    const [exchangeCheckbox, setExchangeCheckbox] = useState(false);

    return (
        <Modal
            isVisible={isVisible}
            backdrop
            size={'lg'}
            header={
                <div className="text-center mt-3 pt-3 pb-3 px-5">
                    <span className="icon icon-1k-logo" />
                    <h4 className="c-grey-700 mt-3">Financial Background</h4>
                </div>
            }
            body={
                <div className="p-4 w-100 d-flex flex-column justify-content-center align-items-center mb-5">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <span className="mt-4">Cash on hand</span>
                        <div>
                            <span className="text-s c-grey-450">Investment</span>
                            <MoneyInput
                                price={capitalAvailable}
                                setPrice={price => {
                                    setCapitalAvailable(price);
                                }}
                                placeholder="Enter your price"
                                skipInitialization={true}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4 w-100">
                        <span>Will you need financing?</span>
                        <ToggleSwitch
                            id={'toggle-switch-financing'}
                            isChecked={financingCheckbox}
                            onChange={() => setFinancingCheckbox(!financingCheckbox)}
                        />
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4 w-100">
                        <span>Currently in 1031 exchange?</span>
                        <ToggleSwitch
                            id={'toggle-switch-financing'}
                            isChecked={exchangeCheckbox}
                            onChange={() => setExchangeCheckbox(!exchangeCheckbox)}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="flex-fill px-5 py-4">
                    <div className="row justify-content-center">
                        <div className="col-6 col-md-4">
                            <button className="btn-secondary ff-i600 w-100 me-2" onClick={handleClose}>
                                Close
                            </button>
                        </div>
                        <div className="col-6 col-md-4">
                            <button
                                color="primary"
                                disabled={!capitalAvailable}
                                className="btn-primary ff-i600 h-100 w-100 me-2"
                                onClick={() => handleAccept(capitalAvailable, financingCheckbox, exchangeCheckbox)}>
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
    );
};

export default FundsModal;