import { Modal } from 'components/atoms/Modal';

const AppPromoBanner = ({ isVisible, handleClose }) => {

    return (
        <Modal
            isVisible={isVisible}
            setIsVisible={handleClose}
            size={'lg'}
            header={
                <>
                    <div className="text-center mt-5 pt-3 pb-3 px-5">
                        <span className="icon icon-1k-logo" />
                        <h4 className="c-grey-700 mt-5">Get The 1Konnection App</h4>
                    </div>
                    <span
                        onClick={handleClose}
                        className="position-absolute cursor-pointer"
                        style={{ top: 25, right: 25 }}>
                        <img src={`${process.env.PUBLIC_URL}/images/icons/remove-grey.svg`} alt="x icon" />
                    </span>
                </>
            }
            body={
                <div className="px-5 mt-3 text-center pb-3 pb-md-5">
                    <h6 className="d-none d-lg-block c-grey-700 ff-i600 text-m pb-3">Scan this QR code to download the app now</h6>
                    <div className="d-lg-none pb-2">
                        <div className="c-grey-700 ff-i600 text-m">Click on QR code to</div>
                        <div className="c-grey-700 ff-i600 text-m">download the app now</div>
                    </div>
                    <a
                        className="d-block d-lg-none"
                        href='https://onelink.to/famtty'
                        target="blank">
                        <img alt="app-qr" src={`${process.env.PUBLIC_URL}/images/app-qr.svg`} />
                    </a>
                    <div className="d-none d-lg-block">
                        <img
                            alt="app-qr"
                            src={`${process.env.PUBLIC_URL}/images/app-qr.svg`}
                            style={{ height: 250, width: 250 }} />
                    </div>
                    <div className="c-grey-700 ff-i600 text-m my-3">Or check it out in the app stores</div>
                    <a
                        href='https://apps.apple.com/us/app/1konnection/id1666912878'
                        target="blank">
                        <span className="icon icon-app-store-button cursor-pointer pe-md-3" />
                    </a>
                    <a
                        href='https://play.google.com/store/apps/details?id=com.onekonnection.app'
                        target="blank">
                        <span className="icon icon-google-play-button cursor-pointer" />
                    </a>
                </div >
            }
            footer={
                <div className="d-flex justify-content-center px-5 py-4" >
                    <span className="ff-i600 c-blue-500 cursor-pointer" onClick={handleClose}>Stay On Web</span>
                </div>
            }
        />
    );
};

export default AppPromoBanner;