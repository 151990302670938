import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { SendBirdProvider } from 'sendbird-uikit';
import 'sendbird-uikit/dist/index.css';

import useQueryParams from 'hooks/useQueryParams';
import useLoader from 'hooks/useLoader';

import { trackPageview } from 'services/common/analytic-trackers';
import CriteriaService from 'services/criteria-service';
import AuthHandler from 'services/common/auth-handler';
import ChatService from 'services/chat-service';
import ContactsService from 'services/contacts-service';

import EmptyChatView from './components/EmptyChatView';
import BuyerChannelsListWrapper from './components/Buyer/BuyerChannelsListWrapper';
import ChannelConversation from './components/ChannelConversation';
import LeadInfoPanelBuyerSide from 'components/organisms/lead-info-panel-buyer-side/LeadInfoPanelBuyerSide';

import useToast from 'hooks/useToast';
import 'styles/pages/_chat.scss';

import useEducationCenter, { BUYER_CHAT_EDUCATION } from 'hooks/useEducationCenter';
import EducationModal from 'components/molecules/education-system/EducationModal';
import Tooltip from 'components/atoms/Tooltip';

const criteriaService = CriteriaService();
const chatService = ChatService();
const authHandler = AuthHandler();
const contactsService = ContactsService();

const ChatBuyerPage = () => {
    const { toast } = useToast();
    const query = useQueryParams();
    const history = useHistory();
    const { load } = useLoader();

    const userId = authHandler.getUserId();
    if (!userId) history.push('/secure', { redirectUrl: history.location.pathname + history.location.search });    
    if (+userId === +process.env.REACT_APP_SEND_BIRD_SYSTEM_USER_ID) history.push('/1k-chats');
    const name = authHandler.getUser()?.name;

    const [showChannelSettings, setShowChannelSettings] = useState(false);
    const [channelsBySellers, setChannelsBySellers] = useState([]);

    const [currentChannelUrl, setCurrentChannelUrl] = useState(query.get('channel'));
    const [allChannels, setAllChannels] = useState([]);
    const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

    const selectedChannel = useMemo(
        () => allChannels.find(ch => ch.channel_url === currentChannelUrl),
        [allChannels, currentChannelUrl]
    );

    /* Education section start */
    const {
        getEducationItem,
        acceptEducation,
        rejectEducation,
        moveNext,
        moveLast } = useEducationCenter();
    const educationScope = BUYER_CHAT_EDUCATION;
    const tooltipsTotal = 3;
    const [educationOverview, setEducationOverview] = useState({});

    const onEducationAccepted = () => acceptEducation(educationScope, educationOverview, setEducationOverview);
    const onEducationRejected = () => rejectEducation(setEducationOverview);
    const onTooltipClicked = (order) => moveNext(educationScope, order, educationOverview, setEducationOverview);
    const onLastTooltipClicked = () => moveLast(educationScope, educationOverview, setEducationOverview);

    const showEducationalTooltip = order =>
        !educationOverview?.rejected
        && educationOverview?.modalShown
        && !educationOverview?.tooltipsShown
        && educationOverview?.order === order;

    /* Education section start */

    useEffect(() => {
        document.title = '1Konnection - Chat Buyer';
        trackPageview();
        criteriaService.init();
        chatService.init();
        contactsService.init();

        setEducationOverview(getEducationItem(educationScope));

        if (userId) {
            load(() => Promise
                .all([
                    chatService.getAllGroupChannelsByMetadata(true),
                    contactsService.getAll()
                ])
                .then(([groupsResponse, contactsResponse]) => {
                    let channels = groupsResponse.data.channels ?? [];
                    const contacts = contactsResponse.data.contacts;
                    if (!channels.some(x => x.channel_url === currentChannelUrl)) setCurrentChannelUrl(undefined);
                    if (channels.length > 0) setAllChannels(channels);
                    channels.forEach(ch => ch.contact = contacts.find(contact => contact.userId === +ch.metadata.sellerId));

                    if (+userId !== +process.env.REACT_APP_SEND_BIRD_SYSTEM_USER_ID) {
                        const buyerChannels = channels.filter(ch => +userId === +ch.metadata?.buyerId);
                        const sellers = buyerChannels.map(x => ({
                            id: x.members.find(member => member.user_id !== userId).user_id,
                            name: x.members.find(member => member.user_id !== userId).nickname
                        }));
                        const sellerIds = [...new Set(sellers.map(seller => seller.id))];
                        const chat1kTeam = channels.find(ch => ch.metadata?.is1KTeamChat);
                        const groupedChannelsBySellers = sellerIds.map(sellerId => ({
                            sellerId,
                            sellerName: sellers.find(seller => seller.id === sellerId).name,
                            channels: buyerChannels.filter(ch => ch.metadata?.sellerId === sellerId),
                        }));
                        if (chat1kTeam) {
                            const channel1k = {
                                sellerId: chat1kTeam.members.find(member => member.user_id !== userId).user_id,
                                sellerName: chat1kTeam.members.find(member => member.user_id !== userId).nickname,
                                channels: [chat1kTeam],
                            }
                            chat1kTeam?.unread_message_count > 0
                                ? groupedChannelsBySellers.unshift(channel1k)
                                : groupedChannelsBySellers.push(channel1k);
                        }
                        setChannelsBySellers(groupedChannelsBySellers);
                    }
                    else {
                        const sellers = channels.map(x => ({
                            id: x.members.find(member => member.user_id !== userId).user_id,
                            name: x.members.find(member => member.user_id !== userId).nickname
                        }));
                        const sellerIds = [...new Set(sellers.map(seller => seller.id))];
                        const groupedChannelsBySellers = sellerIds.map(sellerId => ({
                            sellerId,
                            sellerName: sellers.find(seller => seller.id === sellerId).name,
                            channels: channels.filter(ch => !!ch.members.filter(c => +c.user_id === +sellerId).length),
                        }));
                        setChannelsBySellers(groupedChannelsBySellers);
                    }
                    setIsAllDataLoaded(true);
                })
                .catch(error => {
                    toast.handleError(error);
                    setIsAllDataLoaded(true);
                }
                ));
        }

        return () => {
            criteriaService.dispose();
            chatService.dispose();
            contactsService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty("--sidebar-text-display", showChannelSettings ? 'none' : 'block');
        root?.style.setProperty("--sidebar-width", !showChannelSettings ? '250px' : '75px');

        return () => {
            root?.style.setProperty("--sidebar-text-display", 'block');
            root?.style.setProperty("--sidebar-width", "250px");
        }
    }, [showChannelSettings]);

    return (
        <>
            {isAllDataLoaded &&
                <>
                    {userId && name && channelsBySellers.length > 0 ?
                        <SendBirdProvider appId={process.env.REACT_APP_SEND_BIRD_ID} userId={userId} nickname={name}>
                            <div className="chat d-flex border-grey bc-white ff-i600">
                                <Tooltip
                                    show={showEducationalTooltip(0)}
                                    content="On the left panel, you’ll find all your contacts that you’ve started a chat with. For ease of organization, your chats are sorted by Deal, with each contact listed under the deal. Each chat history is attached to the deal. So, if you have separate chats with the same buyer, those chats (and the corresponding docs) will stay separate."
                                    secondaryContent={`Step 1/${tooltipsTotal}`}
                                    direction="right"
                                    bodyClasses={`channel-list d-md-flex ${currentChannelUrl ? 'display-none' : 'd-flex'}`}
                                    additionalClasses="d-none d-lg-block"
                                    ctaText="Next"
                                    ctaAction={() => onTooltipClicked(1)}>
                                    <Tooltip
                                        show={showEducationalTooltip(0)}
                                        content="On this page, you’ll find all your contacts that you’ve started a chat with. For ease of organization, your chats are sorted by Deal, with each contact listed under the deal. Each chat history is attached to the deal. So, if you have separate chats with the same buyer, those chats (and the corresponding docs) will stay separate."
                                        secondaryContent={`Step 1/${tooltipsTotal}`}
                                        direction="bottom"
                                        bodyClasses="d-lg-none"
                                        additionalClasses="d-block d-lg-none offset-top"
                                        ctaText="Next"
                                        ctaAction={() => onTooltipClicked(1)}>
                                    </Tooltip>
                                    <div className="sendbird-channel-list__body">
                                        <div className="d-flex ps-1 pt-1">
                                            <div className="d-flex align-items-center c-grey-550 text-decoration-none p-2 link cursor-pointer"
                                                onClick={() => history.goBack()}>
                                                <span className="icon icon-arrow-back-primary icon-grayscale me-2" />
                                                <span className="ff-i600">Go Back</span>
                                            </div>
                                        </div>
                                        <BuyerChannelsListWrapper
                                            userId={userId}
                                            currentChannelUrl={currentChannelUrl}
                                            onChannelSelect={(newUrl) => setCurrentChannelUrl(newUrl)}
                                            channelsBySellers={channelsBySellers}
                                            selectedChannel={selectedChannel}
                                        />
                                    </div>
                                </Tooltip>

                                <ChannelConversation
                                    currentChannelUrl={currentChannelUrl}
                                    showChannelSettings={selectedChannel?.metadata?.is1KTeamChat ? false : showChannelSettings}
                                    userId={userId}
                                    sellerView={false}
                                    setCurrentChannelUrl={setCurrentChannelUrl}
                                    setShowChannelSettings={() => setShowChannelSettings(!showChannelSettings)}
                                    selectedChannel={selectedChannel}
                                    messageEducation={
                                        {
                                            show: showEducationalTooltip(1),
                                            total: tooltipsTotal,
                                            moveNext: onTooltipClicked
                                        }
                                    }
                                    manageLeadEducation={
                                        {
                                            show: showEducationalTooltip(2),
                                            total: tooltipsTotal,
                                            moveNext: onLastTooltipClicked
                                        }
                                    }
                                    is1KTeamChat={selectedChannel?.metadata?.is1KTeamChat}
                                />
                                {currentChannelUrl && !selectedChannel?.metadata?.is1KTeamChat &&
                                    <div className={`channel-settings ${showChannelSettings ? 'd-block' : 'd-none'}`}>
                                        <LeadInfoPanelBuyerSide
                                            channelUrl={currentChannelUrl}
                                            isVisible={showChannelSettings}
                                            selectedChannel={selectedChannel}
                                            setIsVisible={setShowChannelSettings}
                                            userId={userId.toString()}
                                        />
                                    </div>
                                }
                            </div>
                            <EducationModal
                                isVisible={!educationOverview?.rejected && !educationOverview?.modalShown && (userId && name && channelsBySellers.length > 0)}
                                title={'Chat'}
                                paragraphs={
                                    [
                                        { text: 'Securely chat with your contacts and sellers you’ve connected with, cutting down the communication lag time from days to seconds. Our Chat Panel gives you quick access to your trusted contacts and instantaneous communication. Batter yet? You can securely share docs without leaving the chat.' },
                                        { text: 'Let’s find out how to stay in touch on the go!', bold: true }
                                    ]}
                                handleAccept={onEducationAccepted}
                                handleClose={onEducationRejected} />
                        </SendBirdProvider>
                        : <EmptyChatView isSeller={false} />
                    }
                </>
            }
        </>
    );
}

export default ChatBuyerPage;