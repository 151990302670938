import axios from 'axios';
import { traceError } from 'services/common/response-handlers';
import { trackCriteriaFinish, trackCriteriaStart } from './common/analytic-trackers';

function CriteriaService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; }
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getCriteriaStats = completed => axios
        .get(`/users/stats/criteria${completed ? '?completed=true' : ''}`)
        .catch(error => traceError(error, `/api/users/stats/criteria${completed ? '?completed=true' : ''}`, 'GET'));

    const getAll = userId => axios
        .get(`/buying-criteria?userId=${userId}`, config())
        .catch(error => traceError(error, `/buying-criteria?userId=${userId}`, 'GET'));

    const getAllCompleted = userId => axios
        .get(`/buying-criteria?userId=${userId}&completed=true`, config())
        .catch(error => traceError(error, `/buying-criteria?userId=${userId}`, 'GET'));

    const get = id => axios
        .get(`/buying-criteria/${id}`, config())
        .catch(error => traceError(error, `/buying-criteria/${id}`, 'GET'));

    const createEmpty = userId => axios
        .post('/buying-criteria/empty', { userId }, config())
        .then(response => {
            getCriteriaStats().then(res => trackCriteriaStart({ count: res?.data?.count }));
            return response;
        })
        .catch(error => traceError(error, '/buying-criteria/empty', 'POST'));

    const create = criteria => axios
        .post('/buying-criteria', criteria, config())
        .catch(error => traceError(error, '/buying-criteria', 'POST'));

    const update = (id, criteria) => axios
        .post(`/buying-criteria/${id}`, criteria, config())
        .catch(error => traceError(error, `/buying-criteria/${id}`, 'PUT'));

    const rename = (id, name) => axios
        .patch(`/buying-criteria/${id}/name`, { name }, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/name`, 'PATCH'));

    const updateInvestmentType = (id, investmentType) => axios
        .patch(`/buying-criteria/${id}/investment-type`, { investmentType }, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/investment-type`, 'PATCH'));

    const updateAssetTypes = (id, assetTypeIds) => axios
        .patch(`/buying-criteria/${id}/asset-types`, { assetTypeIds }, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/asset-types`, 'PATCH'));

    const updateInvestmentCategory = (id, investmentCategory) => axios
        .patch(`/buying-criteria/${id}/investment-category`, { investmentCategory }, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/investment-category`, 'PATCH'));

    const updateOrganizationStatuses = (id, statusIds) => axios
        .patch(`/buying-criteria/${id}/organization-statuses`, { statusIds }, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/organization-statuses`, 'PATCH'));

    const updateFinancialOpportunities = (id, opportunityIds) => axios
        .patch(`/buying-criteria/${id}/financial-opportunities`, { opportunityIds }, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/financial-opportunities`, 'PATCH'));

    const updateOperatorsAvailability = (id, operatorsAvailability) => axios
        .patch(`/buying-criteria/${id}/operators-availability`, { operatorsAvailability }, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/operators-availability`, 'PATCH'));

    const updateBedRange = (id, minBedsNumber, maxBedsNumber) => axios
        .patch(`/buying-criteria/${id}/bed-range`, { minBedsNumber, maxBedsNumber }, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/bed-range`, 'PATCH'));

    const updateStandaloneStates = (id, stateIds) => axios
        .patch(`/buying-criteria/${id}/standalone-states`, { stateIds }, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/standalone-states`, 'PATCH'));

    const updatePortfoliosStates = (id, stateIds) => axios
        .patch(`/buying-criteria/${id}/portfolios-states`, { stateIds }, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/portfolios-states`, 'PATCH'));

    const complete = id => axios
        .patch(`/buying-criteria/${id}/complete`, null, config())
        .then(response => {
            getCriteriaStats(true).then(res => trackCriteriaFinish({ count: res?.data?.count }));
            return response;
        })
        .catch(error => traceError(error, `/buying-criteria/${id}/complete`, 'PATCH'));

    const deactivate = (id, deactivated) => axios
        .patch(`/buying-criteria/${id}/deactivate/${deactivated}`, null, config())
        .catch(error => traceError(error, `/buying-criteria/${id}/deactivate/${deactivated}`, 'PATCH'));

    const remove = id => axios
        .delete(`/buying-criteria/${id}`, config())
        .catch(error => traceError(error, `/buying-criteria/${id}`, 'DELETE'));

    const editCriteria = ({
        id,
        name,
        investmentType,
        capitalAvailableInvestment,
        investmentCategory,
        operatorsAvailability,
        minBedsNumber,
        maxBedsNumber,
        assetTypeIds,
        financialOpportunityIds,
        organizationStatusIds,
        standaloneStateIds,
        portfoliosStateIds,
    }) =>
        axios
            .put(
                `/buying-criteria/${id}/full`,
                {
                    name,
                    investmentType,
                    capitalAvailableInvestment,
                    investmentCategory,
                    operatorsAvailability,
                    minBedsNumber,
                    maxBedsNumber,
                    assetTypeIds,
                    financialOpportunityIds,
                    organizationStatusIds,
                    standaloneStateIds,
                    portfoliosStateIds,
                },
                config()
            )
            .catch(error => traceError(error, `/buying-criteria/${id}/full`, 'PUT'));

    return {
        init,
        dispose,
        getAll,
        getAllCompleted,
        get,
        createEmpty,
        create,
        update,
        rename,
        updateInvestmentType,
        updateAssetTypes,
        updateInvestmentCategory,
        updateOrganizationStatuses,
        updateFinancialOpportunities,
        updateOperatorsAvailability,
        updateBedRange,
        updateStandaloneStates,
        updatePortfoliosStates,
        complete,
        deactivate,
        remove,
        editCriteria
    };
}

export default CriteriaService;