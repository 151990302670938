import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SendBirdProvider } from 'sendbird-uikit';

import useToast from 'hooks/useToast';
import useLoader from 'hooks/useLoader';

import 'styles/pages/_chat.scss';

import AuthHandler from 'services/common/auth-handler';
import ChatService from 'services/chat-service';
import { trackPageview } from 'services/common/analytic-trackers';

import EmptyChatView from './components/EmptyChatView';
import ChannelConversation from './components/ChannelConversation';
import UserChannelsListWrapper from './components/System1K/UserChannelsListWrapper';
import SearchSystemChannels from './components/System1K/SearchSystemChannels';
import ToggleSwitch from 'components/atoms/ToggleSwitch';

const chatService = ChatService();
const authHandler = AuthHandler();

const ChatSystemUser = () => {
    const { toast } = useToast();
    const history = useHistory();
    const { load } = useLoader();

    const userId = authHandler.getUserId();
    if (!userId) history.push('/secure', { redirectUrl: history.location.pathname + history.location.search });
    if (+userId !== +process.env.REACT_APP_SEND_BIRD_SYSTEM_USER_ID) history.push('/forbidden');
    const name = authHandler.getUser()?.name;

    const [nextToken, setNextToken] = useState('');
    const [channelsBySellers, setChannelsBySellers] = useState([]);

    const [currentChannelUrl, setCurrentChannelUrl] = useState();
    const [allChannels, setAllChannels] = useState([]);
    const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

    const [showSearchInput, setShowSearchInput] = useState(false);
    const [searchByEmail, setSearchByEmail] = useState(false);
    const [searchedUsersChannel, setSearchedUsersChannel] = useState([]);

    const selectedChannel = useMemo(() => {
        let channel = allChannels.find(ch => ch.channel_url === currentChannelUrl);
        if (channel) {
            channel.contact = {
                userId: +channel.members.find(member => member.user_id !== userId)?.user_id,
            };
        }
        return channel;
    }, [allChannels, currentChannelUrl, userId]);

    const selectedSystemChannel = useMemo(() => {
        if (!showSearchInput) return undefined;
        let systemChannel = searchedUsersChannel.find(ch => ch.systemChannelUrl === currentChannelUrl);
        if (systemChannel) {
            systemChannel.contact = {
                fullName: systemChannel.fullName,
            };
            systemChannel.members = [
                {
                    user_id: -1,
                    nickname: systemChannel.fullName,
                },
            ];
        }
        return systemChannel;
    }, [showSearchInput, currentChannelUrl, searchedUsersChannel]);

    useEffect(() => {
        document.title = '1Konnection - Chat System User';
        trackPageview();
        chatService.init();

        if (userId) {
            load(() =>
                Promise.all([chatService.getAllGroupChannelsPagination()])
                    .then(([groupsResponse]) => {
                        if (groupsResponse.data.nextToken) setNextToken(groupsResponse.data.nextToken);
                        let channels = groupsResponse.data.channels ?? [];
                        if (channels.length > 0) setAllChannels(channels);

                        const sellers = channels.map(x => ({
                            id: x.members.find(member => member.user_id !== userId).user_id,
                            name: x.members.find(member => member.user_id !== userId).nickname,
                        }));
                        const sellerIds = [...new Set(sellers.map(seller => seller.id))];
                        const groupedChannelsBySellers = sellerIds.map(sellerId => ({
                            sellerId,
                            sellerName: sellers.find(seller => seller.id === sellerId).name,
                            channels: channels.filter(ch => !!ch.members.filter(c => +c.user_id === +sellerId).length),
                        }));
                        setChannelsBySellers(groupedChannelsBySellers);
                        setIsAllDataLoaded(true);
                    })
                    .catch(error => {
                        toast.handleError(error);
                        setIsAllDataLoaded(true);
                    })
            );
        }

        return () => {
            chatService.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadNextChannels = () => {
        load(() =>
            chatService
                .getAllGroupChannelsPagination(nextToken)
                .then(response => {
                    let channels = response.data.channels ?? [];
                    if (channels.length > 0) setAllChannels(prev => [...prev, ...channels]);

                    const sellers = channels.map(x => ({
                        id: x.members.find(member => member.user_id !== userId).user_id,
                        name: x.members.find(member => member.user_id !== userId).nickname,
                    }));
                    const sellerIds = [...new Set(sellers.map(seller => seller.id))];
                    const groupedChannelsBySellers = sellerIds.map(sellerId => ({
                        sellerId,
                        sellerName: sellers.find(seller => seller.id === sellerId).name,
                        channels: channels.filter(ch => !!ch.members.filter(c => +c.user_id === +sellerId).length),
                    }));

                    setChannelsBySellers(prev => [...prev, ...groupedChannelsBySellers]);
                    setNextToken(response.data.nextToken ? response.data.nextToken : '');
                })
                .catch(error => toast.handleError(error))
        );
    };

    return (
        <>
            {isAllDataLoaded && name && channelsBySellers.length > 0 ? (
                <SendBirdProvider appId={process.env.REACT_APP_SEND_BIRD_ID} userId={userId} nickname={name}>
                    <div className="chat d-flex border-grey bc-white ff-i600">
                        <div className={`channel-list ${currentChannelUrl ? 'display-none' : 'd-flex'}`}>
                            <div className="sendbird-channel-list__body">
                                <div className="d-flex ps-1 pt-1 border-bottom-grey-200" style={headerChannelsStyles}>
                                    <div className="d-flex align-items-center justify-content-between c-grey-550 py-3 px-2 w-100">
                                        <button
                                            className="btn-white ff-i600 py-1 px-2"
                                            onClick={() => {
                                                setShowSearchInput(prev => !prev);
                                                setCurrentChannelUrl(undefined);
                                            }}
                                        >
                                            {showSearchInput ? 'Hide search' : 'Search'}
                                        </button>
                                        {showSearchInput ? (
                                            <div>
                                                <ToggleSwitch
                                                    id="toggle-switch-search"
                                                    labelText={searchByEmail ? 'by email' : 'by name'}
                                                    onChange={() => setSearchByEmail(prev => !prev)}
                                                    isChecked={searchByEmail}
                                                />
                                            </div>
                                        ) : (
                                            <span className="ff-i600">Chats: {channelsBySellers.length}</span>
                                        )}
                                    </div>
                                </div>
                                {showSearchInput ? (
                                    <SearchSystemChannels
                                        searchByEmail={searchByEmail}
                                        setCurrentChannelUrl={setCurrentChannelUrl}
                                        usersChannel={searchedUsersChannel}
                                        setUsersChannel={setSearchedUsersChannel}
                                    />
                                ) : (
                                    <>
                                        <UserChannelsListWrapper
                                            userId={userId}
                                            currentChannelUrl={currentChannelUrl}
                                            onChannelSelect={newUrl => setCurrentChannelUrl(newUrl)}
                                            channelsBySellers={channelsBySellers}
                                        />
                                        {nextToken && (
                                            <div
                                                className="py-3 mt-auto border-top-grey text-center cursor-pointer c-white bc-blue-500"
                                                onClick={loadNextChannels}
                                            >
                                                Load more
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <ChannelConversation
                            currentChannelUrl={currentChannelUrl}
                            showChannelSettings={false}
                            userId={userId}
                            sellerView={false}
                            setCurrentChannelUrl={setCurrentChannelUrl}
                            setShowChannelSettings={() => {}}
                            selectedChannel={showSearchInput ? selectedSystemChannel : selectedChannel}
                            is1KTeamChat={true}
                        />
                    </div>
                </SendBirdProvider>
            ) : (
                <EmptyChatView isSeller={false} />
            )}
        </>
    );
};

const headerChannelsStyles = {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 1,
};

export default ChatSystemUser;
