import { useState } from 'react';
import { Switch, NavLink, useRouteMatch, useLocation, Redirect, useHistory } from 'react-router-dom';
import GeneralInformation from './components/GeneralInformation';
import Security from './components/Security';

import AuthRoute from 'guards/AuthRoute';

const SETTINGS_PATH = `/settings`;
const SECURITY_PATH = `/settings/security`;

const SettingsPage = () => {
    let { path, url } = useRouteMatch();
    let location = useLocation();
    const history = useHistory();

    const [showTabs, setShowTabs] = useState(location.state && location.state.hideNavTabs ? false : true);

    return (<>
        <div className="ms-5 ps-5 pt-4 d-none d-lg-block" onClick={() => history.goBack()}>
            <span className="icon cursor-pointer icon-arrow-back-black" style={{ width: '25px', verticalAlign: 'top' }}></span>
            <span> Go Back</span>
        </div>
        <div className='container mt-5 px-4 px-md-3 mt-lg-3'>
            {!showTabs &&
                <div className='d-md-none'>
                    <NavLink
                        to={`${url}`}
                        onClick={() => setShowTabs(true)}
                        className='d-inline-block c-grey-700 ff-i600 bc-grey-100 text-decoration-none border-radius-large p-3'>
                        <span className='d-flex align-items-center'>
                            <span className='icon icon-arrow-right-grey img-rotate-180' style={{ width: '7px' }}></span>
                            <span className='ps-2'>Back to Categories</span>
                        </span>
                    </NavLink>
                    <div className='ff-i600 c-grey-700 my-3'>
                        {(location.pathname === '/settings') &&
                            <span className='text-m'>General Information</span>
                        }
                        {(location.pathname === '/settings/security') &&
                            <span className='text-m'>Security</span>
                        }
                    </div>
                </div>
            }
            <div className='row bc-white border-grey-200 border-radius-small mb-5'>
                <div className='col-12 col-md-5 col-lg-4 col-xl-3 px-0 pt-md-5 border-right-grey-100-md'>
                    {/* Mobile Tabs */}
                    {showTabs &&
                        <div className='d-md-none'>
                            <NavLink
                                to={`${url}`}
                                onClick={() => setShowTabs(false)}
                                className='d-flex align-items-center py-3 ps-4 border-bottom-grey-100 ff-i600 c-grey-700 text-decoration-none position-relative'>
                                <div className='d-flex justify-content-center' style={{ width: '40px' }}>
                                    <span className='icon icon-toggle'></span>
                                </div>
                                <span className='ps-3'>General Information</span>
                                <span className='icon icon-arrow-right-grey me-4 ms-auto'></span>
                            </NavLink>
                            <NavLink
                                to={`${url}/security`}
                                onClick={() => setShowTabs(false)}
                                className='d-flex align-items-center py-3 ps-4 border-bottom-grey-100 ff-i600 c-grey-700 text-decoration-none position-relative'>
                                <div className='d-flex justify-content-center' style={{ width: '40px' }}>
                                    <span className='icon icon-lock'></span>
                                </div>
                                <span className='ps-3'>Security</span>
                                <span className='icon icon-arrow-right-grey me-4 ms-auto'></span>
                            </NavLink>
                        </div>
                    }
                    {/* Desktop Tabs */}
                    <div className='d-none d-md-block' style={{ minHeight: '512px' }}>
                        <NavLink
                            to={`${url}`}
                            className={`d-flex align-items-center py-3 border-bottom-grey-100 tab ff-i600 text-decoration-none ps-3
                            ${(location.pathname === SETTINGS_PATH)
                                    ? 'border-right-blue-500-md c-blue-500 bc-blue-100'
                                    : 'c-grey-700 bg-transparent'}`}>
                            <div className='d-flex justify-content-center' style={{ width: '40px' }}>
                                <span className={`icon icon-toggle ${location.pathname === SETTINGS_PATH ? '' : 'icon-grayscale'}`}></span>
                            </div>
                            <span className='ps-3'>General Information</span>
                        </NavLink>
                        <NavLink
                            to={`${url}/security`}
                            className={`d-flex align-items-center py-3 border-bottom-grey-100 tab ff-i600 text-decoration-none ps-3
                            ${(location.pathname === SECURITY_PATH)
                                    ? 'border-right-blue-500-md c-blue-500 bc-blue-100'
                                    : 'c-grey-700 bg-transparent'}`}>
                            <div className='d-flex justify-content-center' style={{ width: '40px' }}>
                                <span className={`icon icon-lock ${location.pathname === SECURITY_PATH ? '' : 'icon-grayscale'}`}></span>
                            </div>
                            <span className='ps-3'>Security</span>
                        </NavLink>
                    </div>
                </div>

                <div className='col-12 col-md-7 col-lg-8 col-xl-9'>
                    {!showTabs &&
                        <div className='d-md-none'>
                            <Switch>
                                <AuthRoute path={path} exact component={GeneralInformation} />
                                <AuthRoute path={`${path}/security`} component={Security} />
                                <Redirect from={`${path}/**/`} to='/not-found' />
                            </Switch>
                        </div>
                    }
                    <div className='d-none d-md-block'>
                        <Switch>
                            <AuthRoute path={path} exact component={GeneralInformation} />
                            <AuthRoute path={`${path}/security`} component={Security} />
                            <Redirect from={`${path}/**/`} to='/not-found' />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default SettingsPage;