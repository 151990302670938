import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import useLoader from 'hooks/useLoader';

import { trackPageview } from 'services/common/analytic-trackers';
import AuthHandler from 'services/common/auth-handler';
import UsersService from 'services/users-service';

import PasswordInput from 'components/atoms/PasswordInput';

const authHandler = AuthHandler();
const usersService = UsersService();
const SetNewPassword = () => {
    const { load } = useLoader();
    const history = useHistory();
    const passwordRef = useRef();
    const confirmationRef = useRef();
    const user = authHandler.getUser();

    useEffect(() => {
        document.title = '1Konnection - Set New Password';
        trackPageview();
        usersService.init();

        return () => usersService.dispose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [password, setPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(false);

    const [confirmation, setConfirmation] = useState('');
    const [isValidConfirmation, setIsValidConfirmation] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmationShown, setConfirmationShown] = useState(false);

    const [confirmationDoesNotMatchErrorMessage, setConfirmationDoesNotMatchErrorMessage] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        setConfirmationDoesNotMatchErrorMessage(false);
        confirmationRef.current.classList.toggle('invalid', false);
        if (confirmation !== password) {
            confirmationRef.current.classList.toggle('invalid', true);
            setConfirmationDoesNotMatchErrorMessage(true);
            return;
        }
    }, [confirmation, password]);

    const onContinueClick = () => {
        setDisableButton(true);
        load(() => usersService
            .setNewPassword(password)
            .then(() =>
                user.identitytype === 'Buyer' || user.identitytype === 'Broker - Buying'
                    ? history.push('/buyer-dashboard')
                    : history.push('/seller-dashboard')
            )
            .finally(() => setDisableButton(false)));
    }

    return (
        <div className="container mb-md-5 py-5">
            <div className="row bc-white box-shadow mt-md-5 pb-4 pb-md-5 gx-0 border-radius-small">
                <div className="col-12 text-center pt-4 pt-md-5">
                    <h4 className="c-grey-900 pb-md-2">Set your new password</h4>
                </div>
                <div className="col-12 col-md-6 offset-md-3 text-center mt-4 px-2 position-relative">
                    <PasswordInput
                        passwordShown={passwordShown}
                        setPasswordShown={setPasswordShown}
                        password={password}
                        setPassword={setPassword}
                        passwordRef={passwordRef}
                        isValid={setIsValidPassword}
                        placeholder="New password" />
                </div>
                <div className="col-12 col-md-6 offset-md-3 text-center mt-4 px-2 position-relative">
                    <PasswordInput
                        passwordShown={confirmationShown}
                        setPasswordShown={setConfirmationShown}
                        password={confirmation}
                        setPassword={setConfirmation}
                        passwordRef={confirmationRef}
                        isValid={setIsValidConfirmation}
                        placeholder="Confirm new password" />
                    {confirmation && confirmationDoesNotMatchErrorMessage &&
                        <div className="text-start c-red-500 px-2 text-s">
                            Your confirmation password does not match
                        </div>
                    }
                </div>
                <div className="col-12 col-md-6 offset-md-3 mt-4 mb-md-5 pb-md-3 px-2 c-grey-550">
                    <button
                        className="btn-primary ff-i600 w-100 mt-4"
                        onClick={onContinueClick}
                        disabled={!isValidPassword || !isValidConfirmation || password !== confirmation || disableButton}>Continue</button>
                </div>
            </div>
        </div>
    );
};

export default SetNewPassword;