const Pie = ({ percentOfCompleteness = 100 }) => {
    const countOfSectors = 10;
    const arr = Array.from({ length: countOfSectors }, (_, i) => i);

    const completeness = () => {
        if (percentOfCompleteness < 40)
            return 'red';
        if (percentOfCompleteness < 80)
            return 'yellow';
        return 'green';
    }

    return (
        <div className="progress-circle-wrapper">
            <div className="text-container">
                <h2 className={`c-grey-700 text-center c-${completeness()}-500`}>{percentOfCompleteness}%</h2>
            </div>
            {arr.map((item, index) => (
                <div
                    key={item}
                    className={`progress-sector ${index * 10 < percentOfCompleteness ? `completed ${completeness()}` : 'not-completed'}`}
                    style={{ transform: `rotate(${90 + (36 * item)}deg) skew(52deg)` }}
                />
            ))}
        </div>
    );
};

export default Pie;