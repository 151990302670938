import { useRef } from 'react';

function useScroll() {
    const elRef = useRef(null);
    const scroll = () => elRef
        && elRef.current
        && elRef.current.scrollIntoView
        && elRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        })
        && elRef.current.focus
        && elRef.current.focus();

    return [scroll, elRef];
}

export default useScroll;