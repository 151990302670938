import { useState, useEffect, useRef, useContext, useLayoutEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { trackLogout } from 'services/common/analytic-trackers';
import AuthService from 'services/auth-service';
import AuthHandler from 'services/common/auth-handler';
import { getRandomColor, Colors } from 'models/Colors';
import useToast from 'hooks/useToast';
import useLocalStorage from 'hooks/useLocalStorage';
import { useNotifications } from '../contexts/NotificationsContext';
import AppPromoBanner from 'components/molecules/app-promo-banner/AppPromoBanner';
import UserNameContext from 'contexts/UserNameContext';
import NewMatchesContext from 'contexts/NewMatchesContext';
import UsersService from 'services/users-service';
import useLoader from 'hooks/useLoader';

const authService = AuthService();
const authHandler = AuthHandler();
const usersService = UsersService();

function Header() {
    const { toast } = useToast();
    const { load } = useLoader();
    const { clearStorage } = useLocalStorage();
    const location = useLocation();
    const history = useHistory();
    const navbarRef = useRef();

    const LANDING_PATH = '/';
    const BROKERS_LANDING_PATH = '/brokers';
    const SELLERS_LANDING_PATH = '/sellers';
    const BUYERS_LANDING_PATH = '/buyers';
    const BUYERS_DASHBOARD_PATH = '/buyer-dashboard';
    const SELLERS_DASHBOARD_PATH = '/seller-dashboard';
    const CHAT_BUYER = '/chat-buyer';
    const CHAT_SELLER = '/chat-seller';
    const SETTINGS_PATH = '/settings';
    const ADMIN_PATH = '/admin';
    const FAQS_PATH = '/help-center';
    const NOTIFICATIONS_PATH = '/notifications';
    const CONTACT_US_PATH = '/contact-us';
    const BADGE_COLOR_KEY = 'badge-color';
    const CONTACT_SALES_PATH = '/contact-sales';

    const [auth, setAuth] = useState(authHandler.getAuth());
    const [isMobile, setIsMobile] = useState(false);
    const [isTransparentHeader, setIsTransparentHeader] = useState(false);
    const [badgeColor, setBadgeColor] = useState(Colors.Blue);
    const [user, setUser] = useState({});
    const [show, setShow] = useState(false);
    const [userFullName, setUserFullName] = useState(null);

    const { notificationsData } = useNotifications();

    const [isShowAppModal, setIsShowAppModal] = useState(false);
    const { updatedUserName } = useContext(UserNameContext);
    const { newMatchesAmount, setNewMatchesAmount } = useContext(NewMatchesContext);

    useEffect(() => {
        authService.init();
        usersService.init();
        const unsubscribe = authHandler.subscribe(auth => setAuth(auth));

        if (auth.isAuthenticated)
            usersService
                .getStats()
                .then(statsResponse => setNewMatchesAmount(statsResponse?.data?.stats?.newDealsAmount));

        let badgeColor = window.localStorage.getItem(BADGE_COLOR_KEY);
        if (!badgeColor) {
            badgeColor = getRandomColor();
            window.localStorage.setItem(BADGE_COLOR_KEY, badgeColor);
        }
        setBadgeColor(badgeColor);

        return () => {
            authService.dispose();
            usersService.dispose();
            unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const user = authHandler.getUser();
        setUser(user);

        if (user)
            load(() =>
                usersService
                    .get(user.sid)
                    .then(response => setUserFullName(response?.data?.user?.fullName))
                    .catch(error => toast.handleError(error))
            );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const isLandingPage = () =>
            location.pathname === LANDING_PATH ||
            location.pathname === BROKERS_LANDING_PATH ||
            location.pathname === SELLERS_LANDING_PATH ||
            location.pathname === BUYERS_LANDING_PATH;

        const mediaQuery = window.matchMedia('(max-width: 991px)');
        const onMediaQueryChange = (e) => setIsMobile(e.matches);

        let scrollTimeout = null;

        const onPageScroll = () => {
            if (!isLandingPage() || isMobile) {
                return;
            }

            if (scrollTimeout) {
                clearTimeout(scrollTimeout);
            }
            scrollTimeout = setTimeout(onScrollEnd, 100);
        };

        function onScrollEnd() {
            if (window.scrollY < 90) {
                setIsTransparentHeader(true);
            } else {
                setIsTransparentHeader(false);
            }

            scrollTimeout = null;
        }

        try {
            mediaQuery.addEventListener('change', onMediaQueryChange);
        }
        catch (e) {
            // fallback for older safari versions
            mediaQuery.addListener(onMediaQueryChange);
        }

        setIsMobile(mediaQuery.matches);

        window.addEventListener('scroll', onPageScroll);

        if (!isLandingPage() || isMobile)
            setIsTransparentHeader(false);
        else
            setIsTransparentHeader(true);

        return () => {
            window.removeEventListener('scroll', onPageScroll);
        }
    }, [location, isMobile]);

    const onLogout = () =>
        authService
            .logout()
            .catch(error => toast.handleError(error))
            .finally(() => {
                trackLogout();
                clearStorage();
                authHandler.reset();
            })
            .then(() => history.push('/secure'));

    const showDropdown = () => setShow(!show);
    const hideDropdown = () => setShow(false);

    useLayoutEffect(() => hideDropdown(), [location])

    const getInitials = (name) =>
        name?.match(/(^\S\S?|\s\S)?/g)
            .map(v => v.trim())
            .join("")
            .match(/(^\S|\S$)?/g)
            .join("")
            .toLocaleUpperCase();

    const BadgeTitle = () => {
        return (
            <div
                style={{ maxWidth: 250 }}
                className={`account-info nav-link px-4 d-flex align-items-center py-1 ${navLinkClasses}`}>
                <div>
                    <span className={`position-relative account-badge ${badgeColor} me-2`}>
                        {getInitials(updatedUserName || userFullName)}
                    </span>
                </div>
                <span className='d-inline-block text-truncate'>{updatedUserName || userFullName}</span>
            </div>
        );
    }

    const Notifications = () => {
        return (
            <Nav.Link
                as={Link} eventKey="1" to={NOTIFICATIONS_PATH}
                className={`position-relative d-flex align-items-center ${navLinkClasses} ${location.pathname.startsWith(NOTIFICATIONS_PATH) ? 'current' : ''}`}>
                <span className="icon icon-notification my-1" style={{ height: '20.75px' }}>
                </span>
                {notificationsData && !!Object.keys(notificationsData).length &&
                    <span className="position-absolute dot bc-blue-500 align-self-start"
                        style={{ height: '10px', width: '10px', top: '6px', right: '10px' }}></span>
                }
            </Nav.Link>
        );
    }

    const On1KClick = () => {
        if (!auth.isAuthenticated)
            return history.push(LANDING_PATH);

        const userRole = authHandler.getUser()?.identitytype;
        if (userRole?.includes('Seller'))
            return history.push(SELLERS_DASHBOARD_PATH);
        else
            return history.push(BUYERS_DASHBOARD_PATH);
    }

    return (
        <>
            <Navbar
                collapseOnSelect
                expand="lg"
                ref={navbarRef}
                id="header"
                variant={isTransparentHeader ? 'dark' : 'light'}
                className="px-1 ps-lg-5 pe-lg-5 py-1">
                <Container className="d-flex d-lg-none">
                    <Navbar.Brand onClick={() => On1KClick()} className="d-flex">
                        <span className="navbar-logo" style={{ height: "24px" }}></span>
                    </Navbar.Brand>

                    {auth.isAuthenticated &&
                        <div className="d-flex ms-auto">
                            <Notifications />
                            <Navbar.Toggle aria-controls="responsive-navbar-nav">
                                <div className="icon-container align-items-center justify-content-center">
                                    <span className="icon icon-close"></span>
                                </div>
                                <span className={`position-relative account-badge ${badgeColor}`}>
                                    {getInitials(updatedUserName || userFullName)}
                                </span>
                            </Navbar.Toggle>
                        </div>
                    }
                    {!auth.isAuthenticated && <Navbar.Toggle aria-controls="responsive-navbar-nav" />}
                </Container>

                <Navbar.Brand onClick={() => On1KClick()} className="d-none d-lg-flex">
                    <span className="navbar-logo" style={{ height: "24px" }}></span>
                </Navbar.Brand>

                <Navbar.Collapse id="responsive-navbar-nav" className="w-100 text-center header-collapse py-4 py-lg-0">
                    <Nav className="ff-i600 w-100 align-items-center">
                        {auth.isAuthenticated &&
                            <>
                                <div className="d-block d-lg-none">
                                    <span className="d-block text-m ff-i600 c-grey-900 mb-1">
                                        {updatedUserName || userFullName}
                                    </span>
                                    <span className="d-block c-grey-550">
                                        {user?.email}
                                    </span>
                                </div>
                                <div className="w-100 d-block border-top-grey-150 my-3 d-lg-none"></div>
                                <Nav.Link
                                    className={`position-relative ms-lg-auto me-lg-2 mb-2 mb-lg-0 ${navLinkClasses} ${location.pathname.startsWith(BUYERS_DASHBOARD_PATH) || location.pathname.includes(CHAT_BUYER) ? 'current' : ''}`}
                                    as={Link} eventKey="2" to={BUYERS_DASHBOARD_PATH}>
                                    <span className="d-none d-lg-block ff-i600">Buyer Dashboard</span>
                                    <span className="d-block d-lg-none ff-i600 c-grey-550">Buyer Dashboard</span>
                                    {(notificationsData?.buyer || newMatchesAmount > 0) && <span className="position-absolute dot bc-blue-500 align-self-start"
                                        style={{ height: '10px', width: '10px', top: '6px', right: '2px' }} />}
                                </Nav.Link>
                                <Nav.Link
                                    className={`position-relative me-lg-auto ${navLinkClasses} ${location.pathname.startsWith(SELLERS_DASHBOARD_PATH) || location.pathname.includes(CHAT_SELLER) ? 'current' : ''}`}
                                    as={Link} eventKey="3" to={SELLERS_DASHBOARD_PATH}>
                                    <span className="d-none d-lg-block ff-i600">Seller Dashboard</span>
                                    <span className="d-block d-lg-none ff-i600 c-grey-550">Seller Dashboard</span>
                                    {notificationsData?.seller && <span className="position-absolute dot bc-blue-500 align-self-start"
                                        style={{ height: '10px', width: '10px', top: '6px', right: '2px' }} />}
                                </Nav.Link>
                                <button
                                    className="btn-primary mt-3 mt-lg-0 py-2 me-lg-3"
                                    onClick={() => setIsShowAppModal(true)}>
                                    Get The App
                                </button>
                                <div className="w-100 d-block border-top-grey-150 my-3 d-lg-none"></div>
                                <div className="d-flex flex-column">
                                    <Nav.Link
                                        className={`d-flex align-items-center d-lg-none mb-2 ${navLinkClasses} ${location.pathname.startsWith(SETTINGS_PATH) ? 'current' : ''}`}
                                        as={Link} eventKey="4" to={SETTINGS_PATH}>
                                        <span className="icon icon-profile-small me-3"></span>
                                        <span className="ff-i600 c-grey-550">Profile</span>
                                    </Nav.Link>
                                    <Nav.Link
                                        className={`d-flex align-items-center d-lg-none mb-2 ${navLinkClasses} ${location.pathname.startsWith(FAQS_PATH) ? 'current' : ''}`}
                                        as={Link} eventKey="5" to={FAQS_PATH}>
                                        <span className="icon icon-help me-3"></span>
                                        <span className="ff-i600 c-grey-550">FAQs</span>
                                    </Nav.Link>
                                    <Nav.Link
                                        className={`d-flex align-items-center d-lg-none mb-2 ${navLinkClasses} ${location.pathname.startsWith(CONTACT_US_PATH) ? 'current' : ''}`}
                                        as={Link} eventKey="6" to={CONTACT_US_PATH}>
                                        <span className="icon icon-phone-in-talk me-3" style={{ height: '20px' }}></span>
                                        <span className="ff-i600 c-grey-550">Contact Us</span>
                                    </Nav.Link>
                                    {auth.isAdmin &&
                                        <Nav.Link
                                            className={`d-flex align-items-center d-lg-none mb-2 ${navLinkClasses} ${location.pathname.startsWith(ADMIN_PATH) ? 'current' : ''}`}
                                            as={Link} eventKey="7" to={ADMIN_PATH}>
                                            <span className="icon icon-guard me-3"></span>
                                            <span className="ff-i600 c-grey-550">Admin</span>
                                        </Nav.Link>
                                    }
                                    {authHandler.isSimulationEnabled() &&
                                        <Nav.Link as={Link} to={ADMIN_PATH} eventKey="8" className="d-flex d-lg-none">
                                            <span className="ff-i600 c-green-600">Simulation ON</span>
                                        </Nav.Link>
                                    }
                                </div>
                                <div className="w-100 d-block border-top-grey-150 my-3 d-lg-none"></div>
                                <Nav.Link as={Link} to="/secure" eventKey="9" onClick={onLogout} style={{ marginLeft: "-30px" }}
                                    className={`d-flex align-items-center d-lg-none pb-3`}>
                                    <span className="icon icon-exit me-2"></span>
                                    <span className="c-grey-550 ff-i600 ms-1">Logout</span>
                                </Nav.Link>
                            </>
                        }
                        {auth.isAnonymous &&
                            <>
                                <Nav.Link as={Link} to={BROKERS_LANDING_PATH} eventKey="10" className={`ms-lg-auto me-2 ${navLinkClasses} ${location.pathname.startsWith(BROKERS_LANDING_PATH) ? 'current' : ''}`}>
                                    <span className="px-4 px-md-0">Broker</span>
                                </Nav.Link>
                                <Nav.Link as={Link} to={SELLERS_LANDING_PATH} eventKey="11" className={`me-2 ${navLinkClasses} ${location.pathname.startsWith(SELLERS_LANDING_PATH) ? 'current' : ''}`}>
                                    <span className="px-4 px-md-0">Seller</span>
                                </Nav.Link>
                                <Nav.Link as={Link} to={BUYERS_LANDING_PATH} eventKey="12" className={`me-lg-auto ${navLinkClasses} ${location.pathname.startsWith(BUYERS_LANDING_PATH) ? 'current' : ''}`}>
                                    <span className="px-4 px-md-0">Buyer</span>
                                </Nav.Link>
                                <Nav.Link as={Link} to={CONTACT_SALES_PATH} eventKey="21" className={`me-lg-3 ${navLinkClasses} ${location.pathname.startsWith(CONTACT_SALES_PATH) ? 'current' : ''}`}>
                                    <span className="px-4 px-md-0">Contact Sales</span>
                                </Nav.Link>
                                <Nav.Link as={Link} eventKey="13" to="/secure" className="border-radius p-0 me-lg-3 mb-2 mt-5 mt-lg-0">
                                    <button
                                        id='login-button-header-link'
                                        type="button"
                                        style={{ minWidth: '125px' }}
                                        className="btn-secondary btn-oblong btn-transparent ff-i600">
                                        <span className="px-4">Login</span>
                                    </button>
                                </Nav.Link>
                                <Nav.Link as={Link} eventKey="14" to="/secure/register" className="border-radius mb-2 p-0">
                                    <button
                                        type="button"
                                        style={{ minWidth: '125px' }}
                                        className="btn-primary btn-oblong ff-i600 green-600">
                                        <span className="px-4">Sign Up</span>
                                    </button>
                                </Nav.Link>
                            </>
                        }
                        {auth.isAuthenticated &&
                            <div className="d-none d-lg-flex">
                                <Notifications />
                                <NavDropdown
                                    className="border-radius"
                                    title={BadgeTitle()}
                                    id="navbarScrollingDropdown"
                                    show={show}
                                    onClick={showDropdown}>
                                    <NavDropdown.Header className="pt-3 px-4">
                                        <span className="d-block text-m ff-i600 c-grey-900 text-truncate" title={updatedUserName || userFullName}>
                                            {updatedUserName || userFullName}
                                        </span>
                                        <span className="d-block c-grey-550 text-truncate" title={user?.email}>
                                            {user?.email}
                                        </span>
                                    </NavDropdown.Header>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to={SETTINGS_PATH} eventKey="15"
                                        className={`d-flex align-items-center ${location.pathname.startsWith(SETTINGS_PATH) ? 'current' : ''}`}>
                                        <span className="icon icon-profile-small me-2"></span>
                                        <span className="c-grey-700 ff-i600 ms-1">Profile</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={FAQS_PATH} eventKey="16"
                                        className={`d-flex align-items-center ${location.pathname.startsWith(FAQS_PATH) ? 'current' : ''}`}>
                                        <span className="icon icon-help me-2"></span>
                                        <span className="c-grey-700 ff-i600 ms-1">FAQs</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={CONTACT_US_PATH} eventKey="17"
                                        className={`d-flex align-items-center ${location.pathname.startsWith(CONTACT_US_PATH) ? 'current' : ''}`}>
                                        <span className="icon icon-phone-in-talk me-2" style={{ height: '20px' }}></span>
                                        <span className="c-grey-700 ff-i600 ms-1">Contact Us</span>
                                    </NavDropdown.Item>
                                    {auth.isAdmin &&
                                        <>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item as={Link} to={ADMIN_PATH} eventKey="18"
                                                className={`d-flex align-items-center ${location.pathname.startsWith(ADMIN_PATH) ? 'current' : ''}`}>
                                                <span className="icon icon-guard me-2" style={{ height: '20px' }}></span>
                                                <span className="c-grey-700 ff-i600 ms-1">Admin</span>
                                            </NavDropdown.Item>
                                        </>
                                    }

                                    {authHandler.isSimulationEnabled() &&
                                        <>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item as={Link} to={ADMIN_PATH} eventKey="19"
                                                className="bc-grey-100">
                                                <span className="ff-i600 c-green-600">Simulation ON</span>
                                            </NavDropdown.Item>
                                        </>
                                    }

                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={onLogout} eventKey="20"
                                        className={`d-flex align-items-center pb-3`}>
                                        <span className="icon icon-exit me-2"></span>
                                        <span className="c-grey-700 ff-i600 ms-1">Logout</span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <AppPromoBanner
                isVisible={isShowAppModal}
                handleClose={() => setIsShowAppModal(false)} />
        </>
    );
}

const navLinkClasses = "text-decoration-none border-radius";

export default Header;