import PropTypes from 'prop-types';
import setGeneralStyles from './helpers/setGeneralStylesAlert';
import setNecessaryIcon from './helpers/setNecessaryIconAlert';
import setCloseIcon from './helpers/setCloseIconAlert';

const Alert = ({ type, text, closeButton }) => {
    return (
        <div
            className={`d-flex mt-2 ${setGeneralStyles(
                type
            )} py-2 px-3 rounded align-items-center justify-content-between`}
        >
            <div className="d-flex align-items-center">
                <span style={{ width: 25, height: 25 }} className={`icon ${setNecessaryIcon(type)}`} />
                <span className="ms-2">{text}</span>
            </div>
            {closeButton.isVisible && (
                <button
                    className="bc-inherit border-0 d-flex align-items-center justify-content-center"
                    onClick={closeButton.callback}
                >
                    <span style={{ width: 14, height: 14 }} className={`icon ${setCloseIcon(type)} cursor-pointer`} />
                </button>
            )}
        </div>
    );
};

Alert.propTypes = {
    // type should be 'CRITICAL' | 'ERROR' | 'WARNING' | 'SUCCESS' | 'INFORMATION' | 'PROMOTIONAL',
    type: PropTypes.string.isRequired,
    closeButton: PropTypes.shape({
        isVisible: PropTypes.bool.isRequired,
        callback: PropTypes.func,
    }),
    text: PropTypes.string.isRequired,
};
export default Alert;
