import { useState, useEffect } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useNotifications } from 'contexts/NotificationsContext';
import Tooltip from 'components/atoms/Tooltip';

import useEducationCenter, { SELLER_OVERVIEW_EDUCATION } from 'hooks/useEducationCenter';
import EducationModal from 'components/molecules/education-system/EducationModal';

import ListingService from 'services/listing-service';

const listingService = ListingService();

const Sidebar = ({ showEducationalModal }) => {
    const { path } = useRouteMatch();
    const location = useLocation();

    const [isOpenMenu, setIsOpenMenu] = useState(null);

    const SELLER_DASHBOARD_PATH = path;
    const ALL_DEALS_PATH = `${path}/deals`;
    const TASKS_PATH = `${path}/tasks`;
    const CHAT_PATH = `${path}/chat`;
    const CONTACTS_PATH = `${path}/contacts`;
    const MEETINGS_PATH = `${path}/meetings`;
    const PROFILE_PATH = `${path}/profile`

    const DASHBOARD_ICON = 'dashboard';
    const ALL_DEALS_ICON = 'deals';
    const CHAT_ICON = 'chat';
    const TASK_ICON = 'task';
    const CALENDAR_ICON = 'calendar';
    const CONTACTS_ICON = 'contacts';
    const PROFILE_ICON = 'profile';

    const { notificationsData } = useNotifications();

    const buttons = [
        {
            name: 'Dashboard',
            path: SELLER_DASHBOARD_PATH,
            icon: DASHBOARD_ICON
        },
        {
            name: 'My Deals',
            path: ALL_DEALS_PATH,
            icon: ALL_DEALS_ICON
        },
        {
            name: 'Chat',
            path: CHAT_PATH,
            icon: CHAT_ICON
        },
        {
            name: 'Contacts',
            path: CONTACTS_PATH,
            icon: CONTACTS_ICON
        },
        {
            name: 'Meetings',
            path: MEETINGS_PATH,
            icon: CALENDAR_ICON
        },
        {
            name: 'Tasks',
            path: TASKS_PATH,
            icon: TASK_ICON
        },
        {
            name: 'Profile',
            path: PROFILE_PATH,
            icon: PROFILE_ICON
        }
    ];

    /* Education section start */
    useEffect(() => {
        listingService.init();

        setEducationOverview(getEducationItem(educationScope));

        listingService
            .getListingsStats()
            .then(statsResponse => {
                if (!statsResponse?.data?.count)
                    setNeedEducation(true);
            })

        return () => {
            listingService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        getEducationItem,
        acceptEducation,
        rejectEducation,
        moveNext,
        moveLast } = useEducationCenter();
    const educationScope = SELLER_OVERVIEW_EDUCATION;
    const tooltipsTotal = 6;
    const [needEducation, setNeedEducation] = useState(false);
    const [educationOverview, setEducationOverview] = useState({});

    const onEducationAccepted = () => acceptEducation(educationScope, educationOverview, setEducationOverview);
    const onEducationRejected = () => rejectEducation(setEducationOverview);
    const onLastTooltipClicked = () => moveLast(educationScope, educationOverview, setEducationOverview);

    const onTooltipClicked = (order) => {
        if (order === tooltipsTotal)
            onLastTooltipClicked();
        else {
            moveNext(educationScope, order, educationOverview, setEducationOverview);
        }
    }

    const showEducationalTooltip = order =>
        showEducationalModal && needEducation && location.pathname === path && (
            !educationOverview?.rejected
            && educationOverview?.modalShown
            && !educationOverview?.tooltipsShown
            && educationOverview?.order === order);

    const tooltips = [
        {
            content: "Your dashboard is the heartbeat of your business. From here, you can see your deals, tasks, and notifications."
        },
        {
            content: "Say goodbye to emails, spreadsheets and whatever other tool from 2003 you may be using to track your deals. Now, all of your deals (and all of those important details they all contain!) live inside your Deals panel."
        },
        {
            content: "Rolodex of the future — engage! Here, you can import all of your contacts and can connect them to your deals. That’s just the beginning. When you match with buyers (after you approve them, of course!) they are automatically imported in your contacts panel. From one spot, you can quickly manage your contacts, see what deals they’re associated with, and chat with them with one click."
        },
        {
            content: "Oh, did that whole ‘one click to chat’ tidbit get your attention? Good! Securely chat with your contacts and buyers that you’ve approved, cutting down the communication wait time from days to seconds."
        },
        {
            content: "All that back and forth of ‘Let’s find a time to connect!’ is gone. With our meeting scheduling feature, buyers can book meetings based on the availability you set after you sync your calendar."
        },
        {
            content: "When we say you don’t have to leave our platform, we mean it. With our task manager, create and organize your tasks by Due Date, the Deals they’re associated with or even by the lead association. And just like that, you’re 10X more organized."
        }
    ];

    /* Education section end */

    const [currentButtons, setCurrentButtons] = useState([]);
    const [hiddenButtons, setHiddenButtons] = useState([]);
    // const [showChatTooltips, setShowChatTooltips] = useState(true);

    const moveArrayItemToNewIndex = (arr, from, to) => {
        if (to >= arr.length) {
            let k = to - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(to, 0, arr.splice(from, 1)[0]);
    };

    useEffect(() => {
        if (location.pathname === MEETINGS_PATH)
            moveArrayItemToNewIndex(buttons, 4, 3);

        if (location.pathname === TASKS_PATH)
            moveArrayItemToNewIndex(buttons, 5, 3);

        if (location.pathname.includes('profile'))
            moveArrayItemToNewIndex(buttons, 6, 3);

        setCurrentButtons(buttons.slice(0, 4));
        setHiddenButtons(buttons.slice(4, buttons.length));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const activeLink = (href, pathname = location.pathname) => pathname === href
        || (href === ALL_DEALS_PATH && location.pathname.includes('deals') && !location.pathname.includes('archived'))
        || (href === CONTACTS_PATH && location.pathname.includes('contacts'))
        || (pathname.includes('/profile') && href === PROFILE_PATH);

    function CustomLink({ href, pathname, icon, children, ...props }) {
        return (
            <Link
                to={{ pathname: href, state: { showNavTabs: true } }}
                className={`position-relative d-flex align-items-center c-grey-550 border-radius text-decoration-none p-2 mb-3 link ${activeLink(href) ? 'active c-blue-500' : ''}`}
                {...props}>
                <span style={{ width: 24 }} className={`icon icon-${icon} ${!activeLink(href) ? 'icon-grayscale' : ''}`}></span>
                <span className='ff-i600 ms-3 sidebar-text'>{children}</span>
            </Link>
        );
    }

    function CustomLinkMobile({ href, pathname, icon, children, ...props }) {
        return (
            <Link
                to={{ pathname: href, state: { showNavTabs: true } }}
                className={`position-relative d-flex flex-column align-items-center border-radius text-decoration-none mt-2 w-100 link 
                    ${activeLink(href) ? 'active' : ''}`}
                {...props}
            >
                <div className="p-2 border-radius icon-overlay">
                    <span
                        style={{ height: 28, width: 28 }}
                        className={`icon icon-${icon} ${!activeLink(href) ? 'icon-grayscale' : ''} d-flex justify-content-between`}
                    ></span>
                </div>
                <span
                    className={`ff-i600 mt-2 text-center ${activeLink(href) ? 'c-blue-500' : ''}`}
                    style={{ fontSize: '10px' }}
                >
                    {children}
                </span>
            </Link>
        );
    }

    const handleIsShowNotification = (tabName) => {
        if (tabName === "My Deals" && notificationsData?.seller?.dealNotifications?.some(n => (n.lead || n.update))) return true;
        if (tabName === "Meetings" && notificationsData?.seller?.dealNotifications?.some(n => n.meeting)) return true;
        return !!(tabName === "Chat" && notificationsData?.seller?.dealNotifications?.some(n => (n.message || n.lead || n.document)));
    }

    return (
        <>
            <div className="d-none d-lg-block sidebar d-flex flex-column border-right-grey-200 px-3"
                style={{ zIndex: !educationOverview.tooltipsShown && educationOverview?.modalShown && '1049' }}>
                <CustomLink href={SELLER_DASHBOARD_PATH} icon={DASHBOARD_ICON} pathname={location.pathname}>
                    <Tooltip
                        show={showEducationalTooltip(0)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(1)}>
                        <span className="ff-i600">Seller Dashboard</span>
                    </Tooltip>
                </CustomLink>
                <CustomLink href={ALL_DEALS_PATH} icon={ALL_DEALS_ICON} pathname={location.pathname}>
                    <Tooltip
                        show={showEducationalTooltip(1)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(2)}>
                        <span className="ff-i600">My Deals</span>
                        {notificationsData?.seller?.dealNotifications?.some(n => (n.lead || n.update)) &&
                            <span className="position-absolute dot bc-blue-500 align-self-start"
                                style={{ height: '10px', width: '10px', top: '7px', right: '6px' }} />}
                    </Tooltip>
                </CustomLink>
                <CustomLink href={CHAT_PATH} icon={CHAT_ICON} pathname={location.pathname}>
                    <Tooltip
                        show={showEducationalTooltip(2)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(3)}>
                        <span className="ff-i600">Chat</span>
                        {notificationsData?.seller?.dealNotifications?.some(n => (n.message || n.lead || n.document)) &&
                            <span className="position-absolute dot bc-blue-500 align-self-start"
                                style={{ height: '10px', width: '10px', top: '7px', right: '6px' }} />
                        }
                    </Tooltip>
                </CustomLink >
                <CustomLink href={CONTACTS_PATH} icon={CONTACTS_ICON} pathname={location.pathname}>
                    <Tooltip
                        show={showEducationalTooltip(3)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(4)}>
                        <span className="ff-i600">Contacts</span>
                    </Tooltip>
                </CustomLink>
                <CustomLink href={MEETINGS_PATH} icon={CALENDAR_ICON} pathname={location.pathname}>
                    <Tooltip
                        show={showEducationalTooltip(4)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(5)}>
                        <span className="ff-i600">Meetings</span>
                        {notificationsData?.seller?.dealNotifications?.some(n => n.meeting) &&
                            <span className="position-absolute dot bc-blue-500 align-self-start"
                                style={{ height: '10px', width: '10px', top: '7px', right: '6px' }} />
                        }
                    </Tooltip>
                </CustomLink>
                <CustomLink href={TASKS_PATH} icon={TASK_ICON} pathname={location.pathname} >
                    <Tooltip
                        show={showEducationalTooltip(5)}
                        content={tooltips[educationOverview?.order]?.content}
                        secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                        direction="right-bottom"
                        additionalClasses="w-450 top-200"
                        animations=""
                        ctaText="Got it!"
                        ctaAction={() => onTooltipClicked(6)}>
                        <span className="ff-i600">Tasks</span>
                    </Tooltip>
                </CustomLink >
                <CustomLink href={PROFILE_PATH} icon={PROFILE_ICON} pathname={location.pathname}>
                    Profile
                </CustomLink>
            </div >

            {isOpenMenu !== null &&
                <div className="d-lg-none">
                    {isOpenMenu &&
                        <div
                            className='underlay'
                            onClick={() => setIsOpenMenu(false)} />
                    }
                    <div className={`mobile-sidebar ${isOpenMenu ? 'slide-in' : 'slide-out'}`}
                        style={{ height: 241 }}>
                        <h5 className="text-center c-grey-700 pt-3">More</h5>
                        <div className='px-4 pt-3'>
                            {hiddenButtons.map(e =>
                                <CustomLink
                                    key={e.name}
                                    href={e.path}
                                    icon={e.icon}
                                    pathname={location.pathname}
                                    onClick={() => setIsOpenMenu(false)}>
                                    {e.name}
                                </CustomLink>
                            )}
                        </div>
                        <div
                            className='d-flex justify-content-center py-3'
                            onClick={() => setIsOpenMenu(false)}>
                            <div className='grey-line' />
                        </div>
                    </div>
                </div>
            }

            <div
                className='d-flex d-lg-none justify-content-between position-fixed border-top-grey-100 bottom-0 sidebar bc-white w-100 pb-2'
                style={{ zIndex: 1000 }}>
                <Tooltip
                    show={showEducationalTooltip(educationOverview?.order)}
                    content={tooltips[educationOverview?.order]?.content}
                    secondaryContent={`Step ${educationOverview?.order + 1}/${tooltipsTotal}`}
                    direction="top"
                    additionalClasses={`arrow-left-${educationOverview?.order + 1}-5`}
                    bodyClasses="d-flex d-lg-none justify-content-between position-fixed bottom-0 border-top-grey-100 sidebar bc-white w-100 pb-2"
                    ctaText="Got it!"
                    ctaAction={() => onTooltipClicked(educationOverview?.order + 1)}>
                    {
                        currentButtons.map(e =>
                            <CustomLinkMobile
                                key={e.name}
                                href={e.path}
                                icon={e.icon}
                                pathname={location.pathname}>
                                {e.name}
                                {handleIsShowNotification(e.name) && <span className="position-absolute dot bc-blue-500 align-self-start"
                                    style={{ height: '10px', width: '10px', top: 0, right: '12px' }} />}
                            </CustomLinkMobile>
                        )
                    }
                    <div
                        className='active-link d-flex flex-column align-items-center justify-content-between border-radius mt-2 w-100 cursor-pointer'
                        onClick={() => setIsOpenMenu(!isOpenMenu)}>
                        <span className={`mt-2 icon icon-burger-menu ${!isOpenMenu ? 'icon-grayscale' : ''}`} style={{ width: 28, height: 28 }}></span>
                        <span
                            className={`acive-blue ff-i600 c-grey-700 ${isOpenMenu ? 'c-blue-500' : ''}`}
                            style={{ fontSize: '10px' }}>
                            More
                        </span>
                    </div>
                </Tooltip>
            </div >
            {
                showEducationalModal && needEducation && location.pathname === path &&
                <EducationModal
                    isVisible={
                        !educationOverview?.rejected
                        && !educationOverview?.modalShown
                        && needEducation
                        && location.pathname === path}
                    title={'Connect. Transact. Grow. All in one place.'}
                    paragraphs={
                        [
                            { text: 'Welcome to the New 1Konnection Dealmaking Platform, your new one-stop shop for everything you need to get more deals done in the Senior Housing & Healthcare Real Estate space.' },
                            { text: 'Gone are the days of manual outreach, complicated deal flow, and using dozens of tools. Our new confidential dealmaking platform changes what’s possible for our industry. From a bespoke CRM and confidential doc sharing to AI matchmaking and an industry-specific communication platform, we’ve got you (and your business) covered.' },
                            { text: 'Let’s take a look around.', bold: true }
                        ]}
                    handleAccept={onEducationAccepted}
                    handleClose={onEducationRejected} />
            }

        </>
    );
};

export default Sidebar;
