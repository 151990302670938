import { useEffect, useState } from 'react';

import ListingService from 'services/listing-service';
import useToast from 'hooks/useToast';

import UploadFilesPanel from 'components/organisms/UploadFilesPanel';

const listingService = ListingService();

const LeadDocument = ({
    dealId,
    channelUrl,
    recipientId,
    documents,
    dealFiles,
    receivedFiles,
    setDealFiles,
    sortFiles,
    sortLinks,
    dealLinks,
    setDealLinks
}) => {
    const { toast } = useToast();
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [uploadingLinks, setUploadingLinks] = useState([]);
    const [disabledFileUpload, setDisabledFileUpload] = useState(false);

    const [progress, setProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const [showPinnedMessage, setShowPinnedMessage] = useState(0);

    const [showUploadFile, setShowUploadFile] = useState(false);

    useEffect(() => {
        listingService.init();

        return () => listingService.dispose();
    }, []);

    const isDisableShareButton = (items, selectedItems) =>
        !!items.filter(item => !!selectedItems.filter(doc => doc.id === item.id && doc.isFile === !item.link).length && item.sharedDate).length;

    const onShareButtonClick = () => {
        const files = selectedDocuments
            .filter(document => document.isFile)
            .map(document => document.id);
        const links = selectedDocuments
            .filter(document => !document.isFile)
            .map(document => document.id);

        if (!!files.length)
            listingService
                .shareFiles(channelUrl, +recipientId, files)
                .then(response => {
                    let updateFiles = dealFiles.map(file => {
                        if (response.data.fileIds.includes(file.id)) return { ...file, sharedDate: new Date() };

                        return file;
                    });
                    setDealFiles(sortFiles(updateFiles));
                })
                .catch(error => toast.handleError(error))
                .finally(() => setSelectedDocuments([]))

        if (!!links.length)
            listingService
                .shareLinks(channelUrl, +recipientId, links)
                .then(response => {
                    let updateLinks = dealLinks.map(link => {
                        if (response.data.linkIds.includes(link.id)) return { ...link, sharedDate: new Date() };

                        return link;
                    });
                    setDealLinks(sortLinks(updateLinks));
                })
                .catch(error => toast.handleError(error))
                .finally(() => setSelectedDocuments([]));
    }

    const onPinButtonClick = () => {
        const files = selectedDocuments
            .filter(document => document.isFile)
            .map(document => document.id);
        const links = selectedDocuments
            .filter(document => !document.isFile)
            .map(document => document.id);

        if (!!files.length)
            listingService
                .updateFilesPin(files)
                .then(() => {
                    let updateFiles = dealFiles.map(file => {
                        if (files.includes(file.id)) return { ...file, pinned: !file.pinned };

                        return file;
                    });
                    setDealFiles(sortFiles(updateFiles));
                    setShowPinnedMessage(files.length);
                    setTimeout(() => setShowPinnedMessage(0), 2000);
                })
                .catch(error => toast.handleError(error))
                .finally(() => setSelectedDocuments([]))

        if (!!links.length)
            listingService
                .updateLinksPin(links)
                .then(() => {
                    let updateLinks = dealLinks.map(link => {
                        if (links.includes(link.id)) return { ...link, pinned: !link.pinned };

                        return link;
                    });
                    setDealLinks(sortLinks(updateLinks));
                    setShowPinnedMessage(links.length);
                    setTimeout(() => setShowPinnedMessage(0), 2000);
                })
                .catch(error => toast.handleError(error))
                .finally(() => setSelectedDocuments([]));
    }

    const shareFiles = (uploadFiles, uploadFileToDeal) => {
        let correctFiles = uploadFiles
            .filter(file => file.isUploading || file.isSuccess)
            .map(correctFile => correctFile.file);
        if (correctFiles.length > 0) {
            setShowProgress(true);
            setDisabledFileUpload(true);
            return listingService
                .uploadDealFiles(
                    dealId,
                    correctFiles,
                    uploadFileToDeal,
                    +recipientId,
                    channelUrl,
                    event => {
                        setProgress(Math.round((100 * event.loaded) / event.total));
                    }
                )
                .then(response => {
                    let responseFiles = response.data.filesResponse;
                    setUploadingFiles(prev => [...responseFiles, ...prev.filter(file => !file.isUploading)]);
                    setTimeout(() => {
                        responseFiles.forEach(fileResponse => {
                            if (fileResponse.isSuccess) {
                                setUploadingFiles(prev => prev.filter(file => !file.isSuccess));
                                setDealFiles(prev =>
                                    sortFiles([...prev, { ...fileResponse.file, sharedDate: new Date() }])
                                );
                            }
                        });
                    }, 3000);
                })
                .catch(error => {
                    toast.handleError(error);
                    let errorFiles = correctFiles.map(resFile => {
                        return {
                            file: resFile,
                            errorMessage: (
                                <>
                                    File could not be uploaded.
                                    <span
                                        className="cursor-pointer border-bottom-red-500"
                                        onClick={() => onRetryUploadClick(resFile)}
                                    >
                                        &nbsp;Retry
                                    </span>
                                </>
                            ),
                            isSuccess: false,
                        };
                    });
                    setUploadingFiles(prev => [
                        ...errorFiles,
                        ...prev.filter(uploadingFile => !uploadingFile.isUploading),
                    ]);
                })
                .finally(() => {
                    setProgress(0);
                    setShowProgress(false);
                    setDisabledFileUpload(false);
                });
        }
    };

    const onRetryUploadClick = file => {
        setUploadingFiles(prev => [
            { file: file, isUploading: true },
            ...prev.filter(uploadingFile => uploadingFile.file.name !== file.name),
        ]);
        shareFiles([{ file: file, isUploading: true }]);
    };

    const shareLink = (uploadingLinks, uploadLinkToDeal) => {
        return listingService
            .createLinks(
                dealId,
                uploadingLinks.map(link => { return { name: link.linkName, value: link.url } }),
                uploadLinkToDeal,
                +recipientId,
                channelUrl)
            .then(response => {
                let linksResponse = response.data.linksResponse;
                setTimeout(() => {
                    linksResponse.forEach(linkResponse => {
                        if (linkResponse.isSuccess) {
                            setUploadingLinks([]);
                            setDealLinks(prev =>
                                sortLinks([...prev, { ...linkResponse.link, sharedDate: new Date() }])
                            );
                        }
                    });
                }, 3000);
            })
            .catch(error => toast.handleError(error))
    };

    const handleOnChangeFiles = (document, isChecked = false) =>
        setSelectedDocuments(prev => isChecked ? prev.filter(cid => !(cid.id === document.id && cid.isFile === document.isFile)) : [...prev, document]);

    const fileUrl = (containerName, directory, name) =>
        `${process.env.REACT_APP_BLOB_STORAGE}/${containerName}/${directory}/${encodeURIComponent(name)}`;

    const isChecked = document => !!selectedDocuments.filter(doc => doc.id === document.id && doc.isFile === !document.link).length;

    return (
        <>
            {showPinnedMessage > 0 && (
                <div className="d-flex align-items-center border-radius-small border-dashed-green bc-green-25 px-4 py-2 mb-3">
                    <span className="icon icon-success"></span>
                    <span className="c-green-500 ff-i500 ps-2">
                        Files were pinned/unpinned
                    </span>
                </div>
            )}

            <div className="custom-scrollbar">
                {!!documents.length &&
                    <>
                        <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-2">
                            <h5>My Documents</h5>
                            {(channelUrl) &&
                                <span className="cursor-pointer ff-i600 c-blue-500"
                                    onClick={() => {
                                        setShowUploadFile(true);
                                    }}>
                                    Upload
                                </span>
                            }
                        </div>
                        <div className="d-flex align-items-center border-radius-small py-2 px-3">
                            <span className="col-6 col-sm-7 ff-i500 c-grey-550 text-s">Filename</span>
                            <div className="d-sm-none col-6 text-end">
                                <span className="ff-i500 c-grey-550 text-s me-3">Date shared</span>
                            </div>
                            <div className="d-none d-sm-flex col-sm-5">
                                <span className="col-6 text-center ff-i500 c-grey-550 text-s">Date</span>
                                <span className="col-6 text-center ff-i500 c-grey-550 text-s">Shared</span>
                            </div>
                        </div>
                        <div className="py-2 mt-2 px-3">
                            {documents.map(document =>
                                <div key={document.id} className="d-flex align-items-center pb-4">
                                    <input
                                        type="checkbox"
                                        className="col-1 me-3"
                                        checked={isChecked(document)}
                                        onChange={() => handleOnChangeFiles({ id: document.id, isFile: !document.link }, isChecked(document))} />
                                    <div className="col-5 col-sm-6">
                                        {!document.link
                                            ? <>
                                                <span className="ff-i500 c-grey-700 text-s text-break">{document.name}</span>
                                                <span
                                                    className="icon icon-download-grey cursor-pointer ms-2"
                                                    onClick={() => window.location.replace(`${fileUrl(document.containerName, document.directory, document.name)}`)}></span>
                                            </>
                                            : <>
                                                <a className="ff-i500 c-blue-600 text-s text-break"
                                                    href={document.link.startsWith('https://') ? document.link : `https://${document.link}`}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    {document.name}
                                                </a>
                                            </>
                                        }
                                        {document.pinned && <span className="icon icon-pinned-blue ms-2"></span>}
                                    </div>
                                    <div className="d-sm-none col-6 text-end">
                                        <span className="ff-i500 c-grey-550 text-s me-3">{document.sharedDate ? new Date(document.sharedDate).toLocaleDateString() : "--"}</span>
                                        <span className={`icon me-3 ${!document.sharedDate ? 'icon-success icon-grayscale' : 'icon-success-grey'}`}></span>
                                    </div>
                                    <div className="d-none d-sm-flex col-sm-5">
                                        <span className="col-6 text-center ff-i500 c-grey-450 text-s">{document.sharedDate ? new Date(document.sharedDate).toLocaleDateString() : "--"}</span>
                                        <div className="col-6 d-flex justify-content-center">
                                            <span className={`icon ${!document.sharedDate ? 'icon-success icon-grayscale' : 'icon-success-grey'}`}></span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                }
                {!documents.length && (
                    <>
                        <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-2">
                            <h5>My Documents</h5>
                            {(channelUrl && dealId) &&
                                <span className="cursor-pointer ff-i600 c-blue-500"
                                    onClick={() => {
                                        setShowUploadFile(true);
                                    }}>
                                    Upload
                                </span>
                            }
                        </div>
                        <div className="text-center mt-5">
                            <span className="icon icon-empty-files"></span>
                            <div className="ff-i600 c-grey-550 text-m">
                                You don't have any documents.
                            </div>
                        </div>
                    </>)}

                {!!receivedFiles.length &&
                    <>
                        <h5 className="c-grey-700 border-bottom-grey-200 pb-2 mb-2">
                            Received
                        </h5>
                        <div className="d-flex align-items-center border-radius-small py-2 px-3">
                            <span className="col-8 ff-i500 c-grey-550 text-s">Filename</span>
                            <span className="col-4 text-end ff-i500 c-grey-550 text-s">Date</span>
                        </div>
                        <div className="py-2 mt-2 px-3">
                            {receivedFiles.map((file, index) =>
                                <div key={index} className="d-flex align-items-center pb-4">
                                    <div className="col-8">
                                        <span className="ff-i500 c-grey-700 text-s text-break">{file.name}</span>
                                        <span
                                            className="icon icon-download-grey cursor-pointer ms-2 ps-1"
                                            onClick={() => window.location.replace(`${fileUrl(file.containerName, file.directory, file.name)}`)}></span>
                                    </div>
                                    <span className="col-4 text-end ff-i500 c-grey-450 text-s">{new Date(file.sharedDate).toLocaleDateString()}</span>
                                </div>
                            )}
                        </div>
                    </>
                }
            </div>

            {!!documents.length &&
                <div className="mt-auto pt-3 border-top">
                    <span className="d-flex justify-content-center ff-i600 c-grey-700 text-m">
                        {selectedDocuments.length > 0
                            ? `${selectedDocuments.length} ${selectedDocuments.length > 1 ? 'documents' : 'document'} selected`
                            : 'Select documents to activate actions'}
                    </span>
                    <div className="d-flex justify-content-center mt-3">
                        <button
                            className="btn-primary disabled-secondary d-flex justify-content-center align-items-center me-3"
                            onClick={onShareButtonClick}
                            disabled={!selectedDocuments.length || isDisableShareButton(documents, selectedDocuments)}
                        >
                            <span className="icon icon-send-white me-2"></span>
                            <span className="ff-i600">Share</span>
                        </button>
                        <button
                            className="btn-primary disabled-secondary d-flex justify-content-center align-items-center"
                            onClick={onPinButtonClick}
                            disabled={!selectedDocuments.length}
                        >
                            <span className="icon icon-pinned-white me-2"></span>
                            <span className="ff-i600">Pin</span>
                        </button>
                    </div>
                </div>
            }

            <UploadFilesPanel
                show={showUploadFile}
                setShow={setShowUploadFile}
                shareFile={shareFiles}
                shareLink={shareLink}
                uploadingFiles={uploadingFiles}
                setUploadingFiles={setUploadingFiles}
                uploadingLinks={uploadingLinks}
                setUploadingLinks={setUploadingLinks}
                disabledFileUpload={disabledFileUpload}
                progress={progress}
                showProgress={showProgress}
                setDealLinks={setDealLinks}
                setDealFiles={setDealFiles} />
        </>
    );
};

export default LeadDocument;
