import { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import useLoader from 'hooks/useLoader';
import useToast from 'hooks/useToast';
import { SENT_EMAIL_VERIFICATION_KEY } from 'hooks/useLocalStorage';

import { trackPageview } from 'services/common/analytic-trackers';
import AuthHandler from 'services/common/auth-handler';
import UsersService from 'services/users-service';
import AuthService from 'services/auth-service';
import UserEmailsService from 'services/user-emails-service';

import UserNameContext from 'contexts/UserNameContext';

import NameInput from 'components/atoms/NameInput';
import PhoneInput from 'components/atoms/PhoneInput';

const authHandler = AuthHandler();
const usersService = UsersService();
const authService = AuthService();
const userEmailsService = UserEmailsService();

const GeneralInformation = () => {
    const history = useHistory();
    const { toast } = useToast();
    const { load } = useLoader();

    const { setUpdatedUserName } = useContext(UserNameContext);

    const [saveChanges, setSaveChanges] = useState(false);
    const [firstName, setFirstName] = useState('');

    const [isValidFirstName, setIsValidFirstName] = useState(true);
    const [isValidLastName, setIsValidLastName] = useState(true);
    const [isValidPhone, setIsValidPhone] = useState(true);

    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [countryCode, setCountryCode] = useState('');

    const [isVerificationSend, setIsVerificationSend] = useState();

    useEffect(() => {
        document.title = '1Konnection - General Information';
        trackPageview();
        usersService.init();
        authService.init();
        userEmailsService.init();

        setIsVerificationSend(window.localStorage.getItem(SENT_EMAIL_VERIFICATION_KEY));

        return () => {
            usersService.dispose();
            authService.dispose();
            userEmailsService.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userId = authHandler.getUserId();
    if (!userId) history.push('/secure', { redirectUrl: '/settings' });

    const [emailVerified, setEmailVerified] = useState(false);
    useEffect(() => {
        load(() =>
            usersService
                .get(userId)
                .then(response => {
                    const userEmail = response?.data?.user?.email;
                    setEmail(userEmail);
                    setFirstName(response?.data?.user.firstName);
                    setLastName(response?.data?.user.lastName);
                    setPhoneNumber(response?.data?.user.phone);
                    setCountryCode(response?.data?.user?.countryCode);
                    userEmailsService
                        .getAll(userEmail)
                        .then(response =>
                            setEmailVerified(
                                response?.data?.userEmails?.find(emailItem => emailItem.email === userEmail).verified
                            )
                        );
                })
                .catch(error => toast.handleError(error))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const updateUserInformGeneral = () => {
        load(() =>
            usersService
                .update(userId, { firstName, lastName, phone: phoneNumber, CountryCode: '+1' })
                .then(() => {
                    setUpdatedUserName(`${firstName} ${lastName}`);
                    setSaveChanges(false);
                    toast.success({ body: 'Your information was successfully changed.' });
                })
                .catch(error => toast.handleError(error))
        );
    };

    const sendVerified = () =>
        userEmailsService
            .postEmailVerification(email)
            .then(_ => {
                const localStorageStatus = isVerificationSend === null ? '0' : '1';
                window.localStorage.setItem(SENT_EMAIL_VERIFICATION_KEY, localStorageStatus);
                setIsVerificationSend(localStorageStatus);
                toast.success({ body: 'Email was successfully send.' });
            })
            .catch(error => toast.handleError(error));

    const renderSendEmailVerification = () => {
        switch (isVerificationSend) {
            case '0':
                return (
                    <span className="text-s ff-i600 c-blue-500 cursor-pointer" onClick={sendVerified}>
                        &nbsp;Resend
                    </span>
                );
            case '1':
                return (
                    <span className="text-s ff-i500 c-grey-300 ps-1">
                        Didn't get the email?
                        <Link className="text-decoration-none ff-i500" to="/contact-us">
                            &nbsp;Contact us.
                        </Link>
                    </span>
                );
            default:
                return (
                    <span className="text-s ff-i600 c-blue-500 cursor-pointer" onClick={sendVerified}>
                        &nbsp;Verify email
                    </span>
                );
        }
    };

    return (
        <div className="row justify-content-center pt-2 pt-md-4">
            <div className="col-12 col-lg-10 col-xl-8">
                <div className="py-3">
                    <NameInput
                        name={firstName}
                        setName={setFirstName}
                        isValid={valid => {
                            setIsValidFirstName(valid);
                            setSaveChanges(true);
                        }}
                        placeholder="First name"
                    />
                </div>
                <div className="py-3">
                    <NameInput
                        name={lastName}
                        setName={setLastName}
                        isValid={valid => {
                            setIsValidLastName(valid);
                            setSaveChanges(true);
                        }}
                        placeholder="Last name"
                    />
                </div>
                <div className="py-3">
                    <PhoneInput
                        number={phoneNumber}
                        setNumber={setPhoneNumber}
                        isValid={valid => {
                            setIsValidPhone(valid);
                            setSaveChanges(true);
                        }}
                        placeholder="Business number"
                        countryCode={countryCode}
                    />
                </div>
                <div className="py-3 pb-lg-4 position-relative">
                    <span className="w-100" data-tooltip={email || ''}>
                        <input
                            type="text"
                            className="w-100 tooltip-dashboard-overflow icon-left icon-right"
                            style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL}/images/icons/address-sign.svg)`,
                                ...inputImage,
                            }}
                            placeholder="Your Email"
                            value={email || ''}
                            disabled
                        />
                        {emailVerified ? (
                            <span className="icon icon-success icon-medium icon-static"></span>
                        ) : (
                            <span className="icon icon-warning-yellow icon-static"></span>
                        )}
                    </span>
                    {emailVerified ? (
                        <span className="text-s ff-i500 c-grey-300 ps-1">Your email is verified</span>
                    ) : (
                        <>
                            <span className="text-s ff-i500 c-grey-300 ps-1">Your email is not verified.</span>
                            {renderSendEmailVerification()}
                        </>
                    )}
                </div>
                <div className="col-12 py-4 pb-md-0">
                    <button
                        type="button"
                        className="btn-primary ff-i600 w-100"
                        onClick={updateUserInformGeneral}
                        disabled={!saveChanges || !isValidFirstName || !isValidLastName || !isValidPhone}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
};

const inputImage = {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '16px',
};

export default GeneralInformation;
