import { Suspense, lazy, useEffect, useState } from 'react';
import { Switch, useRouteMatch, useLocation, Redirect } from 'react-router-dom';

import { EMAIL_VERIFIED_KEY } from 'hooks/useLocalStorage';

import AuthHandler from 'services/common/auth-handler';
import UserEmailsService from 'services/user-emails-service';
import FundsService from 'services/funds-service';
import UsersService from 'services/users-service';

import VerifyEmailModal from 'components/molecules/verify-email-modal/VerifyEmailModal';
import DashboardPage from './components/dashboard/DashboardPage';
import Sidebar from './components/dashboard/Sidebar';

import AuthRoute from 'guards/AuthRoute';
import ListingService from 'services/listing-service';

import ChatSellerPage from 'pages/Chat/ChatSellerPage';

const ContactsPage = lazy(() => import('./components/dashboard/ContactsPage'));
const ContactsUploadPage = lazy(() => import('./components/dashboard/ContactsUploadPage'));
const Tasks = lazy(() => import('../Tasks/Tasks'));
const Profile = lazy(() => import('./components/dashboard/Profile'));
const MeetingsPage = lazy(() => import('./components/dashboard/MeetingsPage'));
const DealsPage = lazy(() => import('./components/dashboard/DealsPage'));
const EditListingPage = lazy(() => import('./EditListingPage'));
const NewListingPage = lazy(() => import('./NewListingPage'));
const DealPage = lazy(() => import('./components/deal-details/DealPage'));

const SellerDashboardLayoutPage = () => {
    const authHandler = AuthHandler();
    const usersService = UsersService();
    const fundsService = FundsService();
    const listingService = ListingService();
    const userEmailsService = UserEmailsService();

    const { path } = useRouteMatch();
    const location = useLocation();
    const [isShowEmailVerify, setIsShowEmailVerify] = useState(false);
    const [showEducationalModal, setShowEducationModal] = useState(false);

    useEffect(() => {
        usersService.init();
        userEmailsService.init();
        listingService.init();
        fundsService.init();

        const emailVerified = localStorage.getItem(EMAIL_VERIFIED_KEY);
        if (!emailVerified) {
            const userEmail = authHandler.getUserEmail();
            userEmailsService
                .getAll(userEmail)
                .then(response => {
                    const isVerified = response?.data?.userEmails?.find(emailItem => emailItem.email === userEmail)?.verified;
                    if (!isVerified)
                        setIsShowEmailVerify(true);
                    else {
                        localStorage.setItem(EMAIL_VERIFIED_KEY, true);
                        setIsShowEmailVerify(false);
                    }
                });
        }

        return () => {
            usersService.dispose();
            userEmailsService.dispose();
            fundsService.dispose();
            listingService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isShowEmailVerify)
            setShowEducationModal(true);
        else
            setShowEducationModal(false);
    }, [isShowEmailVerify]);

    return (
        <div className="d-flex">
            {!location.pathname.endsWith('/new') && <Sidebar showEducationalModal={showEducationalModal} />}
            {location.state?.showMobileSideBar !== true && (
                <div style={{ marginBottom: 60 }} className="w-100 w-100-sidebar mb-lg-0">
                    {path === location.pathname && <DashboardPage />}
                    <Suspense fallback={
                        <div className="loader">
                            <img alt="loader" className="loader-image" src={`${process.env.PUBLIC_URL}/images/loader-double-ring.gif`} />
                        </div>
                    }>
                        <Switch>
                            <AuthRoute path={`${path}/contacts`} exact component={ContactsPage} />
                            <AuthRoute path={`${path}/contacts/upload-contacts`} component={ContactsUploadPage} />
                            <AuthRoute path={`${path}/tasks`} component={Tasks} />
                            <AuthRoute path={`${path}/chat`} component={ChatSellerPage} />
                            <AuthRoute path={`${path}/profile`} component={Profile} />
                            <AuthRoute path={`${path}/meetings`} component={MeetingsPage} />
                            <AuthRoute path={`${path}/deals`} exact component={DealsPage} />
                            <AuthRoute path={`${path}/deals/:id/edit`} component={EditListingPage} />
                            <AuthRoute path={`${path}/deals/:id/new`} component={NewListingPage} />
                            <AuthRoute path={`${path}/deals/:id`} component={DealPage} />
                            <Redirect from={`${path}/**/`} to="/not-found" />
                        </Switch>
                    </Suspense>
                </div>
            )}

            <VerifyEmailModal isVisible={isShowEmailVerify} />
        </div>
    );
};

export default SellerDashboardLayoutPage;
