import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'components/atoms/Modal';
import PFAAgremeent from 'components/atoms/PFAAgreement';

const PFAModal = ({ isVisible, handleClose, handleAccept }) => {
    const [isAgree, setIsAgree] = useState(false);

    return (
        <Modal
            isVisible={isVisible}
            backdrop
            size={'lg'}
            header={
                <div className="text-center mt-3 pt-3 pb-3 px-5">
                    <span className="icon icon-1k-logo" />
                    <h4 className="ff-i600 c-grey-700 mt-3">Updates to our Buyer Platform Fee Agreement</h4>
                    <h6 className="ff-i500 c-grey-700">Scroll to end to accept and click to agree</h6>
                </div>
            }
            body={
                <div className="custom-scrollbar px-4 px-lg-5 mt-3">
                    <h6 className="c-grey-700 pb-2 ff-i600">1. What is the Buyer Platform Fee Agreement?</h6>
                    <p className="c-grey-700 mb-4">
                        The following agreement specifies, if this introduction results in your purchase, or lease, of the deal, a fee is due to 1Konnection Technologies Inc. upon closing.
                    </p>
                    <h6 className="c-grey-700 pb-2 ff-i600">2. How much is the fee?</h6>
                    <p className="c-grey-700 mb-4">
                        An amount equal to one percent (1%) of the purchase price, or if the event is a lease an amount equal to the price of first month of rent. In no event will the Platform Fee exceed $250,000.
                    </p>
                    <h6 className="c-grey-700 pb-2 ff-i600">3. When is the payment due?</h6>
                    <p className="c-grey-700 mb-4">
                        Payment is due within 24 hours of deal closing. To get more details, contact our support at or <a href="mailto:info@1konnection.com">info@1konnection.com</a>.
                    </p>
                    <PFAAgremeent />
                    <div className="d-flex">
                        <input
                            type="checkbox"
                            id="accept-pfa"
                            className="col-2 me-3"
                            onChange={() => setIsAgree(prev => !prev)}
                            style={{ width: '28px', height: '28px' }} />
                        <label htmlFor="accept-pfa">
                            <p className="c-grey-700 text-lg-center">
                                By clicking on ‘Accept’ button, I agree to
                                <Link className="text-decoration-none" to="/bpfa">&nbsp;Buyer Platform Fee Agreement&nbsp;</Link>
                                and to do business electronically with 1Konnection Technologies, INC.
                            </p>
                        </label>
                    </div>
                </div>
            }
            footer={
                <div className="flex-fill px-4 py-4">
                    <div className="row justify-content-center">
                        <div className="col-6 col-md-4">
                            <button className="btn-secondary ff-i600 w-100 me-2" onClick={handleClose}>
                                Close
                            </button>
                        </div>
                        <div className="col-6 col-md-4">
                            <button
                                color="primary"
                                className="btn-primary ff-i600 h-100 w-100 me-2"
                                disabled={!isAgree}
                                onClick={handleAccept}>
                                Accept
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
    );
};

export default PFAModal;