import { Modal } from 'react-bootstrap';
import FormTask from 'components/atoms/form-task/FormTask';
const AddTask = ({ isVisible, handleClose, formik, dealOptions, leadOptions }) => {
    return (
        <Modal show={isVisible} centered onHide={handleClose} size="lg">
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header className="px-4 pt-4" closeButton>
                    <Modal.Title>
                        <h4 className="c-grey-700">Add Task</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <FormTask formik={formik} dealOptions={dealOptions} leadOptions={leadOptions} />
                </Modal.Body>
                <Modal.Footer className="py-4">
                    <div className="flex-fill">
                        <div className="row justify-content-center">
                            <div className="col-6 col-md-4">
                                <button type={'reset'} className="btn-secondary ff-i600 w-100 me-2" onClick={handleClose}>
                                    Cancel
                                </button>
                            </div>
                            <div className="col-6 col-md-4">
                                <button
                                    type={'submit'}
                                    color="primary"
                                    disabled={
                                        !formik.isValid ||
                                        Object.values(formik.touched).length === 0
                                    }
                                    className="btn-primary ff-i600 w-100 me-2">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default AddTask;
