import axios from 'axios';
import { getCache, traceError } from 'services/common/response-handlers';

function UsersService() {
    let controller = null;
    const init = () => (controller = new AbortController());
    const dispose = () => {
        if (controller) controller.abort();
        controller = null;
    };
    const config = () => (controller && controller.signal ? { signal: controller.signal } : null);

    const get = id => axios.get(`/users/${id}`, config()).catch(error => traceError(error, `/users/${id}`, 'GET'));

    const getByEmail = email =>
        axios.get(`/users?email=${email}`, config()).catch(error => traceError(error, `/users/${id}`, 'GET'));

    const getStats = () => axios.get('/users/stats', config()).catch(error => traceError(error, '/users/stats', 'GET'));

    const changePassword = ({ oldPassword, newPassword }) =>
        axios
            .patch('/users/password', { oldPassword, newPassword }, config())
            .catch(error => traceError(error, '/users/password', 'PATCH'));

    const setNewPassword = newPassword =>
        axios
            .patch('/users/change-password', { newPassword }, config())
            .catch(error => traceError(error, '/users/change-password', 'PATCH'));

    const update = (id, { firstName, lastName, phone, CountryCode }) =>
        axios
            .patch(`/users/${id}/primary`, { firstName, lastName, phone, CountryCode }, config())
            .catch(error => traceError(error, `/users/${id}/primary`, 'PATCH'));

    const updateJob = (id, { companyName, jobTitle, companyWebsiteLink, linkedInProfileLink }) =>
        axios
            .patch(`/users/${id}/company`, { companyName, jobTitle, companyWebsiteLink, linkedInProfileLink }, config())
            .catch(error => traceError(error, `/users/${id}/company`, 'PATCH'));

    const getTypes = () => getCache('/users/types', controller);

    const getCommunities = id =>
        axios
            .get(`/users/${id}/communities`, config())
            .catch(error => traceError(error, `/users/${id}/communities`, 'GET'));

    const addCommunity = (id, { quantity, assetTypeIds, stateIds }) =>
        axios
            .post(`/users/${id}/communities`, { quantity, assetTypeIds, stateIds }, config())
            .catch(error => traceError(error, `/users/${id}/communities`, 'POST'));

    const updateCommunity = (id, communityId, { quantity, assetTypeIds, stateIds }) =>
        axios
            .put(`/users/${id}/communities/${communityId}`, { quantity, assetTypeIds, stateIds }, config())
            .catch(error => traceError(error, `/users/${id}/communities/${communityId}`, 'PUT'));

    const deleteCommunity = (id, communityId) =>
        axios
            .delete(`/users/${id}/communities/${communityId}`, config())
            .catch(error => traceError(error, `/users/${id}/communities/${communityId}`, 'DELETE'));

    const getMetadata = email =>
        axios
            .get(`/users/metadata?email=${email}`, config())
            .catch(error => traceError(error, '/users/metadata', 'GET'));

    const patchMetadata = (id, { noEmails, isShadow, dealsLimit }) =>
        axios
            .patch(`/users/${id}/metadata`, { noEmails, isShadow, dealsLimit }, config())
            .catch(error => traceError(error, `/users/${id}/metadata`, 'PATCH'));

    const getIsShadow = () =>
        axios.get('/users/is-shadow', config()).catch(error => traceError(error, '/users/is-shadow', 'GET'));

    const getAgreementAccepted = () =>
        axios
            .get(`/users/agreement-accepted`, config())
            .catch(error => traceError(error, '/users/agreement-accepted', 'GET'));

    const patchAgreementAccepted = ({ agreementAccepted }) =>
        axios
            .patch(`/users/agreement-accepted`, { agreementAccepted }, config())
            .catch(error => traceError(error, `/users/agreement-accepted`, 'PATCH'));

    const getSellerTypes = () => getCache('/users/seller-types', controller);

    const getBuyerTypes = () => getCache('/users/buyer-types', controller);

    const getSystemChannelsByEmail = email =>
        axios
            .get(`/users/system-channels-url?email=${email}`, config())
            .catch(error => traceError(error, '/users/system-channels-url', 'GET'));

    const getSystemChannelsByName = name =>
        axios
            .get(`/users/system-channels-url?name=${name}`, config())
            .catch(error => traceError(error, '/users/system-channels-url', 'GET'));

    return {
        init,
        dispose,
        get,
        getByEmail,
        getStats,
        changePassword,
        setNewPassword,
        update,
        updateJob,
        getTypes,
        getCommunities,
        addCommunity,
        updateCommunity,
        deleteCommunity,
        getMetadata,
        patchMetadata,
        getIsShadow,
        getAgreementAccepted,
        patchAgreementAccepted,
        getSellerTypes,
        getBuyerTypes,
        getSystemChannelsByEmail,
        getSystemChannelsByName,
    };
}

export default UsersService;
