const toSelectOption =
    ({ valueKey, labelKey }) =>
    source => {
        const value = source[valueKey];
        const label = source[labelKey];

        return {
            value,
            label,
            isDisabled: !!source['isDisabled'],
            source,
        };
    };

export default toSelectOption;
