import { formatDateDifference, toUtcDate } from 'helpers/Time';

const Notification = ({ notification, first, last }) => {
    return (
        <div className={`d-flex flex-wrap bc-white justify-content-between w-100 p-3 border-grey-200
        ${first ? 'border-radius-top-md' : ''}
        ${last ? 'border-radius-bottom-md border-bottom-grey-200' : 'border-bottom-0'}`}>
            <div className='d-flex w-75 align-items-center'>
                <div style={{ width: '25px' }}>
                    {!notification?.processed && <span className={`dot bc-blue-500`}></span>}
                </div>
                <div className='c-grey-700 ff-i500'>{notification.message}</div>
            </div>
            <div className='d-flex align-items-center text-s c-grey-450'>
                {formatDateDifference(new Date(notification.createdDate), toUtcDate(new Date()))}
            </div>
        </div>
    );
}

export default Notification;