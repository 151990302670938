import { useMemo } from 'react';

const UserChannelsWrapper = ({
    name,
    channels,
    userId,
    currentChannelUrl,
    onChannelSelect,
}) => {

    const activeChannelWrapper = useMemo(
        () => channels.some(x => x.channel_url === currentChannelUrl),
        [channels, currentChannelUrl]
    );

    const unreadChannelsCount = channels[0]?.last_message?.user?.user_id !== userId && channels[0]?.last_message?.type !== 'ADMM';

    return (
        <>
            <div
                onClick={() => { onChannelSelect(channels[0]?.channel_url); }}
                className={`d-flex align-items-center position-relative p-2 c-grey-900 border-bottom-grey-200 cursor-pointer ${activeChannelWrapper === true ? 'bc-blue-100' : ''}`}>
                <span className={`text-break ff-i600`}>{name}</span>
                {unreadChannelsCount && (
                    <span
                        className="bc-blue-500 ms-auto"
                        style={unreadChannelsLabel}>
                    </span>
                )}
            </div>
        </>
    );
};

const unreadChannelsLabel = {
    borderRadius: '100vmax',
    height: '10px',
    aspectRatio: '1',
};

export default UserChannelsWrapper;