import { useEffect, useRef } from 'react';

const Toast = ({ show, setShow, title, body, type, dismissTime }) => {
    const toastRef = useRef();

    requestAnimationFrame(() => {
        toastRef?.current?.classList?.toggle('show', true);
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            toastRef?.current?.classList?.toggle('show', false);
        }, dismissTime - 500);
        return () => clearTimeout(timer);
    }, [dismissTime]);

    const closeToast = () => {
        toastRef?.current?.classList?.toggle('show', false);

        setTimeout(function () {
            setShow(false)
        }, 500);
    }

    return (
        <>
            {show && (
                <div ref={toastRef} className={`w-100 toast-notification pt-4 pb-2 px-3 ps-lg-0 pe-lg-4`}>
                    <div className={`d-flex justify-content-between py-3 border-radius cursor-pointer ${type}`}
                        onClick={() => closeToast()}>
                        <div className='d-flex align-items-center'>
                            <span className={`icon icon-${type} ps-4 pe-3`}></span>
                            <div>
                                <h6 className='title mb-1'>{title}</h6>
                                <span className='text-s c-grey-550'>{body}</span>
                            </div>
                        </div>
                        <div>
                            <span className='icon icon-cancel px-2' style={{ height: '30px', marginTop: '-10px' }}></span>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Toast;