import React from 'react';
import SelectComponent from 'react-select';
import defineCustomStyles from './helpers/defineCustomStyles';
import useSelect from './hooks/useSelect';
import defineColorForHelperText from './helpers/defineColorForHelperText';
import DropdownIndicator from './components/DropdownIndicator';
import Option from './components/Option';
import ControlComponent from './components/ControlComponent';

const Select = ({
    value,
    onChange,
    isError,
    helperText,
    placeholder,
    isDisabled,
    options,
    isInheritHeight,
    icon,
    label,
    onInputChange,
    isClearable,
    isLoading,
    keys: { valueKey, labelKey },
    controlText,
}) => {
    const { selectValue, selectChangeHandler, selectOptions } = useSelect({
        value,
        onChange,
        options,
        labelKey,
        valueKey,
    });

    return (
        <div className={`position-relative w-100 ${isInheritHeight ? '' : 'pb-4'}`}>
            <div className="position-relative">
                {label && <span className="mb-1 ff-i600 c-grey-700">{label}</span>}
                <div
                    className={`${icon && 'position-absolute'} h-100 d-flex align-items-center justify-content-center`}
                    style={{ paddingLeft: 18 }}
                >
                    <span className={`${icon} icon`} style={{ width: 20 }} />
                </div>
                <SelectComponent
                    value={selectValue}
                    isDisabled={isDisabled}
                    options={selectOptions}
                    components={{
                        DropdownIndicator,
                        Option,
                        Control: props => ControlComponent({ props, isIcon: !!icon, controlText }),
                    }}
                    styles={defineCustomStyles({
                        isError,
                        isDisabled,
                        isIcon: !!icon,
                        isControlText: !!controlText,
                    })}
                    placeholder={placeholder}
                    onChange={selectChangeHandler}
                    onInputChange={onInputChange}
                    isClearable={isClearable}
                    isLoading={isLoading}
                />
            </div>
            <span
                className={`
                ${defineColorForHelperText({ isError, isDisabled })}
                ${isInheritHeight ? '' : 'position-absolute bottom-0 ps-3'}
                 ff-i500
                 `}
                style={{ fontSize: 14 }}
            >
                {helperText}
            </span>
        </div>
    );
};

export default Select;
