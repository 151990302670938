import { useState } from 'react';
import AuthHandler from 'services/common/auth-handler';
import AuthService from 'services/auth-service';
import { IS_SHADOW_KEY } from 'hooks/useLocalStorage';
import useToast from 'hooks/useToast';
import useLocalStorage from 'hooks/useLocalStorage';
import UsersService from 'services/users-service';

const authHandler = AuthHandler();
const authService = AuthService();
const usersService = UsersService();

const UserSimulation = () => {
    const { toast } = useToast();
    const { clearStorage } = useLocalStorage();
    const [userEmail, setUserEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [simulationMode, setSimulationMode] = useState(authHandler.isSimulationEnabled());

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const getEmailPlaceholder = () => simulationMode ? authHandler.getUserEmail() : 'Enter user email';
    const getIdPlaceholder = () => simulationMode ? authHandler.getUserId() : 'Enter user id';

    const onModeChange = enable => {
        if (enable) {
            if (!userEmail && !userId) return;
            authService
                .simulation({ userEmail: (emailRegex.test(userEmail) && userEmail), userId })
                .then(response => {
                    authHandler.simulate(response.data.token);
                    setSimulationMode(prev => !prev);
                    setUserEmail(authHandler.getUserEmail());
                    setUserId(authHandler.getUserId());
                    clearStorage();
                    usersService.getIsShadow()
                        .then(response => response.data.isShadow && localStorage.setItem(IS_SHADOW_KEY, true));
                    toast.success({ body: `You are now simulating user ${authHandler.getUserEmail()}` });
                })
                .catch(error => toast.handleError(error));
        }
        else {
            toast.success({ body: `Simulation of user ${authHandler.getUserEmail()} has stopped` })
            authHandler.simulate();
            setSimulationMode(prev => !prev);
            setUserEmail('');
            setUserId('');
            clearStorage();
            usersService.getIsShadow()
                .then(response => response.data.isShadow && localStorage.setItem(IS_SHADOW_KEY, true));
        }
    }

    return (
        <div className="p-3">
            <div className="row gx-3">
                <div className="col-12">
                    <div className="px-3 px-lg-5 py-4">
                        <h4 className="c-grey-700">User Simulation</h4>
                        <p className="c-grey-550 ff-i600 pb-3" style={{ fontSize: "16px" }}>
                            Enter either user e-mail or id and click toogle in order to enable simulation mode.
                        </p>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <input
                                    className={`hide-arrows w-100 c-grey-700 ff-i500 border-grey border-radius-sm p-3 mb-3`}
                                    placeholder={getEmailPlaceholder()}
                                    disabled={simulationMode}
                                    value={userEmail}
                                    onChange={e => setUserEmail(e.target.value)} />
                                <input
                                    className="hide-arrows w-100 c-grey-700 ff-i500 border-grey border-radius-sm p-3"
                                    placeholder={getIdPlaceholder()}
                                    disabled={simulationMode}
                                    value={userId}
                                    type="number"
                                    onChange={e => setUserId(e.target.value)} />
                            </div>
                            <div className="col-12 col-lg-6 mt-3 mt-lg-0 d-flex justify-content-center align-items-center">
                                <span>
                                    <label className="custom-switch ms-2">
                                        <input
                                            onChange={e => onModeChange(e.target.checked)}
                                            type="checkbox" checked={simulationMode} />
                                        <span className="slider"></span>
                                    </label>
                                    {simulationMode
                                        ? <span className="c-green-500 align-text-top ms-2">ON</span>
                                        : <span className="c-red align-text-top ms-2">OFF</span>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserSimulation;

